import { Type } from '@angular/core';
import { JsonLd } from '@techniek-team/class-transformer';
import { PreFetchServiceInterface } from './pre-fetch/pre-fetch-service.interface';
import { CacheServiceFetchStorage } from './storage/cache-service.fetch-storage';
import { FetchStorageInterface } from './storage/fetch-storage.interface';

export class TtFetchConfig {
  /**
   * The base url from where to retrieve the Resource when missing from the cache.
   *
   * The decorator appends the jsonLd iri to this baseUrl
   */
  public baseUrl?: string;

  /**
   * This method are triggered immediately after initialization of this service.
   *
   * The implementation is something the needs to be done manually including adding
   * it to the cache. For example like this. IMPORTANT NOTE: the key that should be added
   * to the {@see CacheService} should equal the Name of the class model.
   *
   * @example
   * ```typescript
   *   public getLocations(): Observable<Hydra<LocationModel>> {
   *     const url: string = `${environment.schedulerApi}/v3/locations`;
   *     const headers: HttpHeaders = new HttpHeaders({ accept: 'application/ld+json' });
   *
   *     const call: Observable<Hydra<LocationModel>> = this.httpClient.get<Collection<GetLocationsResponse>>(
   *       url,
   *       { headers: headers },
   *     ).pipe(
   *       map(response => serialize(LocationModel, response)),
   *     );
   *
   *     return this.cacheService.create(
   *       (new LocationModel()).getClassName(),
   *       call,
   *       { refreshInterval: hoursToMilliseconds(3) },
   *     );
   *   }
   * ```
   */
  public preFetchService?: Type<PreFetchServiceInterface>;

  /**
   * When true the Decorator and service output debug messages to the console.
   */
  public debug: boolean = false;

  public storageServices?: Type<FetchStorageInterface<object, JsonLd>>[] = [
    CacheServiceFetchStorage,
  ];
}

import { FilterGroup, FilterGroupOptions } from '../../models/filter/filter-group.model';
import { Filter } from '../../models/filter/filter.model';

/**
 * Helper function which converts one (possibly two) enums info a {@see FilterGroup }
 *
 * @param groupValue value which if use for the FilterGroup. { @see FilterGroup.value }
 * @param filters key-pair object or enum which contains a list of Filters. The key is used to set the
 *     {@see Filter.value} and the value is used to set {@see Filter.label } if labels is not set.
 * @param groupValue Optional string which is used to set {@see FilterGroup.label}
 * @param labels Optional key-pair object or enum where the key exist as value with in object given in the
 *   filters parameter. The value of the object is set in { @see Filter.displayValue }
 * @param excludeKey Optional array of keys(string) each keys from the filters parameter which exists in this array wil
 *                  be omitted from the FilterGroup
 * @param options Optional parameter to set the {@see FilterGroup.options } property of the created FilterGroup
 */
export function createFilterGroupFromEnum(
  groupValue: string,
  filters: object,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  labels?: Record<string, any>,
  excludeKey?: string[],
  options?: FilterGroupOptions,
): FilterGroup {
  const filtersList: Filter[] = [];
  for (let [key, value] of Object.entries(filters)) {
    if (excludeKey && excludeKey.includes(key)) {
      continue;
    }
    let label: string = value;
    if (labels) {
      label = labels[value];
    }
    let filter: Filter = new Filter(key, { label: label });
    filtersList.push(filter);
  }
  return new FilterGroup(groupValue, filtersList, options);
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TtSubMenuComponent } from './sub-menu/tt-sub-menu.component';
import { TtMenuItemComponent } from './tt-menu-item/tt-menu-item.component';
import { TtMenuComponent } from './tt-menu.component';
import { TtSubMenuItemComponent } from './tt-sub-menu-item/tt-sub-menu-item.component';

@NgModule({
  declarations: [
    TtMenuComponent,
    TtSubMenuComponent,
    TtMenuItemComponent,
    TtSubMenuItemComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule,
    CommonModule,
    RouterModule,
    IonicStorageModule,
    FontAwesomeModule,
  ],
  exports: [
    TtMenuComponent,
    TtMenuItemComponent,
    TtSubMenuItemComponent,
  ],
})
export class TtMenuModule {
}

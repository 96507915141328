import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageColor } from '@school-dashboard/enums';
import { denormalize } from '@techniek-team/class-transformer';
import { TtDataSourceFeedbackService } from '@techniek-team/datasource';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ResponseMessageKey, ResponseMessageState } from './response-message.enum';
import { ResponseMessage, ResponseMessageMarkup } from './response-message.model';

export type ResponseOrMessageKey =
  | ResponseMessageKey
  | ResponseMessage
  | (ResponseMessageKey | ResponseMessage)[];
type MessageMap = Map<string, ResponseMessage>;

@Injectable({
  providedIn: 'root',
})
export class ResponseMessageService implements TtDataSourceFeedbackService {
  /**
   * Subject containing the map with messages.
   */
  private messageMap$: BehaviorSubject<MessageMap> = new BehaviorSubject<MessageMap>(new Map());

  /**
   * Internal map containing active dismiss timers.
   */
  private timers: Map<string, Subscription> = new Map();

  /**
   * Returns the observable stream with the mapped list of messages.
   */
  public get messages$(): Observable<ResponseMessage[]> {
    return this.messageMap$.asObservable().pipe(
      map((messageMap) => [...messageMap.values()]),
      distinctUntilChanged((responseMessagesA, responseMessagesB) => {
        if (responseMessagesA.length !== responseMessagesB.length) {
          return false;
        }

        return (
          JSON.stringify(responseMessagesA)
          === JSON.stringify(responseMessagesB)
        );
      }),
    );
  }

  /**
   * Add a new message or update an existing message to the list of response
   * messages. Supply each parameter or a ResponseMessage instance.
   * If you supply a ResponseMessage instance, the second parameter will be the
   * optional duration of the message before it is marked as read. Otherwise,
   * the `duration` parameter is used.
   */
  public setMessage(
    message: ResponseMessageKey | ResponseMessage,
    typeOrDuration?: number | MessageColor,
    value?: string | ResponseMessageMarkup[],
    duration?: number,
  ): ResponseMessage {
    const messageMap: MessageMap = this.messageMap$.getValue();
    const instance: ResponseMessage = message instanceof ResponseMessage ? message : denormalize(
      ResponseMessage, {
        key: message,
        type: typeOrDuration,
        message: value,
      },
    );

    messageMap.set(instance.key, instance);
    this.messageMap$.next(messageMap);
    this.createDismissTimer(
      instance.key,
      typeof typeOrDuration === 'number' ? typeOrDuration : duration,
    );
    return instance;
  }

  /**
   * Get the ResponseMessage instance for the given key.
   */
  public getMessage(key: ResponseMessageKey | string): ResponseMessage {
    return this.messageMap$.getValue().get(key) as ResponseMessage;
  }

  /**
   * Clear one or more messages, as string or ResponseMessage instance, or a
   * combination thereof.
   */
  public clearMessage(message: ResponseOrMessageKey): void {
    const messageMap: MessageMap = this.messageMap$.getValue();
    const messages: ResponseOrMessageKey = Array.isArray(message) ? message : [message];
    for (const item of messages) {
      messageMap.delete(item instanceof ResponseMessage ? item.key : item);
    }
    this.messageMap$.next(messageMap);
  }

  /**
   * TtDataSourceFeedbackService error handling implementation.
   */
  public error(message: string, error: Error | HttpErrorResponse): void {
    this.setMessage(ResponseMessageKey.DATASOURCE, MessageColor.DANGER, [
      {
        value: (error as HttpErrorResponse)?.status + ':',
        styleBold: true,
      },
      {
        value: 'Er is iets misgegaan bij het ophalen van de resultaten.',
      },
    ]);
  }

  /**
   * Returns a boolean stream for the given string or ResponseMessage (list) and
   * checks if any of them are active.
   */
  public isActive(identifier: ResponseOrMessageKey): Observable<boolean> {
    return this.messageMap$.pipe(
      map((messageMap) => {
        const messages: ResponseOrMessageKey = Array.isArray(identifier) ? identifier : [identifier];
        for (const message of messages) {
          const key: string = message instanceof ResponseMessage ? message.key : message;
          const response: ResponseMessage | undefined = messageMap.get(key);
          if (response && response.state === ResponseMessageState.ACTIVE) {
            return true;
          }
        }

        return false;
      }),
    );
  }

  /**
   * Create or restart a timer for marking a message as read automatically.
   */
  private createDismissTimer(
    key: string,
    duration: number | undefined | MessageColor,
  ): void {
    if (typeof duration !== 'number') {
      return;
    }
    // To restart a timer, unsubscribe from the active one if it exists.
    const existing: Subscription | undefined = this.timers.get(key);
    if (existing) {
      existing.unsubscribe();
    }
    // Create an observable via timer() that fires after n-milliseconds.
    this.timers.set(
      key,
      timer(duration).subscribe(() => {
        const messageMap: MessageMap = this.messageMap$.getValue();
        const message: ResponseMessage = messageMap.get(key) as ResponseMessage;
        if (message) {
          // Make sure the message actually exists.
          message.markAsRead();
        }
      }),
    );
  }
}

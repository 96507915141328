import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LOCATIONS_FEATURE_KEY, LocationsState, locationsAdapter } from './locations.reducer';

// Lookup the 'Locations' feature state managed by NgRx
const { selectAll, selectEntities } = locationsAdapter.getSelectors();

export class LocationsSelectors {
  public static readonly selectLocationsState = createFeatureSelector<LocationsState>(LOCATIONS_FEATURE_KEY);

  public static readonly locationsLoading = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => state.loading,
  );

  public static readonly locationsLoaded = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => state.loaded,
  );

  public static readonly locationsInitialized = createSelector(
    LocationsSelectors.selectLocationsState,
    (state) => (state.loaded || state.loading),
  );

  public static readonly locationsError = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => state.error,
  );

  public static readonly allLocations = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => selectAll(state),
  );

  public static readonly locationsEntities = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => selectEntities(state),
  );

  public static readonly activeLocationId = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => state.selectedId,
  );

  public static readonly activeLocation = createSelector(
    LocationsSelectors.locationsEntities,
    LocationsSelectors.activeLocationId,
    (entities, selectedId) => {
      return (selectedId ? entities[selectedId] : undefined);
    },
  );

  public static readonly count = createSelector(
    LocationsSelectors.allLocations,
    (locations) => locations?.length ?? 0,
  );
}

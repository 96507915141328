<ng-container *ngIf="(_data$ | async) as data; else loading">
  <app-school-dashboard-modal [actionButtonText]="data.progressModal?.actionButtonText"
                              [cancelButtonText]="data.progressModal?.closeButtonText"
                              [closeIcon]="data.progressModal?.closeIcon"
                              (action)="dialogAction($event, data)">
    <div position-center
         class="content"
         [class.danger]="data.type === ProgressNotificationType.DANGER"
         [class.success]="data.type === ProgressNotificationType.SUCCESS"
         [class.in-progress]="data.type === ProgressNotificationType.IN_PROGRESS">
      <div class="icon-container">
        <ng-container [ngSwitch]="data.type">
          <fa-icon *ngSwitchCase="ProgressNotificationType.DANGER" [icon]="['fas', 'exclamation']"></fa-icon>
          <fa-icon *ngSwitchCase="ProgressNotificationType.SUCCESS" [icon]="['fas', 'check']"></fa-icon>
          <app-progress-donut *ngSwitchCase="ProgressNotificationType.IN_PROGRESS"
                              [size]="180"
                              [strokeWidth]="10"
                              [value]="data.percentageCompleted"
                              [text]="data.progressText"
                              [showPercentage]="true">
          </app-progress-donut>
        </ng-container>
      </div>

      <app-colored-title color="dark">{{ data.mainText }}</app-colored-title>
      <span class="explanation" [innerHTML]="data.explanation"></span>
    </div>
  </app-school-dashboard-modal>
</ng-container>

<ng-template #loading>
  <app-school-dashboard-modal>
    <div position-center class="content loading">
      <div class="icon-container">
        <ion-skeleton-text></ion-skeleton-text>
      </div>

      <app-colored-title [isLoading]="true"></app-colored-title>
      <div class="explanation">
        <ion-skeleton-text animated></ion-skeleton-text>
        <ion-skeleton-text animated></ion-skeleton-text>
        <ion-skeleton-text animated></ion-skeleton-text>
      </div>
    </div>
  </app-school-dashboard-modal>
</ng-template>

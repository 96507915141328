import { Injector } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { PupilUploadError } from '@school-dashboard/models';
import { simpleCompare } from '@techniek-team/common';
import { TtInfiniteScrollPaginationDatasource } from '@techniek-team/datasource';
import { Filter, FilterGroup } from '@techniek-team/filter-search';
import { TtSearchFormData } from '@techniek-team/search';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, share, startWith } from 'rxjs/operators';

enum UploadFilters {
  TYPE = 'type',
}

/**
 * Datasource assigned to the PupilUpload flow.
 */
export class UploadFeedbackDataSource extends TtInfiniteScrollPaginationDatasource<PupilUploadError> {
  constructor(
    protected override injector: Injector,
    protected override resourceUrl: string,
    protected valueChanges: Observable<TtSearchFormData>,
  ) {
    super(injector, PupilUploadError, resourceUrl);
  }

  /**
   * Return the list of PupilUploadError instances instead of the main object
   * instance.
   */
  public override get observer$(): Observable<PupilUploadError[]> {
    // Base Sort value so the sorting does at least _something_.
    const baseSort: Sort = {
      active: 'row',
      direction: 'asc',
    } as unknown as Sort;
    // If no matSort is present at creation time, fallback to an `of`.
    const sortObservable: Observable<Sort> = this.sort?.sortChange.pipe(startWith(baseSort)) ?? of(baseSort);

    return combineLatest([super.observer$, sortObservable]).pipe(
      filter(([results]) => !!results && results[0] !== null),
      map(([results, sorting]: [PupilUploadError[], Sort]) => {
        if (!sorting?.active || sorting?.direction === '') {
          return results;
        }
        // Shallow copy so original array isn't sorted.
        return this.sortResults([...results], sorting);
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }

  /**
   * @inheritDoc
   */
  public override init(): this {
    this.filterControllerService.filterGroups = this.createFilters();
    // Still ignoring matSort for use in http params.
    super.init(true);
    return this;
  }

  /**
   * Sort the given PupilUploadErrors by the key and direction given in the Sort
   * object, or return the default filtering.
   */
  private sortResults(
    results: PupilUploadError[],
    sorting: Sort,
  ): PupilUploadError[] {
    const asc: boolean = sorting.direction === 'asc';
    switch (sorting.active) {
      case 'row':
        return results.sort((a, b) =>
          simpleCompare(a.rowNumber, b.rowNumber, asc),
        );
      case 'fullName':
        return results.sort((a, b) =>
          simpleCompare(a.row.firstName, b.row.firstName, asc),
        );
      case 'email':
        return results.sort((a, b) =>
          simpleCompare(a.row.emailAddress, b.row.emailAddress, asc),
        );
      case 'message':
        return results.sort((a, b) => simpleCompare(a.type, b.type, asc));
      default:
        return results;
    }
  }

  /**
   * Create a few filters to limit the scope of the error messages.
   */
  private createFilters(): FilterGroup[] {
    return [
      new FilterGroup(UploadFilters.TYPE, this.createMessageTypeObservable(), {
        multiple: false,
      }),
    ];
  }

  /**
   * Convert the PupilUpload to a set of Filters.
   */
  private createMessageTypeObservable(): Observable<Filter[]> {
    return super.observer$.pipe(
      filter((results) => results[0] !== null),
      map((exceptions: PupilUploadError[]) =>
        exceptions.map((exception) => new Filter(exception?.type)),
      ),
      map((filters) => [
        new Filter('ALL', { label: 'Alle meldingen' }),
        ...filters,
      ]),
    );
  }
}

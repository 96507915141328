export enum SummerSchoolTrackVariant {
  PAIRS = 'PAIRS',
  WEEK = 'WEEK',
  SUMMER = 'SUMMER',
}

export enum PractiseTrackVariant {
  METHOD = 'METHOD',
  EXAM = 'EXAM',
}

export enum HomiLicenseTrackVariant {
  PUPIL_LICENSE = 'PUPIL_LICENSE',
  SCHOOL_LICENSE = 'SCHOOL_LICENSE',
}

export enum PractiseTrackVariantDisplayValues {
  METHOD = 'Lyceo Examen Oefenen',
  EXAM = 'Lyceo Oefenen',
}

export enum PractiseTrackVariantSafeUrl {
  METHOD = 'lyceo-examen-oefenen',
  EXAM = 'lyceo-oefenen',
}

export enum PractiseTrackVariantSafeUrlToEnum {
  //eslint-disable-next-line @typescript-eslint/naming-convention
  'lyceo-examen-oefenen' = 'METHOD',
  //eslint-disable-next-line @typescript-eslint/naming-convention
  'lyceo-oefenen' = 'EXAM',
}

export enum OnderwijsheldenTrackVariant {
  CLUSTERBEGELEIDING = 'CLUSTERBEGELEIDING',
  HUISWERKKLAS = 'HUISWERKKLAS',
  LESOPVANG = 'LESOPVANG',
  ONDERWIJSASSISTENT = 'ONDERWIJSASSISTENT',
  PAL = 'PAL',
  PROFIELWERKSTUKBEGELEIDING = 'PROFIELWERKSTUKBEGELEIDING',
  RESERVEPOULE = 'RESERVEPOULE',
  SECTIEONDERSTEUNING = 'SECTIEONDERSTEUNING',
  SURVEILLEREN = 'SURVEILLEREN',
}

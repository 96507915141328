import { Component, Input } from '@angular/core';
import { ModalContentInterface } from '../../../interfaces/modal-content.interface';

@Component({
  selector: 'app-person-products',
  templateUrl: './person-products.component.html',
  styleUrls: ['./person-products.component.scss'],
})
export class PersonProductsComponent implements ModalContentInterface {
  /**
   * The IRI of the pupil has been passed to the modal.
   */
  @Input() public activeIri: string;
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef, HostListener,
  Injector,
  Input,
  OnInit, TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { denormalize } from '@techniek-team/class-transformer';
import { fixFormControlMarkAs } from '@techniek-team/common';
import { AutoCompleteFilterGroup, Filter, FilterInterface } from '@techniek-team/filter-search';
import { IonColor, IonColorType } from '@techniek-team/lyceo-style';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { NoMultiSelectionError } from './errors/no-multi-selection.error';
import { OptionInterface } from './models/option.interface';
import { Option } from './models/option.model';

type OnChangeCallback = (data: TtAutocompleteChipControl) => void;
type OnTouchCallback = (touched: boolean) => void;

export interface TtAutocompleteChipControl {
  filters: Filter[];
  values: string[];
}

/**
 * Form control with an input with auto complete functionality. Selected items
 * are shown as buttons similar to the ToggleSelectComponent. Validation is
 * done outside of this FormControl, since the FormGroup this control is part of
 * should handle these things.
 *
 * - `source` input expects a list of SearchFilter items.
 * - `multiple` input toggles multiple select on or off (true by default).
 * - `placeholder` input is what is shown as placeholder string in the auto
 * complete input field.
 *
 * @example
 * const source = [
 *  {
 *    label: 'yes',
 *    value: 0,
 *  },
 *  {
 *    label: 'no',
 *    value: 1,
 *  }
 * ].map((item) => new SearchFilter(item));
 *
 * <app-autocomplete-select
 *  [source]="source"
 *  [multiple]="false"
 *  [placeholder]="filterGroup.placeholder"
 *  formControlName="youAutocompleteMe">
 * </app-autocomplete-select>
 */
@Component({
  selector: 'tt-autocomplete-chip-control',
  templateUrl: './tt-autocomplete-chip-control.component.html',
  styleUrls: ['./tt-autocomplete-chip-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TtAutocompleteChipControlComponent),
      multi: true,
    },
  ],
})
export class TtAutocompleteChipControlComponent implements ControlValueAccessor, OnInit, AfterViewInit {

  /**
   * View injection. This is needed to empty the input. (emptying formControl
   * isn't enough)
   */
  @ViewChild('searchStringInput') public searchStringInput: ElementRef<HTMLInputElement> | undefined;

  /**
   * Optional TemplateRef to use instead of the default content for the options
   * in the autocomplete list.
   */
  @Input() public optionRef: TemplateRef<unknown> | undefined;

  /**
   * The standard button color used.
   */
  @Input() public color: IonColor = IonColorType.PRIMARY;

  /**
   * Setter for the selectable chips (options) for this formControl.
   */
  @Input()
  public set source(source: (Option | OptionInterface | Filter | FilterInterface)[] | AutoCompleteFilterGroup) {
    if (source instanceof AutoCompleteFilterGroup) {
      this._source = source;
      this.onSourceChange$.next();
      if (source.allowUnlisted) {
        this.createCustomFilterItemSubscriber();
      }
      return;
    }

    if (!Array.isArray(source)) {
      return;
    }

    const filters: Filter[] = [];
    for (let item of source) {
      if (item instanceof Filter || item instanceof Option) {
        filters.push(item);
        continue;
      }

      filters.push(denormalize(Filter, item));
    }
    this._source.filters = filters;
  }

  /**
   * Allow multiple options to be selected (default: false).
   */
  @Input() public set multiple(multiple: boolean) {
    this._source.multiple = multiple;
  }

  /**
   * Placeholder value to display in the autocomplete input field.
   */
  @Input() public set placeholder(placeholder: string) {
    this._source.autoCompletePlaceholder = placeholder;
  }

  /**
   * Toggle the switch between the selected value and the input field when
   * selecting an item. Only works if multiple is disabled.
   */
  @Input() public replace: boolean | undefined;

  /**
   * Options used in template, converted from the input to make sure it's
   * always an array.
   */
  public _source: AutoCompleteFilterGroup = new AutoCompleteFilterGroup(window.crypto.randomUUID());

  /**
   * FormControl for the autocomplete input. Used to filter the results based
   * on the active input.
   */
  public _autocompleteFormControl: UntypedFormControl = new UntypedFormControl();

  /**
   * @inheritDoc
   */
  public onChange: OnChangeCallback = (_data: TtAutocompleteChipControl) => { /* callback */ };

  /**
   * @inheritDoc
   */
  public onTouch: OnTouchCallback = (_touched: boolean) => { /* callback */ };

  /**
   * Subject which completes all hot observers on emit.
   */
  private onDestroy$: Subject<void> = new Subject<void>();

  /**
   * Subject which completes when the source property get set.
   */
  private onSourceChange$: Subject<void> = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private injector: Injector,
  ) {
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.createSearchQueryObserver();
    this.createOnChangeObserver();
  }

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    fixFormControlMarkAs(this.injector, this._autocompleteFormControl, this.elementRef);
  }

  /**
   * @inheritDoc
   */
  public registerOnChange(fn: OnChangeCallback): void {
    this.onChange = fn;
  }

  /**
   * @inheritDoc
   */
  public registerOnTouched(fn: OnTouchCallback): void {
    this.onTouch = fn;
  }

  /**
   * @inheritDoc
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this._source.disable();
      this._autocompleteFormControl.disable();
      return;
    }
    this._source.enable();
    this._autocompleteFormControl.enable();
  }

  /**
   * @inheritDoc
   */
  public writeValue(data: (Filter | unknown)[] | Filter | unknown | null): void {
    if (data === null) {
      this.onTouch(false);
      this._source.clear();
      return;
    }

    if (Array.isArray(data) && data.length > 1 && !this.multiple) {
      throw new NoMultiSelectionError();
    }

    if (!Array.isArray(data)) {
      this._source.select(data);
      return;
    }

    for (let item of data) {
      this._source.select(item);
    }
  }

  /**
   * Returns true when multiple options may be selected.
   */
  public get multiple(): boolean {
    return this._source.multiple;
  }

  /**
   * Return the Placeholder value to display in the autocomplete input field.
   */
  public get placeholder(): string {
    return this._source.autoCompletePlaceholder;
  }

  /**
   * Return whether the user's inputted value can be used as a valid value.
   */
  public get unknown(): boolean {
    //eslint-disable-next-line max-len
    return !this._source?.allowUnlisted ? !this._source?.filteredFilters?.length && this._autocompleteFormControl.value : false;
  }

  /**
   * Selects the given filter for this filterGroup. If filter doesn't exist or
   * the {@see setDisabledState} is true nothing happens.
   */
  public select(event: Event | MatAutocompleteSelectedEvent, item?: Filter | string): void {
    if (this._source.disabled) {
      return;
    }

    if (event instanceof MatAutocompleteSelectedEvent) {
      this._source.select(event.option.value);
    } else {
      this._source.select(item);
    }

    this._autocompleteFormControl.reset(null, { emitEvent: false });
    this.searchStringInput?.nativeElement.blur();
  }

  /**
   * Deselect the given filter for this filterGroup. If filter doesn't exist or
   * the {@see setDisabledState} is true nothing happens.
   */
  public deselect(item: Filter | string | null): void {
    if (this._source.disabled) {
      return;
    }

    this._source.deselect(item);
    if (this._source.allowUnlisted) {
      this._source.unlisted = null;
    }
  }

  /**
   * Angular material helper method used to correctly display the filter within
   * the search bar.
   */
  public displayWith(value: Filter | unknown): string {
    return (value instanceof Filter) ? value.displayText : '';
  }

  /**
   * Select the top-most item in the filtered items list when pressing Tab.
   */
  @HostListener('keydown', ['$event'])
  public onTab(event: KeyboardEvent): void {
    if (event.key === 'Tab' && this._source.filteredFilters.length) {
      this._source.select(this._source.filteredFilters[0]);
    }
  }

  /**
   * This method subscribes to the search query form control and passes the
   * string contents to the source searchQuery property.
   */
  private createSearchQueryObserver(): void {
    this._autocompleteFormControl.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        filter(value => !(value instanceof Filter)),
      )
      .subscribe(searchQuery => this._source.searchQuery = searchQuery);
  }

  /**
   * Create a subscriber which emits the changed values to the onChange.
   */
  private createOnChangeObserver(): void {
    this.onSourceChange$.pipe(
      startWith(undefined as void),
      switchMap(() => {
        return this._source.selected$
          .pipe(
            map(changed => {
              const values: Set<string> = new Set<string>();
              for (let item of changed) {
                for (let value of (Array.isArray(item.value) ? item.value : [item.value])) {
                  values.add(value);
                }
              }

              return {
                filters: changed,
                values: [...values],
              } as TtAutocompleteChipControl;
            }),
            takeUntil(this.onDestroy$),
            takeUntil(this.onSourceChange$),
          );
      }),
    ).subscribe(changed => this.onChange(changed));
  }

  /**
   * If custom filter items are allowed, this subscriber will be loaded and will
   * monitor the results, preloading the custom item in the local behavior
   * subject.
   */
  private createCustomFilterItemSubscriber(): void {
    this._autocompleteFormControl.valueChanges.pipe(
      filter((value: string | TtAutocompleteChipControl) => {
        if (typeof value === 'object') {
          return false;
        }
        this._source.unlisted = null;
        return true;
      }),
      switchMap(() => this._source.filteredFilters$),
      debounceTime(250),
      filter((results) => !(results.length)),
      takeUntil(this.onDestroy$),
    ).subscribe(() => {
      const value: string = this._autocompleteFormControl.value;
      this._source.unlisted = value ? new Filter(
        value,
        { label: value, additionalData: { unlisted: true } },
      ) : null;
    });
  }
}

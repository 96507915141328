import { SelectionChange } from '@angular/cdk/collections';
import { FilterGroup } from '../filter/filter-group.model';
import { Filter } from '../filter/filter.model';

/**
 * This is an extended version of {@see SelectionChange}. The interface can be used
 * and given to the user. For each key {@see T} in the {@see SelectionChange}
 * a corresponding Filter {@see K} can be found in addTrigger and removedTrigger.
 */
export interface TriggerSelectionChange<T> extends SelectionChange<T> {
  /** Options that were added to the model. */
  addedTrigger: Filter[];
  /** Options that were removed from the model. */
  removedTrigger: Filter[];
}

export class Trigger {

  constructor(
    /**
     * This identifying key of the called trigger.
     */
    public readonly triggerKey: string,

    /**
     * The FilterGroup that emitted the change triggering this trigger.
     */
    public readonly triggeringFilterGroup: FilterGroup,

    /**
     * The {@see TriggerSelectionChange} model containing the emitted changes.
     */
    public readonly changes: TriggerSelectionChange<string>,

    /**
     * A Lookup List containing a available filterGroups.
     */
    public readonly allFilterGroups: Map<string, FilterGroup>,
  ) {
  }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HammerModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { DataAccessLocationsModule } from '@school-dashboard/data-access-locations';
import { DataAccessUsersModule } from '@school-dashboard/data-access-users';
import { TtPermissionModule } from '@techniek-team/permissions';
import { AmountIndicatorBarsModule } from '../shared/components/amount-indicator-bars/amount-indicator-bars.module';
import { ColoredSubtitleModule } from '../shared/components/colored-subtitle/colored-subtitle.module';
import { ColoredTitleModule } from '../shared/components/colored-title/colored-title.module';
import { ProgressNotificationModule } from '../shared/components/progress-notification/progress-notification.module';
import { ResponseMessageModule } from '../shared/components/response-message/response-message.module';
import { SchoolDashboardModalModule } from '../shared/components/school-dashboard-modal/school-dashboard-modal.module';
import { SimpleListModule } from '../shared/components/simple-list/simple-list.module';
import { TrackDataAccessModule } from '../store/track/track-data-access.module';
import { IconsModule } from './icons.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FontAwesomeModule,
    HammerModule,
    ReactiveFormsModule,
    IconsModule,
    ColoredTitleModule,
    ColoredSubtitleModule,
    ProgressNotificationModule,
    SimpleListModule,
    AmountIndicatorBarsModule,
    TtPermissionModule,
    ResponseMessageModule,
    TrackDataAccessModule,
    DataAccessUsersModule,
    DataAccessLocationsModule,
  ],
  exports: [
    CommonModule,
    IonicModule,
    FontAwesomeModule,
    HammerModule,
    ReactiveFormsModule,
    IconsModule,
    ColoredTitleModule,
    ColoredSubtitleModule,
    ProgressNotificationModule,
    SchoolDashboardModalModule,
    SimpleListModule,
    AmountIndicatorBarsModule,
    ResponseMessageModule,
    TrackDataAccessModule,
    DataAccessUsersModule,
    DataAccessLocationsModule,
  ],
})
export class CoreModule {}

export enum ResponseMessageState {
  ACTIVE = 'active',
  HIDDEN = 'hidden',
  CLOSED = 'closed',
  READ = 'read',
}

export enum ResponseMessageKey {
  TRACK_DETAILS_ACTIVE_ATTENDANCES = 'TRACK_DETAILS_ACTIVE_ATTENDANCES',
  TRACK_ATTENDANCE_CANCELLATION = 'TRACK_ATTENDANCE_CANCELLATION',
  TRACK_ATTENDANCE_MODAL = 'TRACK_ATTENDANCE_MODAL',
  TRACK_ATTENDANCE_ACCESS = 'TRACK_ATTENDANCE_ACCESS',
  TRACK_ATTENDANCE_CAN_DELETE = 'TRACK_ATTENDANCE_CAN_DELETE',
  TRACK_ATTENDANCE_CREATE_UPDATE = 'TRACK_ATTENDANCE_CREATE_UPDATE',
  TRACK_SELECTION_MODAL = 'TRACK_SELECTION_MODAL',

  PUPIL_FILE_UPLOAD = 'PUPIL_FILE_UPLOAD',
  PUPIL_FILE_UPLOAD_FEEDBACK = 'PUPIL_FILE_UPLOAD_FEEDBACK',

  PUPIL_CREATE_UPDATE = 'PUPIL_CREATE_UPDATE',
  PUPIL_CREATE_UPDATE_EMAIL = 'PUPIL_CREATE_UPDATE_EMAIL',
  PUPIL_CREATE_UPDATE_SCHOOL_CLASS = 'PUPIL_CREATE_UPDATE_SCHOOL_CLASS',
  PUPIL_CREATE_UPDATE_LEVEL_YEAR = 'PUPIL_CREATE_UPDATE_LEVEL_YEAR',
  PUPIL_CREATE_UPDATE_SKOLEO_MESSAGE = 'PUPIL_CREATE_UPDATE_SKOLEO_MESSAGE',

  BUDGET_NO_DATA_FOR_SCHOOL_YEAR = 'BUDGET_NO_DATA_FOR_SCHOOL_YEAR',

  DATASOURCE = 'DATASOURCE',
}

import { Injectable } from '@angular/core';
import { PreFetchMethodsObject, PreFetchServiceInterface } from '@techniek-team/fetch';

@Injectable({
  providedIn: 'root',
})
export class PreFetchService implements PreFetchServiceInterface {
  public preFetch(): PreFetchMethodsObject {
    return {};
  }
}

import { PriorityType } from '@school-dashboard/enums';
import { JsonLd } from '@techniek-team/class-transformer';
import { Expose } from 'class-transformer';

/**
 * This class is the same for (at least) the person's email address and phone
 * number, so extend if needed.
 */
class PersonContactInformation extends JsonLd {
  /**
   * Type of email-address address: primary, secondary or emergency.
   */
  @Expose() public priorityType: PriorityType;

  /**
   * Email address string value.
   */
  @Expose() public value: string;
}

export class PersonEmailAddress extends PersonContactInformation {}

export class PersonPhoneNumber extends PersonContactInformation {
  @Expose() public get valueWithoutSymbols(): string {
    let correctedValue: string = this.value;
    correctedValue = correctedValue.replace('+', '');
    return correctedValue.replace(' ', '');
  }
}

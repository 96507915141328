import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PupilUploadApi } from '../../../api/pupil-upload/pupil-upload.api';
import { IconsModule } from '../../../core/icons.module';
import { ColoredTitleModule } from '../colored-title/colored-title.module';
import { ProgressDonutComponent } from '../progress-donut/progress-donut.component';
import { SchoolDashboardModalModule } from '../school-dashboard-modal/school-dashboard-modal.module';
import {
  ProgressNotificationModalComponent,
} from './progress-notification-modal/progress-notification-modal.component';
import { ProgressNotificationComponent } from './progress-notification.component';

@NgModule({
  declarations: [
    ProgressNotificationComponent,
    ProgressNotificationModalComponent,
    ProgressDonutComponent,
  ],
  providers: [PupilUploadApi],
  imports: [
    IonicModule,
    CommonModule,
    IconsModule,
    ColoredTitleModule,
    SchoolDashboardModalModule,
  ],
  exports: [ProgressNotificationComponent, ProgressDonutComponent],
})
export class ProgressNotificationModule {}

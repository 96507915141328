import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { UsersStoreService } from '@school-dashboard/data-access-users';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { catchError, combineLatestWith, from, map, of, switchMap, tap } from 'rxjs';
import { LocationApi } from './api/location/location.api';
import { locationsActions } from './locations.actions';
import { selectId } from './locations.reducer';

@Injectable()
export class LocationsEffects {

  private readonly initLocations$;

  private readonly locationsFailure$;

  private readonly initSaveLocationToStorage$;

  private readonly setSelectedLocationFromStorage$;

  constructor(
    private readonly actions$: Actions,
    private readonly errorHandler: SentryErrorHandler,
    private readonly toastService: ToastService,
    private readonly locationApi: LocationApi,
    private readonly storage: Storage,
    private readonly userService: UsersStoreService,
  ) {
    this.initLocations$ = this.createInitLocationsEffect();
    this.initSaveLocationToStorage$ = this.createSaveLocationToStorageEffect();
    this.setSelectedLocationFromStorage$ = this.createSetSelectedLocationFromStorageEffect();
    this.locationsFailure$ = this.createLocationsFailureEffect();
  }

  public createInitLocationsEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(locationsActions.initLocations),
      switchMap(() => this.locationApi.getLocations()),
      map(locations => locationsActions.loadLocationsSuccess({
        locations: locations['hydra:member'],
        totalItems: locations['hydra:totalItems'],
      })),
      catchError((error) => of(locationsActions.loadLocationsFailure({ error: error }))),
    ));
  }

  public createLocationsFailureEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(locationsActions.loadLocationsFailure),
      tap(action => {
        return Promise.all([
          this.errorHandler.captureError(action.error),
          this.toastService.error({
            message: 'Er is iets misgegaan bij het laden van Locations.',
            duration: 10000,
            buttons: [{ text: 'Sluiten', role: 'cancel' }],
          }),
        ]);
      }),
    ), { dispatch: false });
  }

  public createSaveLocationToStorageEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(locationsActions.selectLocation),
      concatLatestFrom(() => this.userService.user$),
      tap(([action, user]) => {
        if (!action.locationId) {
          return Promise.all([
            this.storage.remove(`active-location-${user?.id}`),
            this.storage.remove('active-location-global'),
          ]);
        }
        if (user) {
          return from(this.storage.set(`active-location-${user.id}`, action.locationId));
        }
        return from(this.storage.set('active-location-global', action.locationId));
      }),
    ), { dispatch: false });
  }

  public createSetSelectedLocationFromStorageEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(locationsActions.loadLocationsSuccess),
      combineLatestWith(this.userService.user$.pipe(
        switchMap(user => {
          if (user) {
            return this.storage.get(`active-location-${user.id}`);
          }
          return this.storage.get('active-location-global');
        }),
      )),
      map(([action, storageId]) => {
        if (!storageId) {
          storageId = selectId(action.locations[0]);
        }
        return locationsActions.selectLocation({
          locationId: storageId,
        });
      }),
    ));
  }
}

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { HighlightSearchModule } from '@techniek-team/common';
import { TtIonSelectSearchControlComponent } from './tt-ion-select-search-control.component';
import { TtIonSelectSearchOptionDirective } from './tt-ion-select-search-option.directive';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ScrollingModule,
    HighlightSearchModule,
  ],
  declarations: [
    TtIonSelectSearchControlComponent,
    TtIonSelectSearchOptionDirective,
  ],
  exports: [
    TtIonSelectSearchControlComponent,
    TtIonSelectSearchOptionDirective,
  ],
})
export class TtIonSelectSearchModule {
}

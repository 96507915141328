<ng-container *ngIf="(this.locationsStoreService.locations$ | async) as locations; else loading">
  <tt-ion-select-search lines="none"
                        [color]="colorSubject$ | async"
                        [formControl]="locationSearchControl"
                        [width]="475"
                        [alignment]="'center'"
                        [options]="locations"
                        notFoundText="Er zijn geen vestigingen gevonden."
                        placeholder="Zoek op vestigingsnaam">
  </tt-ion-select-search>
</ng-container>

<ng-template #loading>
  <ion-skeleton-text animated="true"></ion-skeleton-text>
</ng-template>

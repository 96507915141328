import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@school-dashboard/environments';
import { Track } from '@school-dashboard/models';
import { Resource } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';
import { GetTrackResponse } from '../track/track.response';
import { PostSubTrackRequest } from './post-sub-track.request';

export interface SubTrackApiInterface {
  postSubTrack(request: PostSubTrackRequest): Observable<Resource<GetTrackResponse>>;
}

@Injectable({
  providedIn: 'root',
})
export class SubTrackApi implements SubTrackApiInterface {
  constructor(private httpClient: HttpClient) {}

  public postSubTrack(request: PostSubTrackRequest): Observable<Resource<GetTrackResponse>> {
    const url: string = `${environment.dashboardApiUrl}/api/tracks/create-child-track`;

    return this.httpClient.post<Resource<GetTrackResponse>>(url, {
      parentTrackId: request.parentTrack instanceof Track ? request.parentTrack.getId() : request.parentTrack,
      validityRange: request.validityRange,
      // Better known as the description field :rolling_eyes:. This is indeed actually saved as name but in the form
      // we see it as if it's the description. This way the user actually enters a better title for the sub track
      // than if we asked for the name.
      name: request.description,
      amountOfPupils: request.amountOfPupils,
      amountOfWeeks: ('amountOfWeeks' in request) ? request.amountOfWeeks : undefined,
    });
  }
}

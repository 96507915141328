export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum GenderDisplayValue {
  MALE = 'man',
  FEMALE = 'vrouw',
  OTHER = 'anders',
}

import { JsonLd, TsRange } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';
import { LevelYear } from './level-year.model';

export class PupilLevelYearStatus extends JsonLd {
  /**
   * Status' year and level.
   */
  @Type(() => LevelYear)
  @Expose()
  public levelYear: LevelYear;

  /**
   * Validity for this status.
   */
  @Type(() => TsRange)
  @Expose()
  public validityRange: TsRange;
}

import { IonContent, IonInfiniteScroll, ScrollCustomEvent } from '@ionic/angular';
import { Hydra, JsonLd } from '@techniek-team/class-transformer';
import { TtHydraPaginationDataSource } from './tt-hydra-pagination.datasource';
import { BehaviorSubject, Observable, OperatorFunction } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { TtDataSourceActiveRequest } from './models/active-request.interface';

/**
 * DataSource that should be used with IonContent and IonInfiniteScroll for
 * pagination, extends the `TtHydraPaginationDataSource`.
 *
 * ## Setup
 * To make the caching and pagination work, the following properties can be set
 * or changed:
 *
 * - `infiniteScroll`: the {@see IonInfiniteScroll} instance that is on the HTML
 * template. Make sure to instantiate the template before calling the
 * {@see TtTableDataSource.init} function.
 * - `itemsPerPage`: the amount of items per page, default `30`.
 * - `itemsPerPageKey`: the query params key to use for pagination, default
 * `itemsPerPage`.
 * - `itemHeight`: the maximum row height in the table. This property is
 * **required** for any form of caching.
 *
 * Remember to call the `init` method after changing the properties.
 *
 * ## Cache
 * If you want to use some form of cache, make sure to update the scroll height
 * using the `ion-content` output and setting it via the
 * {@see TtTableDataSource.setScrollPosition} function. Most of the functions
 * are automated, but you can manually get the last viewed page by calling the
 * {@see TtTableDataSource.getLastViewedChunkIndex} function. If you want to,
 * manually, get new data for the pages, you can call the
 * {@see TtTableDataSource.checkForUnloadedChunks} function. If you want to
 * force the loading of a number of pages or items:
 * ```
 * datasource.clear();
 * datasource.init();
 * ```
 * This combination will clear the cached results and load all items based on
 * the last scroll position and item height.
 *
 * #### --------
 *
 * # TtHydraPaginationDataSource docs
 *
 * @inheritDoc
 */
export class TtInfiniteScrollPaginationDatasource<T extends JsonLd> extends TtHydraPaginationDataSource<T>{

  /**
   * Current scroll position.
   */
  protected scrollPosition$: BehaviorSubject<number> = new BehaviorSubject<number>(null as never);

  /**
   * Largest height of each row in the table.
   */
  protected itemHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(null as never);

  /**
   * The IonContent element, used to force scroll-to-position after cache has
   * been cleared when a scroll position was set.
   */
  protected ionContentInstance?: IonContent;

  /**
   * The infinite scroll element or component instance. Though assumed it's
   * going to be an IonInfiniteScroll component, there's the option to use the
   * Angular Material one or anything else.
   */
  protected infiniteScrollInstance?: IonInfiniteScroll;

  /**
   * Set the infinite scroll instance that is currently used and available on
   * the HTML template.
   */
  public set infiniteScroll(instance: IonInfiniteScroll | undefined) {
    this.infiniteScrollInstance = instance;
  }


  /**
   * Set the ion content instance that is currently used and available on the
   * HTML template.
   */
  public set ionContent(instance: IonContent) {
    this.ionContentInstance = instance;
  }

  /**
   * The height of each item or row in the table. Use the largest value if its
   * a variable height.
   */
  public set itemHeight(height: number) {
    this.itemHeight$.next(height);
  }

  /**
   * #### --------
   * ### TtInfiniteScrollPaginationDatasource -> `init`
   *
   * Expands the init by checking if there's a stored last viewed index and, if
   * so, check if any of the pages are unloaded.
   *
   * Set `noHttpParamsFromMatSort` to true if the MatSort.sortChanges shouldn't
   * be used to create HttpParam filters.
   *
   * Set `jumpToTopOfPageAfterFirstChunkLoaded` to true if the page should
   * display the start of the ion-content after the first chunk has loaded.
   * This is nice when filtering/sorting the results.
   *
   * @inheritDoc
   */
  public override init(
    noHttpParamsFromMatSort?: boolean,
    jumpToTopOfPageAfterFirstChunkLoaded?: boolean,
  ): this {
    const lastViewed: number = this.getLastViewedChunkIndex();
    if (lastViewed) {
      this.checkForUnloadedChunks();
      if (this.infiniteScrollInstance) {
        this.infiniteScrollInstance.disabled = false;
      }
    }

    super.init(!!lastViewed, noHttpParamsFromMatSort);

    if (jumpToTopOfPageAfterFirstChunkLoaded) {
      this.hasLoadedFirstChunk$
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (this.ionContentInstance) {
            this.ionContentInstance.scrollToPoint(null, 0);
          }
        });
    }

    this.createSearchInputSubscription();

    return this;
  }

  /**
   * Clear the stored results, using a Hydra pseudo-model with members and a
   * length property so the template correctly shows the preloading of items.
   * Set the bust value to true to bust the cache with pseudo-hydra loading
   * content.
   */
  public clear(bust?: boolean, customSize?: number): void {
    if (!bust) {
      this.results$.next(this.loadingHydra(this.pageSize));
      return;
    }

    if (this.infiniteScrollInstance) {
      this.infiniteScrollInstance.disabled = true;
    }

    const results: Hydra<T> = this.results$.getValue();
    const loaded: number = customSize ?? results?.collection?.length ?? this.pageSize;
    this.results$.next(this.loadingHydra(loaded, results?.totalItems) as never);
  }

  /**
   * Set or update the current scroll position to the given position.
   */
  public setScrollPosition(event: Event): void {
    const custom: ScrollCustomEvent = event as ScrollCustomEvent;
    const scrollTop: number = custom.detail.scrollTop ?? custom.detail.currentY;
    if (scrollTop === 0 && this.scrollPosition$.getValue() !== 0) {
      return;
    }
    this.scrollPosition$.next(scrollTop);
  }

  /**
   * Return the last active chunk of pagination items the user was on, by using
   * the last scroll position and dividing that by the average item height and
   * dividing that number by the items per page, rounded upward.
   *
   * Use this number to determine what page or chunk to load first if it hasn't
   * been loaded yet, or advance the pages to this point.
   */
  public getLastViewedChunkIndex(): number {
    const position: number = this.scrollPosition$.getValue();
    const height: number = this.itemHeight$.getValue();
    if (!position || !height) {
      return 0;
    }
    const index: number = Math.floor(position / height);
    return Math.ceil(index / this.pageSize);
  }

  /**
   * Check if the last viewed page is actually in the results list. If not, fire
   * up the API call engines.
   */
  public checkForUnloadedChunks(): void {
    const index: number = this.getLastViewedChunkIndex();
    if (!index) {
      return;
    }

    this.lockInfiniteScroll(true);
    const current: Hydra<T> = this.results$.getValue();
    const expectedMin: number = (index * this.pageSize) - this.pageSize;
    const length: number = current?.collection?.length ?? 0;
    if (length >= expectedMin && current.getIri() !== 'LOADING') {
      return;
    }

    // Start the loading state. It's disabled when chunk loading for index 0 has
    // completed the call.
    this.loadingState$.next(true);
    this.loadUnloadedChunks().subscribe((results: Hydra<T>) => {
      this.lockInfiniteScroll(false);
      this.results$.next(results);
    });
  }

  /**
   * @inheritDoc
   */
  protected override completeGetChunkRequest(): void {
    super.completeGetChunkRequest();
    this.checkInfiniteScroll();
  }

  /**
   * Async function to complete the loading of the infinite scroll instance and,
   * if it was the last chunk to be loaded, disable infinite scrolling.
   */
  protected async checkInfiniteScroll(): Promise<void> {
    if (this.infiniteScrollInstance) {
      await this.infiniteScrollInstance.complete();
      this.infiniteScrollInstance.disabled = await this.last();
    }
  }

  /**
   * Lock or unlock the InfiniteScroll instance, so the user can't send multiple
   * requests.
   */
  protected lockInfiniteScroll(state: boolean): void {
    if (!this.infiniteScrollInstance) {
      return;
    }

    this.infiniteScrollInstance.disabled = state;
  }

  /**
   * Subscribe to the changes of the searchInput observable and clear the
   * selected rows on-change, as well as restart the loading state.
   */
  protected createSearchInputSubscription(): void {
    this.searchInput$.pipe(
      takeUntil(this.destroy$),
      filter((input) => !!(input)),
    ).subscribe(() => this.clear());
  }

  /**
   * Using the last viewed page index as a start, create an operator array of
   * mergeMaps that sequentially call the backend when the last result has been
   * fetched. These results are then concatenated using the
   * {@see self.mergeChunkResults} function.
   */
  protected loadUnloadedChunks(): Observable<Hydra<T>> {
    const chunkIdx: number = this.getLastViewedChunkIndex();
    const firstPage: Observable<Hydra<T>> = this.getChunkAtIndex(chunkIdx, true);
    const firstOperators: OperatorFunction<Hydra<T>, Hydra<T>>[] = [
      map((current) => {
        const merged: Hydra<T> = this.mergeChunkResults({ collection: [] } as never, current);
        this.results$.next(merged);
        if (this.ionContentInstance) {
          this.ionContentInstance.scrollToPoint(null, this.scrollPosition$.getValue());
        }
        return merged;
      }),
    ];

    const operators: OperatorFunction<Hydra<T>, Hydra<T>>[] = Array.from(
      { length: chunkIdx - 1 },
      (_, index: number) => mergeMap((previous: Hydra<T>) => this.getChunkAtIndex(index + 1, index !== 0).pipe(
        map((current) => this.mergeChunkResults(previous, current)),
      )),
    ).reverse();

    //@ts-ignore
    return firstPage.pipe.call(firstPage, ...firstOperators, ...operators) as Observable<Hydra<T>>;
  }

  /**
   * Create an API call for the given page, optionally skipping the update of
   * the loadingState$ observable.
   */
  protected getChunkAtIndex(index: number, noLoad?: boolean): Observable<Hydra<T>> {
    const request: TtDataSourceActiveRequest = this.activeRequest$.getValue() ?? {
      page: null,
      limit: null,
      sorting: null,
      filters: null,
    };
    request.page = index;
    request.limit = this.pageSize;
    this.activeRequest$.next(request);

    return this.getChunk(request, noLoad);
  }

  /**
   * Merge the previously fetched result with the latest fetched result and
   * fill the remaining collection items with nulls so a loading skeleton can
   * be displayed if needed.
   */
  private mergeChunkResults(previous: Hydra<T>, current: Hydra<T>): Hydra<T> {
    previous.collection = previous.collection.filter((item) => item !== null);
    current.collection = current.collection.concat(previous.collection);
    const toLoad: number = current.totalItems - current.collection.length;
    if (toLoad < current.totalItems) {
      const loadingItems: null[] = this.loadingPlaceholder(toLoad);
      current.collection = [...loadingItems, ...current.collection] as T[];
    }

    return current;
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthGetProfileResponse, AuthGetTokenResponse } from './auth.response';

/**
 * This is the default profile response object we get from the Lyceo-sso server
 * It should be using our Naming-convention but it doesn't.
 *
 * This model is only used as mock.
 */
/* eslint-disable @typescript-eslint/naming-convention */
export const USER_PROFILE: AuthGetProfileResponse = {
  id: 'account_id',
  person_id: 'person_id',
  skoleo_id: 135854,
  first_name: 'Techniek-Team',
  middle_name: '',
  last_name: 'TestTutor',
  full_name: 'Techniek-Team  TestTutor',
  email: 'administrator+tutor@techniek-team.nl',
  roles: [
    'ROLE_USER',
    'ROLE_SKOLEO_LEGACY_BEGELEIDER_ROL',
    'ROLE_SKOLEO_TT_PLANNER_TUTOR',
    'ROLE_SKOLEO_HOMI_COACH',
  ],
};
/* eslint-enable @typescript-eslint/naming-convention */

/**
 * This interface matches the Access Token response of the Oauth 2.0 RFC. Including
 * the naming-convention. For the reason our own naming convention of Camelcase has
 * been overruled for this instance.
 *
 * @see https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.4
 */
/* eslint-disable @typescript-eslint/naming-convention */
export const AUTH_TOKEN_RESPONSE_MOCK: AuthGetTokenResponse = {
  access_token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6Ij',
  expires_in: 3600,
  refresh_token: 'def502004786b9d24cccba5ec8d9775fdf9a3e275ad1c',
  token_type: 'Bearer',
};
/* eslint-enable @typescript-eslint/naming-convention */

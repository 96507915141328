import { Filter } from '../../models/filter/filter.model';

/**
 * Create a {@see Map} lookup list containing a list of all filters by key and
 * value (if it's unique). Giving you an easy way to find filters based on a value
 * or it's generated key.
 */
export function createFilterLookupList<T extends Filter>(filters: T[]): Map<string, T> {
  const lookupList: Map<string, T> = new Map<string, T>();
  let noneUniqueDisplayValues: string[] = [];
  let valueLookupList: Map<string, T> = new Map<string, T>();
  for (let currentFilter of filters) {
    // We make each filter multiple to make it possible to either find them by key or value.
    // Each value that is not unique over the whole service gets filtered out later on.
    lookupList.set(currentFilter.key, currentFilter);

    for (let value of Array.isArray(currentFilter.value) ? currentFilter.value : [currentFilter.value]) {
      value = value.toString();
      if (valueLookupList.has(value.toLowerCase())) {
        noneUniqueDisplayValues.push(value.toLowerCase());
        continue;
      }
      valueLookupList.set(value.toLowerCase(), currentFilter);
    }
  }

  for (let noneUnique of noneUniqueDisplayValues) {
    valueLookupList.delete(noneUnique);
  }
  return new Map<string, T>([...lookupList.entries(), ...valueLookupList.entries()]);
}

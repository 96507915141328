import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Collection } from '@techniek-team/api-platform';
import { denormalize, Hydra, JsonLd } from '@techniek-team/class-transformer';
import { ClassConstructor } from 'class-transformer/types/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TtDataSourceApi<T extends JsonLd> {
  constructor(private http: HttpClient) {}

  /**
   * Generic API Platform data GET function.
   *
   * @param model - a ClassConstructor model with a RESOURCE_ID static property
   * @param resourceUrl - the combined base url and api resource location
   * @param params - the HttpParams created from the Hydra Mapping
   * @param headers - optional HttpHeaders
   */
  public getData(
    model: ClassConstructor<T>,
    resourceUrl: string,
    params: HttpParams,
    headers?: HttpHeaders,
  ): Observable<Hydra<T>> {
    headers = headers ?? new HttpHeaders();
    headers = headers.set('accept', 'application/ld+json');

    return this.http.get(resourceUrl, { headers: headers, params: params }).pipe(
      //@ts-ignore
      map((response: Collection<Partial<T>>) => denormalize(model, response) as Hydra<T>),
    );
  }
}

import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {
  faAcorn as duoAcorn,
  faArrowRight as duoArrowRight,
  faBookOpen as duoBookOpen,
  faBurst as duoBurst,
  faCalendarDays as duoCalendarDays,
  faDiagramSubtask as duoDiagramSubtask,
  faHouseChimney as duoHouseChimney,
  faIdBadge as duoIdBadge,
  faIdCard as duoIdCard,
  faJetFighter as duoJetFighter,
  faListTree as duoListTree,
  faPeopleArrows as duoPeopleArrows,
  faReceipt as duoReceipt,
  faServer as duoServer,
  faUserGroup as duoUserGroup,
  faUserSecret as duoUserSecret,
} from '@fortawesome/pro-duotone-svg-icons';
import { faIdBadge as faIdBadgeLight, faUser as faUserLight } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowDownToBracket,
  faChevronRight as faChevronRightRegular,
  faCircle as faCircleRegular,
  faCircleCheck as faCircleCheckRegular,
  faCircleExclamation as faCircleExclamationRegular,
  faCircleInfo as faCircleInfoRegular,
  faCircleXmark as faCircleXmarkRegular,
  faMinus as faMinusRegular,
  faXmark as faXmarkRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowRightFromLine,
  faCalendar,
  faChartSimple,
  faCheck,
  faChevronDown,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCirclePhone,
  faCircleXmark,
  faClipboard,
  faClipboardUser as faClipboardUserSolid,
  faEnvelope,
  faExclamation,
  faEye,
  faFileArrowUp,
  faHouse,
  faIdBadge,
  faLocationDot,
  faMessagesQuestion,
  faPencil,
  faPhone,
  faPlay,
  faPlus,
  faRotate,
  faSliders,
  faThumbsUp,
  faTrashCan,
  faTriangleExclamation,
  faUpload,
  faUpRightAndDownLeftFromCenter,
  faUser as faUserSolid,
  faUserGroup,
  faUserPlus,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class IconsModule {
  //eslint-disable-next-line max-lines-per-function
  constructor(library: FaIconLibrary) {
    // All the Font Awesome icons we use throughout the application.
    // Make sure these are ordered by type (brands/solid/regular) first and
    // per type alphabetically, for easier reading/finding.

    library.addIcons(
      // Duo
      //@ts-ignore
      duoAcorn,
      duoArrowRight,
      duoBookOpen,
      duoBurst,
      duoCalendarDays,
      duoDiagramSubtask,
      duoHouseChimney,
      duoIdBadge,
      duoIdCard,
      duoJetFighter,
      duoListTree,
      duoPeopleArrows,
      duoReceipt,
      duoServer,
      duoUserGroup,
      duoUserSecret,
      // Solid
      faArrowRightFromLine,
      faCalendar,
      faChartSimple,
      faCheck,
      faChevronDown,
      faCircleCheck,
      faCircleExclamation,
      faCircleInfo,
      faCirclePhone,
      faCircleXmark,
      faClipboard,
      faClipboardUserSolid,
      faEnvelope,
      faExclamation,
      faEye,
      faFileArrowUp,
      faHouse,
      faIdBadge,
      faLocationDot,
      faMessagesQuestion,
      faPencil,
      faPhone,
      faPlay,
      faPlus,
      faRotate,
      faSliders,
      faThumbsUp,
      faTrashCan,
      faTriangleExclamation,
      faUpRightAndDownLeftFromCenter,
      faUpload,
      faUserGroup,
      faUserPlus,
      faUserSolid,
      faUsers,
      // Regular
      faArrowDownToBracket,
      faChevronRightRegular,
      faCircleCheckRegular,
      faCircleExclamationRegular,
      faCircleRegular,
      faCircleXmarkRegular,
      faMinusRegular,
      faXmarkRegular,
      // Light
      faIdBadgeLight,
      faUserLight,
      // Brands
      faWhatsapp,
      faCircleInfoRegular,
    );
  }
}

import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

//eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
function isEqual(data1: any, data2: any): boolean {
  return (
    data1 !== undefined && data1 !== null
    && data2 !== undefined && data2 !== null
    && typeof data1 === 'object'
    && Object.keys(data1).length > 0
  ) ? Object.keys(data1).length === Object.keys(data2).length
    && Object.keys(data1).every(p => isEqual(data1[p], data2[p])) : data1 === data2;
}

// Without this dynamic parameter AOT compiling will fail.
// @see https://angular.io/guide/angular-compiler-options#strictmetadataemit
// @dynamic
export class UniqueValidators {
  /**
   * Validator to check if all the direct child FormControls of a FormGroup or
   * FormArray have a unique value. This function supports strings, numbers,
   * array and objects as the values of the FormControls.
   */
  public static uniqueOptionsRequired(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control instanceof UntypedFormGroup) && !(control instanceof UntypedFormArray)) {
        throw new Error(
          'The \'uniqueOptionsRequired\' validator only supports an instance of FormGroup or FormArray.',
        );
      }

      const childControls: AbstractControl[] = this.getDirectChildControls(control);
      const duplicates: Set<unknown> = this.getDuplicateFormControlValues(
        this.assertOnlyFormControls(childControls),
      );

      return duplicates.size ? { duplicates: Array.from(duplicates) } : null;
    };
  }

  /**
   * Get the direct child AbstractControls of a FormGroup or FormArray.
   */
  private static getDirectChildControls(
    control: UntypedFormGroup | UntypedFormArray,
  ): AbstractControl[] {
    if (control instanceof UntypedFormArray) {
      return control.controls;
    }

    let controls: AbstractControl[] = [];
    //noinspection JSUnusedLocalSymbols
    for (const [name, childControl] of Object.entries(control.controls)) {
      controls.push(childControl);
    }

    return controls;
  }

  /**
   * Assert that all the passed AbstractControls are FormControls.
   */
  private static assertOnlyFormControls(childControls: AbstractControl[]): UntypedFormControl[] {
    for (const childControl of childControls) {
      if (!(childControl instanceof UntypedFormControl)) {
        throw new Error(
          'The \'uniqueOptionsRequired\' validator only supports FormControl child instances.',
        );
      }
    }

    return childControls as UntypedFormControl[];
  }

  /**
   * Get any duplicate values of passed FormControls.
   */
  private static getDuplicateFormControlValues(
    childControls: UntypedFormControl[],
  ): Set<unknown> {
    const duplicates: Set<unknown> = new Set;

    for (const childControl of childControls) {
      const duplicateControlsByValue: AbstractControl[] = childControls.filter(
        (obj: AbstractControl) => {
          return isEqual(childControl.value, obj.value);
        },
      );

      const hasDuplicateControlValue: boolean = duplicateControlsByValue.length > 1;
      if (hasDuplicateControlValue) {
        if (childControl.value !== undefined && childControl.value !== null) {
          duplicates.add(childControl.value);
        }

        // Filter out duplicate controls, so we don't have to check them
        // in the future.
        childControls = childControls.filter((obj: AbstractControl) => {
          return duplicateControlsByValue.indexOf(obj) === -1;
        });
      }
    }

    return duplicates;
  }
}

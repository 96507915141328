import { CheckType, SchoolLevel } from '@school-dashboard/enums';
import { Hydra } from '@techniek-team/class-transformer';
import { Exclude, Expose, Type } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExamTrainingPurchaseCount } from '../exam-training-purchase-count.model';
import { Track } from '../track.model';
import { HasDeadline } from './has-deadline.interface';
import { HasPupils } from './has-pupils.interface';

export class ExamTrainingTrack extends Track implements HasDeadline, HasPupils {
  /**
   * @inheritDoc
   */
  @Type(() => Date)
  @Expose()
  public deadline: Date;

  /**
   * @inheritDoc
   */
  @Expose() public maxPupils: number;

  @Expose() public levels: SchoolLevel[];

  @Expose() public years: number[];

  @Exclude() public canAddTrackAttendances: boolean = false;

  @Exclude() public canDeleteTrackAttendances: boolean = false;

  @Exclude() public canEditTrackAttendances: boolean = false;

  @Exclude() public override minimumTrackAttendances: number = 40;

  @Exclude() public hasTrainings: boolean = true;

  @Exclude() public canHaveSubTracks: boolean = false;

  @Exclude() public canResendEmailTrackAttendance: boolean = false;

  @Exclude() public availableChecks: Map<CheckType, CheckType[]> = new Map<CheckType, CheckType[]>([
    [CheckType.START_DATE_DEFINITIVE, []],
  ]);

  /**
   * Create a map with iris linking to ExamTrainingPurchaseCount objects. These
   * are used to display the actual values in the TrackModal.
   */
  @Exclude() public createPurchaseCountMap(hydra: Hydra<ExamTrainingPurchaseCount>): void {
    const purchases: ExamTrainingPurchaseCount[] = hydra.toArray();
    const purchasesMap: Map<string, ExamTrainingPurchaseCount> = new Map();
    for (const purchase of purchases) {
      purchasesMap.set(purchase.getIri() as string, purchase);
    }
    this.purchaseCountMap$.next(purchasesMap);
  }

  @Exclude()
  public get purchasesMap$(): Observable<Map<string, ExamTrainingPurchaseCount>> {
    return this.purchaseCountMap$.asObservable();
  }

  @Exclude() private purchaseCountMap$ = new BehaviorSubject<Map<string, ExamTrainingPurchaseCount>>(undefined);

  public get trackAttendancesRemaining(): number {
    return this.maxPupils - (this.currentPupils ?? 0);
  }
}

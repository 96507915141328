import { CollectionViewer } from '@angular/cdk/collections';
import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injector, Type } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { Hydra, JsonLd } from '@techniek-team/class-transformer';
import { Filter, FilterControllerService } from '@techniek-team/filter-search';
import { firstEmitFrom } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { ClassConstructor } from 'class-transformer';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  of,
  shareReplay,
  Subject,
  withLatestFrom,
} from 'rxjs';
import { catchError, filter, first, map, skip, skipWhile, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TtDataSourceActiveRequest, TtSearchFormData } from './models/active-request.interface';
import { TtDataSourceFeedbackService } from './models/feedback-service.interface';
import { TtDataSourceSorting } from './models/sorting.enum';
import { Sorting, SortInterface } from './models/sorting.interface';
import { TtBasePaginationDataSource } from './tt-base-pagination.datasource';
import { TtDataSourceApi } from './tt-datasource.api';

export type ApiCallFunction<T extends JsonLd> = (
  params: HttpParams,
  headers?: HttpHeaders,
) => Observable<Hydra<T>>;

const LOADING_HYDRA_KEY: string = 'LOADING';

/**
 * `TtHydraPaginationDataSource` extends the `TtBasePaginationDataSource` abstract
 * base class and implements the functions required.
 *
 * ## Creation
 * Make sure to inject the Injector via the component constructor (or TestBed)
 * and change the different keys if needed before starting the DataSource's
 * first call via the `init()` function.
 *
 * ## Constructor:
 * - `injector: Injector` - Utilized to bypass some injectables that are loaded
 * anyway, like the SentryErrorHandler.
 * - `model: ClassConstructor<T>` - The JsonLd ClassTransformer based model.
 * Note: when the resourceUrl is a string the model must have the
 * `public static readonly RESOURCE_ID: string` property filled with the
 * API resource path (e.g. `/api/to/my/resource`) for
 * that specific resource!
 * - `resourceUrl: string` - The entire API call url, including variables.
 *                           Make sure to remove any double slashes between
 *                           the server url and the api resource
 *                           url.
 *   `resourceUrl: ApiCallFunction` - Function which return the result Hydra object
 *                                    Basically you can add your api method as here
 * - `sortOrInput: Observable<TtSearchFormData> | MatSort` - Either the
 * valueChanges observable of the form that holds the search bar input and/or
 * the filters, or the MatSort sorting for the data table.
 * - `textSearchKey: string`: The query param key used for the search input.
 * - `sort: MatSort`: MatSort if both the valueChanges and the sorting are
 * available.
 *
 * ## General Settings
 * - **Items per page**: Change `datasource.itemsPerPage` to the number that the
 * API expects for pagination. Default 30.
 * - **Sorting key for MatSort**: Change `datasource.sortingKey` to what the
 * API expects for sorting. Default `order`.
 * - **Query param for items per page**: Change `datasource.itemsPerPageKey` to
 * the string the API accepts. Default `itemsPerPage`.
 * - **Default HttpParams**: Set `datasource.httpParams` with an HttpParams
 * object and these will be used as base for params to be added to.
 * - **Default HttpHeaders**: Set `datasource.httpHeaders` to the headers you
 * want to send with every API call.
 *
 * #### Setup Examples:
 * ##### With api method.
 * ```
 * export class AssignmentsComponent implements OnInit {
 *   public assignmentsDataSource: TtHydraPaginationDataSource<Assignment>;
 *
 *   constructor(
 *     private assignmentApi: AssignmentApi,
 *     private injector: Injector,
 *   ) {
 *   }
 *
 *   public ngOnInit(): void {
 *     this.assignmentsDataSource = new TtInfiniteScrollPaginationDatasource(
 *       this.injector,
 *       Assignment,
 *       this.assignmentApi.getSelfAssignments,
 *     );
 *     this.assignmentsDataSource.itemsPerPage = 10;
 *     this.assignmentsDataSource = this.assignmentsDataSource.init();
 *     this.assignmentsDataSource.observer$.subscribe(a => console.log(a));
 *   }
 * }
 * ```
 *
 * #### With api url string
 * ```
 * export class AssignmentsComponent implements OnInit {
 *   public assignmentsDataSource: TtHydraPaginationDataSource<Assignment>;
 *
 *   constructor(
 *     private assignmentApi: AssignmentApi,
 *     private injector: Injector,
 *   ) {
 *   }
 *
 *   public ngOnInit(): void {
 *     this.assignmentsDataSource = new TtInfiniteScrollPaginationDatasource(
 *       this.injector,
 *       Assignment,
 *       `${environment.schedulerApiUrl}v1/assignments/self-assignable`,
 *     );
 *     this.assignmentsDataSource.itemsPerPage = 10;
 *     this.assignmentsDataSource = this.assignmentsDataSource.init();
 *     this.assignmentsDataSource.observer$.subscribe(a => console.log(a));
 *   }
 * }
 * ```
 * *
 * ## Post-API Call Options
 * Besides changing the loading state, some other functionality could be added
 * by extending the `completeGetChunkRequest` function.
 * #### Example:
 * ```
 *  protected completeGetChunkRequest(): void {
 *    super.completeGetChunkRequest();
 *    this.checkInfiniteScroll();
 *  }
 *
 *  protected async checkInfiniteScroll(): Promise<void> {
 *    if (this.infiniteScrollInstance) {
 *      this.infiniteScrollInstance.complete();
 *    }
 *    this.infiniteScrollInstance.disabled = await this.last();
 *  }
 * ```
 *
 * ## Feedback service
 * You can change the 'feedback service' (default ToastService) the DataSource
 * utilizes to display messages to the end user. It defaults to using Toasts
 * with a generic message, but if you want to use a different service or a proxy
 * service that checks the error code and relays a proper message, you can
 * change it. The custom service should implement the `FeedbackService`
 * interface.
 * #### Example:
 * ```
 * class ProxyFeedbackService implements FeedbackService {
 *   constructor(private toast: ToastService) {}
 *
 *   public error(message: string, error: HttpErrorResponse): void {
 *     if (error.code === 500) {
 *       this.toast.error('BROKEN AF');
 *     }
 *     // Et cetera.
 *   }
 * }
 *
 * datasource.feedbackService = ProxyFeedbackService;
 * ```
 */
//eslint-disable-next-line max-len
export class TtHydraPaginationDataSource<T extends JsonLd> extends TtBasePaginationDataSource<T> {

  /**
   * The unfiltered total amount of results returned by the API.
   */
  public unfilteredTotal$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  /**
   * Observable containing the search form data, holding the search string and
   * other filters that are available in the Hydra Mapping property.
   */
  protected searchInput$: Observable<TtSearchFormData>;

  /**
   * Feedback service to relay messages to the user. Defaults to the
   * ToastService, but can be update to use a different service that at least
   * implements the `error` function. Injected via the Injector.
   */
  protected userFeedbackService: ToastService | TtDataSourceFeedbackService;

  /**
   * Filter Controller Service instance for this DataSource.
   */
  protected filterControllerService: FilterControllerService;

  /**
   * Generic data API with a GET method based on the constructor values for this
   * class, the current http params - like the pagination params and the search
   * filter params - as well as optional http headers. Injected with the
   * Injector.
   */
  protected dataApi: TtDataSourceApi<T>;

  /**
   * Number of items per page, default 30. Use {@see self.itemsPerPage} to
   * change this value.
   */
  protected pageSize: number = 30;

  /**
   * API Platform key designated to the limit of items per page. Use
   * {@see self.itemsPerPageKey} to change this key.
   */
  protected pageSizeKey: string | null = 'itemsPerPage';

  public set sort(instance: Sorting | undefined) {
    this.sortInstance$.next(instance ?? null);
  }

  public get sort(): Sorting | undefined {
    return this.sortInstance$.getValue() ?? undefined;
  }

  protected sortInstance$: BehaviorSubject<Sorting | null> = new BehaviorSubject<Sorting | null>(null);


  /**
   * API Platform key designated to sorting results based on column and
   * direction.
   */
  protected orderKey: string = 'order';

  /**
   * Default HttpHeaders to send to the API.
   */
  protected defaultHeaders?: HttpHeaders;

  /**
   * Default HttpParams to send to the API.
   */
  protected defaultParams?: HttpParams;

  /**
   * Results of the last API request.
   */
  protected results$: BehaviorSubject<Hydra<T>> = new BehaviorSubject<Hydra<T>>(null as never);

  /**
   * If the first chunk has been loaded.
   */
  protected hasLoadedFirstChunk$: Subject<void> = new Subject<void>();

  /**
   * Subject that stops active request that are hooked in to this via the
   * takeUntil operator.
   */
  protected stopRequest$: Subject<void> = new Subject<void>();

  /**
   * Sentry Error Handler service. Injected with the Injector.
   */
  private errorHandler: SentryErrorHandler;

  constructor(
    protected injector: Injector,
    protected model: ClassConstructor<T>,
    protected resourceUrl: string | ApiCallFunction<T>,
    protected sortOrInput?: Observable<TtSearchFormData> | Sorting,
    protected textSearchKey?: string,
    sort?: Sorting,
  ) {
    super();

    // can be undefined if not existing
    this.errorHandler = this.injector.get<SentryErrorHandler>(SentryErrorHandler);
    // can be undefined if not existing
    this.userFeedbackService = this.injector.get<ToastService>(ToastService);
    this.dataApi = this.injector.get<TtDataSourceApi<T>>(TtDataSourceApi);
    this.filterControllerService = new FilterControllerService();
    this.searchInput$ = new BehaviorSubject(undefined as never);

    if (sortOrInput instanceof Observable) {
      this.searchInput$ = sortOrInput as Observable<TtSearchFormData>;
      this.sortInstance$.next(sort ?? null);
    } else {
      this.sortInstance$.next(sortOrInput ?? sort ?? null);
    }

  }

  /**
   * #### --------
   * ### TtHydraPaginationDataSource -> `init`
   * Register the chunk observer and (re)start the first call to the API. Use
   * this function after changing the setters for the HttpParams or HttpHeaders,
   * or chain it after the constructor, like so:
   *
   * `const dataSource = new TtXDataSource(...).init();`
   *
   * Use the `noFirstChunkStartWith` toggle to skip the `startWith` pipes for
   * the first chunk observer.
   *
   * Use the `noHttpParamsFromMatSort` toggle to skip the MatSort.sortChanges
   * EventEmitter stream as HttpParam filters with the API call.
   */
  public init(noFirstChunkStartWith?: boolean, noHttpParamsFromMatSort?: boolean): this {
    this.destroy$.next();
    this.registerResultsObserver(noFirstChunkStartWith, noHttpParamsFromMatSort);
    return this;
  }


  /**
   * Set a custom feedback service instead of the default ToastService. Pass
   * service must at least have an `error` function that accepts two properties:
   *
   * - `message: string` - default message
   * - `error: HttpErrorResponse` - Angular HttpErrorResponse object.
   */
  public set feedbackService(service: Type<TtDataSourceFeedbackService>) {
    this.userFeedbackService = this.injector.get<TtDataSourceFeedbackService>(service);
  }

  /**
   * Set the total items per page that will be returned per pagination call.
   */
  public set itemsPerPage(amount: number) {
    this.pageSize = amount ?? 30;
  }

  /**
   * Override the key used in the HttpParams to designate the amount of items
   * that will be returned per pagination call. If no page size can be sent as
   * a query param, set to null.
   */
  public set itemsPerPageKey(key: string | null) {
    this.pageSizeKey = key ?? 'itemsPerPage';
  }

  /**
   * Override the key used when setting sorting options via the Sorting interface.
   */
  public set sortingKey(key: string | null) {
    this.orderKey = key ?? 'order';
  }

  /**
   * Set additional HttpHeaders to use by default with each API call.
   */
  public set httpHeaders(headers: HttpHeaders) {
    this.defaultHeaders = headers;
  }

  /**
   * Set additional HttpHeaders to use by default with each API call.
   */
  public set httpParams(params: HttpParams) {
    this.defaultParams = params;
  }

  /**
   * #### --------
   * ### TtHydraPaginationDataSource -> `observer$`
   *
   * Returns the results as a hot observable. Use this to implement pipes that
   * transform or adjust the results in extending classes.
   *
   * @inheritDoc
   */
  public get observer$(): Observable<T[]> {
    return this.results$.pipe(
      map((results) => results?.toArray() ?? []),
      shareReplay(1),
    );
  }

  /**
   * Returns the available items from the current results, but filtered to have
   * all null items used to determine loading removed, so counting isn't skewed.
   */
  public get available$(): Observable<number> {
    return this.results$.asObservable().pipe(
      startWith({ totalItems: 0, collection: [] }),
      map((hydra) => hydra?.collection?.filter((item) => item !== null)?.length ?? 0),
    );
  }

  /**
   * Get the current available results as an observable stream.
   */
  public get totalItems$(): Observable<number> {
    return this.results$.asObservable().pipe(
      startWith({ totalItems: 0 }),
      map((hydra) => (hydra?.totalItems ?? 0)),
    );
  }

  /**
   * Returns the loading state of the datasource. Includes checking the results
   * for loading states.
   */
  public override get loading$(): Observable<boolean> {
    return combineLatest([
      this.results$,
      this.loadingState$,
    ]).pipe(
      map(([results, loading]) => {
        return loading || results.getIri() === LOADING_HYDRA_KEY || results.collection.some((item) => item === null);
      }),
    );
  }

  /**
   * @inheritDoc
   */
  public availableResults(): Promise<number | null> {
    const results: Hydra<T> = this.results$.value;
    if (results) {
      return Promise.resolve(results.totalItems);
    }

    return Promise.resolve(null);
  }

  /**
   * @inheritDoc
   */
  public override connect(collectionViewer: CollectionViewer): Observable<T[] | readonly T[]> {
    return super.connect(collectionViewer).pipe();
  }

  /**
   * @inheritDoc
   */
  public disconnect(collectionViewer: CollectionViewer): void {
    this.destroy();
  }

  /**
   * @inheritDoc
   */
  public override destroy(): void {
    super.destroy();
  }

  /**
   * @inheritDoc
   */
  public async next(): Promise<void> {
    this.loadingState$.next(true);
    this.loadChunk$.next();

    if (await this.last()) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      firstEmitFrom(this.loadingState$.pipe(
        skipWhile(loading => loading),
        first(),
      )).then(() => resolve());
    });
  }

  /**
   * @inheritDoc
   */
  public last(): Promise<boolean> {
    const results: Hydra<T> = this.results$.value;
    if (results) {
      return Promise.resolve(results.collection.length === results.totalItems);
    }

    return Promise.resolve(false);
  }


  /**
   * Loading placeholder for Hydra items, to override results$ stream when
   * loading multiple pages.
   */
  protected loadingHydra(length: number = 10, total?: number): Hydra<T> {
    const collection: null[] = super.loadingPlaceholder(length);
    return {
      collection: collection,
      totalItems: total ?? length,
      toArray: () => {
        return collection;
      },
      getIri: () => {
        return LOADING_HYDRA_KEY;
      },
    } as unknown as Hydra<T>;
  }

  /**
   * Stop a request like {@see loadRemainingChunks} during execution. Clears all
   * loading Hydra items (i.e. nulls) from the collection after stopping the
   * request and sets the loading state to false. The active request is reset to
   * have a page number less than it currently is.
   */
  public stopRequest(): void {
    this.stopRequest$.next();
    const current: Hydra<T> = this.results$.value;
    current.collection = current.collection.filter((item) => item !== null);
    this.results$.next(current);

    const request: TtDataSourceActiveRequest = this.activeRequest$.value;
    request.page = request.page > 1 ? request.page - 1 : request.page;
    this.activeRequest$.next(request);
    this.loadingState$.next(false);
  }


  /**
   * Function to extend what needs to be done when the API request has
   * completed or failed. Extend this to add more actions.
   */
  protected completeGetChunkRequest(): void {
    this.loadingState$.next(false);
  }

  /**
   * Use the generic data API to create a request and apply error handling.
   */
  protected getChunk(request: TtDataSourceActiveRequest, noLoad?: boolean): Observable<Hydra<T>> {
    if (!noLoad) {
      this.loadingState$.next(true);
    }
    const params: HttpParams = this.createParamsFromRequest(request);

    let observable: Observable<Hydra<T>>;
    if (typeof this.resourceUrl === 'string') {
      observable = this.dataApi.getData(this.model, this.resourceUrl, params, this.defaultHeaders);
    } else {
      observable = this.resourceUrl(params, this.defaultHeaders);
    }
    return observable.pipe(
      catchError((error: HttpErrorResponse, expected) => {
        this.errorHandler.captureError(error);
        const message: string = 'Er is iets misgegaan bij het ophalen van de resultaten.';
        this.propagateErrorMessage(message, error);
        return expected;
      }),
      filter((results: Hydra<T>) => !!(results)),
      tap({
        next: (results: Hydra<T>) => {
          this.unfilteredTotal$.next(results.totalItems);
        },
        complete: () => {
          if (!noLoad) {
            this.completeGetChunkRequest();
          }
        },
      }),
    );
  }

  /**
   * Convert the ActiveRequest to an HttpParams object.
   */
  protected createParamsFromRequest(request: TtDataSourceActiveRequest): HttpParams {
    let params: HttpParams = this.defaultParams ?? new HttpParams();

    // Since not all endpoints accept a limit, only add if key is set.
    if (this.pageSizeKey) {
      params = params.set(this.pageSizeKey, request.limit.toString());
    }
    params = params.set('page', request.page.toString());

    if (request.sorting && request.sorting.direction !== TtDataSourceSorting.CLEAR) {
      params = params.set(`${this.orderKey}[${request.sorting.active}]`, request.sorting.direction.toLowerCase());
    }

    if (this.textSearchKey && request.filters?.searchString) {
      params = params.set(this.textSearchKey, request.filters.searchString);
    }

    if (request.other) {
      for (const [key, value] of Object.entries(request.other)) {
        params = params.append(key, String(value).toString());
      }
    }

    params = this.createParamsFromFilters(params, request.filters?.filters ?? []);

    return params;
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Loop through the filters and append the HttpParams object.
   */
  private createParamsFromFilters(params: HttpParams, filters: Filter[]): HttpParams {
    for (const searchFilter of filters) {
      //@ts-ignore
      params = params.append(searchFilter.filterGroup.value, String(searchFilter.value).toString());
    }

    return params;
  }

  /**
   * Use the (pre)defined user feedback service to either create a toast, or
   * a different feedback message, using the FeedbackService interface.
   */
  private propagateErrorMessage(message: string, error: HttpErrorResponse): void {
    if (this.userFeedbackService instanceof ToastService) {
      this.userFeedbackService.error(message);
    } else {
      this.userFeedbackService.error(message, error);
    }
  }

  /**
   * Combines the chunk creation observables and subscribe to both. When either
   * of them fires off, the latest results and request values are taken and used
   * to see if the request page is 1 (a new chunk) or higher (an additional
   * chunk) and the results stream is updated accordingly.
   */
  private registerResultsObserver(clean?: boolean, ignoreSort?: boolean): void {
    merge(
      this.createFirstChunkObserver(clean, ignoreSort),
      this.createNextChunkObserver(),
    ).pipe(
      takeUntil(this.destroy$),
      filter(results => !!(results)),
    ).subscribe((current) => {
      const previous: Hydra<T> = this.results$.value;
      const request: TtDataSourceActiveRequest = this.activeRequest$.value;

      if (request?.page > 1 && previous) {
        previous.collection = previous.collection.concat(current?.collection ?? []);
        this.results$.next(previous);
        return;
      }

      this.results$.next(current);
    });
  }

  /**
   * Combines the search input and sorting observables, if available, or null
   * streams if not, to create a new ActiveRequest object that is then mapped
   * and used to create a new GET request from the API.
   */
  private createFirstChunkObserver(clean?: boolean, ignoreSort?: boolean): Observable<Hydra<T>> {
    const emitter: Observable<null> = of(null);
    const sortEmitter: Observable<SortInterface | null> = this.sortInstance$.pipe(
      switchMap(sort => {
        if (ignoreSort) {
          return emitter;
        }
        return (sort) ? sort.sortChange : emitter;
      }),
    );
    return combineLatest([
      this.searchInput$.pipe(startWith(undefined)),
      sortEmitter.pipe(startWith(ignoreSort ? undefined : this.sortInstance$.getValue() as SortInterface)),
    ]).pipe(
      skip(clean ? 1 : 0),
      takeUntil(this.destroy$),
      map(([filters, sorting]) => {
        const request: TtDataSourceActiveRequest = {
          filters: filters as TtSearchFormData,
          sorting: sorting ?? undefined,
          limit: this.pageSize,
          page: 1,
        };
        this.activeRequest$.next(request);
        this.hasLoadedFirstChunk$.next();
        return this.activeRequest$.value;
      }),
      switchMap((request) => this.getChunk(request)),
    );
  }

  /**
   * Observes the loadChunk subject and uses the last ActiveRequest to add an
   * additional page number to the request, before updating the ActiveRequest
   * and sending that value to get an additional chunk.
   */
  private createNextChunkObserver(): Observable<Hydra<T>> {
    return this.loadChunk$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.activeRequest$),
      map(([, current]) => {
        const request: TtDataSourceActiveRequest = current ?? {
          page: 1,
          limit: this.pageSize,
        };

        this.activeRequest$.next({
          ...request,
          page: request.page + 1,
        });
        return this.activeRequest$.value;
      }),
      switchMap((request) => this.getChunk(request)),
    );
  }
}

export enum UploadExceptionType {
  API = 'API',
  EMAIL = 'EMAIL',
  LEVEL = 'LEVEL',
  LEVEL_YEAR = 'LEVEL_YEAR',
  EXISTS = 'EXISTS',
  EXISTS_AS_NON_PUPIL = 'EXISTS_AS_NON_PUPIL',
  IN_SAME = 'IN_SAME',
  UNKNOWN = 'UNKNOWN',
  VMBO_OR_MAVO = 'VMBO_OR_MAVO',
}

<div class="messages">
  <ng-container *ngFor="let message of messages$ | async">
    <div [class]="'wrapper ' + message.type"
         *ngIf="message.state === ResponseMessageState.ACTIVE">
      <div class="icon">
        <ng-container [ngSwitch]="message.type">
          <fa-icon *ngSwitchCase="ResponseMessageType.WARNING" [icon]="['fas', 'circle-exclamation']"></fa-icon>
          <fa-icon *ngSwitchCase="ResponseMessageType.DANGER" [icon]="['fas', 'triangle-exclamation']"></fa-icon>
          <fa-icon *ngSwitchCase="ResponseMessageType.INFO" [icon]="['fas', 'circle-info']"></fa-icon>
          <fa-icon *ngSwitchCase="ResponseMessageType.SUCCESS" [icon]="['fas', 'circle-check']"></fa-icon>
        </ng-container>
      </div>
      <div class="message">
        <div>
          <ng-container *ngFor="let markup of message.markup; trackBy: trackByMarkup">
            <span [class.block]="markup.display === MarkupDisplay.BLOCK"
                  [class.inline-block]="markup.display === MarkupDisplay.INLINE_BLOCK"
                  [class.italic]="markup.italic"
                  [class.indent]="markup.indent"
                  [class.newline]="markup.newline"
                  [class.bullet]="markup.bullet"
                  [class.bold]="markup.styleBold">
              <ng-container *ngIf="markup.url; else messageValue">
                <a href="{{ markup.urlPrefix }}{{ markup.urlValue || markup.value }}">
                  <ng-container *ngTemplateOutlet="messageValue"></ng-container>
                </a>
              </ng-container>

              <ng-template #messageValue> {{ markup.value }} </ng-template>
            </span>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>

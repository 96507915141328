import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ProgressNotificationType } from '../shared/components/progress-notification/progress-notification.enum';
import { ProgressPupilUploadService, StorageItem } from '../shared/services/progress/progress-pupil-upload.service';

@Component({
  selector: 'app-menu-layout',
  templateUrl: './menu-layout.page.html',
  styleUrls: ['./menu-layout.page.scss'],
})
export class MenuLayoutPage implements OnInit, OnDestroy {
  /**
   * Whether to display the notification containing information about the
   * progress of a pupil Excel file upload.
   */
  public displayProgressNotification$: Observable<boolean>;

  /**
   * Enum pass through for progress notification types.
   */
  public readonly ProgressNotificationType: typeof ProgressNotificationType = ProgressNotificationType;

  private destroy$: Subject<void> = new Subject();

  constructor(public progressPupilUploadService: ProgressPupilUploadService) {}

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.progressPupilUploadService.init().then(() => {
      this.displayProgressNotification$ = this.createDisplayProgressNotificationObservable();
    });
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Create an observable that contains a boolean value. When the value is
   * true the progress notification should be display. When the value is false
   * it should be hidden.
   */
  private createDisplayProgressNotificationObservable(): Observable<boolean> {
    return this.progressPupilUploadService
      .getInProgressForCurrentLocation$()
      .pipe(
        takeUntil(this.destroy$),
        map((items: StorageItem[]) => {
          return items.length > 0;
        }),
      );
  }
}

import { Component, Input, OnInit } from '@angular/core';

/**
 * A generic implementation of a progress donut. You can determine how the
 * element looks by passing variables and applying styling via CSS variables.
 *
 * @example `
 * <app-progress-donut [value]="50"
 *                     [size]="300"
 *                     [strokeWidth]="10"
 *                     [showPercentage]="true"
 *                     [text]="Loading...">
 * </app-progress-donut>`
 */
@Component({
  selector: 'app-progress-donut',
  templateUrl: './progress-donut.component.html',
  styleUrls: ['./progress-donut.component.scss'],
})
export class ProgressDonutComponent implements OnInit {
  /**
   * The width and height of the progress donut.
   */
  @Input() public size: number = 32;

  /**
   * The width of the progress donut itself.
   */
  @Input() public strokeWidth: number = 4;

  /**
   * Set the current progress value of the progress donut. This can range from
   * 0 to 100. For values smaller than 0 this will be altered to 0. For values
   * larger than 100 this will be altered to 100.
   */
  @Input() public set value(value: number | undefined | null) {
    value = value || 0;
    if (value < 0) {
      value = 0;
    }

    if (value > 100) {
      value = 100;
    }

    this._value = value;
    this.calculate();
  }

  /**
   * A text that will be displayed in the center of the progress donut.
   */
  @Input() public text: string | undefined | null;

  /**
   * Whether the percentage will be shown inside the progress donut.
   */
  @Input() public showPercentage: boolean;

  /**
   * The internal value of the progress donut. This represents the percentage
   * the progress donut is filled. 0 = completely empty. 100 = completely filled.
   */
  public _value: number = 0;

  /**
   * The radius of the progress donut.
   */
  public radius: number;

  /**
   * The circumference of the progress donut. This is needed to determine the
   * total amount that can be filled.
   */
  public circumference: number;

  /**
   * The amount of the circumference of the progress donut that should still be
   * filled.
   */
  public circumferenceRemaining: number;

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.calculate();
  }

  /**
   * Do some calculations to render a correct progress donut.
   */
  private calculate(): void {
    // When calculating the radius we have to take the stroke width into account
    // as half of that width ends up 'outside' of the circle.
    this.radius = this.size / 2 - this.strokeWidth / 2;
    this.circumference = 2 * Math.PI * this.radius;
    this.circumferenceRemaining = this.circumference - (this.circumference / 100) * this._value;
  }
}

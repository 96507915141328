import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { LocationsStoreService } from '@school-dashboard/data-access-locations';
import { LocationModel, UserModel } from '@school-dashboard/models';
import {
  InvalidRoleError,
  OAuthGuard as TtOAuthGuard,
  OAuthService,
  UserInterface,
  UserService,
} from '@techniek-team/oauth';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OAuthGuard extends TtOAuthGuard {
  /**
   * Boolean toggle to prevent every page change triggering a (potential) call
   * to the locations' endpoint.
   */
  private privileged: boolean = false;

  /**
   * Roles that have access to this dashboard.
   */
  private allowedRoles: string[] = [
    'ROLE_SKOLEO_CORE_ADMIN',
    'ROLE_SKOLEO_CORE_READ_ONLY_ADMIN',
    'ROLE_SKOLEO_CORE_COORDINATOR',
    'ROLE_SKOLEO_CORE_SCHOOLCONTACT',
  ];

  constructor(
    protected override oauthService: OAuthService,
    protected override userService: UserService<UserModel>,
    protected override toastController: ToastController,
    storage: Storage,
    private locationsStoreService: LocationsStoreService,
  ) {
    super(oauthService, userService, toastController, storage);
  }

  /**
   * Full copy of the original OAuthGuard version, but added the check for the
   * right privileges.
   *
   * @inheritDoc
   */
  public override async canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    await this.saveOriginUrl(state);
    try {
      const authenticated: boolean = await this.userService.isAuthenticated();

      if (!authenticated) {
        return this.oauthService.getLoginUrlTree();
      }

      this.checkUserRoles(await this.userService.getUser());
      await this.checkPrivilegesUsingLocationApi();

      return authenticated;
    } catch (error) {
      this.privileged = false;
      const isHandled: boolean | UrlTree = await this.handleError(error);
      if (isHandled instanceof UrlTree) {
        return isHandled;
      }
      // If the handleError function didn't handle the error it will return false
      // as a fallback we're going to redirect the user to the login page.
      return this.oauthService.getLoginUrlTree();
    }
  }

  /**
   * Check if the user has the proper role to check this dashboard.
   * @inheritDoc
   */
  protected override checkUserRoles(currentUser: UserInterface): void {
    if (!currentUser) {
      throw new InvalidRoleError();
    }
    const roles: string[] = currentUser.roles ?? [];
    const allowed: boolean = this.allowedRoles.some((role) =>
      roles.includes(role),
    );
    if (!allowed) {
      throw new InvalidRoleError();
    }
  }

  /**
   * If the locations-endpoint returns a 401, the user doesn't have the proper
   * rights to use the Core Api, even if the SSO says they do, so they should be
   * yeeted back to the login page. Since the locations are used by default
   * anyway, might as well use and store them if successful.
   */
  private async checkPrivilegesUsingLocationApi(): Promise<void> {
    if (this.privileged) {
      return;
    }

    try {
      // todo we need to make a proper endpoint for this like a profile endpoint or something.
      //   or even better JUST CHECK THE USER ROLES INSTEAD OF THIS NASTYNESS @harm jacob 01-10-2023
      const locations: LocationModel[] = await firstValueFrom(this.locationsStoreService.locations$);
      if (locations.length === 0) {
        throw new Error('very nasty very crappy error');
      }
      this.privileged = true;
    } catch (error) {
      if (error?.status === 401) {
        this.privileged = false;
        throw new InvalidRoleError();
      }
      // No need to log out if it's just a hiccup in the backend, right?
      return Promise.resolve();
    }
  }
}

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TtDataSourceActiveRequest } from './models/active-request.interface';
import { TtBaseDataSource } from './tt-base.datasource';

/**
 * This class is a extension of TTBaseDataSource and implements some basic
 * pagination options.
 *
 * ### Functions:
 * - `next` - Call to trigger the retrieval of a new chunk.
 * - `last` - Return if the total retrieved items equals the
 * expected total number of items like the `hydra:totalItems` property.
 * - `availableResults` - Return the available results
 * - `destroy` - An onDestroy implementation that at by default
 * completes the `destroy$` subject.
 * - `whenLoading` - CDK Table function that can be used with row definitions.
 *
 * ### Properties:
 * - `loadingState$` - Hook for current loading state (true/false)
 * - `loading$` - Exposed observable of `loadingState$`.
 * - `activeRequest$` - Hook for the current ActiveRequest object.
 * {@see TtDataSourceActiveRequest}
 * - `loadChunk$` - Hook to trigger new API calls from.
 * - `destroy$` - Subscription completion subject.
 */
export abstract class TtBasePaginationDataSource<T, Request = TtDataSourceActiveRequest> extends TtBaseDataSource<T> {

  /**
   * Use this method to trigger fetching the next chunk/page of data. The
   * Promise should resolve when the data is completely loaded.
   */
  public abstract next(): Promise<void>;

  /**
   * This method returns whether or not the last chunk has loaded.
   */
  public abstract last(): Promise<boolean>;

  /**
   * This method returns the total available results, regardless of the current
   * loaded chunk of data. This is taken from Hydra's `totalItems` property in
   * the default Collection response. If there are no results stored, returns
   * `null` instead.
   */
  public abstract availableResults(): Promise<number | null>;

  /**
   * BehaviorSubject with the current loading state. Expose via the `loading$`
   * getter. Default start value is `true`.
   */
  protected loadingState$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  /**
   * Current metadata for the request.
   */
  protected activeRequest$: BehaviorSubject<Request> = new BehaviorSubject({} as unknown as Request);

  /**
   * Void subject used to trigger a request.
   */
  protected loadChunk$: Subject<void> = new Subject<void>();

  /**
   * Void subject used by other subscriptions can hook in to to yeet themselves.
   */
  protected destroy$: Subject<void> = new Subject<void>();

  /**
   * Function containing all kinds of actions that need to be completed when the
   * data source is destroyed, like terminating subscriptions to observables.
   * If the DataSource is used in a CDK Table, call this function in the
   * `disconnect()` function.
   */
  public destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Get the loadingState$ stream as an immutable observable.
   */
  public get loading$(): Observable<boolean> {
    return this.loadingState$.asObservable();
  }

  /**
   * Helper function which can be used to determine when to show a loading
   * template in a CDK Table or other view.
   *
   * @example
   * <cdk-table [dataSource]="dataSource">
   *   <ng-container cdkColumnDef="loading">
   *     <cdk-cell *cdkCellDef="let sessions">
   *       <ion-skeleton-text animated></ion-skeleton-text>
   *     </cdk-cell>
   *   </ng-container>
   *   <cdk-row *cdkRowDef="let row; columns: displayedColumns; when: dataSource.whenLoading(false)">
   *   </cdk-row>
   *   <cdk-row *cdkRowDef="let row; columns: loadingColumns; when: dataSource.whenLoading(true)" class="loading">
   *   </cdk-row>
   * </cdk-table>
   */
  public whenLoading(is: boolean): (index: number, rowData: null|unknown) => boolean {
    if (is) {
      return (index: number, rowData: null|unknown): boolean => !(rowData);
    }
    return (index: number, rowData: null|unknown): boolean => !!(rowData);
  }
}

import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { denormalize } from '@techniek-team/class-transformer';
import { Filter, FilterGroup, FilterInterface } from '@techniek-team/filter-search';
import { IonColor, IonColorType } from '@techniek-team/lyceo-style';
import { Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { NoMultiSelectionError } from './errors/no-multi-selection.error';
import { OptionInterface } from './models/option.interface';
import { Option } from './models/option.model';

type OnChangeCallback = (_data: { filters: Filter[]; values: string[] }) => void;
type OnCallback = (_touched: boolean) => void;

/**
 * Form control gives a list of buttons that serve as an alternative (multiple)
 * select form control. Control validation is handled by the FormGroup this
 * control is part of, since no internal validation is done.
 *
 * - `source` input acts similarly to any other select, expecting an array of
 * SearchFilter class instances with (at least) label and value.
 * - `multiple` input toggle for multiple select (default false)
 *
 * @example
 * const source = [
 *  {
 *    value: 0,
 *    label: 'yes',
 *  },
 *  {
 *    value: 1,
 *    label: 'no',
 *  },
 *  new Filter(2, 'maybe'),
 *  new Option(2, 'maybe'),
 * ];
 *
 * <app-toggle-select
 *  [source]="source"
 *  [multiple]="false"
 *  formControlName="yesOrNo">
 * </app-toggle-select>
 */
@Component({
  selector: 'tt-chip-control',
  templateUrl: './tt-chip-control.component.html',
  styleUrls: ['./tt-chip-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TtChipControlComponent),
      multi: true,
    },
  ],
})
export class TtChipControlComponent implements OnInit, ControlValueAccessor {

  /**
   * Setter for the selectable chips (options) for this formControl.
   */
  @Input()
  public set source(source: (Option | OptionInterface | Filter | FilterInterface)[] | FilterGroup) {
    if (source instanceof FilterGroup) {
      this._source = source;
      this.onSourceChange$.next();
      return;
    }

    if (!Array.isArray(source)) {
      return;
    }

    const filters: Filter[] = [];
    for (let item of source) {
      if (item instanceof Filter || item instanceof Option) {
        filters.push(item);
        continue;
      }

      filters.push(denormalize(Filter, item));
    }
    this._source.filters = filters;
  }

  /**
   * Allow multiple options to be selected (default: false).
   */
  @Input()
  public set multiple(multiple: boolean) {
    this._source.multiple = multiple;
  }

  /**
   * Set the color of the chips.
   */
  @Input() public color: IonColor = IonColorType.PRIMARY;

  /**
   * Set the color of the chips.
   */
  @Input() public size: 'small' | 'medium' | 'large' = 'small';

  /**
   * Options used in template, converted from the input
   * to make sure it's always an array.
   */
  public _source: FilterGroup = new FilterGroup(window.crypto.randomUUID());

  /**
   * @inheritDoc
   */
  public onChange: OnChangeCallback = (_data: { filters: Filter[]; values: string[] }) => { /* callback */ };

  /**
   * @inheritDoc
   */
  public onTouch: OnCallback = (_touched: boolean) => { /* callback */ };

  /**
   * Subject which completes all hot observers on emit.
   */
  private onDestroy$: Subject<void> = new Subject<void>();

  /**
   * Subject which completes when the source property get set.
   */
  private onSourceChange$: Subject<void> = new Subject<void>();

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.createOnChangeObserver();
  }

  /**
   * @inheritDoc
   */
  public registerOnChange(fn: OnChangeCallback): void {
    this.onChange = fn;
  }

  /**
   * @inheritDoc
   */
  public registerOnTouched(fn: OnCallback): void {
    this.onTouch = fn;
  }

  /**
   * @inheritDoc
   */
  public setDisabledState(isDisabled: boolean): void {
    // Angular is so vast with settings the enable/disable state that it results in
    // ExpressionChangedAfterItHasBeenCheckedError this small timeout fixes this.
    setTimeout(() => {
      if (isDisabled) {
        this._source.disable();
        return;
      }
      this._source.enable();
    }, 20);
  }

  /**
   * @inheritDoc
   */
  public writeValue(data: (Filter | unknown)[] | Filter | unknown | null): void {
    if (data === null) {
      this.onTouch(false);
      this._source.clear();
      return;
    }

    if (Array.isArray(data) && data.length > 1 && !this.multiple) {
      throw new NoMultiSelectionError();
    }

    if (!Array.isArray(data)) {
      this._source.select(data);
      return;
    }

    for (let item of data) {
      this._source.select(item);
    }
  }

  /**
   * Returns true when multiple options may be selected.
   */
  public get multiple(): boolean {
    return this._source.multiple;
  }

  /**
   * (De)select an item from the current list.
   */
  public toggle(filter: Filter | unknown): void {
    this._source.toggle(filter);
    this.onTouch(true);
  }

  /**
   * Create a subscriber which emits the changed values to the onChange.
   */
  private createOnChangeObserver(): void {
    this.onSourceChange$.pipe(
      startWith(undefined as void),
      switchMap(() => {
        return this._source.selected$
          .pipe(
            takeUntil(this.onDestroy$),
            takeUntil(this.onSourceChange$),
            map(changed => {
              const values: Set<string> = new Set<string>();
              for (let item of changed) {
                for (let value of (Array.isArray(item.value) ? item.value : [item.value])) {
                  values.add(value);
                }
              }
              return {
                filters: changed,
                values: [...values],
              };
            }),
          );
      }),
    ).subscribe(changed => this.onChange(changed));
  }
}

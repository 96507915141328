import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Inject, inject, InjectionToken, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { JsonLd } from '@techniek-team/class-transformer';
import { CacheService } from '@techniek-team/services';
import { consoleInDev } from '@techniek-team/common';
import { Fetch } from './decorators/fetch.decorator';
import { FetchPipe } from './fetch.pipe';
import { PreFetchService } from './pre-fetch/pre-fetch.service';
import { CacheServiceFetchStorage } from './storage/cache-service.fetch-storage';
import { FetchStorageInterface } from './storage/fetch-storage.interface';
import { TtFetchConfig } from './tt-fetch-config';

@NgModule({
  declarations: [
    FetchPipe,
  ],
  exports: [
    FetchPipe,
  ],
})
export class TtFetchModule {

  /**
   * @inheritDoc
   */
  public static forRoot(options: TtFetchConfig): ModuleWithProviders<TtFetchModule> {
    options = { ...new TtFetchConfig(), ...options };

    (options.storageServices ?? []).push(CacheServiceFetchStorage);

    return {
      ngModule: TtFetchModule,
      providers: [
        { provide: TtFetchConfig, useValue: options },
        { provide: PreFetchService },
        { provide: CacheServiceFetchStorage },
        {
          provide: APP_INITIALIZER,
          useFactory: (
            httpClient: HttpClient,
            cacheService: CacheService,
            config: TtFetchConfig,
            preFetch: PreFetchService,
            ...storages: FetchStorageInterface<unknown, unknown>[]
          ): Function => (): void => {
            consoleInDev(config.debug).debug('initializing Metadata');
            Reflect.defineMetadata('httpClient', httpClient, Fetch);
            Reflect.defineMetadata('cacheService', cacheService, Fetch);
            Reflect.defineMetadata('storages', storages, Fetch);
            Reflect.defineMetadata('baseUrl', config.baseUrl, Fetch);
            Reflect.defineMetadata('ttDebug', config.debug, Fetch);
            preFetch.initPreLoad();
          },
          deps: [HttpClient, CacheService, TtFetchConfig, PreFetchService, ...(options.storageServices ?? [])],
          multi: true,
        },
      ],
    };
  }
}

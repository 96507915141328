import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { LocationContract } from './contract/locations.contract';
import { locationsActions } from './locations.actions';

export const LOCATIONS_FEATURE_KEY = 'locations';

export interface LocationsState extends EntityState<LocationContract> {
  selectedId?: string; // which Locations record has been selected
  loaded: boolean; // has the Locations list been loaded
  loading: boolean; // is the Locations busy with loading
  error?: string | null; // last known error (if any)
  totalItems: number | null;
}

export function selectId(item: LocationContract): string {
  // In this case this would be optional since primary key is id.
  const iriRegex: RegExp = new RegExp(/^\/.*\/([A-z\d\-_]*)/);
  const match: string[] | null = item['@id'].match(iriRegex);
  return match ? match.pop() ?? '' : '';
}

export const locationsAdapter: EntityAdapter<LocationContract> = createEntityAdapter<LocationContract>({
  selectId: selectId,
});

export const initialLocationsState: LocationsState = locationsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  loading: false,
  totalItems: null,
});

const reducer = createReducer(
  initialLocationsState,
  on(
    locationsActions.initLocations,
    state => ({ ...state, loaded: false, loading: true, error: null }),
  ),
  on(
    locationsActions.loadLocationsSuccess,
    (state, { locations, totalItems }) => locationsAdapter.setAll(locations, {
      ...state,
      loaded: true,
      loading: false,
      totalItems: totalItems,
    }),
  ),
  on(
    locationsActions.loadLocationsFailure,
    (state, { error }) => ({ ...state, error: error }),
  ),
  on(locationsActions.selectLocation, (state, { locationId }) => ({
    ...state,
    selectedId: locationId,
  })),
);

export function locationsReducer(
  state: LocationsState | undefined,
  action: Action,
): LocationsState {
  return reducer(state, action);
}

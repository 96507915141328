import { ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Color } from '@ionic/core';
import { ToastButton } from '@ionic/core/dist/types/components/toast/toast-interface';


export interface ToastOptions {
  message: string;
  duration?: number;
  color?: Color;
  cssClass?: string | string[];
  position?: 'top' | 'bottom' | 'middle';
  buttons?: (ToastButton | string)[];
}

function isToastOptionsObject(options: string | ToastOptions): options is ToastOptions {
  return !(typeof options === 'string');
}

/**
 * @example
 * this.toast.create('De wijzigingen zijn opgeslagen!');
 * this.toast.error('Oeps! Er is iets misgegaan bij het opslaan');
 */
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastCtrl: ToastController,
  ) {}

  /**
   * @inheritDoc
   */
  public create(
    message?: string,
    duration?: number,
    color?: Color,
    cssClass?: string | string[],
    position?: 'top' | 'bottom' | 'middle',
    buttons?: (ToastButton | string)[],
  ): Promise<void>;

  public create(
    options: ToastOptions,
  ): Promise<void>;

  /**
   * Create a toast to show to the user.
   * When you want to create an error toast, please use {@see error()}.
   */
  public create(
    message: string | ToastOptions = 'Er is iets misgegaan. Probeer het opnieuw.',
    duration: number = 5000,
    color: Color = 'dark',
    cssClass: string | string[] = [],
    position: 'top' | 'bottom' | 'middle' = 'bottom',
    buttons: (ToastButton | string)[] = [],
  ): Promise<void> {
    if (isToastOptionsObject(message)) {
      duration = message?.duration ?? 5000;
      color = message?.color ?? 'dark';
      cssClass = message?.cssClass ?? [];
      position = message?.position ?? 'bottom';
      buttons = message?.buttons ?? [];
      message = message?.message ?? 'Er is iets misgegaan. Probeer het opnieuw.';
    }

    return this.toastCtrl.create({
      message: message,
      duration: duration,
      color: color,
      cssClass: cssClass,
      position: position,
      buttons: buttons,
    }).then(newToast => newToast.present());
  }


  public error(
    message?: string,
    duration?: number,
    cssClass?: string | string[],
    position?: 'top' | 'bottom' | 'middle',
    buttons?: (ToastButton | string)[],
  ): Promise<void>;

  //eslint-disable-next-line @typescript-eslint/unified-signatures
  public error(options: ToastOptions): Promise<void>;

  public error(
    message: string | ToastOptions = 'Er is iets misgegaan. Probeer het opnieuw.',
    duration: number = 5000,
    cssClass: string | string[] = [],
    position: 'top' | 'bottom' | 'middle' = 'bottom',
    buttons: (ToastButton | string)[] = [],
  ): Promise<void> {
    if (isToastOptionsObject(message)) {
      duration = message?.duration ?? 5000;
      cssClass = message?.cssClass ?? [];
      position = message?.position ?? 'bottom';
      buttons = message?.buttons ?? [];
      message = message?.message ?? 'Er is iets misgegaan. Probeer het opnieuw.';
    }

    return this.create(message, duration, 'danger', cssClass, position, buttons);
  }
}

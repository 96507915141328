import { Gender, GenderDisplayValue } from '@school-dashboard/enums';
import { Exclude, Expose, Type } from 'class-transformer';
import { PersonEmailAddress, PersonPhoneNumber } from './person-contact-information.model';
import { PersonMinimal } from './person-minimal.model';

export class Person extends PersonMinimal {
  /**
   * The person's Skoleo id.
   */
  @Expose() public skoleoId?: number;

  /**
   * The first name of the person.
   */
  @Expose() public firstName: string;

  /**
   * The person's prefix to their last name.
   */
  @Expose({ name: 'lastNamePrefix' }) public prefix?: string;

  /**
   * The person's last name.
   */
  @Expose() public lastName: string;

  /**
   * The person's primary email-address address.
   */
  @Type(() => PersonEmailAddress)
  @Expose({ name: 'primaryEmailAddress' })
  public emailAddress?: PersonEmailAddress;

  /**
   * The person's primary email-address address.
   */
  @Type(() => PersonPhoneNumber)
  @Expose({ name: 'primaryPhoneNumber' })
  public phoneNumber?: PersonPhoneNumber;

  /**
   * The person's gender.
   */
  @Expose() public gender: Gender;

  /**
   * Get a readable version of the gender.
   */
  @Exclude() public get genderFormatted(): string {
    return (this.gender) ? GenderDisplayValue[this.gender]: 'onbekend';
  }

  /**
   * Getter for {@see _fullName}. If not set the fullName will be extracted from
   * the combination of {@see firstName}, {@see prefix} and {@see lastName}.
   */
  public override get fullName(): string {
    return (
      this._fullName
      ?? [this.firstName, this.prefix, this.lastName]
        .filter((name) => !!name)
        .join(' ')
    );
  }

  /**
   * Setter for {@see _fullName}.
   */
  @Expose() public override set fullName(fullName: string) {
    this._fullName = fullName;
  }

  /**
   * Combined last name and prefix, so the display value for lastName matches
   * the expected value. Sorting will still be done on lastName.
   */
  @Exclude() private lastNameAndPrefix: string;

  /**
   * Return the combined prefix and last name properties as a string.
   */
  @Exclude() public get displayLastName(): string {
    if (!this.lastNameAndPrefix) {
      this.lastNameAndPrefix = [this.prefix, this.lastName]
        .filter((name) => !!name)
        .join(' ');
    }
    return this.lastNameAndPrefix;
  }

  //eslint-disable-next-line @typescript-eslint/naming-convention
  @Exclude() private _initials: string;

  /**
   * Set and return the person's initials.
   */
  @Exclude() public get initials(): string {
    if (!this._initials && (this.firstName || this.lastName)) {
      this._initials = [this.firstName?.charAt(0), this.lastName?.charAt(0)]
        .filter((item) => !!item)
        .join('')
        .toUpperCase();
    }

    return this._initials;
  }
}

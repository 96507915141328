import { JsonLd, TsRange } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';
import { SchoolClass } from './school-class.model';

export class PupilEnrollment extends JsonLd {
  /**
   * School class for this enrollment.
   */
  @Type(() => SchoolClass)
  @Expose()
  public schoolClass: SchoolClass;

  /**
   * Validity range for this enrollment (school year length, usually).
   */
  @Type(() => TsRange)
  @Expose()
  public validityRange: TsRange;
}

import { Component, DestroyRef, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { LocationsStoreService } from '@school-dashboard/data-access-locations';
import { LocationModel } from '@school-dashboard/models';
import { IonColor, IonColorType } from '@techniek-team/lyceo-style';
import { BehaviorSubject, filter, Observable } from 'rxjs';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {

  public locationSearchControl: FormControl<LocationModel | null>;

  /**
   * Color of the Statistics Text.
   */
  @Input()
  public set color(color: IonColor) {
    if (color) {
      this.colorSubject$.next(color);
    }
  }

  /**
   * Color of the Statistics Text.
   */
  protected colorSubject$: BehaviorSubject<IonColor> = new BehaviorSubject<IonColor>(IonColorType.DARK);

  protected locations$: Observable<LocationModel[]>;

  constructor(
    protected locationsStoreService: LocationsStoreService,
    private element: ElementRef,
    private renderer: Renderer2,
    private destroyRef: DestroyRef,
  ) {
    this.locationsStoreService.init();
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.locationSearchControl = new FormControl<LocationModel | null>(null);
    this.createColorSubscriber();
    this.createValueChangeSubscriber();
    this.createOnActiveLocationSubscriber();
  }

  private createColorSubscriber(): void {
    this.colorSubject$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(color => {
        const previousColorClasses: string[] = this.element.nativeElement.className.split(' ').filter(item => {
          if (item) {
            return item.match(/ion-color-*./);
          }
          return false;
        });
        for (let className of previousColorClasses) {
          this.renderer.removeClass(this.element.nativeElement, className);
        }
        this.renderer.addClass(this.element.nativeElement, 'ion-color-' + (color ?? 'primary'));
      });
  }

  private createValueChangeSubscriber() {
    this.locationSearchControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        this.locationsStoreService.selectLocation(value);
      });
  }

  private createOnActiveLocationSubscriber() {
    this.locationsStoreService.active$.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(active => active?.getIri() !== this.locationSearchControl.value?.getIri()),
    ).subscribe(active => {
      this.locationSearchControl.setValue(active, { emitEvent: false });
    });
  }
}

import { JsonLd } from '@techniek-team/class-transformer';
import { Expose } from 'class-transformer';

export class LocationModel extends JsonLd {
  /**
   * Location name.
   */
  @Expose() public name!: string;

  public override toString(): string {
    return this.name;
  }
}

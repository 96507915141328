/**
 * Generic enum covering the various colours used by Ionic, among other things.
 * This is also used by ResponseMessage to determine the type of box color.
 */
export enum MessageColor {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
}

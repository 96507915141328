import { CheckType } from '@school-dashboard/enums';
import { Exclude } from 'class-transformer';
import { Track } from '../track.model';

export class CodelabTrack extends Track {

  @Exclude() public canAddTrackAttendances: boolean = false;

  @Exclude() public canDeleteTrackAttendances: boolean = false;

  @Exclude() public canEditTrackAttendances: boolean = false;

  @Exclude() public hasTrainings: boolean = false;

  @Exclude() public canHaveSubTracks: boolean = false;

  @Exclude() public canResendEmailTrackAttendance: boolean = false;

  @Exclude() public availableChecks: Map<CheckType, CheckType[]> = new Map<CheckType, CheckType[]>([]);
}

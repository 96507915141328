<ng-container
  *ngTemplateOutlet="
    resultList;
    context: {
      results: dataSource?.observer$ | async
    }
  "
></ng-container>

<ng-template let-results="results" #resultList>
  <app-school-dashboard-modal
    actionButtonText="Opnieuw uploaden"
    cancelButtonText="Annuleren"
    (action)="dialogAction($event)"
  >
    <div position-center>
      <app-colored-title
        color="tertiary"
        [isLoading]="!results?.length || results[0] === null"
      >
        <ng-container *ngIf="dataSource?.totalItems$ | async as errorCount">
          {{ errorCount }} {{ errorCount === 1 ? 'fout' : 'fouten' }} gevonden,
          corrigeer het bestand.
        </ng-container>
      </app-colored-title>
      <div
        class="info-cancel"
        *ngIf="results?.length && results[0] !== null; else loadingResults"
      >
        <p>
          <span class="count">{{ pupilUpload?.successfulCount }}</span>
          {{
            pupilUpload?.successfulCount === 1
              ? 'leerling is'
              : 'leerlingen zijn'
          }}
          succesvol geïmporteerd. Bij het importeren van
          <span class="count">{{ pupilUpload?.pupilsWithError }}</span>
          {{ pupilUpload?.pupilsWithError === 1 ? 'leerling' : 'leerlingen' }}
          is een fout opgetreden.
          <ng-container *ngIf="pupilUpload?.hasErrorFile">
            <br />
            <br />
            Download alle foute regels met de 'Download fouten' knop. Pas het
            bestand aan en upload het opnieuw. Kom je er niet uit? Neem gerust
            contact met ons op.
          </ng-container>
        </p>
      </div>
      <div class="info-cancel">
        <p>
          <ion-button
            *ngIf="pupilUpload?.hasErrorFile"
            color="primary"
            (click)="downloadErrors()"
            >Download fouten</ion-button
          >
        </p>
        <p>
          <ion-button color="secondary" fill="outline" (click)="cancelUpload()"
            >Importeren afbreken</ion-button
          >
        </p>
      </div>

      <ng-template #loadingResults>
        <div class="loading-info">
          <p>
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
          </p>

          <div>
            <ion-skeleton-text animated></ion-skeleton-text>
          </div>
        </div>
      </ng-template>

      <tt-response-message
        [watch]="_supportedResponseMessageKeys"
      ></tt-response-message>

      <ion-content
        [scrollY]="true"
        [scrollEvents]="true"
        (ionScroll)="dataSource?.setScrollPosition($event)"
      >
        <table
          mat-table
          matSort
          [dataSource]="dataSource"
          [trackBy]="trackBy"
          class="sticky darker-header"
          infinite-scroll-content
          #forInfScroll="infiniteScrollContent"
        >
          <ng-container matColumnDef="row">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Rij</th>
            <td class="cell accent" mat-cell *matCellDef="let element">
              <ng-container *ngIf="element; else showSkeletor">
                {{ element.rowNumber }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="fullName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Leerling</th>
            <td class="cell accent" mat-cell *matCellDef="let element">
              <ng-container *ngIf="element; else showSkeletor">
                <span class="bold">{{ element.row.firstName }}</span>
                {{ element.row.lastNamePrefix || '' }}
                {{ element.row.lastName }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              E-mailadres
            </th>
            <td class="cell accent" mat-cell *matCellDef="let element">
              <ng-container *ngIf="element; else showSkeletor">
                {{ element.row.emailAddress }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Melding</th>
            <td
              class="cell accent ion-color-secondary"
              mat-cell
              *matCellDef="let element"
            >
              <ng-container *ngIf="element; else showSkeletor">
                <ng-container [ngSwitch]="element.type">
                  <ng-container *ngSwitchCase="UploadExceptionType.EMAIL">
                    Het ingevoerde e-mailadres
                    <span class="inline">{{ element.row.email }}</span> is niet
                    geldig.
                  </ng-container>
                  <ng-container *ngSwitchCase="UploadExceptionType.LEVEL">
                    Het niveau
                    <span class="inline">{{ element.row.classLevel }}</span>
                    wordt niet herkend.
                  </ng-container>
                  <ng-container *ngSwitchCase="UploadExceptionType.LEVEL_YEAR">
                    Er is geen leerjaar
                    <span class="inline">{{ element.row.year }}</span> bekend
                    voor <span class="inline">{{ element.row.classLevel }}</span
                    >.
                  </ng-container>
                  <ng-container *ngSwitchCase="UploadExceptionType.EXISTS">
                    Deze leerling staat al ingeschreven voor een andere school
                    met dit e-mailadres.
                  </ng-container>
                  <ng-container *ngSwitchCase="UploadExceptionType.IN_SAME">
                    Het ingevoerde e-mailadres wordt al gebruikt op deze
                    locatie.
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="UploadExceptionType.VMBO_OR_MAVO"
                  >
                    <span class="inline">Vmbo/mavo</span> is geen geldige
                    waarde, gebruik vmbo-b, vmbo-k, vmbo-gl of vmbo-tl.
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="UploadExceptionType.EXISTS_AS_NON_PUPIL"
                  >
                    Het ingevoerde e-mailadres wordt al gebruikt voor een
                    profiel dat geen leerling is.
                  </ng-container>
                  <ng-container *ngSwitchCase="UploadExceptionType.API">
                    Storing.
                  </ng-container>
                  <ng-container *ngSwitchCase="UploadExceptionType.UNKNOWN">
                    Er is een onbekende fout opgetreden bij het verwerken van
                    deze leerling.
                  </ng-container>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <ng-template #showSkeletor>
            <ion-skeleton-text animated></ion-skeleton-text>
          </ng-template>

          <tr mat-header-row *matHeaderRowDef="_columns"></tr>
          <tr mat-row *matRowDef="let row; columns: _columns"></tr>
        </table>

        <ion-infinite-scroll
          threshold="50px"
          (ionInfinite)="dataSource?.next()"
          *ngIf="dataSource && (dataSource.totalItems$ | async) !== 0"
        >
          <ion-infinite-scroll-content [loadingSpinner]="null">
            <app-infinite-scroll-content [table]="forInfScroll">
            </app-infinite-scroll-content>
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-content>
    </div>
  </app-school-dashboard-modal>
</ng-template>

import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthConfig } from './auth.config';
import { BaseAuthModule } from './base-auth.module';
import { InAppBrowserWebService } from './shared/in-app-browser/in-app-browser-web.service';
import { InAppBrowserService } from './shared/in-app-browser/in-app-browser.service';
import { UserInterface } from './shared/user/user.interface';

@NgModule({
  imports: [
    BaseAuthModule,
  ],
  exports: [
    BaseAuthModule,
  ],
})
export class AuthModule {
  public static forRoot<T extends UserInterface>(options: AuthConfig<T>): ModuleWithProviders<AuthModule> {
    options = { ...new AuthConfig(), ...options };

    return {
      ngModule: AuthModule,
      providers: [
        { provide: AuthConfig, useValue: options },
        { provide: InAppBrowserService, useClass: InAppBrowserWebService },
      ],
    };
  }
}

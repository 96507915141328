import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { LocationsStoreService } from '@school-dashboard/data-access-locations';
import { UserService } from '@techniek-team/oauth';
import { isDefined } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { combineLatest, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocationMemberApi } from './api/location-member/location-member.api';
import { FromEventService } from './shared/services/from-event/from-event.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private errorHandler: SentryErrorHandler,
    private fromEventService: FromEventService,
    private locationMemberApi: LocationMemberApi,
    private storage: Storage,
    private toastService: ToastService,
    private userService: UserService,
    private locationsStoreService: LocationsStoreService,
  ) {
    this.storage.create().then(() => {
      this.locationsStoreService.init();
    });

  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    // Register key-presses before Ionic has a chance to 'preventDefault' them.
    this.fromEventService.set('keyup', 'Escape');
    this.fromEventService.set('keyup', 'Enter');

    // Set the location members for the current location of the current user.
    // When switching location these will be overwritten by the ones of that
    // specific location. They are used to check the permissions of the user.
    combineLatest([
      this.userService.currentUser(),
      this.locationMemberApi.getLocationMembersCurrentUser(),
    ])
      .pipe(
        catchError((error) => {
          this.errorHandler.captureError(error);
          this.toastService.error(
            'Er is iets misgegaan bij uw rechtencontrole.',
          );
          return EMPTY;
        }),
        isDefined(),
      )
      .subscribe(([user, locationMembers]): void => {
        user.rolesOnCurrentLocation = locationMembers;
      });

    this.platform.ready().then(() => {
      setTimeout(() => {
        document.getElementById('init-app-loading')?.remove();
      }, 1000);
    });
  }
}

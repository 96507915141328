import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IconsModule } from '../../../core/icons.module';
import { ResponseMessageComponent } from './response-message.component';

@NgModule({
  declarations: [ResponseMessageComponent],
  imports: [IonicModule, IconsModule, CommonModule],
  exports: [ResponseMessageComponent],
})
export class ResponseMessageModule {}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TtAutocompleteChipControl } from '@techniek-team/autocomplete-chip-control';

export enum AsyncValidatorState {
  LOADING = 'loading',
  CLEAR = 'clear',
}

export class SchoolDashboardValidators {
  /**
   * Check if the given control actually has a valid required input value, which
   * can be a TtAutocompleteChipControl value as well as a string.
   */
  public static autocompleteValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control?.value) {
        return { required: true };
      }

      if (typeof control.value !== 'object') {
        return control.value !== '' ? null : { required: true };
      }

      const fallback: TtAutocompleteChipControl = { values: [], filters: [] };
      const { values } = (control.value as TtAutocompleteChipControl) ?? fallback;
      return !values?.length ? { required: true } : null;
    };
  }
}

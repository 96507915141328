import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HighlightSearchModule } from '@techniek-team/common';
import { FilterControllerService } from './filter-controller.service';


@NgModule({
  providers: [
    FilterControllerService,
  ],
  imports: [
    CommonModule,
    HighlightSearchModule,
  ],
})
export class TtFilterSearchModule {
}

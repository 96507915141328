import { MessageColor } from '@school-dashboard/enums';
import { Exclude, Expose } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseMessageKey, ResponseMessageState } from './response-message.enum';

/**
 * Define a style for (parts of) the message string.
 */
export interface ResponseMessageMarkup {
  // Message style is italic.
  italic?: boolean;
  // Message style is bold.
  styleBold?: boolean;
  // Display as a block, inline-block or as default inline.
  display?: MarkupDisplay;
  // New, empty line after block?
  newline?: boolean;
  // Indent the item, like in lists?
  indent?: boolean;
  // Bullet list item?
  bullet?: boolean;
  // Is this part of the message a link?
  url?: boolean;
  // Specify a link, otherwise the value is used.
  urlValue?: string;
  // Type of link if it's not a URL.
  urlPrefix?: 'mailto:' | 'tel:';
  // The part's value.
  value: string;
}

export enum MarkupDisplay {
  BLOCK = 'BLOCK',
  INLINE_BLOCK = 'INLINE_BLOCK',
}

export class ResponseMessage {
  /**
   * The response message's unique identifier.
   */
  @Expose() public key: ResponseMessageKey;

  /**
   * Type, and thus color, of the response message.
   */
  @Expose() public type: MessageColor;

  /**
   * Simple message string or more extended markup.
   */
  @Expose() public message: string | ResponseMessageMarkup[];

  /**
   * Internal state subject.
   */
  @Exclude() private stateSubject$ = new BehaviorSubject<ResponseMessageState>(ResponseMessageState.ACTIVE);

  /**
   * Active message state snapshot.
   */
  @Exclude() public get state(): ResponseMessageState {
    return this.stateSubject$.getValue();
  }

  /**
   * Response message state observable, for determining the template state of
   * the message.
   */
  @Exclude() public get state$(): Observable<ResponseMessageState> {
    return this.stateSubject$.asObservable();
  }

  /**
   * Return a (converted) stream of the current message content.
   */
  @Exclude() public get markup(): ResponseMessageMarkup[] {
    return Array.isArray(this.message) ? this.message : [{ value: this.message }];
  }

  /**
   * Mark this message as active, showing it in the list.
   */
  public markAsActive(): void {
    this.stateSubject$.next(ResponseMessageState.ACTIVE);
  }

  /**
   * Mark this message as hidden, minimizing the message.
   */
  public markAsHidden(): void {
    this.stateSubject$.next(ResponseMessageState.HIDDEN);
  }

  /**
   * Close the message and hide it from the response message stream, until it is
   * removed or reactivated.
   */
  public markAsClosed(): void {
    this.stateSubject$.next(ResponseMessageState.CLOSED);
  }

  /**
   * Mark this message as read, hiding the message from the stream, but still
   * visible in the overview.
   */
  public markAsRead(): void {
    this.stateSubject$.next(ResponseMessageState.READ);
  }
}

import { TrackType, TrackTypeResource } from '@school-dashboard/enums';
import {
  CodelabTrack,
  ExamTrainingTrack,
  HomiLicenseTrack,
  HuiswerkbegeleidingTrack,
  OnderwijsheldenTrack,
  OverstapTrack,
  PractiseTrack,
  StudyCoachingTrack,
  SummerschoolTrack,
  Track,
  TutoringTrack,
  TwokTrack,
  UnknownTrack,
} from '@school-dashboard/models';
import { Collection, Resource } from '@techniek-team/api-platform';
import { denormalizeWithDiscriminator, Hydra } from '@techniek-team/class-transformer';
import { ClassConstructor } from 'class-transformer';
import { GetTrackResponse } from './track.response';

// todo when moving the track store and it's models to a different library when can
// move this to there
export const TRACK_TYPE_INSTANCE: { [Key in TrackType]: ClassConstructor<unknown> } = {
  [TrackType.CODELAB]: CodelabTrack,
  [TrackType.EXAMTRAINING]: ExamTrainingTrack,
  [TrackType.HOMI_LICENSE]: HomiLicenseTrack,
  [TrackType.HUISWERKBEGELEIDING]: HuiswerkbegeleidingTrack,
  [TrackType.ONDERWIJSHELDEN]: OnderwijsheldenTrack,
  [TrackType.OVERSTAP]: OverstapTrack,
  [TrackType.PRACTICE]: PractiseTrack,
  [TrackType.STUDY_COACHING]: StudyCoachingTrack,
  [TrackType.SUMMERSCHOOLS]: SummerschoolTrack,
  [TrackType.TUTORING]: TutoringTrack,
  [TrackType.TWOK]: TwokTrack,
  [TrackType.UNKNOWN]: UnknownTrack,
};

/**
 * Serialize to the correct track model.
 */
export function serializeTrack<R extends Resource<GetTrackResponse>>(response: R): Track;
export function serializeTrack<R extends Resource<GetTrackResponse>[]>(response: R): Track[];
export function serializeTrack<R extends Collection<GetTrackResponse>>(response: R): Hydra<Track>;
export function serializeTrack<R>(response: R): Track | Hydra<Track> | Track[] {
  return denormalizeWithDiscriminator(UnknownTrack, response, {
    discriminator: {
      property: '@type',
      subTypes: Object.entries(TRACK_TYPE_INSTANCE)
        .map(([key, value]) => ({ value: value, name: TrackTypeResource[key] })),
    },
  });
}

import { CheckType, ResourceToTrackType, SignupState, TrackType } from '@school-dashboard/enums';
import { JsonLd, TsRange } from '@techniek-team/class-transformer';
import { Exclude, Expose, Type } from 'class-transformer';
import { Check } from './check.model';

/**
 * A track for a certain school location.
 */
export abstract class Track extends JsonLd {
  /**
   * Track name as human-readable string.
   */
  @Expose() public name: string;

  /**
   * Description for this Track, possibly adding something of value to the
   * end user.
   */
  @Expose() public description: string;

  /**
   * Amount of LocationMembers currently assigned to this Track.
   */
  @Expose({ name: 'trackAttendanceCount' }) public currentPupils: number;

  /**
   * The total price for this track.
   */
  @Expose({ name: 'totalTrackPrice' }) public priceTotal: string;

  /**
   * The spent amount for this track.
   */
  @Expose({ name: 'spentTrackPrice' }) public priceSpent: string;

  /**
   * The leftover amount for this track.
   * Essentially this is the total price minus the spent amount.
   */
  @Expose({ name: 'leftoverTrackPrice' }) public priceLeftover: string;

  /**
   * Direct link for the user to let future attendees signup easily.
   */
  @Expose({ name: 'pupilSignUpLink' }) public signupLink: string;

  /**
   * Enum to allow easy visual representation of the amount of assignees.
   */
  @Expose() public signupState: SignupState;

  @Expose() public trackReports: string[];

  /**
   * Track validity range, including the start and end dates. This is the
   * range in which the Track will be active and is used to allow or prevent
   * users in the dashboard from assigning LocationMembers to this track.
   */
  @Type(() => TsRange)
  @Expose()
  public validityRange: TsRange;

  @Type(() => Check)
  @Expose()
  public checks: Check[] = [];

  @Type(() => Track)
  @Expose()
  public parentTrack: Track;

  /**
   * Using the JsonLd type, switch the class name to a usable type.
   */
  @Exclude()
  public get type(): TrackType {
    return ResourceToTrackType[this._type];
  }

  /**
   * A formatter to format a string to euro currency.
   */
  @Exclude() private priceFormatter: Intl.NumberFormat = new Intl.NumberFormat(
    'nl-NL',
    { style: 'currency', currency: 'EUR' },
  );

  public abstract canAddTrackAttendances: boolean;

  public abstract canDeleteTrackAttendances: boolean;

  public abstract canEditTrackAttendances: boolean;

  public abstract canResendEmailTrackAttendance: boolean;

  public abstract hasTrainings: boolean;

  public abstract canHaveSubTracks: boolean;

  /**
   * Which checks are available and on which checks they depend.
   */
  public abstract availableChecks: Map<CheckType, CheckType[]>;

  public get isPupilAmountDefinitiveChecked(): boolean {
    const pupilAmountDefinitiveCheck: Check | undefined = this.checks.find((check: Check): boolean => {
      return check.type === CheckType.PUPIL_AMOUNT_DEFINITIVE;
    });

    if (pupilAmountDefinitiveCheck === undefined) {
      return false;
    }

    return !!pupilAmountDefinitiveCheck.confirmedAt;
  }

  public minimumTrackAttendances: number = 0;

  /**
   * Return the total price as a user readable formatted string.
   */
  public get readablePriceTotal(): string {
    return this.priceFormatter.format(+this.priceTotal);
  }

  /**
   * Return the spent amount as a user readable formatted string.
   */
  public get readablePriceSpent(): string {
    return this.priceFormatter.format(+this.priceSpent);
  }

  /**
   * Return the leftover amount as a user readable formatted string.
   */
  public get readableLeftoverPrice(): string {
    return this.priceFormatter.format(Math.max(+this.priceLeftover, 0));
  }

  /**
   * Whether to show the signup link box in the modal.
   */
  public get showSignupLink(): boolean {
    if (!this.signupLink) {
      return false;
    }

    return this.signupState !== SignupState.TOO_MANY;
  }

  public get isSubTrack(): boolean {
    return !!this.parentTrack;
  }

  public hasTrackReport(): boolean {
    if (!this.trackReports) {
      return false;
    }

    return this.trackReports.length > 0;
  }
}

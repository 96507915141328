import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { OAuthService } from '../shared/oauth/oauth.service';

@Injectable({
  providedIn: 'root',
})
export class PKCEGuard {

  constructor(private oauthService: OAuthService) {
  }

  /**
   * If a code has been provided as query param, exchange it for an
   * accessToken
   */
  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const authCodePKCE: string = route.queryParams['code'];
    let params: Params = { ...route.queryParams };
    delete params['code'];

    if (authCodePKCE) {
      await firstValueFrom(this.oauthService.exchangeAuthCodePKCE(authCodePKCE), { defaultValue: undefined });
      // we do not want to display the code in the address bar so
      // do a redirect to the home or save origin page including the left
      // over queryParameters.
      return this.oauthService.getRedirectAfterLoginUrlTree({ queryParams: params });
    }

    return true;
  }
}


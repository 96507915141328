import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { LocationContract } from './contract/locations.contract';

export const locationsActions = createActionGroup({
  source: 'Locations',
  events: {
    initLocations: emptyProps(),
    loadLocationsSuccess: props<{ locations: LocationContract[]; totalItems: number }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadLocationsFailure: props<{ error: any }>(),
    selectLocation: props<{ locationId: string | undefined }>(),
  },
});

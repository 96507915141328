import { AsyncPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { FetchObservable } from '@techniek-team/rxjs';
import { Observable, Subscribable } from 'rxjs';

function isSubscribable<T>(obj: Subscribable<T> | object): obj is Subscribable<T> {
  return obj?.hasOwnProperty('subscribe');
}

/**
 * This by can be used in combination with the @Fetch decorator for class models.
 * It functions as a `async` pipe, but it checks the property at forehand checking if it's
 * an observable. If not (for example an EagerLoaded model) is just returns the property.
 *
 * @example
 * ```html
 *   <div>{{ (model.address | ttFetch).city }}</div>
 * ```
 */
@Pipe({
  name: 'ttFetch',
  //eslint-disable-next-line @angular-eslint/no-pipe-impure
  pure: false,
})
export class FetchPipe extends AsyncPipe implements PipeTransform {

  public override transform<T>(obj: FetchObservable<T> | Observable<T> | Subscribable<T> | Promise<T>): T | null;
  public override transform<T>(obj: T): T;
  public override transform<T>(obj: null | undefined): null;
  public override transform<T>(
    obj: T | FetchObservable<T> | Subscribable<T> | Observable<T> | Promise<T> | null | undefined,
  ): T | null {
    if (!obj) {
      return null;
    }
    //eslint-disable-next-line max-len
    if ((obj instanceof FetchObservable) || isSubscribable(obj) || (obj instanceof Observable) || (obj instanceof Promise)) {
      return super.transform(obj) as T | null;
    }
    return obj as T | null;
  }
}

export enum PersonType {
  PUPIL = 'PUPIL',
  COORDINATOR = 'COORDINATOR',
  SCHOOL_CONTACT = 'SCHOOL_CONTACT',
  LOCATION_MANAGER = 'LOCATION_MANAGER',
  CARETAKER = 'CARETAKER',
  MENTOR = 'MENTOR',
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  SALES_MANAGER = 'SALES_MANAGER',
}

import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, share, startWith } from 'rxjs/operators';
import {
  ModalAction,
  ModalButtonState,
  ModalDismiss,
} from '../../components/school-dashboard-modal/school-dashboard-modal.component';

/**
 * Simple service that's just a DRY version of the default button state
 * implementation used by the SchoolDashboardModal and the active form of the
 * nesting modal to create validity-based states for each of the default buttons
 * available on the template.
 *
 * Create the service when the FormGroup or equivalent is available, or create
 * one with an empty FormControl and re-init it once the proper one is available
 * via the {@see ButtonStateService.init} function, using the boolean parameter
 * `noObservers` to create static observables.
 *
 * @example ```
 * <app-school-dashboard-modal actionButtonText="Aanmelden"
 *                             cancelButtonText="Annuleer"
 *                             [actionButtonState]="service.actionState$ | async"
 *                             [closeButtonState]="service.cancelState$ | async"
 *                             [cancelButtonState]="service.cancelState$ | async"
 *                             [dismiss]="_canDismiss$"
 *                             (action)="dialogAction($event)">
 * </app-school-dashboard-modal>
 * ```
 */
export class ButtonStateService {
  /**
   * Subject to trigger dismissal to the SchoolDashboardModal. Used in the
   * `[dismiss]="service.canDismiss$` input as an observable input value.
   */
  public canDismiss$: Subject<ModalDismiss | ModalAction> = new Subject();

  /**
   * The state of the save (action) button. Used in the SchoolDashboardModal's
   * input for `[actionButtonState]`.
   */
  public actionState$: Observable<ModalButtonState>;

  /**
   * The state of the close (fab) button. Used in the SchoolDashboardModal's
   * input for `[closeButtonState]`.
   */
  public closeState$: Observable<ModalButtonState>;

  /**
   * The state of the cancel button. Used in the SchoolDashboardModal's input
   * for `[cancelButtonState]`.
   */
  public cancelState$: Observable<ModalButtonState>;

  /**
   * Subject to set the default value of the SchoolDashboardModal's action
   * button and to trigger the `ModalButtonState.LOADING` for all the buttons.
   */
  public action$: BehaviorSubject<ModalButtonState> = new BehaviorSubject<ModalButtonState>(ModalButtonState.DISABLED);

  /**
   * Subject to set the default value of the SchoolDashboardModal's close
   * button and to trigger the `ModalButtonState.LOADING` for the close button.
   */
  public close$: BehaviorSubject<ModalButtonState> = new BehaviorSubject<ModalButtonState>(ModalButtonState.WARNING);

  /**
   * Subject to set the default value of the SchoolDashboardModal's cancel
   * button and to trigger the `ModalButtonState.LOADING` for the cancel button.
   */
  public cancel$: BehaviorSubject<ModalButtonState> = new BehaviorSubject<ModalButtonState>(ModalButtonState.WARNING);

  /**
   * Instantiate with any AbstractControl and call the {@see self.init} function
   * with or without the noObservers boolean parameter.
   */
  constructor(
    public activeForm: AbstractControl,
    public checkActiveFormPristine: boolean = true,
  ) {}

  /**
   * Initialize the observables with active observables or use the matching
   * state BehaviorSubjects to create a static observable.
   */
  public init(noObservers?: boolean): void {
    if (noObservers) {
      this.actionState$ = this.action$.asObservable();
      this.closeState$ = this.close$.asObservable();
      this.cancelState$ = this.cancel$.asObservable();
      return;
    }

    this.actionState$ = this.createActionStateObservable();
    this.closeState$ = this.createCloseStateObservable();
    this.cancelState$ = this.createCancelStateObservable();
  }

  /**
   * Create an observable that watches the status changes of the currently set
   * abstract control. Status changes is used instead of value changes to take
   * AsyncValidators in consideration.
   */
  public createValidFormObserver(debounce: number = 250): Observable<boolean> {
    const form: AbstractControl = this.activeForm;
    const statusChanges: Observable<unknown> = form.statusChanges ?? of('VALID');

    return statusChanges.pipe(
      debounceTime(debounce),
      map(() => form.valid),
      startWith(form.valid),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }

  /**
   * Use form validation and the current action state to create a button state
   * that the template will use for the action button.
   */
  private createActionStateObservable(): Observable<ModalButtonState> {
    return combineLatest([this.createValidFormObserver(), this.action$]).pipe(
      map(([valid, action]) => {
        if (action === ModalButtonState.LOADING) {
          return ModalButtonState.LOADING;
        }

        if (this.activeForm.pristine && this.checkActiveFormPristine) {
          return ModalButtonState.DISABLED;
        }

        return valid ? ModalButtonState.WARNING : ModalButtonState.DISABLED;
      }),
    );
  }

  /**
   * Create an observer for the cancel state by using the Subject as a base and
   * checking if the form is pristine and/or valid.
   */
  private createCloseStateObservable(): Observable<ModalButtonState> {
    return combineLatest([
      this.close$,
      this.action$,
      this.createValidFormObserver(),
    ]).pipe(
      map(([state, action]) => {
        if (
          action === ModalButtonState.LOADING
          || state === ModalButtonState.LOADING
        ) {
          return ModalButtonState.LOADING;
        }

        return ModalButtonState.WARNING;
      }),
    );
  }

  /**
   * Create an observer for the cancel state by using the Subject as a base and
   * checking if the form is pristine and/or valid.
   */
  private createCancelStateObservable(): Observable<ModalButtonState> {
    return combineLatest([
      this.cancel$,
      this.action$,
      this.createValidFormObserver(),
    ]).pipe(
      map(([state, action]) => {
        if (
          action === ModalButtonState.LOADING
          || state === ModalButtonState.LOADING
        ) {
          return ModalButtonState.LOADING;
        }

        return ModalButtonState.WARNING;
      }),
    );
  }
}

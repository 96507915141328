import { Type } from '@angular/core';
import { ReportDialogOptions } from '@sentry/angular-ivy';
import { UserServiceInterface } from '@techniek-team/common';
import { SentryBrowserConfig } from './sentry-browser.config';
import { UserInterface } from './user-service.interface';

export enum SentryEnvironment {
  PRODUCTION = 'production',
  LOCAL = 'local',
  TESTING = 'testing',
  ACCEPTANCE = 'acceptance',
}

export class SentryWebConfig extends SentryBrowserConfig {
  /**
   * List of strings/regex controlling to which outgoing requests
   * the SDK will attach tracing headers.
   *
   * By default the SDK will attach those headers to all requests to localhost
   * and same origin. If this option is provided, the SDK will match the
   * request URL of outgoing requests against the items in this
   * array, and only attach tracing headers if a match was found.
   *
   * @example
   * ```js
   * Sentry.init({
   *   tracePropagationTargets: ['api.site.com'],
   * });
   * ```
   *
   * Default: ['localhost', /^\//] {@see DEFAULT_TRACE_PROPAGATION_TARGETS}
   */
  public tracePropagationTargets?: string[];

  /**
   * Enable Sentry tracing module
   */
  public enableTracing?: boolean = false;

  /**
   * List of strings / regex where the integration should create Spans out of. Additionally this will be used
   * to define which outgoing requests the `sentry-trace` header will be attached to.
   *
   * Default: ['localhost', /^\//] {@see DEFAULT_TRACING_ORIGINS}
   * @see https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrume
   *   ntation/#tracingorigins
   */
  public tracingOrigins?: string[] = [
    'localhost', '/^//', 'http[s]?:\\/\\/.*?\\.techniek-team.nl',
  ];

  /**
   * Override the default where error thrown in {@see SentryEnvironment.LOCAL} mode aren't
   * send to sentry.
   */
  public sendLocalErrorsToSentry?: boolean = false;

  /**
   * Optionally you can add a User service class. This class will be used to
   * retrieve the information of the logged-in and passes this to sentry.
   *
   * The specified service needs to implement the UserServiceInterface and need to
   * be a @Injectable service within the root or module.
   */
  public userService?: Type<UserServiceInterface<UserInterface>>;

  /**
   * If enable they sentry error dialog is presented inquiring feedback from the
   * user for additional data about the crash. This dialog is only shown with
   * handleError. which means that the user only sees error that haven't been
   * properly caught.
   *
   * Also the dialog is presented only once and it will not stack multiple windows
   * on top of each other
   */
  public showDialog?: boolean = false;

  /**
   * When the dialog is able you can changes the text of the dialog through this
   * object.
   *
   * @see https://docs.sentry.io/platforms/javascript/enriching-events/user-feedback/ for more information
   */
  public dialogOptions?: ReportDialogOptions;

  /**
   * Custom implementation of error extraction from the raw value captured by the Angular.
   *
   * Default to the angular extractor implementation.
   * @param error Value captured by Angular's ErrorHandler provider
   * @param defaultExtractor Default implementation that can be used as the fallback in case of custom implementation
   */
  public extractor?(error: unknown, defaultExtractor: (error: unknown) => unknown): unknown;

  /**
   * Use in the extractMessage function to map a given error message to the correct message.
   */
  public errorMessageMapping?: Record<string, string>;

  /**
   * The sample rate for session-long replays.
   * 1.0 will record all sessions and 0 will record none.
   */
  public replaysSessionSampleRate?: number = 0.1;

  /**
   * The sample rate for sessions that has had an error occur.
   * This is independent of `sessionSampleRate`.
   * 1.0 will record all sessions and 0 will record none.
   */
  public replaysOnErrorSampleRate?: number = 1.0;
}

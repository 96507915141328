import { JsonLd } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';

/**
 * A model for the Excel pupil upload.
 */
export class PupilUpload extends JsonLd {
  @Expose() public processedRows?: number;

  @Expose() public totalRows?: number;

  @Type(() => Date)
  @Expose()
  public completedAt?: Date;

  @Expose() public pupilsWithError?: number;

  @Expose() public extension?: string;

  @Expose() public hasErrorFile?: boolean;

  /**
   * Get the percentage completed. This is a value between (and including)
   * 0 and 100. When all the rows are processed, but the completedAt is not
   * set then the upload isn't completely done, so we will return 99.
   */
  public get percentageCompleted(): number {
    if (this.completedAt) {
      return 100;
    }

    if (!this.processedRows || !this.totalRows) {
      return 0;
    }

    return Math.min(
      Math.round((this.processedRows / this.totalRows) * 100),
      99,
    );
  }

  /**
   * Determine the amount of rows that were successfully processed.
   */
  public get successfulCount(): number {
    return (this.totalRows ?? 0) - (this.pupilsWithError ?? 0);
  }
}

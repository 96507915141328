import { SchoolLevel } from '@school-dashboard/enums';
import { JsonLd } from '@techniek-team/class-transformer';
import { Exclude, Expose, Type } from 'class-transformer';
import { LevelYear } from './level-year.model';
import { Person } from './person.model';
import { PupilEnrollment } from './pupil-enrollment.model';
import { PupilLevelYearStatus } from './pupil-level-year-status.model';

export class Pupil extends JsonLd {
  /**
   * Notes or description for this pupil.
   */
  @Expose() public description: string;

  /**
   * The Person model for this pupil. Only filled when retrieving the Pupil
   * resource directly. Otherwise, the PersonPupil model will be used and have
   * a property `pupil` holding a Pupil instance.
   */
  @Type(() => Person)
  @Expose()
  public person: Person;

  /**
   * The school locations this pupil is known at.
   */
  @Type(() => PupilEnrollment)
  @Expose({ name: 'pupilEnrollments' })
  public enrollments: PupilEnrollment[];

  /**
   * The years/levels this student is supposed to be in.
   */
  @Type(() => PupilLevelYearStatus)
  @Expose({ name: 'pupilLevelYearStatuses' })
  public levelYears: PupilLevelYearStatus[];

  /**
   * Return a list of all school class names.
   */
  @Exclude() public get classes(): string[] {
    return (
      this.enrollments
        ?.map((enrollment) => enrollment.schoolClass.name)
        .sort((a, b) => a.localeCompare(b)) ?? []
    );
  }

  /**
   * Get a list of all levels for this pupil.
   */
  @Exclude() public get levels(): SchoolLevel[] {
    return (
      this.levelYears
        ?.map((year) => year.levelYear.schoolLevel)
        .sort((a, b) => a.localeCompare(b)) ?? []
    );
  }

  /**
   * Return the enum values as more readable values.
   */
  @Exclude() public get levelsFormatted(): string[] {
    return (
      this.levels
        .map((level: SchoolLevel) => this.formatSchoolLevel(level))
        .sort((a, b) => a.localeCompare(b)) ?? []
    );
  }

  /**
   * Get a list of all years this pupil is present in.
   */
  @Exclude() public get years(): number[] {
    const years: Set<number> = new Set(
      this.levelYears?.map((year) => year.levelYear.year) ?? [],
    );
    return [...years].sort();
  }

  /**
   * Return (and set) inExamYear boolean.
   */
  @Exclude() public get isInExamYear(): boolean {
    const levelYear: LevelYear = this.levelYears[0].levelYear;
    switch (levelYear.schoolLevel) {
      case SchoolLevel.VWO:
        return levelYear.year === 6;
      case SchoolLevel.HAVO:
        return levelYear.year === 5;
      default:
        return levelYear.year === 4;
    }
  }

  /**
   * Return (and set) inPreExamYear boolean.
   */
  @Exclude() public get isInPreExamYear(): boolean {
    const levelYear: LevelYear = this.levelYears[0].levelYear;
    switch (levelYear.schoolLevel) {
      case SchoolLevel.VWO:
        return levelYear.year === 5;
      case SchoolLevel.HAVO:
        return levelYear.year === 4;
      default:
        return levelYear.year === 3;
    }
  }

  //eslint-disable-next-line complexity
  private formatSchoolLevel(level: SchoolLevel): string {
    switch (level) {
      case SchoolLevel.VMBO_B:
        return 'VMBO B';
      case SchoolLevel.VMBO_K:
        return 'VMBO K';
      case SchoolLevel.VMBO_GL:
        return 'VMBO GL';
      case SchoolLevel.VMBO_TL:
        return 'VMBO TL';
      case SchoolLevel.PO:
      case SchoolLevel.VMBO:
      case SchoolLevel.HAVO:
      case SchoolLevel.VWO:
      case SchoolLevel.MBO:
      case SchoolLevel.HBO:
      case SchoolLevel.WO:
        return level.toString();
      case SchoolLevel.ANY:
        return 'Alle niveaus';
      case SchoolLevel.UNKNOWN:
        return 'Niveau onbekend';
    }
  }
}

import { Directive, ElementRef } from '@angular/core';

@Directive({
  //eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[infinite-scroll-content]',
  exportAs: 'infiniteScrollContent',
})
export class InfiniteScrollContentDirective {
  constructor(public elementRef: ElementRef) {}
}

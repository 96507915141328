import { Filter } from '@techniek-team/filter-search';

/**
 * This model is essentially the same as the {@see Filter}. The reason
 * this stub is added, is because it's confusing that you need to add a set of Filters
 * to a FormControl in which you can choose one or multiple options.
 */
export class Option extends Filter {

}

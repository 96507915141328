import { SchoolLevel } from '@school-dashboard/enums';

//eslint-disable-next-line complexity
export function formatSchoolLevel(level: SchoolLevel): string {
  switch (level) {
    case SchoolLevel.VMBO_B:
      return 'VMBO B';
    case SchoolLevel.VMBO_K:
      return 'VMBO K';
    case SchoolLevel.VMBO_GL:
      return 'VMBO GL';
    case SchoolLevel.VMBO_TL:
      return 'VMBO TL';
    case SchoolLevel.PO:
    case SchoolLevel.VMBO:
    case SchoolLevel.HAVO:
    case SchoolLevel.VWO:
    case SchoolLevel.MBO:
    case SchoolLevel.HBO:
    case SchoolLevel.WO:
      return level.toString();
    case SchoolLevel.ANY:
      return 'Alle niveaus';
    case SchoolLevel.UNKNOWN:
      return 'Niveau onbekend';
  }
}

/**
 * Returns the associated amount of years for each level.
 */
export function maxYearForLevel(level: SchoolLevel): number {
  switch (level) {
    case SchoolLevel.VMBO:
    case SchoolLevel.VMBO_TL:
    case SchoolLevel.VMBO_B:
    case SchoolLevel.VMBO_K:
    case SchoolLevel.VMBO_GL:
    case SchoolLevel.MBO:
    case SchoolLevel.HBO:
    case SchoolLevel.WO:
      return 4;
    case SchoolLevel.HAVO:
      return 5;
    default:
      return 6;
  }
}

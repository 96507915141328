import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SimpleListComponent } from './simple-list.component';

@NgModule({
  declarations: [SimpleListComponent],
  imports: [IonicModule, CommonModule],
  exports: [SimpleListComponent],
})
export class SimpleListModule {}

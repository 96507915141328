import { Directive, ViewContainerRef } from '@angular/core';

/**
 * A directive used to mark an element as a placeholder for dynamically
 * loading in content.
 *
 * @example `
 * <ng-template contentPlaceholder></ng-template>
 * `
 */
@Directive({
  //eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[contentPlaceholder]',
})
export class ContentPlaceholderDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

import { SelectionModel } from '@angular/cdk/collections';
import { CheckboxCustomEvent, IonInfiniteScroll } from '@ionic/angular';
import { Hydra, JsonLd } from '@techniek-team/class-transformer';
import { BehaviorSubject, combineLatest, debounceTime, Observable, OperatorFunction } from 'rxjs';
import { map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { TtDataSourceSelectAction } from './models/select-actions.interface';
import { TtInfiniteScrollPaginationDatasource } from './tt-infinite-scroll-pagination.datasource';

interface SelectedAll {
  state: boolean;
  page: number;
}

/**
 * DataSource that should be used with a CDK Table with IonInfiniteScroll for
 * pagination, extends the `TtApiPlatformDataSource`.
 *
 * ## Setup
 * To make the caching and pagination work, the following properties can be set
 * or changed:
 *
 * - `infiniteScroll`: the {@see IonInfiniteScroll} instance that is on the HTML
 * template. Make sure to instantiate the template before calling the
 * {@see TtTableDataSource.init} function.
 * - `itemsPerPage`: the amount of items per page, default `30`.
 * - `itemsPerPageKey`: the query params key to use for pagination, default
 * `itemsPerPage`.
 * - `itemHeight`: the maximum row height in the table. This property is
 * **required** for any form of caching.
 *
 * Remember to call the `init` method after changing the properties.
 *
 * ## Selection
 * If the table allows for rows to be selected, the following things need to be
 * done and used:
 * - Create a function for the abstract {@see createSelectionLabelObserver} if
 * there's something the user will see when items are selected.
 * - Create a function for the abstract {@see createSelectionActionsObserver} to
 * determine the actions that can be done with the selection.
 * - Create a function for the abstract {@see handleSelectionAction} to handle
 * any of the actions with in the datasource.
 * - Use the {@see toggleItem} function to select/deselect rows. Because of the
 * way Ionic's change event handles selection versus the SelectionModel,
 * selecting a row can trigger twice when using {@see SelectionModel.toggle} or
 * {@see SelectionModel.select} and the selection will bug out.
 *
 * ## Cache
 * If you want to use some form of cache, make sure to update the scroll height
 * using the `ion-content` output and setting it via the
 * {@see TtTableDataSource.setScrollPosition} function. Most of the functions
 * are automated, but you can manually get the last viewed page by calling the
 * {@see TtTableDataSource.getLastViewedChunkIndex} function. If you want to,
 * manually, get new data for the pages, you can call the
 * {@see TtTableDataSource.checkForUnloadedChunks} function. If you want to
 * force the loading of a number of pages or items:
 * ```
 * datasource.clear();
 * datasource.init();
 * ```
 * This combination will clear the cached results and load all items based on
 * the last scroll position and item height.
 *
 * #### --------
 *
 * # TtApiPlatformDataSource docs
 *
 * @inheritDoc
 */
export abstract class TtTableDataSource<T extends JsonLd> extends TtInfiniteScrollPaginationDatasource<T> {

  /**
   * Material SelectionModel for handling selection of items from the table.
   */
  public selection: SelectionModel<T> = new SelectionModel(true, []) as unknown as SelectionModel<T>;

  /**
   * Selection status stream. Since the template refuses to update properly.
   */
  //eslint-disable-next-line max-len
  protected selectedAllSubject$: BehaviorSubject<SelectedAll> = new BehaviorSubject<SelectedAll>({ state: false, page: 0 });

  /**
   * Abstract function declaration for use with the {@see self.selectionOptions}
   * that will determine the contents of the label. Since this is completely
   * dependent on the extending class, no implementation is provided.
   */
  public abstract createSelectionLabelObserver(): Observable<string | unknown | unknown[]>;

  /**
   * Abstract function declaration for use with the {@see self.selectionOptions}
   * that will determine the contents and state of the actions that can be taken
   * with the selection. Since this is completely dependent on the extending
   * class, no implementation is provided.
   */
  public abstract createSelectionActionsObserver(): Observable<TtDataSourceSelectAction[]>;

  /**
   * Abstract function declaration for use with the {@see self.selectionOptions}
   * that will be used to handle any action that is done by the user after
   * selecting rows in a table.
   */
  public abstract handleSelectionAction(action: TtDataSourceSelectAction): void;

  /**
   * #### --------
   * ### TtTableDataSource -> `observer$`
   *
   * Uses the super class `observer$` getter to implement checks that enable or
   * disable the selection of all items when new items are loaded.
   *
   * @inheritDoc
   */
  public override get observer$(): Observable<T[]> {
    return super.observer$.pipe(
      tap((results) => {
        const selectedAll: SelectedAll = this.selectedAllSubject$.value;
        if (selectedAll.state && !results.some((item) => item === null)) {
          this.selectRowsFromIndex(results, selectedAll.page * this.pageSize);
        }
      }),
    );
  }

  /**
   * Check if the {@see SelectionModel} length matches the collection length.
   * Used together with the {@see self.selectedAll$} subject to create a state
   * that does not kill the template.
   */
  public get allSelected(): boolean {
    const collection: number = this.results$.value?.collection?.length;
    return !!collection && collection === this.selection.selected.length;
  }

  /**
   * Getter to expose the selectedAllSubject$.
   */
  public get selectedAll$(): Observable<boolean> {
    return combineLatest([
      this.selectedAllSubject$,
      this.selection.changed,
    ]).pipe(
      map(([selected]) => {
        return selected.state && this.allSelected;
      }),
    );
  }

  /**
   * Simple getter for the last page that has been loaded. Can be overridden to
   * return a custom value. Used to determine which pages to load in the
   * {@see loadRemainingChunks} method.
   */
  public get currentPage(): number {
    return this.activeRequest$.value.page || 1;
  }

  /**
   * @inheritDoc
   *
   * Init this datasource.
   */
  public override init(
    noHttpParamsFromMatSort?: boolean,
    jumpToTopOfPageAfterFirstChunkLoaded?: boolean,
  ): this {
    // Clean house before init!
    this.selection.clear();
    super.init(noHttpParamsFromMatSort, jumpToTopOfPageAfterFirstChunkLoaded);

    return this;
  }

  /**
   * @inheritDoc
   */
  public override clear(bust?: boolean, customSize?: number): void {
    // Clean house before clearing!
    this.selection.clear();
    super.clear(bust, customSize);
  }

  /**
   * Toggle the given item when selected in a table with IonCheckboxes.
   */
  public toggleItem(event: Event, item: T): void {
    const custom: CheckboxCustomEvent = event as CheckboxCustomEvent;
    const checked: boolean = custom.detail.checked;
    const selected: boolean = this.selection.isSelected(item);

    if (checked !== selected) {
      this.selection.toggle(item);
    }
  }

  /**
   * Toggle the selected state for the table rows and reset the last-checked row
   * by page index to 0. Can be forced-set to a state if needed.
   */
  public selectedStateToggle(forceTo?: boolean): void {
    const selectedAll: SelectedAll = this.selectedAllSubject$.value;
    selectedAll.state = forceTo ?? !selectedAll.state;
    selectedAll.page = 0;
    this.selectedAllSubject$.next(selectedAll);
  }

  /**
   * Select or deselect all available results and update the selectedAll state
   * internally.
   */
  public selectedToggle(): void {
    const selectedAll: SelectedAll = this.selectedAllSubject$.value;

    if (selectedAll.state) {
      this.selection.clear();
      this.selectedStateToggle();
      return;
    }

    const available: T[] = this.results$.value?.collection ?? [];
    this.selection.select(...available);
    selectedAll.state = true;
    selectedAll.page = Math.ceil(available.length / this.pageSize);
    this.selectedAllSubject$.next(selectedAll);
  }

  /**
   * @inheritDoc
   *
   * Also stops the InfiniteScroll instance from being able to trigger more
   * requests, if it is set.
   */
  public override stopRequest(): void {
    super.stopRequest();
    this.lockInfiniteScroll(false);
  }

  /**
   * Load all unloaded chunks starting from the current chunk index. No scroll
   * is done.
   */
  public loadRemainingChunks(): void {
    const lastIdx: number = Math.ceil(this.unfilteredTotal$.value / this.pageSize);
    if (this.currentPage === lastIdx) {
      return;
    }
    // Manually set loading state, since no call starts it.
    this.loadingState$.next(true);
    this.lockInfiniteScroll(true);
    const nextIdx: number = this.currentPage + 1;
    this.appendLoadingPage(this.results$.value);

    const nextChunk: Observable<Hydra<T>> = this.getChunkAtIndex(nextIdx, true);
    const nextChunkOperator: OperatorFunction<Hydra<T>, Hydra<T>> = map((appending) =>
      this.appendChunkResults(this.results$.value, appending),
    );

    const operators: OperatorFunction<Hydra<T>, Hydra<T>>[] = nextIdx < lastIdx ? Array.from(
      { length: lastIdx - nextIdx },
      (_, index: number) => mergeMap((current: Hydra<T>) => {
        this.appendLoadingPage(current);
        return this.getChunkAtIndex(nextIdx + index + 1,true).pipe(
          map((appending) => this.appendChunkResults(current, appending)),
          debounceTime(100),
        );
      }),
    ) : [];

    //@ts-ignore
    nextChunk.pipe.call(nextChunk, nextChunkOperator, ...operators, takeUntil(this.stopRequest$)).subscribe(() => {
      this.lockInfiniteScroll(false);
      this.completeGetChunkRequest();
    });
  }

  /**
   * Append the current page size amount of null items to the Hydra collection,
   * so the table will show loading skeleton items.
   */
  private appendLoadingPage(current: Hydra<T>): void {
    const loadingNulls: null[] = [].constructor(this.pageSize).fill(null);
    //@ts-ignore
    current.collection = current.collection.concat(loadingNulls);
    this.results$.next(current);
  }

  /**
   * Appends the results of the GET action to the current set of results. For
   * all pages that are still unloaded, null items are inserted so the loading
   * skeletons can be shown.
   */
  private appendChunkResults(current: Hydra<T>, appending: Hydra<T>): Hydra<T> {
    current.collection = current.collection.filter((item) => item !== null);
    current.collection = current.collection.concat(appending.collection);

    this.results$.next(current);
    return current;
  }

  /**
   * Slice a part of the results to add to the selection if the user has toggled
   * the select-all checkbox, so only the newly loaded results are added and not
   * the `n*i` already existing results.
   */
  private selectRowsFromIndex(results: T[], index: number): void {
    if (this.selection.selected.length === results.length) {
      return;
    }

    const sliced: T[] = results.slice(index);
    this.selection.select(...sliced);
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@school-dashboard/environments';
import { Pupil } from '@school-dashboard/models';
import { Resource } from '@techniek-team/api-platform';
import { denormalize } from '@techniek-team/class-transformer';
import { CacheService } from '@techniek-team/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CacheIdentifierService } from '../../shared/services/cache-identifier/cache-identifier.service';
import { GetPupilResponse, PatchPupilPerson, PostPupilPerson, PostPupilResponse } from './pupil.response';

@Injectable({
  providedIn: 'root',
})
export class PupilApi {
  constructor(
    private httpClient: HttpClient,
    private cache: CacheService,
    private cacheIdentifierService: CacheIdentifierService,
  ) {}

  /**
   * Get details for a pupil with the given IRI.
   */
  public getPupil(pupilIri: string): Observable<Pupil> {
    const url: string = `${environment.coreApiUrl}${pupilIri}`;
    return this.cache
      .create(
        this.cacheIdentifierService.getPupilCacheIdentifier(pupilIri),
        this.httpClient.get<Resource<GetPupilResponse>>(url),
        {
          clearInterval: 10000,
        },
      )
      .pipe(map((response) => denormalize(Pupil, response)));
  }

  /**
   * Create a new pupil for the currently selected location.
   */
  public postPupil(payload: PostPupilPerson): Observable<Pupil> {
    const url: string = `${environment.coreApiUrl}/api/pupils`;
    return this.httpClient
      .post<PostPupilResponse>(url, payload)
      .pipe(map((response) => denormalize(Pupil, response)));
  }

  /**
   * Update the given pupil.
   */
  public patchPupil(
    pupilIri: string,
    locationIri: string,
    payload: PatchPupilPerson,
  ): Observable<Pupil> {
    // Clear the cached item first, if it exists.
    this.cache.clear(
      this.cacheIdentifierService.getPupilCacheIdentifier(pupilIri),
    );
    const url: string = environment.coreApiUrl + pupilIri;
    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/merge-patch+json',
    );

    return this.httpClient
      .patch<PatchPupilPerson>(
      url,
      { location: locationIri, ...payload },
      { headers: headers },
    )
      .pipe(map((response) => denormalize(Pupil, response)));
  }
}

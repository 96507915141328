export enum SchoolLevel {
  PO = 'PO',
  VMBO = 'VMBO',
  VMBO_TL = 'VMBO_TL',
  VMBO_B = 'VMBO_B',
  VMBO_K = 'VMBO_K',
  VMBO_GL = 'VMBO_GL',
  HAVO = 'HAVO',
  VWO = 'VWO',
  MBO = 'MBO',
  HBO = 'HBO',
  WO = 'WO',
  ANY = 'ANY',
  UNKNOWN = 'UNKNOWN',
}
export enum SchoolLevelDisplayValue {
  PO = 'PO',
  VMBO = 'VMBO',
  VMBO_TL = 'VMBO TL',
  VMBO_B = 'VMBO B',
  VMBO_K = 'VMBO K',
  VMBO_GL = 'VMBO GL',
  HAVO = 'HAVO',
  VWO = 'VWO',
  MBO = 'MBO',
  HBO = 'HBO',
  WO = 'WO',
  ANY = 'Alle niveaus',
  UNKNOWN = 'Niveau onbekend',
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, combineLatest, filter, merge, Observable, Subject, switchMap } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TtMenuItemComponent } from './tt-menu-item/tt-menu-item.component';

/**
 * The component is the menu you see on the left hand side of the screen. It gives
 * the ability to navigate to other parts of the system.
 */
@Component({
  selector: 'tt-menu',
  templateUrl: './tt-menu.component.html',
  styleUrls: ['./tt-menu.component.scss'],
  animations: [
    trigger('expandMenu', [
      state('true', style({
        width: 'var(--tt-menu-width-expanded, 184px)',
        flex: '0 0 var(--tt-menu-width-expanded)',
      })),
      state('false', style({
        width: 'var(--tt-menu-width-collapsed, 184px)',
        flex: '0 0 var(--tt-menu-width-collapsed)',
      })),
      state('false', style({ width: '0' })),
      transition('true <=> false', [
        animate(200),
      ]),
    ]),
  ],
})
export class TtMenuComponent implements AfterViewInit, OnDestroy {

  /**
   * List of all the menu items that are placed within the ng-content.
   */
  @ContentChildren(TtMenuItemComponent) public menuItems: QueryList<TtMenuItemComponent> | undefined;

  @ViewChild(TtMenuItemComponent) public footerMenuIem: TtMenuItemComponent | undefined;

  @Input() public header?: string;

  /**
   * Emits when the menu is expanded or not.
   */
  @Output() public readonly isExpandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Observable which emits when a different menu item is activated.
   */
  public activeMenuItemChange$: Observable<TtMenuItemComponent> | undefined;

  /**
   * if true the menu is enlarged, and it shows the text value of the navigation links
   */
  public isExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private onDestroy: Subject<void> = new Subject();

  constructor(private storage: Storage) {
  }

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    this.storage.create().then(() => this.storage.get('ttMenuExpanded')).then(isExpanded => {
      if (isExpanded !== undefined) {
        this.toggleExpandMenu(isExpanded);
      }
    });
    this.activeMenuItemChange$ = this.createActiveMenuItemObserver();
    this.createIsExpandedSubscriber();
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public get expanded(): boolean {
    return this.isExpanded.getValue();
  }

  /**
   * Enlarges or reduces the size of the menu.
   */
  public toggleExpandMenu(isExpanded?: boolean): void {
    if (isExpanded === undefined) {
      isExpanded = !this.isExpanded.getValue();
    }
    this.isExpanded.next(isExpanded);
    this.isExpandedChange.emit(isExpanded);
    this.storage.create().then(() => this.storage.set('ttMenuExpanded', isExpanded));
  }

  /**
   * Creates an observable which returns the currently active menu item.
   */
  private createActiveMenuItemObserver(): Observable<TtMenuItemComponent> {
    return (this.menuItems as QueryList<TtMenuItemComponent>).changes.pipe(
      startWith(this.menuItems),
      switchMap((menuItems: QueryList<TtMenuItemComponent>) => {
        const observer: Observable<TtMenuItemComponent>[] = menuItems.toArray()
          .map(menuItem => menuItem.isActiveChange.pipe(startWith(menuItem), map((_) => menuItem)));

        return merge(...observer) as Observable<TtMenuItemComponent>;
      }),
      filter(menuItem => ((menuItem.routerLinkActive) ? menuItem.routerLinkActive.isActive : false)),
    );
  }

  /**
   * Subscribe which subscribe to the menu items change and sets the isExpanded
   * property of all the menu items.
   */
  private createIsExpandedSubscriber(): void{
    combineLatest([
      (this.menuItems as QueryList<TtMenuItemComponent>).changes.pipe(startWith(this.menuItems)),
      this.isExpanded,
    ]).subscribe(([menuItems, isExpanded]) => {
      (this.footerMenuIem as TtMenuItemComponent).expanded = isExpanded;
      for (let menuItem of menuItems) {
        menuItem.expanded = isExpanded;
      }
    });
  }
}

<div class="menu"
     [class.expanded]="(isExpanded | async)"
     [class.no-submenu]="!((activeMenuItemChange$ | async)?.submenu)"
     [@expandMenu]="isExpanded | async">
  <ion-item lines="none"
            [routerLink]="['/']"
            class="logo">
    <ion-icon [class.expanded]="(isExpanded | async)"
              name="lyceo">
    </ion-icon>
    <ion-label *ngIf="header && (isExpanded | async)">{{ header }}</ion-label>
  </ion-item>

  <div class="container">
    <ng-content></ng-content>
  </div>

  <!-- base64 Double Chevron Svg  -->
  <tt-menu-item class='footer'
                (click)="toggleExpandMenu()"
                [disablePopover]="true"
                [icon]="'double-chevron'"
                [title]="'Menu inklappen'">
  </tt-menu-item>
</div>

<tt-sub-menu *ngIf="activeMenuItemChange$ | async as activeMenuItem"
             [template]="activeMenuItem.submenu"
             [title]="activeMenuItem.title">
</tt-sub-menu>
<ion-router-outlet [animated]="false"></ion-router-outlet>


import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { LocationsStoreService } from '@school-dashboard/data-access-locations';
import { LocationModel, PupilUpload } from '@school-dashboard/models';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { BehaviorSubject, combineLatest, EMPTY, firstValueFrom, Observable, switchMap } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { PupilUploadApi } from '../../../api/pupil-upload/pupil-upload.api';
import { DataSource } from '../../../pupils/pupil-admin/pupil-admin.page';
import { ProgressNotificationType } from '../../components/progress-notification/progress-notification.enum';
import { PupilUploadModalComponent } from '../../components/pupil-upload-modal/pupil-upload-modal.component';
import { ModalAction } from '../../components/school-dashboard-modal/school-dashboard-modal.component';
import { UploadFeedbackModalComponent } from '../../components/upload-feedback-modal/upload-feedback-modal.component';
import { DataSourceService } from '../datasource/datasource.service';
import { ProgressData, ProgressServiceInterface } from './progressServiceInterface';

/**
 * The local storage key for a recent {@see PupilUpload}.
 */
export const PUPIL_UPLOAD_STORAGE_KEY: string = 'latest-pupil-uploads';

export interface StorageItem {
  location: string;
  upload: string;
}

//noinspection JSMethodCanBeStatic
/**
 * Service containing logic regarding keeping track of the progress of a
 * pupil upload. After uploading a new file the progress of that file is
 * polled (fetched every x seconds). This polling will be cancelled when the
 * file is done processing.
 */
@Injectable({
  providedIn: 'root',
})
export class ProgressPupilUploadService implements ProgressServiceInterface {
  /**
   * Server-based state to change the visibility of the progress indicator that
   * is visible on all pages.
   */
  public hideProgressIndicator$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * All the pupil uploads that are in progress. There can be multiple uploads
   * active, but only a single one is managed per location.
   */
  private uploadsInProgress$: BehaviorSubject<StorageItem[]> = new BehaviorSubject<StorageItem[]>([]);

  /**
   * The pupil upload that is currently being polled.
   */
  //eslint-disable-next-line max-len
  private pupilUpload$: BehaviorSubject<PupilUpload | undefined> = new BehaviorSubject<PupilUpload | undefined>(undefined);

  /**
   * The data containing information about the progress.
   */
  private data$: Observable<ProgressData>;

  /**
   * The interval that will poll the progress of an active pupil upload.
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private interval: any;

  /**
   * Boolean indicating whether the progressModal is Visible.
   */
  public forceProgressModalVisible: boolean;

  constructor(
    private locationsStoreService: LocationsStoreService,
    private pupilUploadApi: PupilUploadApi,
    private storage: Storage,
    private router: Router,
    private dataSourceService: DataSourceService,
    private errorHandler: SentryErrorHandler,
    private modalController: ModalController,
  ) {
    this.locationsStoreService.init();
  }

  /**
   * Initialize the service logic to check the progress of a pupil upload.
   */
  public async init(): Promise<void> {
    // Check for current uploads that might be in progress.
    const storageData: StorageItem[] = await this.storage.get(
      PUPIL_UPLOAD_STORAGE_KEY,
    );
    if (storageData) {
      this.uploadsInProgress$.next(storageData);
    }

    this.data$ = this.createDataObservable();

    this.locationsStoreService.active$.subscribe(
      (_activeLocation: LocationModel | undefined) => {
        this.fetchUploadAndSetupInterval();
      },
    );
  }

  /**
   * Get data containing information about the progress.
   */
  public getData$(): Observable<ProgressData> {
    return this.data$;
  }

  /**
   * Post a file to create a PupilUpload for the current location.
   */
  public postPupilFile(file: File): Observable<PupilUpload> {
    return this.locationsStoreService.active$.pipe(
      switchMap(location => {
        return this.pupilUploadApi.postPupilFile(location.getIri(), file).pipe(
          tap((upload: PupilUpload) => {
            this.setPupilUploadDataInStorage(upload.getIri() as string);
          }),
        );
      }),
    );
  }

  /**
   * Handle the dialog action. Depending on the action given, the data and type
   * can be used to change the origin modal and the response.
   */
  //eslint-disable-next-line complexity
  public async handleDialogAction(
    action: ModalAction,
    data: ProgressData,
    callback: () => Promise<boolean | void>,
  ): Promise<void> {
    if (action === ModalAction.OK) {
      if (data.percentageCompleted === 100 && !data.uploadErrors) {
        this.dataSourceService.refresh(DataSource.PUPIL);
        await this.removePupilUploadIriFromStorage();
        await this.router.navigate(['/pupils/overview']);
      }

      if (data.uploadErrors) {
        this.hideProgressIndicator$.next(true);
        await callback();
        await this.showUploadFeedbackModal();
      }

      if (data.type === ProgressNotificationType.RESTART) {
        this.hideProgressIndicator$.next(true);
        await (callback ? callback() : Promise.resolve());
        await this.showPupilUploadModal();
      }
    }

    if (action === ModalAction.CANCEL || action === ModalAction.CLOSE) {
      if (data.uploadErrors) {
        this.hideProgressIndicator$.next(false);
      }

      if (data.type === ProgressNotificationType.RESTART) {
        this.hideProgressIndicator$.next(false);
      }
    }
  }

  /**
   * Display the feedback modal with the current PupilUpload and errors.
   */
  private async showUploadFeedbackModal(): Promise<void> {
    const feedbackModal: HTMLIonModalElement = await this.modalController.create({
      component: UploadFeedbackModalComponent,
      cssClass: 'modal-md',
      componentProps: {
        progressService: this,
        pupilUpload: this.pupilUpload$.getValue(),
      },
    });

    await feedbackModal.present();
  }

  /**
   * Display the initial upload modal.
   */
  private async showPupilUploadModal(): Promise<void> {
    const uploadModal: HTMLIonModalElement = await this.modalController.create({
      component: PupilUploadModalComponent,
      cssClass: 'modal-xs',
      componentProps: {
        pupilUpload: this.pupilUpload$.getValue(),
      },
    });

    await uploadModal.present();
  }

  /**
   * Get data about the upload that might currently be in progress for the
   * current location.
   */
  public getInProgressForCurrentLocation$(): Observable<StorageItem[]> {
    return combineLatest([
      this.uploadsInProgress$,
      this.locationsStoreService.active$,
    ]).pipe(
      isDefined([0, 1]),
      map(([items, location]) => {
        return items.filter((item) => item.location === location?.getIri());
      }),
    );
  }

  /**
   * Set new data in the local storage regarding a pupil upload.
   */
  public async setPupilUploadDataInStorage(uploadIri: string): Promise<void> {
    const locationIri: string = (await firstValueFrom(this.locationsStoreService.active$))?.getIri() as string;
    const items: StorageItem[] = (await this.storage.get(PUPIL_UPLOAD_STORAGE_KEY)) ?? [];
    // Remove existing upload of location from the local storage just in case
    // there still is one in it.
    let newItems: StorageItem[] = items.filter(
      (item) => item.location !== locationIri,
    );
    // Add new value.
    newItems = [...newItems, { location: locationIri, upload: uploadIri }];

    await this.storage.set(PUPIL_UPLOAD_STORAGE_KEY, newItems);
    await this.fetchUploadAndSetupInterval();

    this.uploadsInProgress$.next(newItems);
    this.hideProgressIndicator$.next(false);
  }

  /**
   * Remove existing data in the local storage regarding a pupil upload.
   */
  public async removePupilUploadIriFromStorage(): Promise<void> {
    const locationIri: string = (await firstValueFrom(this.locationsStoreService.active$))?.getIri() as string;
    const items: StorageItem[] = (await this.storage.get(PUPIL_UPLOAD_STORAGE_KEY)) ?? [];
    const newItems: StorageItem[] = items.filter(
      (item) => item.location !== locationIri,
    );

    await this.storage.set(PUPIL_UPLOAD_STORAGE_KEY, newItems);

    this.uploadsInProgress$.next(newItems);
  }

  /**
   * Get the data from the local storage regarding a pupil upload.
   */
  public async getPupilUploadIriFromStorage(): Promise<string | undefined> {
    const locationIri: string = (await firstValueFrom(this.locationsStoreService.active$))?.getIri() as string;
    let items: StorageItem[] = await this.storage.get(PUPIL_UPLOAD_STORAGE_KEY);
    // In case the storage key contains incorrect formatted data somehow.
    items = Array.isArray(items) ? items : [];
    const foundItem: StorageItem[] = items.filter(
      (item) => item.location === locationIri,
    );

    if (!foundItem || foundItem.length === 0) {
      return;
    }

    return foundItem[0].upload;
  }

  /**
   * Fetch the upload and set up the interval.
   */
  private async fetchUploadAndSetupInterval(): Promise<void> {
    await this.fetchUploadInProgress();
    this.createPupilUploadInterval();
  }

  /**
   * Create an interval that fetches the latest data of the current
   * pupil upload of the current location every 5 seconds. This will only fetch
   * new results if there is an upload in progress (a.k.a. located in the local
   * storage) and if the upload has not been completed yet.
   */
  private createPupilUploadInterval(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setInterval(() => this.fetchUploadInProgress(), 5000);
  }

  /**
   * Fetch the upload (if existing) from the API.
   */
  private async fetchUploadInProgress(): Promise<void> {
    await firstValueFrom(this.uploadsInProgress$);
    const fromStorage: string | undefined = await this.getPupilUploadIriFromStorage();
    if (!fromStorage) {
      return;
    }

    const pupilUpload: PupilUpload = await firstEmitFrom(
      this.pupilUploadApi.getPupilUpload(fromStorage as string).pipe(
        catchError((error) => {
          // When an error occurs calling the endpoint we stop the polling.
          clearInterval(this.interval);
          if (error?.status === 404) {
            // When the upload can't be found (deleted?) we should clear this
            // upload from the local storage.
            this.removePupilUploadIriFromStorage();
          }
          return EMPTY;
        }),
      ),
    );

    if (pupilUpload?.completedAt) {
      clearInterval(this.interval);
    }

    this.pupilUpload$.next(pupilUpload);
  }

  /**
   * Create an observable containing the data that can be put in the
   * {@see ProgressNotificationComponent} and the
   * {@see ProgressNotificationModalComponent}.
   */
  private createDataObservable(): Observable<ProgressData> {
    return this.pupilUpload$.pipe(
      //@ts-ignore After checking if defined, it will for sure return a PupilUpload.
      isDefined<PupilUpload>(),
      // Only emit a new value when we should show something new.
      distinctUntilChanged((previous: PupilUpload, current: PupilUpload) => {
        return (
          previous?.processedRows === current?.processedRows
          && previous?.totalRows === current?.totalRows
          && previous?.completedAt === current?.completedAt
          && previous?.pupilsWithError === current.pupilsWithError
        );
      }),
      map((upload: PupilUpload) => {
        return {
          mainText: this.getMainText(upload),
          subText: this.getSubText(upload),
          progressText: this.getProgressText(upload),
          explanation: this.getExplanation(upload),
          type: this.getProgressNotificationType(upload),
          percentageCompleted: upload.percentageCompleted,
          uploadErrors: upload.pupilsWithError,
          progressModal: {
            closeIcon: this.getProgressModalCloseIcon(upload),
            actionButtonText: this.getActionButtonText(upload),
            closeButtonText: this.getCloseButtonText(upload),
          },
        };
      }),
    );
  }

  /**
   * Get the main text for the progress notification.
   */
  private getMainText(upload: PupilUpload): string {
    if (upload.percentageCompleted < 100) {
      return 'Leerlingen worden geïmporteerd';
    }

    if (upload.successfulCount === 0) {
      return 'Importeren mislukt';
    }

    if (upload.pupilsWithError) {
      return 'Importeren gedeeltelijk geslaagd';
    }

    return 'Importeren geslaagd';
  }

  /**
   * Get the sub text for the progress notification.
   */
  private getSubText(upload: PupilUpload): string | undefined {
    const text: string | undefined = this.getProgressText(upload);
    if (!text) {
      return;
    }

    return upload.percentageCompleted + '% &#183; ' + text;
  }

  /**
   * Get the information about the progress.
   */
  private getProgressText(upload: PupilUpload): string | undefined {
    switch (upload.percentageCompleted) {
      case 0:
        return 'Importeren voorbereiden...';
      case 99:
        return 'Importeren afronden...';
      case 100:
        return;
      default:
        return 'Bezig met importeren...';
    }
  }

  /**
   * Get the explanation of the Excel pupil upload.
   */
  private getExplanation(upload: PupilUpload): string {
    if (upload.percentageCompleted < 100) {
      return (
        'De leerlingen worden geïmporteerd. Dit kan enkele minuten duren.'
        + ' Werk gerust verder, het importeren zal op de achtergrond doorgaan.'
      );
    }

    const successMessage: string = 'Er zijn <span style="font-weight: var(--font-weight-medium)">'
      + upload.successfulCount
      + '</span> leerlingen succesvol geïmporteerd.';

    if (upload.pupilsWithError) {
      return (
        successMessage
        + '</br> Bij <span style="font-weight: var(--font-weight-medium)">'
        + upload.pupilsWithError
        + '</span> leerlingen is een fout opgetreden.'
        + ' Bekijk de details en upload het bestand opnieuw.'
      );
    }

    return successMessage;
  }

  /**
   * Get the type for the progress.
   */
  private getProgressNotificationType(
    upload: PupilUpload,
  ): ProgressNotificationType {
    if (upload.percentageCompleted < 100) {
      return ProgressNotificationType.IN_PROGRESS;
    }

    if (upload.pupilsWithError) {
      return ProgressNotificationType.DANGER;
    }

    return ProgressNotificationType.SUCCESS;
  }

  /**
   * Get the close icon for the progress modal.
   */
  private getProgressModalCloseIcon(upload: PupilUpload): IconProp {
    if (upload.percentageCompleted < 100) {
      return ['far', 'minus'];
    }

    return ['far', 'xmark'];
  }

  /**
   * Get the action button text for the progress modal.
   */
  private getActionButtonText(upload: PupilUpload): string {
    if (upload.percentageCompleted < 100) {
      return 'Verder werken';
    }

    if (upload.pupilsWithError) {
      return 'Bekijk details';
    }

    return 'Naar leerlingenbeheer';
  }

  /**
   * Get the close button text for the progress modal.
   * Or return undefined when no close button should be shown.
   */
  private getCloseButtonText(upload: PupilUpload): string | undefined {
    if (upload.percentageCompleted < 100) {
      return;
    }

    return 'Sluiten';
  }
}

import { Gender } from '@school-dashboard/enums';
import { Expose, Transform } from 'class-transformer';

/**
 * The array content of a row, converted to an object.
 */
export class UploadExceptionSpreadSheetRow {
  @Expose() public firstName: string;

  @Expose() public lastNamePrefix: string;

  @Expose() public lastName: string;

  @Expose() public emailAddress: string;

  @Expose() public schoolClassName: string;

  @Expose() public classLevel: string;

  @Expose() public year: string;

  /**
   * Transform the gender value to the known enum.
   */
  @Transform(({ value }) => {
    switch (value?.toLowerCase()) {
      case 'm':
      case 'male':
        return Gender.MALE;
      case 'v':
      case 'female':
        return Gender.FEMALE;
      default:
        return Gender.OTHER;
    }
  })
  @Expose()
  public gender: Gender;
}

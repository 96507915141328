import { SelectionModel, SelectionChange } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, share, Subject } from 'rxjs';
import { filter, map, mergeAll, mergeMap, startWith, takeUntil } from 'rxjs/operators';
import { keyToFilterModels } from './functions/key-to-filter-models/key-to-filter-models.function';
import { BaseFilterGroup } from './models/base-filter-group.model';
import { Filter } from './models/filter/filter.model';
import { Trigger, TriggerSelectionChange } from './models/trigger/trigger.model';

type FilterMap = Map<string, Filter>;
type FilterGroupMap = Map<string, BaseFilterGroup>;
export type TriggerFunction = (data: Trigger) => void;

@Injectable()
export class FilterControllerService {

  /**
   * All the filters from which the user can choose.
   */
  private filtersSubject$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);

  /**
   * Subject containing the current filterGroups.
   */
  private filterGroupsSubject$: BehaviorSubject<BaseFilterGroup[]> = new BehaviorSubject<BaseFilterGroup[]>([]);

  /**
   * A list of all filterGroup index by {@see BaseFilterGroup.key } and
   * {@see Filter.value } within a {@see Map} so we can easily look up a
   * specific filterGroup, by key and value.
   *
   * Note: the value property doesn't have to be unique, multiple FilterGroups
   * could contain the same values. So the lookup list contains only unique
   * values, Duplicates are omitted from this map.
   */
  private filterGroupLookupList$: BehaviorSubject<FilterGroupMap> = new BehaviorSubject<FilterGroupMap>(new Map([]));

  /**
   * A list of all available filter index by {@see Filter.key } and
   * {@see Filter.value } within a {@see Map} so we can easily look up a
   * specific filter, by key and value.
   *
   * Note: the value property doesn't have to be unique, multiple Filter in
   * multiple FilterGroups could contain the same values. So the lookup list
   * contains only unique values, Duplicates are omitted from this map.
   */
  private filterLookupList$: BehaviorSubject<FilterMap> = new BehaviorSubject<FilterMap>(new Map([]));

  /**
   * A {@see SelectionModel} containing the current selection of all filters
   * spanning all filterGroups. This SelectionModel is completely managed
   * through the {@see self.createSelectionFiltersObserver}, which subscribes to
   * each of the {@see BaseFilterGroup.selected} SelectionModel instances. These
   * hold the current selection of filters within that FilterGroup and
   * concatenates these SelectionModels.
   */
  private selectedFilters: SelectionModel<string> = new SelectionModel<string>(true);

  /**
   * This subject is used to let the {@see self.createSelectionFiltersObserver}
   * observer know that it should stop listening to the changes of the current
   * FilterGroups because a new list has been given through the
   * {@see self.filterGroups} function.
   */
  private onNewAvailableFilters$: Subject<void> = new Subject<void>();

  /**
   * Map containing all triggers registered via the {@see self.registerTrigger}
   * function. These trigger functions are used by the consumers of this service
   * to listen to changes in the filters available in this instance of the
   * service. Every change, the registered trigger functions are called.
   */
  private triggers: Map<string, TriggerFunction> = new Map<string, TriggerFunction>([]);

  constructor() {
    this.createSelectionFiltersObserver();
  }

  /**
   * Function used to set all the filters that can be chosen from the popover
   * lists.
   *
   * It will add the FilterGroup to each filter Model, generates a new
   * {@see self.filterLookupList$}, updates the {@see self.filtersSubject$} and
   * updates the {@see self.filterGroupsSubject$}. In Addition to trigger the
   * {@see self.onNewAvailableFilters$} so that the
   * {@see self.createSelectionFiltersObserver} observer stop listing to all
   * previous {@see FilterGroup.changes} observables.
   */
  public set filterGroups(filterGroups: BaseFilterGroup[]) {
    this.onNewAvailableFilters$.next();
    const groupLookupList: FilterGroupMap = new Map<string, BaseFilterGroup>();
    let noneUniqueGroupValues: string[] = [];
    for (let filterGroup of filterGroups) {
      groupLookupList.set(filterGroup.key, filterGroup);
      if (groupLookupList.has(filterGroup.value)) {
        noneUniqueGroupValues.push(filterGroup.value);
      }
      groupLookupList.set(filterGroup.value, filterGroup);
    }
    for (let noneUnique of noneUniqueGroupValues) {
      groupLookupList.delete(noneUnique);
    }
    this.filterGroupsSubject$.next(filterGroups.sort(this.sortAlphabetically));
    this.filterGroupLookupList$.next(groupLookupList);

    this.createLookupListSubscriber(filterGroups);
  }

  /**
   * Getter for {@see self.filterGroupsSubject$}
   * Returns an array of available {@see BaseFilterGroup}.
   */
  public get filterGroups(): BaseFilterGroup[] {
    return this.filterGroupsSubject$.getValue();
  }

  /**
   * Observable getter for {@see self.filterGroupsSubject$}
   * Returns an observable version of the {@see self.filterGroups} property,
   * which is an array of available {@see BaseFilterGroup} instances.
   */
  public get filterGroups$(): Observable<BaseFilterGroup[]> {
    return this.filterGroupsSubject$.asObservable();
  }

  /**
   * Returns a snapshot array of available filters. All filters have the
   * {@see Filter.filterGroup} property set to their parent FilterGroup
   * instance.
   */
  public get filters(): Filter[] {
    return this.filtersSubject$.getValue();
  }

  /**
   * An observable version of the {@see self.filters} property.
   */
  public get filters$(): Observable<Filter[]> {
    return this.filtersSubject$.asObservable();
  }

  /**
   * This Readonly property return this current list of selected filters of the
   * service instance. All filters have the {@see Filter.filterGroup} property
   * set to their parent FilterGroup instance.
   */
  public get selected(): Filter[] {
    return this.keyToFilterModels(this.selectedFilters.selected);
  }

  /**
   * An observable version of the {@see self.selected} property.
   */
  public get selected$(): Observable<Filter[]> {
    return this.selectedFilters.changed.pipe(
      map(changed => {
        return this.keyToFilterModels(changed.source.selected);
      }),
      startWith([] as Filter[]),
    );
  }

  /**
   * This property contains an observable emitting the change of the
   * {@see self.selectedFilters}, which is an object with Filter model instance,
   * mapped from the changed keys.
   *
   * Note: this observable doesn't emit the {@see SelectionChange.source}
   * property. This is on purpose because it would give a false appearance of
   * something that the {@see SelectionModel} doesn't support. The
   * {@see SelectionModel} only types which can be consumed by a Set, which are
   * primitives or object references.
   */
  public get changes$(): Observable<{ added: Filter[]; removed: Filter[] }> {
    return this.selectedFilters.changed.pipe(map(changed => {
      return {
        added: this.keyToFilterModels(changed.added),
        removed: this.keyToFilterModels(changed.removed),
      };
    }));
  }

  /**
   * This method enable the user to register a Trigger function to the
   * FilterControlService.
   *
   * Each given trigger is called on any {@see BaseFilterGroup.change} emit of
   * the FilterGroups within this service instance. Each trigger is triggered
   * alphabetically based on the given triggerName. Each trigger receives a
   * {@see Trigger} model containing multiple readonly data entry points.
   * Although the entry points are readonly, the instances themselves are fully
   * mutable which enables the user to change certain states of filter as they
   * see fit.
   *
   * @param triggerName Unique identifying key for the trigger.
   * @param triggerFunction The function which is called on each change of any
   *                        FilterGroup within the Controller.
   */
  public registerTrigger(triggerName: string, triggerFunction: TriggerFunction): void {
    this.triggers.set(triggerName, triggerFunction);
  }

  /**
   * This method remove a previously add Trigger function from this Controller.
   *
   * @param triggerName Name of the trigger you want to remove.
   */
  public removeTrigger(triggerName: string): void {
    this.triggers.delete(triggerName);
  }

  /**
   * Add or remove a filter from the {@see BaseFilterGroup.selected}
   * SelectionModel. Which in turn updates the {@see selectedFilters } list.
   *
   * @return boolean state for the existence of the given targetFilter. If true,
   * the filter has been toggled. If false, the filter does not exist in this
   * service instance.
   */
  public toggle(targetFilter: string | Filter): boolean {
    targetFilter = this.getFilter(targetFilter) as Filter;

    if (!targetFilter) {
      return false;
    }
    (targetFilter.filterGroup as BaseFilterGroup).toggle(targetFilter.key);
    return true;
  }

  /**
   * Add a filter from the {@see BaseFilterGroup.selected} SelectionModel, if it
   * isn't there already. This, in turn, updates the {@see self.selectedFilters}
   * list.
   *
   * @return boolean state for the existence of the given targetFilter. If true,
   * the filter has been selected. If false, the filter does not exist in this
   * service instance.
   */
  public select(targetFilter: string | Filter): boolean {
    targetFilter = this.getFilter(targetFilter) as Filter;

    if (!targetFilter) {
      return false;
    }

    (targetFilter.filterGroup as BaseFilterGroup).select(targetFilter.key);
    return true;
  }

  /**
   * Removes filter from the {@see BaseFilterGroup.selected} SelectionModel if
   * found, otherwise it's ignored. If there is a change, an update will happen
   * on the {@see self.selectedFilters} list.
   *
   * @return boolean state for the existence of the given targetFilter. If true,
   * the filter has been deselected. If false, the filter does not exist in this
   * service instance.
   */
  public deselect(targetFilter: string | Filter): boolean {
    targetFilter = this.getFilter(targetFilter) as Filter;

    if (!targetFilter) {
      return false;
    }

    (targetFilter.filterGroup as BaseFilterGroup).deselect(targetFilter.key);
    return true;
  }

  /**
   * Clears the list of selected filters.
   */
  public clear(): void {
    this.selectedFilters.clear();
    for (const group of this.filterGroupsSubject$.getValue()) {
      group.clear();
    }
  }

  /**
   * Returns a list of {@see Filter} instances that have a match in the given
   * array of keys.
   */
  private keyToFilterModels(keys: string[]): Filter[] {
    const lookupList: Map<string, Filter> = this.filterLookupList$.getValue();

    return keyToFilterModels(keys, lookupList);
  }

  /**
   * This method merges all {@see BaseFilterGroup.selected} models into one big
   * {@see SelectionModel} containing all the selected filters.
   *
   * It creates an observable that merges all emitters in the
   * {@see BaseFilterGroup.selected.changed}. It does this by using a mergeMap,
   * which means that each time one of the {@see SelectionModel} changes it does
   * this subscribe callback. The Subscribe method updates the
   * {@see self.selectedFilters} according to the changes emitted. It watches
   * the changes until the {@see self.onNewAvailableFilters$} emits. This emits
   * when new set of FilterGroups was added through the {@see self.filterGroups}
   * setter. This prevents the subscription logic emitting when the same group
   * has been added again.
   */
  private createSelectionFiltersObserver(): void {
    this.filterGroupsSubject$.pipe(
      filter(groups => groups.length > 0),
      mergeMap(filterGroups => filterGroups.map(filterGroup => {
        return filterGroup.changed
          .pipe(
            takeUntil(this.onNewAvailableFilters$),
            map(change => [filterGroup,change] as [BaseFilterGroup, SelectionChange<string>]),
          );
      })),
      mergeAll(),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    ).subscribe(([filterGroup, change]) => {
      this.selectedFilters.select(...change.added);
      this.selectedFilters.deselect(...change.removed);
      this.executeTrigger(filterGroup, change);
    });
  }

  /**
   * Execute a trigger function.
   */
  private executeTrigger(triggerFilterGroup: BaseFilterGroup, changes: SelectionChange<string>): void {
    for (const [key, callback] of this.triggers) {
      const changesModel: TriggerSelectionChange<string> = {
        ...changes,
        addedTrigger: changes.added.map(filterKey => triggerFilterGroup.lookupList.get(filterKey) as Filter),
        removedTrigger: changes.removed.map(filterKey => triggerFilterGroup.lookupList.get(filterKey) as Filter),
      };

      callback(new Trigger(
        key,
        triggerFilterGroup,
        changesModel,
        this.filterGroupLookupList$.getValue(),
      ));
    }
  }

  /**
   * Lookups a filter based on a string or Filter instance.
   */
  private getFilter(filterToAdd: string | Filter): Filter | void {
    const filterLookup: Map<string, Filter> = this.filterLookupList$.getValue();

    if (!(filterToAdd instanceof Filter)) {
      return filterLookup.get(filterToAdd.toLowerCase());
    }

    if (filterLookup.has(filterToAdd.key)) {
      return filterLookup.get(filterToAdd.key.toLowerCase());
    }

    if (!Array.isArray(filterToAdd.value)) {
      return filterLookup.get((filterToAdd.value as string).toLowerCase());
    }
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Sort the list of FilterGroups.
   *
   * 1. sort by position Ascending.
   * 2. groups with a position take precedence over groups without
   * 3. if no position is set sort alphabetically on displayValue or name if
   *   display value is not set
   *
   */
  private sortAlphabetically(
    valueA: BaseFilterGroup,
    valueB: BaseFilterGroup,
  ): number {
    if (typeof valueA.position === 'number' && typeof valueB.position === 'number') {
      return valueA.position - valueB.position;
    }

    if (typeof valueA.position === 'number' || typeof valueB.position === 'number') {
      return (typeof valueA.position === 'number') ? -1 : 1;
    }
    return (valueA?.displayText ?? '').localeCompare(valueB?.displayText ?? '');
  }

  /**
   * Subscribes to the FilterGroup lookup list and creates joined lists of
   * filters and lookup lists.
   */
  private createLookupListSubscriber(filterGroups: BaseFilterGroup[]): void {
    combineLatest(
      filterGroups.map(filterGroup => filterGroup.lookupList$.pipe(map(lookuplist => {
        return [filterGroup, lookuplist] as [BaseFilterGroup, Map<string, Filter>];
      }))),
    ).pipe(
      takeUntil(this.onNewAvailableFilters$),
    ).subscribe((lookupLists: [BaseFilterGroup, Map<string, Filter>][]) => {
      let filters: Filter[] = [];
      let lookupList: FilterMap = new Map<string, Filter>();
      let noneUniqueValues: string[] = [];

      for (let [filterGroup, lookupListMap] of lookupLists) {
        filters = filters.concat(filterGroup.filters);
        for (const [key, lookupFilter] of lookupListMap.entries()) {
          if (lookupList.has(key)) {
            noneUniqueValues.push(key);
          }
          lookupList.set(key, lookupFilter);
        }
      }

      for (let noneUnique of noneUniqueValues) {
        lookupList.delete(noneUnique);
      }

      this.filtersSubject$.next(filters);
      this.filterLookupList$.next(lookupList);
    });
  }
}

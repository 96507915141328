import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import { Track } from '@school-dashboard/models';
import { Resource } from '@techniek-team/api-platform';
import { sortChain, sortDate, sortString } from '@techniek-team/common';
import { GetTrackResponse, PurchaseCountInterface } from '../../../api/track/track.response';
import { trackActions } from './track.actions';

export const trackFeatureKey: string = 'track';

export interface ExamenTrainingPurchaseCountState
  extends EntityState<PurchaseCountInterface> {
  loading: boolean;
  loaded: boolean;
}

export interface TrackState extends EntityState<Resource<GetTrackResponse>> {
  saving: boolean;
  loading: boolean;
  loaded: boolean;
  onlyUpcomingTracks: boolean | null;
  activeTrack: {
    id: string | null;
  };
  error?: Error | null;
  examenTrainingPurchaseCount: ExamenTrainingPurchaseCountState;
}

export function selectTrackId(item: Resource<GetTrackResponse>): string {
  // In this case this would be optional since primary key is id.
  const iriRegex: RegExp = new RegExp(/^\/.*\/([A-z\d\-_]*)/);
  const match: string[] | null = item['@id'].match(iriRegex);
  return match ? match.pop() : undefined;
}

export function sortTracksByValidityRangeAndSubTracks(
  itemA: Resource<GetTrackResponse> | Track,
  itemB: Resource<GetTrackResponse> | Track,
): number {
  return sortChain(itemA, itemB).pipe(
    (a, b) =>
      sortDate(
        new Date(a?.parentTrack?.validityRange?.end ?? a.validityRange.end),
        new Date(b?.parentTrack?.validityRange?.end ?? b.validityRange.end),
        'asc',
      ),
    (a, b) =>
      sortString(
        a?.parentTrack?.['@id'] ?? a['@id'],
        b?.parentTrack?.['@id'] ?? b['@id'],
      ),
    (a, b) => {
      if (a.parentTrack && !b.parentTrack) {
        return 1;
      }
      if (!a.parentTrack && b.parentTrack) {
        return -1;
      }
      return 0;
    },
    (a, b) =>
      sortDate(
        new Date(a.validityRange.end),
        new Date(b.validityRange.end),
        'asc',
      ),
    (a, b) => sortString(a.name, b.name),
    (a, b) => sortString(a['@id'], b['@id']),
  );
}

export const trackAdapter: EntityAdapter<Resource<GetTrackResponse>> = createEntityAdapter<Resource<GetTrackResponse>>({
  selectId: selectTrackId,
  sortComparer: sortTracksByValidityRangeAndSubTracks,
});

//eslint-disable-next-line max-len
export const examTrainingPurchaseCountAdapter: EntityAdapter<PurchaseCountInterface> = createEntityAdapter<PurchaseCountInterface>({
  selectId: (item) => item.track,
});

export const initialState: TrackState = trackAdapter.getInitialState({
  saving: false,
  loading: false,
  loaded: false,
  onlyUpcomingTracks: null,
  activeTrack: {
    id: null,
    loading: false,
  },
  examenTrainingPurchaseCount: examTrainingPurchaseCountAdapter.getInitialState(
    {
      loading: false,
      loaded: false,
    },
  ),
});

export const trackReducer: ActionReducer<TrackState> = createReducer(
  initialState,
  on(trackActions.initTracks, (state, { onlyUpcomingTracks }) => {
    return {
      ...state,
      onlyUpcomingTracks: onlyUpcomingTracks,
      loading: true,
      error: null,
      examenTrainingPurchaseCount: {
        ...state.examenTrainingPurchaseCount,
        loading: true,
      },
    };
  }),
  on(trackActions.refreshTracks, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),
  on(trackActions.loadTracksSuccess, (state, { tracks }) => {
    return trackAdapter.setAll(tracks, {
      ...state,
      loaded: true,
      loading: false,
    });
  }),
  on(trackActions.loadTracksFailure, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error: error,
    };
  }),
  on(trackActions.setActiveTrack, (state, { track }) => ({
    ...state,
    activeTrack: {
      ...state.activeTrack,
      id: track,
    },
  })),
  on(trackActions.loadActiveTrackSuccess, (state, { track }) => {
    return trackAdapter.setOne(track, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(trackActions.loadActiveTrackFailure, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error: error,
    };
  }),
  on(trackActions.refreshActiveTrack, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),
  on(trackActions.addNewSubTrack, (state) => ({
    ...state,
    saving: true,
  })),
  on(trackActions.addNewSubTrackSuccess, (state, { newTrack }) => {
    return trackAdapter.addOne(newTrack, {
      ...state,
      saving: false,
    });
  }),
  on(trackActions.addNewSubTrackFailure, (state, { error }) => {
    return {
      ...state,
      error: error,
      saving: false,
    };
  }),
  on(
    trackActions.loadPurchaseCountForExamTrainingSuccess,
    (state, { purchaseCounts }) => {
      return {
        ...state,
        examenTrainingPurchaseCount: examTrainingPurchaseCountAdapter.setAll(
          purchaseCounts,
          {
            ...state.examenTrainingPurchaseCount,
            loading: false,
            loaded: true,
          },
        ),
      };
    },
  ),
);

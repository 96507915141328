import { CheckType } from '@school-dashboard/enums';
import { isTrackWithDeadline, Track, UserModel } from '@school-dashboard/models';
import { BasePermission, InsufficientPermissionsError } from '@techniek-team/permissions';
import { isBefore } from 'date-fns';
import { SchoolDashboardRole } from '../school-dashboard-role.enum';

/**
 * The permissions that are handled by the {@see TrackPermission}.
 */
export enum TrackPermissionSubjects {
  INIT_ADD_LOCATION_MEMBER = 'INIT_ADD_LOCATION_MEMBER',
  ADD_LOCATION_MEMBER = 'ADD_LOCATION_MEMBER',
  UPDATE_LOCATION_MEMBER = 'UPDATE_LOCATION_MEMBER',
  DELETE_LOCATION_MEMBER = 'DELETE_LOCATION_MEMBER',
  INIT_APPROVE_TRACK_APPROVALS = 'INIT_APPROVE_TRACK_APPROVALS',
  APPROVE_TRACK_APPROVAL = 'APPROVE_TRACK_APPROVAL',
  ADD_SUB_TRACK = 'ADD_SUB_TRACK',
}

export interface TrackPermissionData {
  track: Track;
  checkType: CheckType;
}

/**
 * Check whether the logged-in user has certain permissions for tracks.
 * A track is always linked to a single school location.
 */
export class TrackPermission extends BasePermission<UserModel> {
  /**
   * @inheritDoc
   */
  public validate(subject: TrackPermissionSubjects, data?: TrackPermissionData): boolean {
    switch (subject) {
      case TrackPermissionSubjects.INIT_ADD_LOCATION_MEMBER:
        return this.canInitAddLocationMemberToTrack();
      case TrackPermissionSubjects.ADD_LOCATION_MEMBER:
      case TrackPermissionSubjects.UPDATE_LOCATION_MEMBER:
        return this.canAddOrUpdateTrackAttendance(data);
      case TrackPermissionSubjects.DELETE_LOCATION_MEMBER:
        return this.canDeleteTrackAttendance(data);
      case TrackPermissionSubjects.INIT_APPROVE_TRACK_APPROVALS:
        return this.canInitApproveTrackApprovals();
      case TrackPermissionSubjects.APPROVE_TRACK_APPROVAL:
        return this.canApproveTrackApproval(data);
      case TrackPermissionSubjects.ADD_SUB_TRACK:
        return this.canAddSubTrack();
      default:
        throw new InsufficientPermissionsError();
    }
  }

  /**
   * Helper function to check if the logged-in user can initialize the flow to
   * add a location member to a track.
   * - An admin can always initialize this flow.
   *
   * - A coordinator can only initialize this flow when he/she is an actual
   *   location member with the role coordinator for the school location.
   *   (Later on the user might see that a deadline has passed, but that is
   *   checked by a different permission subject).
   *
   * - A school contact can only initialize this flow when he/she is an actual
   *   location member with the role school contact for the school location.
   *   (Later on the user might see that a deadline has passed, but that is
   *   checked by a different permission subject).
   *
   * - An admin with readonly rights can never initialize this flow.
   */
  private canInitAddLocationMemberToTrack(): boolean {
    return (
      this.is(SchoolDashboardRole.ADMIN)
      || this.isCoordinatorForCurrentLocation()
      || this.isSchoolContactForActiveLocation()
    );
  }

  /**
   * Helper function to check if the logged-in user can add or update a
   * location member to/from a track.
   * - An admin can always add/update/remove a location member to/from a track.
   *
   * - A coordinator can only add/update/remove a location member to/from a track if
   *   he/she is a coordinator of the location and the track has not yet ended.
   *
   * - A school contact can only add/update/remove a location member to/from a track if
   *   he/she is a school contact of the location and the deadline of the track
   *   has not yet been passed.
   *
   * - An admin with readonly rights can never add/update/remove a location member
   *   to/from a track.
   */
  //eslint-disable-next-line complexity
  private canAddOrUpdateTrackAttendance(
    data: TrackPermissionData | undefined,
  ): boolean {
    if (this.is(SchoolDashboardRole.ADMIN)) {
      return true;
    }

    if (!data?.track) {
      return false;
    }

    const today: Date = new Date();

    if (this.isCoordinatorForCurrentLocation() && isBefore(today, data.track.validityRange.end)) {
      return true;
    }

    if (isTrackWithDeadline(data.track)) {
      return (this.isSchoolContactForActiveLocation() && isBefore(today, data.track.deadline));
    }

    return (this.isSchoolContactForActiveLocation() && isBefore(today, data.track.validityRange.end));
  }

  /**
   * Similar to {@see canAddOrUpdateTrackAttendance}, this helper checks the
   * logged-in user for permissions based on the given track's permissions.
   * When the pupil amount definitive approval has been checked then no-one
   * can delete track attendances, otherwise:
   * - An admin can always remove items from a track.
   * - Anyone else has their rights dictated by the Track's permission settings.
   */
  private canDeleteTrackAttendance(data: TrackPermissionData | undefined): boolean {
    if (!data?.track) {
      return false;
    }

    if (data.track.isPupilAmountDefinitiveChecked) {
      return false;
    }

    if (this.is(SchoolDashboardRole.ADMIN)) {
      return true;
    }

    return (this.isCoordinatorForCurrentLocation() && data.track.canDeleteTrackAttendances);
  }

  /**
   * Helper function to check if the logged-in user can add sub tracks.
   */
  private canAddSubTrack(): boolean {
    return (this.is(SchoolDashboardRole.ADMIN) || this.isCoordinatorForCurrentLocation());
  }

  /**
   * Helper function to check if the logged-in user can initialize the flow to
   * approve the track approvals.
   */
  private canInitApproveTrackApprovals(): boolean {
    return (this.is(SchoolDashboardRole.ADMIN) || this.isCoordinatorForCurrentLocation());
  }

  /**
   * Helper function to check if the logged-in user can approve a track approval
   * (check). This can only be done if the depending checks are already
   * approved.
   */
  private canApproveTrackApproval(data: TrackPermissionData | undefined): boolean {
    if (!data?.track || !data?.checkType) {
      return false;
    }

    // Determine if the passed check type depends on other check types that
    // should already be approved.
    for (const shouldBeApprovedCheckType of data.track.availableChecks.get(data.checkType)) {
      if (!data.track.checks.find(check => check.type === shouldBeApprovedCheckType)) {
        return false;
      }
    }

    // The pupil amount can only be approved as definitive when there are pupils
    // signed-up for the track.
    if (data.checkType === CheckType.PUPIL_AMOUNT_DEFINITIVE && data.track.currentPupils === 0) {
      return false;
    }

    // Check if the current user can actually approve the checks.
    return this.canInitApproveTrackApprovals();
  }

  /**
   * Helper function to check if the logged-in user has the role for a
   * coordinator and is an actual location member for the current location
   * as a coordinator.
   */
  private isCoordinatorForCurrentLocation(): boolean {
    return (this.is(SchoolDashboardRole.COORDINATOR) && this.user.isCoordinatorForCurrentLocation);
  }

  /**
   * Helper function to check if the logged-in user has the role for a
   * school contact and is an actual location member for the current location
   * as a school contact.
   */
  private isSchoolContactForActiveLocation(): boolean {
    return (this.is(SchoolDashboardRole.SCHOOL_CONTACT) && this.user.isSchoolContactForCurrentLocation);
  }
}

import { Injectable } from '@angular/core';
import { CacheService } from '@techniek-team/services';
import { TrackStoreService } from '../../../store/track/track-store.service';

@Injectable({
  providedIn: 'root',
})
export class CacheIdentifierService {
  constructor(
    private cacheService: CacheService,
    private trackStoreService: TrackStoreService,
  ) {}

  public getAllCancellationReasonsCacheIdentifier(): string {
    return 'ALL_CANCELLATION_REASONS';
  }

  public getAllClustersReasonsCacheIdentifier(): string {
    return 'ALL_CLUSTERS';
  }

  public getAllLocationsCacheIdentifier(): string {
    return 'ALL_LOCATIONS';
  }

  public getAllSubjectsCacheIdentifier(): string {
    return 'ALL_SUBJECTS';
  }

  public getAllTracksCacheIdentifier(locationIri: string): string {
    return 'ALL_TRACKS_OF_LOCATION_' + locationIri;
  }

  public getUpcomingTracksCacheIdentifier(locationIri: string): string {
    return 'UPCOMING_TRACKS_OF_LOCATION_' + locationIri;
  }

  public getPractiseTracksCacheIdentifier(locationIri: string): string {
    return 'PRACTISE_TRACKS_OF_LOCATION_' + locationIri;
  }

  public getAllLocationMemberManagersCacheIdentifier(
    locationIri: string,
  ): string {
    return 'ALL_LOCATION_MEMBERS_FOR_LOCATION_' + locationIri;
  }

  public getSpecificLocationMemberManagerCacheIdentifier(
    locationIri: string,
    personId: string,
  ): string {
    return (
      'LOCATION_MEMBER_FOR_LOCATION_'
      + locationIri
      + '_AND_PERSON_ID_'
      + personId
    );
  }

  public getPupilCacheIdentifier(pupilIri: string): string {
    return 'PUPIL_' + pupilIri;
  }

  public getSchoolClassesCacheIdentifier(locationIri: string): string {
    return 'SCHOOL_CLASSES_FOR_LOCATION_' + locationIri;
  }

  public refreshAllAndUpcomingTracks(locationIri: string): void {
    this.cacheService.refresh(this.getAllTracksCacheIdentifier(locationIri));
    this.cacheService.refresh(
      this.getPractiseTracksCacheIdentifier(locationIri),
    );
    this.cacheService.refresh(
      this.getUpcomingTracksCacheIdentifier(locationIri),
    );
    this.trackStoreService.refreshTracks();
  }
}

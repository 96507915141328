import { FilterNotFoundError } from '../../errors/filter-not-found.error';
import { Filter } from '../../models/filter/filter.model';

/**
 * Convert an array of string (usually retrieved from {@see SelectionModel.changed})
 * to the original Filter instance based on the given lookup list.
 */
export function keyToFilterModels<T extends Filter>(keys: string[], lookupList: Map<string, T>): T[] {
  const targetFilters: T[] = [];
  for (let key of keys) {
    key = key.toLowerCase();
    if (!lookupList.has(key)) {
      throw new FilterNotFoundError(key);
    }
    targetFilters.push(lookupList.get(key) as T);
  }
  return targetFilters;
}

import { NgModule, Type } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginModule } from './login/login.module';
import { MenuLayoutPage } from './menu-layout/menu-layout.page';
import { OAuthGuard } from './shared/oauth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [OAuthGuard],
    component: MenuLayoutPage,
    children: [
      {
        path: 'home',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> =>
          import('./home/home.module').then((m) => m.HomePageModule),
      },
      {
        path: 'pupils',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> =>
          import('./pupils/pupils.module').then((m) => m.PupilsModule),
      },
      {
        path: 'tracks',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> =>
          import('./tracks/tracks.module').then((m) => m.TracksModule),
      },
      {
        path: 'services',
        canActivate: [OAuthGuard],
        loadChildren: () =>
          import('./services/services.module').then((m) => m.ServicesModule),
      },
    ],
  },
  {
    path: 'login',
    loadChildren: (): Promise<typeof LoginModule> =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<app-colored-title>Algemeen</app-colored-title>

<ion-content [scrollY]="true">
  <ng-container *ngIf="_listItems$ | async as listItems; else loading">
    <ion-list lines="none" class="no-bg">
      <ion-item *ngFor="let listItem of listItems; let first = first">
        <ion-label [class.button-container]="first">
          <span>{{ listItem.label }}</span>
          <span>
            {{ listItem.value }}

            <ion-button
              *ngIf="first"
              color="primary"
              class="underline ion-no-padding"
              fill="clear"
              size="default"
              (click)="switchToEdit()"
            >
              Leerling bewerken
            </ion-button>
          </span>
        </ion-label>
      </ion-item>
    </ion-list>

    <ion-button
      disabled
      class="underline primary ion-no-padding delete"
      fill="clear"
      size="default"
    >
      Leerling verwijderen
    </ion-button>
  </ng-container>

  <ng-template #loading>
    <ion-list lines="none">
      <ion-item *ngFor="let x of [].constructor(7)">
        <ion-label>
          <span><ion-skeleton-text animated="true"></ion-skeleton-text></span>
          <span><ion-skeleton-text animated="true"></ion-skeleton-text></span>
        </ion-label>
      </ion-item>
    </ion-list>

    <ion-skeleton-text animated="true" class="delete"></ion-skeleton-text>
  </ng-template>
</ion-content>

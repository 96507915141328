import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LyceoUser } from '../../models/lyceo-user.model';
import { Token } from '../../models/token.model';
import { UserInterface } from '../../shared/user/user.interface';
import { AuthGetProfileResponse, AuthGetTokenResponse } from './auth.response';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthConfig, UserModelClassConstructor } from '../../auth.config';


@Injectable()
export class AuthApi<T extends UserInterface = LyceoUser, I extends UserInterface = AuthGetProfileResponse> {

  constructor(
    private http: HttpClient,
    private config: AuthConfig<T, I>,
  ) {}

  /**
   * Get the users profile
   */
  public getProfile(): Observable<T> {
    return this.http.get<I>(this.config.profileBaseUrl).pipe(
      map(response => this.createModelFromResponse(response)),
    );
  }

  /**
   * Log the user out
   */
  public logout(): Observable<void> {
    const url: string = `${this.config.ssoBaseUrl}/logout`;
    return this.http.get<void>(url, {
      withCredentials: true,
    });
  }

  /**
   * Exchange the code for an accessToken
   */
  public getToken(
    code: string,
    codeVerifier: string,
    redirectUri: string,
  ): Observable<Token> {
    const headers: HttpHeaders = new HttpHeaders()
      .set('content-type', 'application/x-www-form-urlencoded');

    // we use the HttpParams class to easly construct the www-form-urlencoded
    // data object
    const params: HttpParams = new HttpParams()
      .set('client_id', this.config.clientId)
      .set('grant_type', 'authorization_code')
      .set('code', code)
      .set('code_verifier', codeVerifier)
      .set('redirect_uri', redirectUri);

    return this.http.post<AuthGetTokenResponse>(
      `${this.config.ssoBaseUrl}/token`,
      params.toString(),
      {
        headers: headers,
      },
    ).pipe(map(response => new Token(
      response['token_type'],
      response['access_token'],
      response['expires_in'],
      response['refresh_token'],
    )));
  }

  /**
   * Get a new accessToken using the refreshToken
   */
  public refreshAccessToken(refreshToken: string): Observable<Token> {
    const headers: HttpHeaders = new HttpHeaders()
      .set('content-type', 'application/x-www-form-urlencoded');

    const params: HttpParams = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken)
      .set('client_id', this.config.clientId);

    return this.http.post<AuthGetTokenResponse>(
      `${this.config.ssoBaseUrl}/token`,
      params.toString(),
      {
        headers: headers,
      },
    ).pipe(map(response => new Token(
      response['token_type'],
      response['access_token'],
      response['expires_in'],
      response['refresh_token'],
    )));
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createModelFromResponse(response: I): T {
    try {
      return new (this.config.model as UserModelClassConstructor<T, I>)(response);
    } catch (e) {
      return response as unknown as T;
    }
  }
}

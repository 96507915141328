import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { map, Observable, shareReplay } from 'rxjs';
import { locationsActions } from './+state/locations.actions';
import { LocationsSelectors } from './+state/locations.selectors';
import { LocationModel } from '@school-dashboard/models';

@Injectable()
export class LocationsStoreService {

  public loading$: Observable<boolean> = this.store.pipe(select(LocationsSelectors.locationsLoading));

  public loaded$: Observable<boolean> = this.store.pipe(select(LocationsSelectors.locationsLoaded));

  public count$: Observable<number> = this.store.pipe(select(LocationsSelectors.count));

  public locations$: Observable<LocationModel[]> = this.store.pipe(
    select(LocationsSelectors.allLocations),
    map(data => denormalize(LocationModel, data)),
    shareReplay(),
  );

  public active$: Observable<LocationModel> = this.store.pipe(
    select(LocationsSelectors.activeLocation),
    isDefined(),
    map(data => denormalize(LocationModel, data)),
    shareReplay(),
  );

  constructor(private readonly store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  public async init(): Promise<void> {
    if (await firstEmitFrom(this.store.pipe(select(LocationsSelectors.locationsInitialized)))) {
      return;
    }
    this.store.dispatch(locationsActions.initLocations());
  }

  public selectLocation(location: LocationModel | string | undefined): void {
    if (location instanceof LocationModel) {
      location = location.getId();
    }
    this.store.dispatch(locationsActions.selectLocation({ locationId: location }));
  }
}

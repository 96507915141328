import { Component, ElementRef, forwardRef, HostListener, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

type OnChangeCallback = (file: File) => void;
type OnTouchCallback = (touched: boolean) => void;

/**
 * A form control used for file uploads.
 *
 * @example `
 *   <app-file-upload formControlName="file"
 *                    dragAndDropNote="Afbeelding hierheen slepen of"
 *                    placeholder="kies een afbeelding"
 *                    accept="image/x-png,image/jpeg"
 *                    note="Ondersteunt .png, .jpg en .jpeg.">
 *   </app-file-upload>
 * `
 */
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  /**
   * File input element. Needed to clear the value for same-name file uploads.
   */
  @ViewChild('fileInput', { static: true })
  public fileInput: ElementRef<HTMLInputElement>;

  /**
   * A note regarding the drag and drop.
   */
  @Input() public dragAndDropNote: string = 'Bestand hierheen slepen of';

  /**
   * The placeholder of the input field.
   */
  @Input() public placeholder: string = 'Klik hier om het bestand te selecteren';

  /**
   * The note regarding the file type.
   */
  @Input() public note: string = 'Ondersteunt alle bestandsformaten.';

  /**
   * File types accepted by the input field.
   */
  @Input() public accept: string = '*';

  /**
   * Unique id for referencing correct input field.
   */
  public uuid: string;

  /**
   * OnChange method
   */
  public onChange: OnChangeCallback = (_file: File) => {
    // Callback.
  };

  /**
   * the onTouch callback
   */
  public onTouch: OnTouchCallback = (_touched: boolean) => {
    // callback
  };

  /**
   * The file that has been selected
   */
  public file: File | null = null;

  /**
   * Whether this form control is disabled or not.
   */
  public _isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  /**
   * A listener that triggers if the file changed
   */
  @HostListener('change', ['$event.target.files'])
  public emitFiles(event: FileList): void {
    // The file input is disabled, so this will only trigger when drag and
    // dropping a file.
    if (this._isDisabled$.getValue()) {
      this.presentInfoAboutDisabledAlert();
      return;
    }

    const file: File = event?.item(0) as File;
    // file.size
    // file.type

    this.onChange(file);
    this.file = file;
  }

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private alertController: AlertController,
  ) {
    this.uuid = window.crypto.randomUUID();
  }

  /**
   * @inheritDoc
   */
  public writeValue(value: null): void {
    // Clear file input.
    this.host.nativeElement.value = '';
    this.fileInput.nativeElement.value = '';
    this.file = null;
  }

  /**
   * @inheritDoc
   */
  public registerOnChange(fn: OnChangeCallback): void {
    this.onChange = fn;
  }

  /**
   * @inheritDoc
   */
  public registerOnTouched(fn: OnTouchCallback): void {
    this.onTouch = fn;
  }

  /**
   * @inheritDoc
   */
  public setDisabledState(isDisabled: boolean): void {
    this._isDisabled$.next(isDisabled);
  }

  /**
   * Present an alert with info about the disabled file upload control.
   */
  private async presentInfoAboutDisabledAlert(): Promise<void> {
    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: 'Uploaden niet mogelijk',
      message:
        'Een bestand uploaden is niet mogelijk, omdat het uploadveld is uitgeschakeld.',
      buttons: ['Doorgaan'],
    });

    await alert.present();
  }
}

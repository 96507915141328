import { Type } from '@angular/core';
import { UserServiceInterface } from '@techniek-team/common';
import { PermissionUserInterface } from './contracts/permission-user.interface';

export class TtPermissionConfig<T extends PermissionUserInterface = PermissionUserInterface> {

  /**
   * You need to provide a User service class. This class will be used to
   * retrieve the information of the logged-in user, and it's corresponding roles
   *
   * The specified service needs to implement the UserServiceInterface and need to
   * be an @Injectable service within the root or module.
   */
  public userService!: Type<UserServiceInterface<T>>;

  /**
   * Optionally you can supply a role hierarchy to give the ability to extend of
   * alias certain roles.
   *
   * Instead of giving many roles to each user, you can define role inheritance rules by creating a role hierarchy:
   * ```typescript
   *  roleHierarchy: {
   *    ROLE_SKOLEO_TT_PLANNER_ADMIN: [
   *      'ADMIN',
   *      'ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT',
   *      'ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM',
   *    ],
   *    ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT: 'LOCATION_MANAGER',
   *    ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM: 'SCHEDULING_TEAM',
   *  }
   * ```
   * Users with the ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT role will also have the LOCATION_MANAGER role.
   * Users with ROLE_SKOLEO_TT_PLANNER_ADMIN, will automatically have the roles:
   *  - ADMIN
   *  - ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT
   *  - ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM
   *  - LOCATION_MANAGER (inherited from ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT).
   *  - SCHEDULING_TEAM (inherited from ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM).
   */
  public roleHierarchy?: Record<string, string | string[]>;

}

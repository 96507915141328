import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

type EventObservable = Observable<KeyboardEvent>;
type EventMap = Map<string, EventObservable>;
type HandlerMap = Map<keyof GlobalEventHandlersEventMap, EventMap>;

@Injectable({
  providedIn: 'root',
})
export class FromEventService {
  private handlerMap: HandlerMap = new Map();

  /**
   * Create an observable that watches the given Event name and filters for a
   * given key.
   */
  public set(event: keyof GlobalEventHandlersEventMap, key: string): void {
    const eventMap: EventMap = this.handlerMap.get(event) ?? new Map();
    if (!eventMap.has(key)) {
      eventMap.set(
        key,
        fromEvent<KeyboardEvent>(document, event).pipe(
          filter((keypress) => (keypress as KeyboardEvent).key === key),
        ),
      );

      this.handlerMap.set(event, eventMap);
    }
  }

  /**
   * Watch for key presses for the given event and key.
   */
  public watch(
    event: keyof GlobalEventHandlersEventMap,
    key: string,
  ): EventObservable {
    let eventMap: EventMap = this.handlerMap.get(event) ?? new Map();
    if (!eventMap.has(key)) {
      this.set(event, key);
      eventMap = this.handlerMap.get(event) ?? new Map();
    }
    return eventMap.get(key) as EventObservable;
  }
}

type Entries<T> = { [K in keyof T]: [K, T[K]]; }[keyof T][];

export enum TrackType {
  CODELAB = 'CODELAB',
  EXAMTRAINING = 'EXAMTRAINING',
  HOMI_LICENSE = 'HOMI_LICENSE',
  HUISWERKBEGELEIDING = 'HUISWERKBEGELEIDING',
  ONDERWIJSHELDEN = 'ONDERWIJSHELDEN',
  OVERSTAP = 'OVERSTAP',
  PRACTICE = 'PRACTICE',
  STUDY_COACHING = 'STUDY_COACHING',
  SUMMERSCHOOLS = 'SUMMERSCHOOLS',
  TUTORING = 'TUTORING',
  TWOK = 'TWOK',
  UNKNOWN = 'UNKNOWN',
}

const TYPES: { [Key in TrackType]: { display: string; entity: EntityTrack } } = {
  [TrackType.CODELAB]: { display: 'codelab', entity: 'CodelabTrack' },
  [TrackType.EXAMTRAINING]: { display: 'examentraining', entity: 'ExamTrainingTrack' },
  [TrackType.HOMI_LICENSE]: { display: 'HOMi-licentie', entity: 'HomiLicenseTrack' },
  [TrackType.HUISWERKBEGELEIDING]: { display: 'huiswerkbegeleiding', entity: 'HuiswerkbegeleidingTrack' },
  [TrackType.ONDERWIJSHELDEN]: { display: 'onderwijshelden', entity: 'OnderwijsheldenTrack' },
  [TrackType.OVERSTAP]: { display: 'overstap', entity: 'OverstapTrack' },
  [TrackType.PRACTICE]: { display: 'Lyceo Oefenen', entity: 'PracticeTrack' },
  [TrackType.STUDY_COACHING]: { display: 'studiecoaching', entity: 'StudyCoachingTrack' },
  [TrackType.SUMMERSCHOOLS]: { display: 'vakantieschool', entity: 'SummerSchoolTrack' },
  [TrackType.TUTORING]: { display: 'bijles', entity: 'TutoringTrack' },
  [TrackType.TWOK]: { display: 'TWOK', entity: 'TwokTrack' },
  [TrackType.UNKNOWN]: { display: 'standaard', entity: 'UNKNOWN' },
};

export type EntityTrack = string;
export type SupportedTracksType = Exclude<TrackType, TrackType.UNKNOWN>;
const EXCLUDED_TRACKS: TrackType[] = [TrackType.UNKNOWN];

type TrackTypeResourceType = { [Key in TrackType]: EntityTrack };
/**
 * Dynamically build the following Objects (which can be used in the same way as enums).
 * The created object are like these:
 *
 * export enum TrackTypeDisplayValue {
 *   SUMMERSCHOOLS = 'vakantieschool',
 *   HUISWERKBEGELEIDING = 'huiswerkbegeleiding',
 * }
 *
 * export enum TrackTypeResource {
 *   SUMMERSCHOOLS = 'SummerSchoolTrack',
 *   HUISWERKBEGELEIDING = 'HuiswerkbegeleidingTrack',
 * }
 *
 * ResourceToTrackType {
 *   SummerSchoolTrack = 'SUMMERSCHOOLS',
 *   HuiswerkbegeleidingTrack = 'HUISWERKBEGELEIDING',
 * }
 */
//eslint-disable-next-line @typescript-eslint/naming-convention
export const { TrackTypeDisplayValue, TrackTypeResource, ResourceToTrackType, SupportedTrackTypes } = function () {
  let trackTypeDisplayValue = {} as { [Key in TrackType]: string };
  let trackTypeResource = {} as { [Key in TrackType]: EntityTrack };
  let resourceToTrackType = {} as { [Key in TrackTypeResourceType[TrackType]]: TrackType };
  let supportedTrackType = {} as { [Key in SupportedTracksType]: SupportedTracksType };

  for (let [key, value] of Object.entries(TYPES) as Entries<typeof TYPES>) {
    trackTypeDisplayValue[key] = value.display;
    trackTypeResource[key] = value.entity;
    resourceToTrackType[value.entity] = key;
    if (!EXCLUDED_TRACKS.includes(key as TrackType)) {
      supportedTrackType[key as SupportedTracksType] = TrackType[key] as SupportedTracksType;
    }
  }

  return {
    //eslint-disable-next-line @typescript-eslint/naming-convention
    TrackTypeDisplayValue: trackTypeDisplayValue,
    //eslint-disable-next-line @typescript-eslint/naming-convention
    TrackTypeResource: trackTypeResource,
    //eslint-disable-next-line @typescript-eslint/naming-convention
    ResourceToTrackType: resourceToTrackType,
    //eslint-disable-next-line @typescript-eslint/naming-convention
    SupportedTrackTypes: supportedTrackType,
  };
}();

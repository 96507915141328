import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  //eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[infinite-scroll-cell]',
})
export class InfiniteScrollCellDirective implements OnChanges {
  /**
   * Input for the CSSStyleDeclaration, which is used to extract the various
   * styles and apply them to the element this directive is on.
   */
  @Input() public declaration: CSSStyleDeclaration;

  /**
   * Apply the actual width of the column instead of '100%', so columns that
   * override the dynamic width of the row are included.
   */
  @Input() public doWidth: boolean = false;

  /**
   * Stored width value, if available.
   */
  private width: string = '100%';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  /**
   * @inheritDoc
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if ('declaration' in changes && !!this.declaration) {
      this.width = this.declaration.width;
      this.applyStyles();
    }

    if ('doWidth' in changes && !!this.width) {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'width',
        this.doWidth ? this.width : '100%',
      );
    }
  }

  /**
   * Use the Renderer2 and update the various styles.
   */
  private applyStyles(): void {
    const names: string[] = [
      'height',
      'padding',
      'width',
      'margin',
      'border-bottom',
      'border-top',
    ];

    for (const name of names) {
      let value: unknown = this.declaration[name];

      if (name === 'width') {
        value = this.doWidth ? value : '100%';
      }

      this.renderer.setStyle(this.elementRef.nativeElement, name, value);
    }
  }
}

import { CheckType, SummerSchoolTrackVariant } from '@school-dashboard/enums';
import { Exclude, Expose, Type } from 'class-transformer';
import { Track } from '../track.model';
import { HasDeadline } from './has-deadline.interface';
import { HasPupils } from './has-pupils.interface';

export class SummerschoolTrack extends Track implements HasDeadline, HasPupils {
  /**
   * @inheritDoc
   */
  @Type(() => Date)
  @Expose()
  public deadline: Date;

  /**
   * @inheritDoc
   */
  @Expose() public maxPupils: number;

  /**
   * Variant of this track. Determines things like preferences forms for the
   * LocationMembers when assigning to a track.
   */
  @Expose() public variant: SummerSchoolTrackVariant;

  @Exclude() public canAddTrackAttendances: boolean = true;

  @Exclude() public canDeleteTrackAttendances: boolean = true;

  @Exclude() public canEditTrackAttendances: boolean = true;

  @Exclude() public hasTrainings: boolean = false;

  @Exclude() public canHaveSubTracks: boolean = false;

  @Exclude() public canResendEmailTrackAttendance: boolean = false;

  @Exclude() public availableChecks: Map<CheckType, CheckType[]> = new Map<CheckType, CheckType[]>([
    [CheckType.PUPIL_AMOUNT_DEFINITIVE, []],
    [CheckType.START_DATE_DEFINITIVE, [CheckType.PUPIL_AMOUNT_DEFINITIVE]],
  ]);

  /**
   * If the preference for this track should be specified as days and a cluster.
   */
  public get isPreferenceDaysAndCluster(): boolean {
    return this.variant === SummerSchoolTrackVariant.SUMMER;
  }

  /**
   * If the preference for this track should be specified as subjects.
   */
  public get isPreferenceSubjects(): boolean {
    return (this.variant === SummerSchoolTrackVariant.PAIRS || this.variant === SummerSchoolTrackVariant.WEEK);
  }

  public get trackAttendancesRemaining(): number {
    return this.maxPupils - (this.currentPupils ?? 0);
  }
}

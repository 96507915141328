<div *ngIf="(actions$ | async) as actions"
     class="select"
     data-test="table-select-actions-container"
     [class.selected]="(count$ | async) > 0">
    <p>
        <ng-container *ngIf="(_dataSource?.loading$ | async) == true && !!actions?.length">
            <ion-spinner color="dark"></ion-spinner>
        </ng-container>
        <ng-container *ngFor="let markup of (label$ | async)">
        <span data-test="table-selection-actions-description"
              [class.bold]="markup.styleBold">
          {{ markup.value }}
        </span>
        </ng-container>
    </p>
    <div *ngIf="(_dataSource?.loading$ | async) == false && actions?.length > 0" class="actions">
        <ng-container *ngFor="let action of actions">
            <ion-button *ngIf="action.state !== SelectActionType.HIDDEN"
                        data-test="table-selection-actions-button"
                        color="white"
                        fill="solid"
                        (click)="handleSelectionAction(action)"
                        [disabled]="action.state === SelectActionType.LOADING || action.state === SelectActionType.DISABLED">
                <fa-icon [icon]="action.icon"
                         *ngIf="action.icon && action.state !== SelectActionType.LOADING"></fa-icon>
                <ng-container *ngIf="action.state === SelectActionType.LOADING">
                    <ion-spinner color="dark"></ion-spinner>
                </ng-container>
                {{ action.label }}
            </ion-button>
        </ng-container>
    </div>
</div>

<ng-container *ngFor="let filter of _source.filters$ | async">
  <ion-button *ngIf="filter.visible"
              [size]="size"
              [color]="color"
              [disabled]="filter.disabled"
              [fill]="(_source.isSelected$(filter.key) | async) ? 'solid' : 'outline'"
              (click)="toggle(filter)">
    {{ filter.displayText }}
  </ion-button>
</ng-container>

/** @format **/
import { HttpClient } from '@angular/common/http';
import { denormalize, JsonLd } from '@techniek-team/class-transformer';
import { FetchObservable, isDefined } from '@techniek-team/rxjs';
import { ClassConstructor, Transform, TransformationType } from 'class-transformer';
import 'reflect-metadata';
import {
  find,
  from,
  merge,
  Observable,
  of,
  ReplaySubject,
  share,
  switchMap,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  FetchStorageInterface,
  UpdatableFetchStorageInterface,
} from '../storage/fetch-storage.interface';

/**
 * Logical type used to switch the property type based on the given Generic in a model.
 *
 * @see Fetch
 */
export type Fetched<T, K> = T extends FetchObservable<unknown> ? FetchObservable<K> : K;

/**
 * Placeholder but given a clear description to the Generic of the models. This could be everything but
 * FetchObservable<unknown>
 *
 @see Fetch
 */
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EagerLoaded = Record<any, any>;

/**
 * Placeholder but given a Clear description to the Generic of the models.
 *
 * @see Fetch
 */
export type LazyLoaded = FetchObservable<unknown>;

/**
 * This function can be used as a Decorator. It can be used to lazy loaded a property from the server when needed.
 * It also first looks at the {@see CacheService} for the object, retrieving it only once.
 *
 * The class should have a Generic type `<Lazy = FetchObservable<unknown>>` This way state that it's an lazyLoaded
 * object. Which means that all properties with the @Fetch decorator are {@see FetchObservable} to which you can
 * subscribe to get the object or from which you can retrieve the iri through {@see FetchObservable.getIri }
 *
 * When you can to retrieve the object and want all the properties to be eagerLoaded at forhand can you use the
 * {@see JsonLd.fetchAll} which you should implement youself in the model (see example below). It should return the
 * same model but with the generic Model<EagerLoaded>. When returning this generic all properties with the @Fetch
 * decorator are typed with the models they should contain. For each use see the {@see eagerLoad } rxjs Operator.
 *
 * Important the {@see TtFetchModule } should be loaded before using this decorator, or you should set the
 * {@see HttpClient}, {@see CacheService}, BaseUrl and debug property with {@see Reflect.setMetaData} (see second
 * example) If not set the decorator wil throw an error.
 *
 * If a request returns an error, to prevent every Zone action from calling the same request again, a fallback object
 * can be supplied that will be returned instead. This should match the structure of the ClassConstructor object.
 *
 * @example
 * ```typescript
 * export class AuthorModel<Lazy = FetchObservable<unknown>> extends JsonLd<Lazy> {
 *
 *   public readonly className: string ='AuthorModel';
 *
 *   @Expose() public fullName!: string;
 *
 *   @Fetch(() => AddressModel)
 *   @Expose() public address!: Fetched<Lazy, AddressModel>;
 *
 *   public async fetchAll(): Promise<AuthorModel<EagerLoaded>> {
 *     let address: FetchObservable<AddressModel> | AddressModel = this.address;
 *
 *     if (address instanceof FetchObservable) {
 *       address = await firstValueFrom(address.pipe(take(1)));
 *     }
 *
 *     const model: AuthorModel<EagerLoaded> = this as AuthorModel<EagerLoaded>;
 *     model.address = address;
 *     return model;
 *   }
 * }
 * ```
 *
 * @example
 * ```typescript
 *   Reflect.defineMetadata('httpClient', httpClient, Fetch);
 *   Reflect.defineMetadata('cacheService', cacheService, Fetch);
 *   Reflect.defineMetadata('baseUrl', config.baseUrl, Fetch);
 *   Reflect.defineMetadata('ttDebug', config.debug, Fetch);
 * ```
 */

//eslint-disable-next-line @typescript-eslint/naming-convention,max-lines-per-function
export function Fetch<T extends JsonLd>(
  instanceType: () => ClassConstructor<T>,
  fallback?: Object,
): PropertyDecorator {
  return Transform(
    ({ value, obj, key, type, options }) => {
      /* istanbul ignore next */
      if (type !== TransformationType.PLAIN_TO_CLASS || options.ignoreDecorators) {
        return value;
      }

      if (!value) {
        return;
      }

      if (value instanceof FetchObservable) {
        // This transform method is trigger for each Fetch Decorator on the property. This happens for example when the
        // property is overloaded. To Ensure that the correct instanceType is used we reset the previous created
        // FetchObservable with the original string. Because the baseClass decorators are trigger first and the child
        // version later.
        if (value.instanceType === instanceType()) {
          return value;
        }

        value = value.getIri() ?? obj[key].getIri();
      }

      /* istanbul ignore next */
      if (typeof value !== 'string') {
        /* istanbul ignore next */
        console.warn("Cloning a Lazy Loading model isn't supported yet");
        /* istanbul ignore next */
        return value;
      }

      return new Fetcher<T>(instanceType()).modifyToFetchObservable(value, obj, key, fallback);
    },
    { toClassOnly: true },
  );
}

/**
 * This is an internal class used by the {@see Fetch} Decorator.
 * To modify the object into an {@see FetchObservable}
 */
class Fetcher<T extends JsonLd> {
  /**
   * It will output debug console message when true.
   *
   * The property is set through {@see Reflect} metadata.
   */
  protected readonly debug: boolean;

  /**
   * The {@see HttpClient} used to retrieve the data from the api.
   *
   * The property is set through {@see Reflect} metadata.
   */
  protected readonly httpClient: HttpClient;

  /**
   * The {@see CacheService} used to store the retrieved data.
   *
   * The property is set through {@see Reflect} metadata.
   */
  protected readonly storages: FetchStorageInterface<unknown, unknown>[];

  protected readonly supportedStorages: FetchStorageInterface<unknown, unknown>[];

  /**
   * Base url. The combination of this and the properties iri should be a complete
   * absolute url.
   *
   * The property is set through {@see Reflect} metadata.
   */
  protected readonly baseUrl: string;

  constructor(public readonly instanceType: ClassConstructor<T>) {
    this.debug = Reflect.getMetadata('ttDebug', Fetch) ?? false;
    this.httpClient = Reflect.getMetadata('httpClient', Fetch);
    this.baseUrl = Reflect.getMetadata('baseUrl', Fetch);
    this.storages = Reflect.getMetadata('storages', Fetch);
    this.supportedStorages = (this.storages ?? []).filter((storage) =>
      storage.supportsFetch(this.typeName),
    );

    if (!this.httpClient || !this.baseUrl) {
      throw new Error("Couldn't find Fetch metadata Without it the Fetch decorator can't function");
    }
  }

  /**
   * Getter for the instance type.
   */
  private get instance(): T {
    //eslint-disable-next-line new-cap
    return new this.instanceType();
  }

  /**
   * Getter for the name of the model.
   */
  private get typeName(): string {
    return this.instance.getClassName();
  }

  /**
   * Here we override the default property with is getter which returns a FetchObservable.
   */
  public modifyToFetchObservable<O, K extends keyof O>(
    value: string,
    obj: O,
    key: K,
    fallback?: Object,
  ): O[K] {
    let observable = this.waitForInitialization().pipe(
      switchMap(() =>
        merge(
          ...this.createStorageObservables(value, obj, fallback),
          this.retrieveObjectMissingFromCache(value, fallback),
        ).pipe(
          find(({ item }) => !!item),
          tap((item) => {
            const error = new Error("Fetch decorator couldn't find the model");
            return !item ? error : undefined;
          }),
          isDefined(),
          tap(({ item, isFallback }) => this.addFetchedFallbackToStorage(item, isFallback)),
          map(({ item, isFallback }) => {
            return (item instanceof JsonLd ? item : denormalize(this.instanceType, item)) as T;
          }),
        ),
      ),
    );

    return FetchObservable.createFromExisting(value, this.instanceType, observable) as O[K];
  }

  private waitForInitialization() {
    return from(
      Promise.all(this.supportedStorages.map((storage) => storage.waitForInitialization())),
    );
  }

  private addFetchedFallbackToStorage(item: T, isFallback: boolean) {
    if (isFallback) {
      for (let storage of this.supportedStorages) {
        if (this.isUpdatable(storage)) {
          storage.addFetchedItem(this.typeName, item);
        }
      }
    }
  }

  private isUpdatable(
    storage: FetchStorageInterface<unknown, unknown>,
  ): storage is UpdatableFetchStorageInterface<unknown, unknown> {
    return storage.hasOwnProperty('addFetchedItem');
  }

  private createStorageObservables<O>(value: string, obj: O, fallback?: Object) {
    return this.supportedStorages.map((storage) => {
      return storage.getFetchFromStorage(value, obj, this.typeName, fallback).pipe(
        isDefined(),
        map((result) => ({ item: result as T, isFallback: false })),
      );
    });
  }

  /**
   * If the requested content is not yet stored in cache, the content is retrieved first. If a fallback object is given,
   * the returned content will always be defined, even if an HttpError is returned by the HttpClient.
   */
  private retrieveObjectMissingFromCache(
    value: string,
    fallback?: Object,
  ): Observable<{ item: T; isFallback: boolean }> {
    return timer(500).pipe(
      switchMap(() =>
        this.httpClient.get(this.baseUrl + value).pipe(
          map((response) => denormalize(this.instanceType, response)),
          share({
            connector: () => new ReplaySubject(1),
            resetOnError: false,
            resetOnComplete: false,
            resetOnRefCountZero: false,
          }),
          catchError((error) => {
            if (fallback) {
              return of(fallback as T);
            }
            return throwError(() => error);
          }),
          map((result) => ({ item: result, isFallback: true })),
        ),
      ),
    );
  }
}

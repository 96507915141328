import { createReducer, on, Action } from '@ngrx/store';
import { UserModel } from '@school-dashboard/models';
import { usersActions } from './users.actions';

export const USERS_FEATURE_KEY = 'users';

export interface UsersState {
  user?: UserModel; // which Users record has been selected
  loaded: boolean; // has the Users list been loaded
  loading: boolean; // is the Users busy with loading
  error?: Error | null; // last known error (if any)
}

export const initialUsersState: UsersState = {
  // set initial required properties
  loaded: false,
  loading: false,
};

const reducer = createReducer(
  initialUsersState,
  on(
    usersActions.initUser,
    state => ({ ...state, loaded: false, loading: true, error: null }),
  ),
  on(usersActions.loadUserSuccess, (state, { user }) => ({
    ...state,
    user: user,
    loaded: true,
    loading: false,
  }),
  ),
  on(usersActions.loadUserFailure, (state, { error }) => ({ ...state, error: error })),
);

export function usersReducer(state: UsersState | undefined, action: Action): UsersState {
  return reducer(state, action);
}

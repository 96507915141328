import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AlertController } from '@ionic/angular';
import { LocationsStoreService } from '@school-dashboard/data-access-locations';
import { Pupil } from '@school-dashboard/models';
import { CacheService } from '@techniek-team/services';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  first,
  Observable,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import { ModalContent, ModalContentFormInterface } from '../../interfaces/modal-content.interface';
import { ButtonStateService } from '../../services/button-state/button-state.service';
import { CacheIdentifierService } from '../../services/cache-identifier/cache-identifier.service';
import { FromEventService } from '../../services/from-event/from-event.service';
import { ModalAction, ModalButtonState } from '../school-dashboard-modal/school-dashboard-modal.component';
import { ContentName } from './content-name.enum';
import { ContentPlaceholderDirective } from './content-placeholder.directive';
import { PupilModalService } from './pupil-modal.service';
import { ContentData, MENU_ITEMS_DATA, MenuItemData, SUPPORTED_CONTENT_DATA } from './supported-content-data';

@Component({
  selector: 'app-pupil-modal',
  templateUrl: './pupil-modal.component.html',
  styleUrls: ['./pupil-modal.component.scss'],
})
export class PupilModalComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Input for automatically setting the type of content.
   */
  @Input() public set contentName(name: ContentName) {
    if (name) {
      this._currentContent$.next(name);
    }
  }

  /**
   * The IRI of the pupil has been passed to the modal.
   */
  @Input() public pupilIri: string;

  /**
   * The placeholder where the content of the currently selected menu item will
   * be dynamically loaded.
   */
  @ViewChild(ContentPlaceholderDirective, { static: true })
  private contentPlaceholder: ContentPlaceholderDirective;

  /**
   * The data of all the menu items that are available.
   */
  public readonly menuItemsData: Map<ContentName, MenuItemData> = MENU_ITEMS_DATA;

  /**
   * The current content that is active and displayed to the user.
   */
  public _currentContent$: BehaviorSubject<ContentName> = new BehaviorSubject<ContentName>(ContentName.PERSON_DETAILS);

  /**
   * The pupil of which the details are shown within this modal.
   */
  public _pupil$: Observable<Pupil>;

  /**
   * Text to display on the action button when it's visible.
   */
  public actionButtonText: string | undefined;

  /**
   * Text to display on the cancel button when it's visible.
   */
  public cancelButtonText: string | undefined;

  /**
   * ButtonStateService instance used on the template to link the proper button
   * state observables.
   */
  public buttonService: ButtonStateService;

  /**
   * If the current Content has a form, it will be referenced here.
   */
  private formInstance: ModalContentFormInterface | undefined;

  /**
   * Data regarding the supported content. This content can be shown when
   * clicking on a certain menu item.
   */
  private readonly supportedContentData: Map<ContentName, ContentData> = SUPPORTED_CONTENT_DATA;

  /**
   * Subject which completes all hot observers on emit.
   */
  private onDestroy$: Subject<void> = new Subject<void>();

  /**
   * Subject to trigger completion of the contentSwitch watcher.
   */
  private onSwitch$: Subject<void> = new Subject<void>();

  constructor(
    public locationsStoreService: LocationsStoreService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private pupilModalService: PupilModalService,
    private alertController: AlertController,
    private fromEventService: FromEventService,
    private cache: CacheService,
    private cacheIdentifierService: CacheIdentifierService,
  ) {}

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.createButtonStateService();
    this.createCurrentContentSubscriber();
    this._pupil$ = this.pupilModalService.getPupil$(this.pupilIri, true);
  }

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    this.actionButtonText = !this.formInstance ? undefined : 'Opslaan';
    this.cancelButtonText = !this.formInstance ? undefined : 'Terug zonder opslaan';
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Check if the given ContentName in the menu items matches certain criteria
   * to be marked as active.
   */
  public getActiveContent(key: ContentName): boolean {
    const current: ContentName = this._currentContent$.getValue();
    if (current === ContentName.PERSON_EDIT) {
      return key === ContentName.PERSON_DETAILS;
    }
    return key === current;
  }

  /**
   * Return the active icon in the menu, which can differ for alternate views,
   * like edit and details.
   */
  public getActiveIcon(key: ContentName): IconProp {
    const current: ContentName = this._currentContent$.getValue();
    if (current === ContentName.PERSON_EDIT) {
      return this.menuItemsData.get(ContentName.PERSON_DETAILS)?.icons
        .alt as IconProp;
    }
    return this.menuItemsData.get(key)?.icons.active as IconProp;
  }

  /**
   * Based on the action, change states and/or close the modal.
   */
  public async dialogAction(action: ModalAction): Promise<void> {
    if (!this.formInstance) {
      this.buttonService.canDismiss$.next(action);
      return;
    }

    if (action === ModalAction.CANCEL) {
      if (this.pupilIri) {
        await this.cancelDialogAction(action);
      } else {
        await this.closeDialogAction(action);
      }
    }

    if (action === ModalAction.CLOSE) {
      await this.closeDialogAction(action);
    }

    if (action === ModalAction.OK) {
      this.okDialogAction(action);
    }
  }

  /**
   * Set new content as active. This will trigger the {@see loadTemplateContent} with
   * a new value.
   */
  public async setActiveContent(contentName: ContentName): Promise<void> {
    if (this._currentContent$.getValue() === ContentName.PERSON_EDIT) {
      if (this.formInstance && !this.formInstance.activeForm.pristine) {
        await this.cancelDialogAction(ModalAction.CANCEL, contentName);
        return;
      }
    }

    this._currentContent$.next(contentName);
  }

  /**
   * Create or re-init the ButtonStateService based on the existence or absence
   * of the TrackPreference's FormArray instance.
   */
  private createButtonStateService(): void {
    const form: AbstractControl = this.formInstance?.activeForm ?? new UntypedFormControl();

    if (!this.buttonService) {
      this.buttonService = new ButtonStateService(form);
    }

    this.buttonService.activeForm = form;
    this.buttonService.init(!this.formInstance?.activeForm);
  }

  /**
   * Save the pupil, if the form is valid. This method can only be called if
   * there's a valid form instance present, since it's guarded by the check in
   * the {@see dialogAction} function.
   */
  private okDialogAction(action: ModalAction): void {
    const instance: ModalContentFormInterface = this
      .formInstance as ModalContentFormInterface;
    this.buttonService.action$.next(ModalButtonState.LOADING);
    combineLatest([
      instance.apiAction(),
      this.locationsStoreService.active$,
    ]).pipe(catchError(() => {
      this.buttonService.action$.next(
        instance.activeForm.valid ? ModalButtonState.WARNING : ModalButtonState.DISABLED,
      );
      return EMPTY;
    }),
    ).subscribe(([response, active]) => {
      instance.apiActionSuccess(response as never);
      this.cache.refresh(
        this.cacheIdentifierService.getSchoolClassesCacheIdentifier(
          active.getIri(),
        ),
      );
      this.buttonService.canDismiss$.next({ action: action, data: response });
    });
  }

  /**
   * Prevent closing the modal because the form is not pristine. Prompt the user
   * with a message before allowing the close.
   */
  private async closeDialogAction(action: ModalAction): Promise<void> {
    if (this.formInstance?.activeForm.pristine) {
      this.buttonService.canDismiss$.next(action);
      return;
    }

    if (await this.alertController.getTop()) {
      return;
    }

    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: 'Invoeren annuleren',
      message:
        'Weet u zeker dat u dit dialoogvenster wilt sluiten? De gemaakte wijzigingen worden niet behouden.',
      buttons: [
        { text: 'Annuleren', role: 'cancel' },
        {
          text: 'Sluit venster',
          role: 'confirm',
        },
      ],
    });

    await this.dismissDialogAction(action, alert);
  }

  /**
   * Prevent switching inside the modal because the form is not pristine. Prompt
   * the user with a message before allowing the switch.
   */
  private async cancelDialogAction(
    action: ModalAction,
    contentName?: ContentName,
  ): Promise<void> {
    if (this.formInstance?.activeForm.pristine) {
      this._currentContent$.next(contentName ?? ContentName.PERSON_DETAILS);
      return;
    }

    if (await this.alertController.getTop()) {
      return;
    }

    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: 'Invoeren stoppen',
      message:
        'Weet u zeker dat u het invoeren van het formulier wilt stoppen?'
        + ' De gemaakte wijzigingen worden niet behouden.',
      buttons: [
        { text: 'Annuleren', role: 'cancel' },
        {
          text: 'Invoer stoppen',
          role: 'confirm',
        },
      ],
    });

    await this.dismissDialogAction(
      action,
      alert,
      contentName ?? ContentName.PERSON_DETAILS,
    );
  }

  /**
   * Combined logic for close and cancel dialogs, since they only differ in the
   * content of their alert. Watches for Enter key press, to close 'confirm' the
   * dialog. If a content name is given and the dialog is confirmed, the new
   * content is displayed instead. Also watches for Escape key press, which will
   * close the alert dialog as if it was cancelled.
   */
  private async dismissDialogAction(
    action: ModalAction,
    alert: HTMLIonAlertElement,
    contentName?: ContentName,
  ): Promise<void> {
    // Dismiss as continue on enter.
    const onKeyUpEnter: Subscription = this.fromEventService.watch('keyup', 'Enter')
      .subscribe(() => {
        alert.dismiss();
        if (contentName) {
          this._currentContent$.next(contentName);
        } else {
          this.buttonService.canDismiss$.next(action);
        }
      });
    // Dismiss as cancel on escape.
    const onKeyUpEscape: Subscription = this.fromEventService.watch('keyup', 'Escape')
      .subscribe(() => alert.dismiss());

    await alert.present();
    const { role } = await alert.onDidDismiss();

    if (role === 'confirm' && !contentName) {
      this.buttonService.canDismiss$.next(action);
    }

    if (role === 'confirm' && contentName) {
      this._currentContent$.next(contentName);
    }

    onKeyUpEnter.unsubscribe();
    onKeyUpEscape.unsubscribe();
  }

  /**
   * Create a subscriber that listens to the {@see _currentContent$}. This
   * subscriber will decide if other content should be shown. If so it triggers
   * the {@see loadTemplateContent} with a new value.
   */
  private createCurrentContentSubscriber(): void {
    this._currentContent$
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((contentName: ContentName) => {
        this.buttonService.action$.next(ModalButtonState.DISABLED);
        this.loadTemplateContent(contentName);
      });
  }

  /**
   * Determine which content should be loaded into the content placeholder.
   * Dynamic component loading is used.
   */
  private loadTemplateContent(contentName: ContentName): void {
    // Clear the formInstance component if available.
    this.formInstance = undefined;
    let contentData: ContentData;

    if (this.supportedContentData.has(contentName)) {
      contentData = this.supportedContentData.get(contentName) as ContentData;
    } else {
      // Set default content when no match was found.
      this._currentContent$.next(ContentName.PERSON_DETAILS);
      return;
    }

    const componentInstance: ModalContent = this.createComponentInstance(contentData);
    componentInstance.activeIri = this.pupilIri;
    // Subscribe to the contentSwitch if it exists.
    componentInstance.componentSwitch$
      ?.pipe(takeUntil(this.onSwitch$))
      .subscribe((switchName) => this.setActiveContent(switchName));

    if ((componentInstance as ModalContentFormInterface).apiAction) {
      this.formInstance = componentInstance as ModalContentFormInterface;
      this.setupFormInstance();
    } else {
      this.setupDefaultInstance();
    }
  }

  /**
   * Load in the specific component and return the instance.
   */
  private createComponentInstance(contentData: ContentData): ModalContent {
    const component: Type<ModalContent> = contentData.component;
    const componentFactory: ComponentFactory<ModalContent> = this.componentFactoryResolver
      .resolveComponentFactory(component);

    const viewContainerRef: ViewContainerRef = this.contentPlaceholder.viewContainerRef;
    viewContainerRef.clear();

    const componentRef: ComponentRef<ModalContent> = viewContainerRef.createComponent(componentFactory);
    componentRef.changeDetectorRef.markForCheck();

    return componentRef.instance;
  }

  /**
   * Setup observables and update the content of the buttons.
   */
  private setupDefaultInstance(): void {
    this.actionButtonText = undefined;
    this.cancelButtonText = undefined;
    this.createButtonStateService();
  }

  /**
   * Setup observables and update the content of the formInstance where needed.
   */
  private setupFormInstance(): void {
    this.cancelButtonText = 'Terug zonder opslaan';
    this.actionButtonText = 'Opslaan';

    this.formInstance?.componentReady$
      .pipe(first())
      .subscribe(() => this.createButtonStateService());
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-colored-subtitle',
  templateUrl: './colored-subtitle.component.html',
  styleUrls: ['./colored-subtitle.component.scss'],
})
export class ColoredSubtitleComponent {
  /**
   * The color the title should be.
   * Use the colors that are defined by Ionic.
   */
  @Input() public color: string = 'primary';

  /**
   * Whether this component should be in the loading-state.
   */
  @Input() public isLoading: boolean = false;
}

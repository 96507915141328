import { CheckType } from '@school-dashboard/enums';
import { JsonLd } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';
import { PersonMinimal } from './person-minimal.model';

export class Check extends JsonLd {
  @Type(() => Date)
  @Expose()
  public confirmedAt: Date;

  @Type(() => PersonMinimal)
  @Expose()
  public approvedBy: PersonMinimal;

  @Expose() public type: CheckType;
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { MessageColor } from '@school-dashboard/enums';
import { PupilUpload } from '@school-dashboard/models';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ButtonStateService } from '../../services/button-state/button-state.service';
import { ProgressPupilUploadService } from '../../services/progress/progress-pupil-upload.service';
import { ProgressData } from '../../services/progress/progressServiceInterface';
import { ProgressNotificationType } from '../progress-notification/progress-notification.enum';
import { ResponseMessageKey } from '../response-message/response-message.enum';
import { ResponseMessage } from '../response-message/response-message.model';
import { ResponseMessageService } from '../response-message/response-message.service';
import { ModalAction, ModalButtonState } from '../school-dashboard-modal/school-dashboard-modal.component';
import { PupilUploadApiErrors } from './pupil-upload-api-errors.functions';

@Component({
  selector: 'app-pupil-upload-modal',
  templateUrl: './pupil-upload-modal.component.html',
  styleUrls: ['./pupil-upload-modal.component.scss'],
})
export class PupilUploadModalComponent implements OnInit, OnDestroy {
  /**
   * Data for the Datasource to use.
   */
  @Input() public pupilUpload: PupilUpload;

  /**
   * FormControl to manage file input.
   */
  public fileControl: UntypedFormControl = new UntypedFormControl();

  /**
   * ButtonStateService instance used on the template to link the proper button
   * state observables.
   */
  public buttonService: ButtonStateService;

  public _supportedResponseMessageKeys: ResponseMessageKey[] = [
    ResponseMessageKey.PUPIL_FILE_UPLOAD,
  ];

  constructor(
    private alertController: AlertController,
    private responseService: ResponseMessageService,
    private errorHandler: SentryErrorHandler,
    private progressService: ProgressPupilUploadService,
    private pupilUploadApiErrors: PupilUploadApiErrors,
  ) {}

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.buttonService = new ButtonStateService(this.fileControl);
    this.buttonService.init();
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.responseService.clearMessage(ResponseMessageKey.PUPIL_FILE_UPLOAD);
  }

  /**
   * Based on the action, change states and/or close the modal.
   */
  public async dialogAction(action: ModalAction): Promise<void> {
    if (this.fileControl.pristine) {
      await this.progressService.handleDialogAction(
        action,
        {
          type: ProgressNotificationType.RESTART,
        } as unknown as ProgressData,
        null as never,
      );
      this.buttonService.canDismiss$.next(action);
      return;
    }

    if (action === ModalAction.CANCEL || action === ModalAction.CLOSE) {
      await this.cancelDialogAction(action);
    }

    if (action === ModalAction.OK) {
      this.okDialogAction(action);
    }
  }

  /**
   * Upload the pupil, if the file matches the accepted extensions. If the
   * content of the file is invalid, each returned error will be displayed.
   */
  private okDialogAction(action: ModalAction): void {
    this.responseService.clearMessage(ResponseMessageKey.PUPIL_FILE_UPLOAD);
    this.buttonService.action$.next(ModalButtonState.LOADING);
    const fileName: string = this.fileControl.value.name;

    this.progressService
      .postPupilFile(this.fileControl.value)
      .pipe(
        catchError((error) => {
          this.fileControl.patchValue(null);
          this.fileControl.setErrors({ invalid: true });
          this.buttonService.action$.next(ModalButtonState.DISABLED);
          const message: ResponseMessage | null = this.pupilUploadApiErrors.extractError(error, fileName);
          if (message) {
            this.responseService.setMessage(message);
          } else {
            this.errorHandler.handleError(error);
            this.responseService.setMessage(
              ResponseMessageKey.PUPIL_FILE_UPLOAD,
              MessageColor.DANGER,
              'Er is een fout opgetreden en het bestand kon niet worden geüpload.',
            );
          }
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.buttonService.canDismiss$.next(action);
        this.progressService.forceProgressModalVisible = true;
      });
  }

  /**
   * Prevent closing the modal because the form is not pristine. Prompt the user
   * with a message before allowing the close.
   */
  private async cancelDialogAction(action: ModalAction): Promise<void> {
    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: 'Uploaden stoppen',
      message:
        'Weet u zeker dat u het uploaden wilt stoppen? Het geselecteerde bestand wordt niet bewaard.',
      buttons: [
        { text: 'Annuleren', role: 'cancel' },
        { text: 'Sluit venster', role: 'confirm' },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();

    if (role === 'confirm') {
      await this.progressService.handleDialogAction(
        action,
        {
          type: ProgressNotificationType.RESTART,
        } as unknown as ProgressData,
        null as never,
      );
      this.buttonService.canDismiss$.next(action);
    }
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserModel } from '@school-dashboard/models';
import { UserService } from '@techniek-team/oauth';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { catchError, of, switchMap, tap } from 'rxjs';
import { usersActions } from './users.actions';

@Injectable()
export class UsersEffects {

  private readonly initUsers$;

  private readonly usersFailure$;

  constructor(
    private readonly actions$: Actions,
    private readonly errorHandler: SentryErrorHandler,
    private readonly toastService: ToastService,
    private readonly userService: UserService<UserModel>,
  ) {
    this.initUsers$ = this.createInitUsersEffect();
    this.usersFailure$ = this.createUsersFailureEffect();
  }

  private createInitUsersEffect() {
    return createEffect(() => this.userService.currentUser().pipe(
      switchMap((user) => of(usersActions.loadUserSuccess({ user: user }))),
      catchError((error) => of(usersActions.loadUserFailure({ error: error }))),
    ));
  }

  private createUsersFailureEffect() {
    return createEffect(() => this.actions$.pipe(
      ofType(usersActions.loadUserFailure),
      tap(action => {
        return Promise.all([
          this.errorHandler.captureError(action.error),
          this.toastService.error({
            message: 'Er is iets misgegaan bij het laden van uw gebruikersgegevens.',
            duration: 10000,
            buttons: [{ text: 'Sluiten', role: 'cancel' }],
          }),
        ]);
      }),
    ), { dispatch: false });
  }
}

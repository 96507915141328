import { CheckType } from '@school-dashboard/enums';
import { Exclude, Expose, Type } from 'class-transformer';
import { Track } from '../track.model';
import { HasDeadline } from './has-deadline.interface';
import { HasPupils } from './has-pupils.interface';

export class HuiswerkbegeleidingTrack extends Track implements HasDeadline, HasPupils {
  /**
   * @inheritDoc
   */
  @Type(() => Date)
  @Expose()
  public deadline: Date;

  /**
   * Maximum amount of LocationMembers that should be assigned to this track.
   * This is not a hard limit.
   */
  @Expose() public maxPupils: number;

  @Exclude() public canAddTrackAttendances: boolean = true;

  @Exclude() public canDeleteTrackAttendances: boolean = true;

  @Exclude() public canEditTrackAttendances: boolean = false;

  @Exclude() public hasTrainings: boolean = false;

  @Exclude() public canHaveSubTracks: boolean = true;

  @Exclude() public canResendEmailTrackAttendance: boolean = false;

  @Exclude() public availableChecks: Map<CheckType, CheckType[]> = new Map<CheckType, CheckType[]>([
    [CheckType.SCHOOL_AGREED, []],
    [CheckType.PUPIL_AMOUNT_DEFINITIVE, []],
    [CheckType.START_DATE_DEFINITIVE, [CheckType.PUPIL_AMOUNT_DEFINITIVE]],
  ]);

  public get trackAttendancesRemaining(): number {
    return this.maxPupils - (this.currentPupils ?? 0);
  }
}

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PractiseTrackVariant, TrackType, TrackTypeResource } from '@school-dashboard/enums';
import { PractiseTrack, Track } from '@school-dashboard/models';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { PostHuiswerkBegeleidingTrackRequest, PostSubTrackRequest } from '../../api/sub-track/post-sub-track.request';
import { serializeTrack } from '../../api/track/track.serializer';
import { IndicatorBar } from '../../shared/components/amount-indicator-bars/indicator-bar.interface';
import { trackActions } from './track/track.actions';
import { TrackSelectors } from './track/track.selectors';

@Injectable()
export class TrackStoreService {
  public tracks$: Observable<Track[]> = this.store.pipe(
    select(TrackSelectors.selectAll),
    isDefined(),
    map((response) => serializeTrack(response)),
    shareReplay(1),
  );

  public upcomingTracks$: Observable<Track[]> = this.store.pipe(
    select(TrackSelectors.selectAllUpcomingTracks),
    isDefined(),
    map((response) => serializeTrack(response)),
    shareReplay(1),
  );

  public activeTrack$: Observable<Track> = this.store.pipe(
    select(TrackSelectors.selectActiveTrack),
    isDefined(),
    map((response) => serializeTrack(response)),
    shareReplay(1),
  );

  public activeTrackHasPupils$: Observable<boolean> = this.store.pipe(
    select(TrackSelectors.activeTrackHasPupils),
    isDefined(),
    shareReplay(1),
  );

  public tracksOpenForSignup$: Observable<Track[]> = this.tracks$.pipe(
    map((tracks: Track[]): Track[] => {
      tracks = tracks.filter((track: Track) => track.canAddTrackAttendances);

      if (tracks.length) {
        return tracks;
      }

      return [];
    }),
    shareReplay(1),
  );

  public selectPossibleUpsellTrackTypes$: Observable<TrackType[]> = this.store.pipe(
    select(TrackSelectors.selectPossibleUpsellTrackTypes),
    isDefined(),
    shareReplay(1),
  );

  public tracksLoading$: Observable<boolean> = this.store.pipe(
    select(TrackSelectors.selectLoading),
    shareReplay(1),
  );

  public savingTrack$: Observable<boolean> = this.store.pipe(
    select(TrackSelectors.selectSaving),
  );

  public tracksLoaded$: Observable<boolean> = this.store.pipe(
    select(TrackSelectors.selectLoaded),
    shareReplay(1),
  );

  public subTracks$: Observable<Track[]> = this.store.pipe(
    select(TrackSelectors.getSubTracksByMainTrackId),
    isDefined(),
    map((response) => serializeTrack(response)),
    shareReplay(1),
  );

  constructor(private readonly store: Store) {
  }

  public tracksByType$(trackType: TrackType): Observable<Track[]> {
    return this.store.pipe(
      select(TrackSelectors.selectMainTracks),
      map((tracks) => {
        return tracks.filter((track) => track['@type'] === TrackTypeResource[trackType]);
      }),
      map((response) => {
        return serializeTrack(response);
      }),
      isDefined(),
      shareReplay(1),
    );
  }

  public practiseTracksByVariant(
    variant: PractiseTrackVariant,
  ): Observable<PractiseTrack[]> {
    return this.tracksByType$(TrackType.PRACTICE).pipe(
      map((tracks) => {
        return tracks.filter(
          (track: PractiseTrack) => track.variant === variant,
        ) as PractiseTrack[];
      }),
    );
  }

  public trackAccumulatedProgressData$(
    track: string | Track,
  ): Observable<IndicatorBar> {
    if (track instanceof Track) {
      track = track.getId();
    }
    return this.store.pipe(
      select(TrackSelectors.selectAccumulatedProgressDataForTrack),
      map((data) => data[track as string]),
      isDefined(),
      shareReplay(1),
    );
  }

  public trackProgressData$(track: string | Track): Observable<IndicatorBar> {
    if (track instanceof Track) {
      track = track.getId();
    }
    return this.store.pipe(
      select(TrackSelectors.selectProgressBarDataForTracks),
      map((data) => data[track as string]),
      isDefined(),
      shareReplay(1),
    );
  }

  public trackSubTracks$(track: string | Track): Observable<Track[]> {
    if (track instanceof Track) {
      track = track.getIri();
    }
    return this.store.pipe(
      select(TrackSelectors.selectAll),
      isDefined(),
      map((response) => serializeTrack(response)),
      shareReplay(1),
      map((data) => {
        return data.filter((item) => item.parentTrack?.getIri() === track);
      }),
    );
  }

  public async initTracks(onlyUpcomingTracks: boolean = false): Promise<void> {
    const selectOnlyUpcomingIsInitialized: Observable<boolean>= this.store
      .pipe(select(TrackSelectors.onlyUpcomingIsInitialized));

    if (onlyUpcomingTracks && await firstEmitFrom(selectOnlyUpcomingIsInitialized)) {
      return;
    }

    if (await firstEmitFrom(this.store.pipe(select(TrackSelectors.allTracksIsInitialized)))) {
      return;
    }

    this.store.dispatch(
      trackActions.initTracks({ onlyUpcomingTracks: onlyUpcomingTracks }),
    );
  }

  public addTrack(request: PostSubTrackRequest | PostHuiswerkBegeleidingTrackRequest): void {
    this.store.dispatch(trackActions.addNewSubTrack({ request: request }));
  }

  public refreshTracks(): void {
    this.store.dispatch(trackActions.refreshTracks());
  }

  public refreshActiveTrack(): void {
    this.store.dispatch(trackActions.refreshActiveTrack());
  }

  public setActiveTrack(track?: string | Track | null | undefined): void {
    if (track === undefined) {
      track = null;
    }

    if (track instanceof Track) {
      track = track.getId();
    }

    this.store.dispatch(trackActions.setActiveTrack({ track: track }));
  }
}

<ng-container *ngFor="let x of [].constructor(rows)">
  <div class="row" [style.grid-template-columns]="columns$ | async">
    <ng-container *ngFor="let cell of cells$ | async">
      <div class="cell" infinite-scroll-cell [declaration]="cell.style">
        <ion-skeleton-text
          animated
          infinite-scroll-cell
          [declaration]="cell.skeleton?.style"
          [doWidth]="!!cell.skeleton"
        >
        </ion-skeleton-text>
      </div>
    </ng-container>
  </div>
</ng-container>

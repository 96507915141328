import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthApi } from './api/auth/auth.api';
import { AuthInterceptor } from './auth-interceptor/auth.interceptor';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthenticatePage } from './authenticate/authenticate.page';
import { OAuthService } from './shared/oauth/oauth.service';
import { UserService } from './shared/user/user.service';

@NgModule({
  declarations: [AuthenticatePage],
  imports: [
    AuthRoutingModule,
  ],
  exports: [
    AuthRoutingModule,
    AuthenticatePage,
  ],
  providers: [
    AuthApi,
    UserService,
    OAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class BaseAuthModule {
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';
import { HighlightSearchModule } from '@techniek-team/common';
import { TtFilterSearchModule } from '@techniek-team/filter-search';
import { TtAutocompleteChipControlComponent } from './tt-autocomplete-chip-control.component';

@NgModule({
  declarations: [
    TtAutocompleteChipControlComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    HighlightSearchModule,
    TtFilterSearchModule,
  ],
  exports: [
    TtAutocompleteChipControlComponent,
  ],
})
export class TtAutocompleteChipControlModule {}

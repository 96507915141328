import { RouterModule, Routes } from '@angular/router';
import { PKCEGuard } from './pkce/pkce.guard';
import { NgModule } from '@angular/core';
import { AuthenticatePage } from './authenticate/authenticate.page';

/**
 * After being redirected back by Skoleo we need to exchange the
 * PKCE code inside the OAuthLoginGuard. Apparently we can't do this
 * for routes like /tabs or /tabs/dashboard because ionic does an
 * internal redirect, and we then lose the PKCE code as query
 * parameter. Therefore, we created the authenticate page which
 * we can define inside this file and which uses the OAuthLoginGuard.
 */
const routes: Routes = [
  {
    path: '',
    redirectTo: 'authenticate',
    pathMatch: 'full',
  },
  {
    path: 'authenticate',
    canActivate: [PKCEGuard],
    component: AuthenticatePage,
  },
  {
    path: 'ios-login.html',
    redirectTo: 'authenticate',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}

import { AuthGetProfileResponse } from './api/auth/auth.response';
import { LyceoUser } from './models/lyceo-user.model';
import { UserInterface } from './shared/user/user.interface';

export type UserModelClassConstructor<T extends UserInterface, I extends UserInterface> = new (
  user: I,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any
) => T;

export class AuthConfig<T extends UserInterface = LyceoUser, I extends UserInterface = AuthGetProfileResponse> {

  public clientId!: string;

  public ssoBaseUrl!: string;

  public profileBaseUrl!: string;

  public authorizeUrl?: string;

  public baseUrl!: string;

  public redirectUri!: string;

  public loginUrl: string = '/login';

  public homeUrl!: string;

  public whitelist?: string[] = [];

  public model?: UserModelClassConstructor<T, I> = LyceoUser as unknown as UserModelClassConstructor<T, I>;
}


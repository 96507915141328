import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isDefined } from '@techniek-team/rxjs';
import { Observable } from 'rxjs';
import { UsersSelectors } from './+state/users.selectors';

@Injectable()
export class UsersStoreService {

  public loading$: Observable<boolean> = this.store.pipe(select(UsersSelectors.selectUserLoading));

  public loaded$: Observable<boolean> = this.store.pipe(select(UsersSelectors.selectUserLoaded));

  public user$ = this.store.pipe(select(UsersSelectors.selectUser))
    .pipe(isDefined());

  constructor(private readonly store: Store) {
  }
}

<div class="header-menu"
     data-test="header-menu-container"
     (click)="triggerPopover($event)">
  <tt-avatar [name]="(user$ | async)?.fullName" color="tertiary"></tt-avatar>
  <ion-icon icon="chevron-down"
            data-test="header-menu-chevron"
            [id]="'popover-trigger-' + id"
            #icon></ion-icon>
</div>

<ion-popover class="header-menu-popover"
             data-test="header-menu-popover-element"
             [trigger]="'popover-trigger-' + id"
             [dismissOnSelect]="true"
             [showBackdrop]="false">
  <ng-template>
    <ion-content>
      <ion-list>
        <ion-item lines="full"
                  *ngIf="(user$ | async) as user">
          <strong>{{user.fullName}}</strong>
        </ion-item>
        <ion-item lines="full"
                  class="whats-new">
          versie
          <ion-badge color="secondary" slot="end">{{ release }}</ion-badge>
        </ion-item>
        <ion-item lines="none"
                  data-test="header-menu-logout-button"
                  [button]="true"
                  (click)="logout()">
          Uitloggen
        </ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>

<app-school-dashboard-modal actionButtonText="Leerlingen importeren"
                            cancelButtonText="Annuleer"
                            [actionButtonState]="buttonService.actionState$ | async"
                            [closeButtonState]="buttonService.cancelState$ | async"
                            [cancelButtonState]="buttonService.cancelState$ | async"
                            [dismiss]="buttonService.canDismiss$"
                            (action)="dialogAction($event)">
  <div position-center>
    <app-colored-title color="secondary">Leerlingen importeren</app-colored-title>
    <app-colored-subtitle color="primary">Bestand verkrijgen</app-colored-subtitle>
    <p>
      U heeft één Excel-bestand nodig met alle leerlingen. Zorg dat dit bestand goed is opgemaakt.
      <a href="/assets/voorbeeld.xlsx" download>Download hier een voorbeeldbestand.</a>
    </p>
    <app-colored-subtitle color="primary">Bestand invoeren</app-colored-subtitle>
    <app-file-upload [formControl]="fileControl" accept=".csv,.xlsx,.xls"></app-file-upload>
    <tt-response-message [watch]="_supportedResponseMessageKeys"></tt-response-message>
  </div>
</app-school-dashboard-modal>

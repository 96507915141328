import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocationsStoreService } from '@school-dashboard/data-access-locations';
import { UsersStoreService } from '@school-dashboard/data-access-users';
import { PersonType } from '@school-dashboard/enums';
import { environment } from '@school-dashboard/environments';
import { LocationMember } from '@school-dashboard/models';
import { Collection } from '@techniek-team/api-platform';
import { denormalize } from '@techniek-team/class-transformer';
import { isDefined } from '@techniek-team/rxjs';
import { CacheService } from '@techniek-team/services';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CacheIdentifierService } from '../../shared/services/cache-identifier/cache-identifier.service';
import { GetLocationMember, LocationMemberPerson } from './location-member.response';

@Injectable({
  providedIn: 'root',
})
export class LocationMemberApi {
  constructor(
    private httpClient: HttpClient,
    private cache: CacheService,
    private cacheIdentifierService: CacheIdentifierService,
    private locationStoreService: LocationsStoreService,
    private usersStoreService: UsersStoreService,
  ) {
  }

  /**
   * Returns a list of LocationMember instances based on the given PersonType.
   */
  public getLocationMembers(
    locationIri: string,
    types: PersonType[] = [],
    personId?: string,
  ): Observable<LocationMember[]> {
    const url: string = `${environment.coreApiUrl}${locationIri}/location-members`;
    let params: HttpParams = new HttpParams();

    for (let type of types) {
      params = params.append('type[]', type);
    }

    if (personId) {
      params = params.set('person.id', personId);
    }

    let cacheId: string = this.cacheIdentifierService.getAllLocationMemberManagersCacheIdentifier(locationIri);
    if (personId) {
      cacheId = this.cacheIdentifierService.getSpecificLocationMemberManagerCacheIdentifier(locationIri, personId);
    }
    return this.cache
      .create(
        cacheId,
        this.httpClient.get<Collection<GetLocationMember<LocationMemberPerson>>>(url, { params: params }),
        { clearInterval: 300000 },
      ).pipe(
        map((response) => {
          response['hydra:member'] = response['hydra:member'].map((item) => {
            return { ...item, profilePhoto: item.person['@id'] + '/photo' };
          });
          return response;
        }),
        map((response) => denormalize(LocationMember, response).toArray()),
      );
  }

  /**
   * Get the location members of the current user.
   */
  public getLocationMembersCurrentUser(): Observable<LocationMember[]> {
    return combineLatest([
      this.usersStoreService.user$,
      this.locationStoreService.active$,
    ]).pipe(
      isDefined([0, 1]),
      switchMap(([user, location]) =>
        this.getLocationMembers(location.getIri() as string, undefined, user.id),
      ),
    );
  }
}

import { Component, Input } from '@angular/core';
import { LocationsStoreService } from '@school-dashboard/data-access-locations';
import { IonColor } from '@techniek-team/lyceo-style';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  @Input() public flat: boolean = true;

  @Input() public translucent: boolean = false;

  @Input() public color: IonColor | undefined = undefined;

  @Input() public contrastColor: IonColor | undefined = undefined;

  constructor(
    protected locationsStoreService: LocationsStoreService,
  ) {
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Pupil } from '@school-dashboard/models';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalContentInterface } from '../../../interfaces/modal-content.interface';
import { ContentName } from '../content-name.enum';
import { PupilModalService } from '../pupil-modal.service';

interface ListItem {
  label: string;
  value: string;
}

@Component({
  selector: 'app-person-details',
  templateUrl: './person-details.component.html',
  styleUrls: ['./person-details.component.scss'],
})
export class PersonDetailsComponent implements ModalContentInterface, OnInit {
  /**
   * The IRI of the pupil has been passed to the modal.
   */
  @Input() public activeIri: string;

  /**
   * Content switch trigger subject.
   */
  public componentSwitch$: Subject<ContentName> = new Subject<ContentName>();

  /**
   * An observable containing all the list items to show in the details.
   */
  public _listItems$: Observable<ListItem[]>;

  constructor(private pupilModalService: PupilModalService) {}

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this._listItems$ = this.createListItemsObservable();
  }

  /**
   * Switch the view to the edit form.
   */
  public switchToEdit(): void {
    this.componentSwitch$.next(ContentName.PERSON_EDIT);
  }

  /**
   * Create an observable that contains all the list items for the details.
   */
  private createListItemsObservable(): Observable<ListItem[]> {
    return this.pupilModalService.getPupil$(this.activeIri).pipe(
      map((pupil: Pupil) => {
        return [
          { label: 'Naam', value: pupil.person.fullName },
          {
            label: 'E-mailadres',
            value: pupil.person.emailAddress?.value ?? 'onbekend',
          },
          { label: 'Geslacht', value: pupil.person.genderFormatted },
          { label: 'Klas', value: pupil.classes.join(', ') },
          { label: 'Niveau', value: pupil.levelsFormatted.join(', ') },
          { label: 'Leerjaar', value: pupil.years.join(', ') },
          { label: 'Opmerkingen', value: pupil.description },
        ];
      }),
    );
  }
}

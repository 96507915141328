/**
 * Enum determining when a message should be displayed below the indicator bar.
 * - `UNDER_MIN`: When the amount is below the minimum (default).
 * - `OVER_MAX`: When the amount is over the maximum.
 * - `BETWEEN_MIN_MAX`: When the amount is between the minimum and the maximum.
 * - `ALWAYS`: Always.
 */
export enum IndicatorMessageType {
  UNDER_MIN = 'UNDER_MIN',
  OVER_MAX = 'OVER_MAX',
  BETWEEN_MIN_MAX = 'BETWEEN_MIN_MAX',
  ALWAYS = 'ALWAYS',
  LOADING = 'LOADING',
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageColor } from '@school-dashboard/enums';
import { ApiPlatformError, denormalize } from '@techniek-team/class-transformer';
import { ApiErrorExtractorBase } from '../../../functions/api-error-extractor-base.function';
import { ResponseMessageKey } from '../../response-message/response-message.enum';
import { MarkupDisplay, ResponseMessage, ResponseMessageMarkup } from '../../response-message/response-message.model';

@Injectable()
export class PupilFormApiErrors extends ApiErrorExtractorBase {
  /**
   * @inheritDoc
   */
  public extractError(
    response: HttpErrorResponse,
    values: Object,
  ): ResponseMessage {
    if (response?.status === 403) {
      return denormalize(ResponseMessage, {
        key: this.getKeyForError(null),
        type: MessageColor.DANGER,
        message: this.invalidRights(),
      });
    }

    const error: ApiPlatformError | null = this.getApiPlatformError(response);
    return denormalize(ResponseMessage, {
      key: this.getKeyForError(error),
      type: MessageColor.DANGER,
      message: this.matchError(error, values),
    });
  }

  /**
   * @inheritDoc
   */
  public matchError(
    error: ApiPlatformError,
    values: Object,
  ): ResponseMessageMarkup[] {
    switch (true) {
      case error?.description.includes(
        'Did not process expected message from skoleo in time.',
      ):
        return this.slowConnection();
      case error?.description.includes('No LevelYear record found for level'):
        return this.levelOrYearError(values);
      default:
        return this.unknownError();
    }
  }

  /**
   * Since there are different keys for different errors, a simple switch.
   */
  private getKeyForError(error: ApiPlatformError): ResponseMessageKey {
    switch (true) {
      case error?.description.includes(
        'Did not process expected message from skoleo in time.',
      ):
        return ResponseMessageKey.PUPIL_CREATE_UPDATE_SKOLEO_MESSAGE;
      case error?.description.includes('No LevelYear record found for level'):
        return ResponseMessageKey.PUPIL_CREATE_UPDATE_LEVEL_YEAR;
      default:
        return ResponseMessageKey.PUPIL_CREATE_UPDATE_SKOLEO_MESSAGE;
    }
  }

  /**
   * Some people assume too much.
   */
  private invalidRights(): ResponseMessageMarkup[] {
    return [
      {
        value: 'U heeft niet de juiste rechten.',
        styleBold: true,
        display: MarkupDisplay.BLOCK,
        newline: true,
      },
      {
        value:
          'De huidige rechten op uw gebruiker laten alleen toe dat u data inziet, '
          + 'maar niet kunt aanpassen of toevoegen.',
        display: MarkupDisplay.BLOCK,
      },
    ];
  }

  /**
   * The connection between the various APIs is slooooow.
   */
  private slowConnection(): ResponseMessageMarkup[] {
    return [
      {
        value: 'Er is een fout opgetreden in de achterliggende verbinding. ',
        styleBold: true,
        display: MarkupDisplay.BLOCK,
      },
      {
        value:
          'Probeer het nog een keer, maar neem contact op met Lyceo als dit probleem zich voor blijft doen.',
      },
    ];
  }

  /**
   * A combination for the level and year doesn't exist.
   */
  private levelOrYearError(values: Object): ResponseMessageMarkup[] {
    const levelValue: string = values['schoolLevel'];
    const yearValue: number = values['year'];
    return [
      { value: 'Er is geen leerjaar' },
      { value: yearValue.toString(), styleBold: true },
      { value: 'bekend voor' },
      { value: levelValue, styleBold: true },
    ];
  }

  private unknownError(): ResponseMessageMarkup[] {
    return [
      {
        value: 'Er is een onbekende fout opgetreden.',
        styleBold: true,
        display: MarkupDisplay.BLOCK,
      },
    ];
  }
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@school-dashboard/environments';
import { PupilUpload, PupilUploadError } from '@school-dashboard/models';
import { Collection, Resource } from '@techniek-team/api-platform';
import { denormalize, Hydra } from '@techniek-team/class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetPupilUploadErrorResponse, GetPupilUploadResponse, PostPupilUploadResponse } from './pupil-upload.response';

@Injectable()
export class PupilUploadApi {
  constructor(private http: HttpClient) {}

  /**
   * Get the details of a pupil upload.
   */
  public getPupilUpload(pupilUploadIri: string): Observable<PupilUpload> {
    const url: string = environment.coreApiUrl + pupilUploadIri;

    return this.http
      .get<Resource<GetPupilUploadResponse>>(url)
      .pipe(map((response) => denormalize(PupilUpload, response)));
  }

  /**
   * Get the errors linked to a pupil upload.
   */
  public getPupilUploadErrors(
    pupilUploadIri: string,
  ): Observable<Hydra<PupilUploadError>> {
    const url: string = environment.coreApiUrl + pupilUploadIri + '/pupil-upload-errors';

    return this.http
      .get<Collection<GetPupilUploadErrorResponse>>(url)
      .pipe(map((response) => denormalize(PupilUploadError, response)));
  }

  /**
   * Convert the given File blob to FormData and POST it to the API. Any errors
   * are handled in the relevant component.
   */
  public postPupilFile(
    locationIri: string,
    file: File,
  ): Observable<PupilUpload> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const url: string = environment.coreApiUrl + locationIri + '/pupil-uploads';
    return this.http
      .post<Resource<PostPupilUploadResponse>>(url, formData)
      .pipe(map((response) => denormalize(PupilUpload, response)));
  }

  /**
   * Fetches the errorFile Blob from the CoreInformationApi.
   * @param pupilUploadIri
   */
  public getErrorFile(pupilUploadIri: string): Observable<HttpResponse<Blob>> {
    const url: string = environment.coreApiUrl + pupilUploadIri + '/error-file';
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonPopover, RadioGroupCustomEvent } from '@ionic/angular';
import { PositionAlign } from '@ionic/core';
import {
  defaultPredicate,
  predicateFromString,
  setIconValidationClasses,
  TtSelectionModel,
  UniqueIdentifierPredicate,
} from '@techniek-team/common';
import { IonColor, IonColorType } from '@techniek-team/lyceo-style';
import { BehaviorSubject, combineLatest, delay, fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { TtIonSelectSearchOptionDirective } from './tt-ion-select-search-option.directive';

type OnChangeCallback<T> = (output: T) => void;
type OnTouchCallback = () => void;

export type Option = string | { toString: () => string } | number | Date;

export type RemoteSearchObservable<T> = (searchInputObserver: Observable<string | undefined>) => Observable<T[]>;

@Component({
  selector: 'tt-ion-select-search',
  templateUrl: './tt-ion-select-search-control.component.html',
  styleUrls: ['./tt-ion-select-search-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TtIonSelectSearchControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
//eslint-disable-next-line max-len
export class TtIonSelectSearchControlComponent<T extends Option, Multi extends boolean = boolean> implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {

  @ViewChild('popover') public popover!: IonPopover;

  @ContentChildren(
    TtIonSelectSearchOptionDirective,
    { descendants: true },
  ) public optionTemplate!: QueryList<TtIonSelectSearchOptionDirective>;

  /**
   * The fill for the item. If `'solid'` the item will have a background. If
   * `'outline'` the item will be transparent with a border.
   * Only available in `md` mode.
   */
  @Input() public fill: 'outline' | 'solid' | undefined = undefined;

  /**
   * The visible label associated with the select.
   */
  @Input() public label: string | undefined = undefined;

  /**
   * How the bottom border should be displayed on the item.
   */
  @Input() public lines: 'full' | 'inset' | 'none' | undefined = undefined;

  /**
   * Instructional text that shows when search text doesn't match any op the options.
   */
  @Input() public notFoundText: string = 'Er zijn geen items gevonden.';

  /**
   * The text to display when the select is empty.
   */
  @Input() public placeholder: string = 'Select';

  /**
   * If `true`, a backdrop will be displayed behind the popover.
   * This property controls whether the backdrop darkens the screen when
   * the popover is presented. It does not control whether the backdrop
   * is active or present in the DOM.
   */
  @Input() public showBackdrop: boolean = true;

  /**
   * If `true`, the popover will display an arrow that points at the
   * `reference` when running in `ios` mode. Does not apply in `md` mode.
   */
  @Input() public arrow: boolean = false;

  /**
   * Describes how to align the popover content with the `reference` point.
   * Defaults to `'center'` for `ios` mode, and `'start'` for `md` mode.
   */
  @Input() public alignment: PositionAlign = 'start';

  /**
   * function used to compare object values
   */
  @Input() public compareWith: UniqueIdentifierPredicate<T> | string | null = null;

  @Input() public required: boolean = false;

  /**
   * If `true`, the select can accept multiple values.
   */
  @Input() public multiple: Multi = false as Multi;

  /**
   * placing the checkbox either left or right
   */
  @Input() public checkboxPlacement: 'start' | 'end' = 'start';

  /**
   * Describes how to calculate the popover width. If `'cover'`, the popover
   * width will match the width of the trigger. If `'auto'`, the popover width
   * will be determined by the content in the popover.
   */
  @Input() public size: 'auto' | 'cover' = 'auto';

  /**
   * width of the popover
   */
  @Input() public width: number = 400;

  @HostBinding('tabindex') public tabindex: number = 0;

  private cachedSelection!: T[];

  /**
   * Complete list of options from which the user can select.
   */
  @Input()
  public set options(items: T[] | RemoteSearchObservable<T> | null | undefined) {
    if (typeof items === 'function') {
      this.remoteOptionsObservableCreator = items;
      return;
    }

    if (items) {
      this.optionsSubject$.next(items);
    }
  }

  /**
   * The color to use from your application's color palette.
   * Default options are: `"primary"`, `"secondary"`, `"tertiary"`,
   * `"success"`, `"warning"`, `"danger"`, `"light"`, `"medium"`, and
   * `"dark"`.
   * For more information on colors, see [theming](/docs/theming/basics).
   */
  @Input()
  public set color(color: IonColor | null | undefined) {
    if (color) {
      this.colorSubject$.next(color);
    }
  }

  protected readonly uniqueId: string = window.crypto.randomUUID();

  protected readonly colorSubject$: BehaviorSubject<IonColor> = new BehaviorSubject<IonColor>(IonColorType.DARK);

  protected searchInput: FormControl<string> = new FormControl<string>('', { nonNullable: true });

  protected filteredItems$!: Observable<T[]>;

  protected selectedDisableString$!: Observable<string>;

  protected selection!: TtSelectionModel<T>;

  protected disabled: boolean = false;

  protected searchTerm$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  //eslint-disable-next-line max-len
  protected templateSubject$: BehaviorSubject<TemplateRef<unknown> | undefined> = new BehaviorSubject<TemplateRef<unknown> | undefined>(
    undefined);

  protected isOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private onDestroy$: Subject<void> = new Subject<void>();

  private popOverOnDestroy$!: Subject<void>;

  private optionsSubject$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

  private remoteOptions$!: Observable<T[]>;

  private remoteOptionsObservableCreator!: RemoteSearchObservable<T>;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef,
  ) {

  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    const predicate = this.compareWith ?? defaultPredicate;
    this.selection = new TtSelectionModel<T>(
      this.multiple,
      (typeof predicate === 'string') ? predicateFromString(predicate) : predicate,
    );
    if (this.remoteOptionsObservableCreator) {
      this.remoteOptions$ = this.remoteOptionsObservableCreator(this.searchTerm$);
    }
    this.filteredItems$ = this.createFilteredItemsObservable();

    this.selectedDisableString$ = this.createSelectedDisableStringObserver();
    this.createColorSubscriber();
    this.createSearchInputValueChangesSubscriber();
    this.createOnChangeSubscriber();
  }

  public ngAfterViewInit(): void {
    setIconValidationClasses(this.injector, this.element);
    this.renderer.setAttribute(this.element.nativeElement, 'id', `select-popup-${this.uniqueId}`);
    const ionItem: HTMLElement = this.element.nativeElement.parentElement;
    const ionLabel: HTMLIonLabelElement = this.element.nativeElement.querySelector('ion-label');
    if (ionLabel) {
      this.renderer.addClass(ionLabel, 'required');
    }
    if (ionItem.nodeName === 'ION-ITEM') {
      this.renderer.addClass(ionItem, 'tt-ion-select-search');
    }
    this.optionTemplate.changes.pipe(startWith(this.optionTemplate)).subscribe(queryList => {
      if (queryList.length > 0) {
        this.templateSubject$.next(queryList.get(0).template);
      }
    });
    this.onPopoverPresentSubscribers();
    this.onPopoverDismissSubscribers();
    this.changeDetectorRef.markForCheck();
    this.optionsSubject$
      .pipe(distinctUntilChanged())
      .subscribe(() => this.changeDetectorRef.markForCheck());
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    if (this.popOverOnDestroy$) {
      this.popOverOnDestroy$.next();
      this.popOverOnDestroy$.complete();
    }
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * @inheritDoc
   */
  public registerOnChange(fn: OnChangeCallback<Multi extends true ? T[] : T>): void {
    this.onChange = fn;
  }

  /**
   * @inheritDoc
   */
  public registerOnTouched(fn: OnTouchCallback): void {
    this.onTouch = fn;
  }

  /**
   * @inheritDoc
   */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * @inheritDoc
   */
  public writeValue(value: T[] | T): void {
    if (!value) {
      // set default value;
      this.selection.clear();
      return;
    }

    if (!Array.isArray(value)) {
      value = [value] as T[];
    }

    this.selection.setSelection(...value);
    this.changeDetectorRef.markForCheck();
  }

  @HostListener('keydown.enter')
  protected onEnter(): Promise<void> {
    return this.popover.present();
  }

  @HostListener('focus')
  protected onFocus(): void {
    const ionItem: HTMLIonItemElement = this.element.nativeElement.querySelector('ion-item');
    if (ionItem) {
      this.renderer.addClass(ionItem, 'ion-focused');
    }
  }

  @HostListener('blur')
  protected onBlur(): void {
    const ionItem: HTMLIonItemElement = this.element.nativeElement.querySelector('ion-item');
    if (ionItem) {
      this.renderer.removeClass(ionItem, 'ion-focused');
    }
  }

  protected shiftFocusToList(event: Event): void {
    event.preventDefault();
    (document.querySelector('ion-popover.ion-select-search-control ion-radio-group ion-item') as HTMLElement)?.focus();
  }

  protected selectFirstItem(event: Event): void {
    event.preventDefault();
    (document.querySelector(
      'ion-popover.ion-select-search-control ion-radio-group ion-item',
    ) as HTMLIonItemElement)?.click();
  }

  protected change(ev: Event, item?: T): Promise<boolean | void> {
    const event: RadioGroupCustomEvent<T> = ev as RadioGroupCustomEvent<T>;
    if (!item) {
      item = event.detail.value;
    }
    if (this.disabled) {
      return Promise.resolve();
    }
    this.onTouch();

    if (this.multiple) {
      this.selection.toggle(item as T);
      this.changeDetectorRef.markForCheck();
    } else {
      this.selection.select(item as T);
      this.changeDetectorRef.markForCheck();
      return this.popover.dismiss(true);
    }

    return Promise.resolve();
  }

  private onChange: OnChangeCallback<Multi extends true ? T[] : T> = (
    _output: Multi extends true ? T[] : T,
  ) => { /* callback */
  };

  private onTouch: OnTouchCallback = () => { /* callback */
  };

  private createColorSubscriber(): void {
    this.colorSubject$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(color => {
        const previousColorClasses: string[] = this.element.nativeElement.className.split(' ')
          .filter((item: string) => {
            if (item) {
              return item.match(/ion-color-*./);
            }
            return false;
          });
        for (let className of previousColorClasses) {
          this.renderer.removeClass(this.element.nativeElement, className);
        }
        this.renderer.addClass(this.element.nativeElement, 'ion-color-' + (color ?? 'primary'));
        this.changeDetectorRef.markForCheck();
      });
  }

  private createSearchInputValueChangesSubscriber(): void {
    this.searchInput.valueChanges.pipe(
      distinctUntilChanged(
        (prev: string | undefined, curr: string | undefined) => {
          return prev?.trim() === curr?.trim();
        },
      ),
      startWith(''),
    ).subscribe(this.searchTerm$);
  }

  private createFilteredItemsObservable(): Observable<T[]> {
    if (this.remoteOptions$) {
      return this.remoteOptions$;
    }
    return combineLatest([
      this.optionsSubject$,
      this.searchTerm$,
    ]).pipe(map(([items, searchTerm]) => {
      if (!searchTerm) {
        return items;
      }
      const keyword: string[] = this.removeDiacriticCharacters(searchTerm).split(' ');
      return items.filter((item: T) => {
        return !!(this.removeDiacriticCharacters(item.toString()).match(new RegExp(`.*${keyword.join('.*')}.*`, 'gi')));
      });
    }));
  }

  /**
   * The normalize and replace function replaces all diacritics characters with normal ones
   */
  private removeDiacriticCharacters(value: string): string {
    return value.normalize('NFKD')
      .replace(/[\u{0080}-\u{FFFF}]/gu, '')
      .toLowerCase();
  }

  /**
   * Create a subscriber which emits the changed values to the onChange.
   */
  private createOnChangeSubscriber(): void {
    this.selection.changed.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(change => {
      this.onChange(
        //eslint-disable-next-line max-len
        ((this.multiple) ? change.source.selected : change.source.selected[0] ?? null) as Multi extends true ? T[] : T,
      );
      if (!this.multiple && this.popover && this.popover.isOpen) {
        return this.popover?.dismiss(true);
      }
      return Promise.resolve();
    });
  }

  private createSelectedDisableStringObserver(): Observable<string> {
    return this.selection.changed.pipe(
      map(change => change.source),
      takeUntil(this.onDestroy$),
      startWith(this.selection),
      map(selection => selection.selected[0]?.toString() ?? ''),
    );
  }

  private onPopoverPresentSubscribers(): void {
    this.popOverOnDestroy$ = new Subject<void>();
    this.popover.willPresent.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.isOpen$.next(true));
    this.popover.didPresent.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.cachedSelection = this.selection.selected;
      this.onTouch();
      this.isOpen$.next(true);
      this.changeDetectorRef.markForCheck();
      combineLatest([
        fromEvent((document.querySelector(
          'ion-popover.ion-select-search-control ion-searchbar',
        ) as HTMLIonSearchbarElement), 'keydown')
          .pipe(debounceTime(20)),
        this.filteredItems$,
      ]).pipe(delay(20), takeUntil(this.popOverOnDestroy$))
        .subscribe(() => {
          const ionItem: HTMLIonItemElement | null = document.querySelector(
            'ion-popover.ion-select-search-control ion-radio-group ion-item',
          );
          if (ionItem) {
            this.renderer.addClass(ionItem, 'ion-focused');
          }
        });

      return (document.querySelector(
        'ion-popover.ion-select-search-control ion-searchbar',
      ) as HTMLIonSearchbarElement)?.setFocus();
    });
  }

  private onPopoverDismissSubscribers(): void {
    this.popover.didDismiss.pipe(takeUntil(this.onDestroy$))
      .subscribe((event: CustomEvent<{ data: never; role: string }>) => {
        if (event.detail.role === 'cancel') {
          this.selection.setSelection(...this.cachedSelection);
        }
        this.isOpen$.next(false);
        this.popOverOnDestroy$.next();
        this.popOverOnDestroy$.complete();
        this.searchTerm$.next(undefined);
        this.changeDetectorRef.markForCheck();
        const ionItem: HTMLIonItemElement | null = document.querySelector(
          'ion-popover.ion-select-search-control ion-radio-group ion-item',
        );
        if (ionItem) {
          this.renderer.removeClass(ionItem, 'ion-focused');
        }
      });
  }

  public cancel(): void {
    this.popover.dismiss(false).then();
    this.changeDetectorRef.markForCheck();
  }
}

import { CheckType, HomiLicenseTrackVariant } from '@school-dashboard/enums';
import { Exclude, Expose } from 'class-transformer';
import { Track } from '../track.model';

export class HomiLicenseTrack extends Track {

  @Expose() public variant: HomiLicenseTrackVariant;

  @Exclude() public canAddTrackAttendances: boolean = false;

  @Exclude() public canDeleteTrackAttendances: boolean = false;

  @Exclude() public canEditTrackAttendances: boolean = false;

  @Exclude() public hasTrainings: boolean = false;

  @Exclude() public canHaveSubTracks: boolean = false;

  @Exclude() public canResendEmailTrackAttendance: boolean = false;

  @Exclude() public availableChecks: Map<CheckType, CheckType[]> = new Map<CheckType, CheckType[]>([
    [CheckType.START_DATE_DEFINITIVE, []],
  ]);
}

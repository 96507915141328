import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TtAvatarComponent } from './tt-avatar.component';

@NgModule({
  declarations: [TtAvatarComponent],
  imports: [
    IonicModule,
    CommonModule,
  ],
  exports: [TtAvatarComponent],
})
export class TtAvatarModule {
}

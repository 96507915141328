import { UploadExceptionType } from '@school-dashboard/enums';
import { denormalize, JsonLd } from '@techniek-team/class-transformer';
import { Expose, Transform, Type } from 'class-transformer';
import { PupilUpload } from './pupil-upload.model';
import { UploadExceptionSpreadSheetRow } from './upload-exception-spreadsheet-row.model';

export class PupilUploadError extends JsonLd {
  /**
   * The exception message, converted to a type.
   */
  @Transform(({ value }) => {
    switch (true) {
      case value.includes('VMBO and MAVO are not allowed'):
        return UploadExceptionType.VMBO_OR_MAVO;
      case value.includes('is not a valid email address'):
        return UploadExceptionType.EMAIL;
      case value.includes('Unhandled match case'):
        return UploadExceptionType.LEVEL;
      case value.includes('No LevelYear record found for level'):
        return UploadExceptionType.LEVEL_YEAR;
      case value.includes('already has another location'):
        return UploadExceptionType.EXISTS;
      case value.includes('already exists as a non-pupil'):
        return UploadExceptionType.EXISTS_AS_NON_PUPIL;
      case value.includes('already is linked to this location'):
        return UploadExceptionType.IN_SAME;
      case value.includes('process expected message'):
        return UploadExceptionType.API;
      default:
        return UploadExceptionType.UNKNOWN;
    }
  })
  @Expose({ name: 'exceptionMessage' })
  public type: UploadExceptionType;

  /**
   * Current pupil upload instance, containing the iri and completed rows.
   */
  @Type(() => PupilUpload)
  @Expose()
  public pupilUpload: PupilUpload;

  /**
   * The row number the error message applies to.
   */
  @Expose() public rowNumber: number;

  /**
   * The resulting row is an array of the column values in a set order. To make
   * this somewhat readable and usable
   */
  @Type(() => UploadExceptionSpreadSheetRow)
  @Transform(({ value }) => {
    return denormalize(UploadExceptionSpreadSheetRow, {
      firstName: value[0],
      lastNamePrefix: value[1],
      lastName: value[2],
      emailAddress: value[3],
      schoolClassName: value[4],
      classLevel: value[5],
      year: value[6],
      gender: value[7],
    });
  })
  @Expose()
  public row: UploadExceptionSpreadSheetRow;
}

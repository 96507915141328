import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { CoreModule } from '../../../core/core.module';
import { DataSourceService } from '../../services/datasource/datasource.service';
import { InfiniteScrollContentModule } from '../infinite-scroll-content/infinite-scroll-content.module';
import { UploadFeedbackModalComponent } from './upload-feedback-modal.component';

@NgModule({
  declarations: [UploadFeedbackModalComponent],
  providers: [DataSourceService],
  imports: [
    CoreModule,
    MatTableModule,
    MatSortModule,
    InfiniteScrollContentModule,
  ],
  exports: [UploadFeedbackModalComponent],
})
export class UploadFeedbackModule {}

import { Injectable } from '@angular/core';
import { FetchObservable } from '@techniek-team/rxjs';
import {
  combineLatest,
  EMPTY,
  interval,
  Observable,
  ObservableInput,
  share,
  ReplaySubject,
  Subject,
  throwError,
  timer,
} from 'rxjs';
import { catchError, distinctUntilChanged, startWith, switchMap, takeUntil } from 'rxjs/operators';


export interface CacheOptions {
  /**
   * Set the force flag to true to override the cached Observable and emit the
   * Observable provided to all subscribers. Defaults to false.
   *
   * Note: normally you wouldn't have to use this flag.
   */
  force?: boolean;

  /**
   * Time in milliseconds for the original observable to be automatically
   * refreshed in the background.
   */
  refreshInterval?: number;

  /**
   * Time in milliseconds before the returned observable is reset back to 'cold'
   * when there are no more subscribers. The first new connection will force a
   * new result from the original observable.
   */
  clearInterval?: number | boolean;
}

/**
 * This service stores the observables (for instance of an api call) using
 * an identifier
 *
 * If this method is called a second time the cached response will be
 * returned instead of having to make a new http call.
 *
 * This method is useful if we want to store a response across multiple
 * pages. for example. a user profile call.
 *
 * When a value in the cache has to be updated the .refresh() can be called
 * which will trigger a fresh http call, the major advanced of this class is
 * that all subscribers will automatically get updated
 *
 */
@Injectable({
  providedIn: 'root',
})
export class CacheService {

  /**
   * Map of cached Observables mapped to an identifier.
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected cacheList: Map<string, Observable<any>> = new Map();

  /**
   * EventSubject used to trigger the reloads, called with {@see self.refresh}.
   */
  protected reloads: Map<string, Subject<void>> = new Map();

  /**
   * Map of Subjects used to emit a new value of the observable to all
   * new subscribers.
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected updates: Map<string, Subject<Observable<any>>> = new Map();

  /**
   * Map of interval timers.
   */
  protected timers: Map<string, Observable<number>> = new Map();

  /**
   * Map of subjects that kill timers.
   */
  protected killers: Map<string, Subject<void>> = new Map();

  /**
   * Create and return a cached reference Observable that can be refreshed via
   * this service, or auto-refreshed via an interval timer.
   *
   * Optionally you can give a Generic to state what kind of Observable this
   * method will return for example {@see FetchObservable}
   */
  //eslint-disable-next-line max-lines-per-function
  public create<T, K extends Observable<T> = Observable<T>>(
    identifier: string,
    observer: Observable<T>,
    options?: CacheOptions,
  ): K {
    // Check if the identifier already exists and if not, make it first.
    if (!this.cacheList.has(identifier)) {

      /**
       * EventSubject used to trigger the reloads
       * this reload is triggered in the this.refresh()
       */
      const newReload: Subject<void> = new Subject<void>();
      this.reloads.set(identifier, newReload);

      /**
       * EventSubject used to emit a new value of the observable
       */
      const newUpdater: Subject<Observable<T>> = new Subject<Observable<T>>();
      this.updates.set(identifier, newUpdater);

      /**
       * CombineLatest observables list, which starts with the updater and the
       * reloader.
       */
      const streams$: Observable<ObservableInput<T> | unknown>[] = [
        newUpdater.pipe(
          distinctUntilChanged(),
          startWith(observer.pipe(
            catchError((error) => {
              // If there's an error, clear any result immediately.
              this.clear(identifier);
              return throwError(error);
            }),
          )),
        ),
        newReload.pipe(
          startWith(undefined),
        ),
      ];

      // Cast the optional clearInterval to its value or a boolean.
      const clearInterval: number | boolean = options?.clearInterval ?? false;
      // Cast the clearInterval to a timer function or a boolean.
      //eslint-disable-next-line max-len
      const clearFn: boolean | (() => Observable<number>) = !isNaN(parseInt(clearInterval.toString(), 10)) ? (): Observable<number> => timer(clearInterval as number) : clearInterval as boolean;

      /**
       * If the refreshInterval exists in the list of options, add the hot
       * SchedulerLike Interval observable to the list. Uses the same clearing
       * timer as the cached content, so both will be cleared if the interval or
       * state is set.
       */
      if (options?.refreshInterval) {
        const newKiller: Subject<void> = new Subject();
        const newInterval: Observable<number> = interval(options.refreshInterval).pipe(
          takeUntil(newKiller),
          share({
            connector: () => new ReplaySubject(1),
            resetOnRefCountZero: clearFn,
          }),
        );
        this.killers.set(identifier, newKiller);
        this.timers.set(identifier, newInterval);
        streams$.push(
          newInterval.pipe(
            startWith(undefined),
          ),
        );
      }

      /**
       * Combine the source observable in the updater, the reloading trigger and
       * the optional interval timer via the (custom) share
       */
      let newObserver: Observable<T> = combineLatest(streams$).pipe(
        switchMap((inputs: unknown[]) => inputs[0] as ObservableInput<T>),
        share({
          connector: () => new ReplaySubject(1),
          resetOnRefCountZero: clearFn,
        }),
      );

      /**
       * turns the cache observable into a FetchObservable if the origin observable
       * also was an instance of FetchObservable.
       */
      if (observer instanceof FetchObservable) {
        newObserver = FetchObservable.createFromExisting(observer.getIri(), observer.instanceType, newObserver);
      }

      // Store the observable in the map
      this.cacheList.set(identifier, newObserver);
    }

    /**
     * If the force toggle is set to true, the cached results are force-updated
     * to all currently active subscribers.
     */
    if (options?.force === true) {
      this.updates.get(identifier)?.next(observer);
    }

    return this.cacheList.get(identifier) as K;
  }

  /**
   * Backwards compatible pass-through.
   */
  public cache<T>(identifier: string, observer: Observable<T>, force: boolean = false): Observable<T> {
    return this.create(identifier, observer, { force: force });
  }

  /**
   * Updates the given cache identifier with a newly given Observer.
   */
  public next<T>(identifier: string, observer: Observable<T>): void {
    const cache: Observable<T> | undefined = this.cacheList.get(identifier);

    if (!cache) {
      throw new Error('cannot find cache with the key: `identifier`');
    }

    this.updates.get(identifier)?.next(observer);
  }

  /**
   * Return true if the given identifier can be found in the CacheService.
   */
  public has(identifier: string): boolean {
    return this.cacheList.has(identifier);
  }

  /**
   * Get a cached item directly, if it exists.
   *
   * Optionally you can give a Generic to state what kind of Observable this
   * method will return for example {@see FetchObservable}
   */
  public get<T, K extends Observable<T> = Observable<T>>(identifier: string): K {
    return (this.cacheList.get(identifier) ?? EMPTY) as K;
  }

  /**
   * Triggers a refresh for all subscriptions for the observable of to the
   * provided identifier.
   */
  public refresh(identifier: string): void {
    if (!this.reloads.has(identifier)) {
      return;
    }
    const reload: Subject<void> = this.reloads.get(identifier) as Subject<void>;
    reload.next();
  }

  /**
   * Remove all references and complete subjects related to the identifier.
   */
  public clear(identifier: string): void {
    if (!this.reloads.has(identifier)) {
      return;
    }

    this.cacheList.delete(identifier);
    this.reloads.get(identifier)?.complete();
    this.reloads.delete(identifier);
    this.updates.get(identifier)?.complete();
    this.updates.delete(identifier);
    this.killers.get(identifier)?.next();
    this.killers.get(identifier)?.complete();
    this.killers.delete(identifier);
    this.timers.delete(identifier);
  }
}

import { NgModule } from '@angular/core';
import { CoreModule } from '../../../core/core.module';
import { InfiniteScrollCellDirective } from './infinite-scroll-cell.directive';
import { InfiniteScrollContentComponent } from './infinite-scroll-content.component';
import { InfiniteScrollContentDirective } from './infinite-scroll-content.directive';

@NgModule({
  imports: [CoreModule],
  declarations: [
    InfiniteScrollContentComponent,
    InfiniteScrollContentDirective,
    InfiniteScrollCellDirective,
  ],
  exports: [InfiniteScrollContentComponent, InfiniteScrollContentDirective],
})
export class InfiniteScrollContentModule {}

<ion-header [class.flat]="flat"
            mode="md">
  <ion-toolbar mode="md"
               [color]="translucent ? undefined : color ?? 'white'"
               [class.translucent]="translucent"
               class="ion-padding-horizontal">
    <div slot="start">
      <ng-content select="[slot=start]"></ng-content>
    </div>
    <div>
      <ng-content></ng-content>
      <app-location [color]="translucent ? 'white' : color"></app-location>
    </div>
    <div slot="end">
      <ng-content select="[slot=end]"></ng-content>
      <app-header-menu></app-header-menu>
    </div>
  </ion-toolbar>
  <ng-content select="ion-toolbar"></ng-content>
  <ng-content select="[slot='footer']"></ng-content>
  <ng-content select="[footer]"></ng-content>
</ion-header>

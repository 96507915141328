import { HttpErrorResponse } from '@angular/common/http';
import { ApiPlatformError, denormalize } from '@techniek-team/class-transformer';
import { ResponseMessage, ResponseMessageMarkup } from '../components/response-message/response-message.model';

/**
 * Base class to extract API platform errors. Extend this to transform the
 * HttpErrorResponse to a {@see ResponseMessage} to display to the user.
 */
export abstract class ApiErrorExtractorBase {
  /**
   * Convert the given HttpErrorResponse to an ApiPlatformError if possible and
   * extract the error from the error description. The message is returned as a
   * translated and marked up string.
   */
  public abstract extractError(
    response: HttpErrorResponse,
    ...params: never
  ): ResponseMessage | null;

  /**
   * Given the ApiPlatformError, match the value in the description with a list
   * of known errors and return a translated error with markup.
   */
  public abstract matchError(
    error: ApiPlatformError,
    ...params: never
  ): ResponseMessageMarkup[];

  /**
   * Convert the HttpErrorResponse to an ApiPlatformError.
   */
  public getApiPlatformError(
    response: HttpErrorResponse,
  ): ApiPlatformError | null {
    if (!response?.error) {
      return null;
    }

    const parsed: ApiPlatformError = denormalize(
      ApiPlatformError,
      response.error as object,
    );
    // In case of a non API-platform error we might not have a description. We
    // should set an empty description as `.includes()` is called within
    // `matchError()` on the description and this only works for strings.
    parsed.description = parsed.description ?? '';

    return parsed;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IconsModule } from '../../../core/icons.module';
import { SchoolDashboardModalComponent } from './school-dashboard-modal.component';

@NgModule({
  imports: [CommonModule, IonicModule, IconsModule],
  declarations: [SchoolDashboardModalComponent],
  exports: [SchoolDashboardModalComponent],
})
export class SchoolDashboardModalModule {}

import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, delay, filter, firstValueFrom, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'tt-sub-menu',
  templateUrl: './tt-sub-menu.component.html',
  styleUrls: ['./tt-sub-menu.component.scss'],
  animations: [
    trigger('showSubMenu', [
      state('true', style({ width: 'var(--tt-sub-menu-width, 184px)' })),
      state('false', style({ width: '0' })),
      transition('true <=> false', [
        animate(300),
      ]),
    ]),
  ],
})
export class TtSubMenuComponent implements AfterViewInit, OnDestroy {

  /**
   * ViewContainer where the submenu is placed.
   */
  @ViewChild(
    'viewContainer',
    { read: ViewContainerRef, static: true },
  ) public viewContainer: ViewContainerRef | undefined;

  /**
   * Input for the title of the submenu.
   */
  @Input() public title: string | undefined;

  /**
   * HostBinding of the showSubMenu animation to the host element.
   */
  @HostBinding('@showSubMenu')
  public get getShowMenu(): boolean {
    return this.showMenu;
  }

  /**
   * Input for the subMenu template.
   */
  @Input()
  public set template(template: TemplateRef<unknown> | undefined) {
    this.templateSubject$.next(template);
  }

  /**
   * Title shown in the submenu. This is different from the input {@see title}.
   * It waits until the close animation has completed before setting it in the DOM.
   */
  protected titleShown: string | undefined;

  private hasTemplate: boolean = false;

  /**
   * Boolean stating that the sub menu is shown or not.
   */
  private showMenu: boolean = false;

  /**
   * Subject holding the current submenu template if any.
   */
  //eslint-disable-next-line max-len
  private templateSubject$: BehaviorSubject<TemplateRef<unknown> | undefined> = new BehaviorSubject<TemplateRef<unknown> | undefined>(undefined);

  /**
   * Use to do a full animation when switching from item with sub menu to item with sub menu
   */
  private fullTransition$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private onDestroy: Subject<void> = new Subject();

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    this.createOnTemplateChangeSubscriber();
  }

  @HostListener('@showSubMenu.start', ['$event'])
  public onShowMenuStart(event: AnimationEvent): void {
    if ((event.toState as string | boolean) === true && this.viewContainer) {
      this.viewContainer.clear();
      const template: TemplateRef<unknown> | undefined = this.templateSubject$.getValue();
      if (template) {
        this.viewContainer.createEmbeddedView(template);
      }
      this.titleShown = this.title;
    }
  }

  @HostListener('@showSubMenu.done', ['$event'])
  public onShowMenuDone(event: AnimationEvent): void {
    if ((event.toState as string | boolean) === false && this.viewContainer) {
      this.fullTransition$.next(false);
      this.viewContainer.clear();
    }
  }

  /**
   * Subscribe which listens to template changes. On a change it checks if it already
   * has a template if so it starts a full transition of closing the menu and after that
   * opening it. If the menu was closed it just opens the menu.
   */
  public createOnTemplateChangeSubscriber(): void {
    this.templateSubject$
      .pipe(
        takeUntil(this.onDestroy),
        // This delay is needed to fix the ExpressionChangedAfterItHasBeenCheckedError Error.
        // We can't use a behaviorSubject here because @HostBinding doesn't support this yet.
        delay(0),
      )
      .subscribe(template => {
        if (this.hasTemplate && !!(template)) {
          this.hasTemplate = !!(template);
          this.showMenu = false;
          this.fullTransition$.next(true);
          firstValueFrom(this.fullTransition$.pipe(filter(isAnimating => !isAnimating), take(1)))
            .then(() => {
              this.showMenu = true;
            });
          return;
        }

        this.showMenu = !!(template);
        this.hasTemplate = !!(template);
      });
  }
}

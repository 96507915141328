import { HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, Injector, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { AlertController, IonContent, IonInfiniteScroll, ModalController } from '@ionic/angular';
import { MessageColor, UploadExceptionType } from '@school-dashboard/enums';
import { environment } from '@school-dashboard/environments';
import { PupilUpload, PupilUploadError } from '@school-dashboard/models';
import { denormalize } from '@techniek-team/class-transformer';
import { isDefined } from '@techniek-team/rxjs';
import * as FileSaver from 'file-saver';
import { BehaviorSubject, combineLatest, firstValueFrom, Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { PupilUploadApi } from '../../../api/pupil-upload/pupil-upload.api';
import { DataSourceService } from '../../services/datasource/datasource.service';
import { ProgressData, ProgressServiceInterface } from '../../services/progress/progressServiceInterface';
import { ProgressNotificationType } from '../progress-notification/progress-notification.enum';
import { ResponseMessageKey } from '../response-message/response-message.enum';
import { MarkupDisplay, ResponseMessage } from '../response-message/response-message.model';
import { ResponseMessageService } from '../response-message/response-message.service';
import { ModalAction } from '../school-dashboard-modal/school-dashboard-modal.component';
import { UploadFeedbackDataSource } from './datasource/upload-feedback.datasource';

export const UPLOAD_DATASOURCE_KEY: string = 'pupil-upload';

@Component({
  selector: 'app-upload-feedback-modal',
  templateUrl: './upload-feedback-modal.component.html',
  styleUrls: ['./upload-feedback-modal.component.scss'],
})
export class UploadFeedbackModalComponent implements AfterViewInit, OnDestroy {
  /**
   * Data for the Datasource to use.
   */
  @Input() public pupilUpload: PupilUpload;

  /**
   * Set a service that keeps track of the progress.
   */
  @Input() public progressService: ProgressServiceInterface;

  /**
   * The infinite scroll instance sent to the DataSource.
   */
  @ViewChild(IonInfiniteScroll) public set infiniteScroll(
    instance: IonInfiniteScroll,
  ) {
    if (instance) {
      this.infiniteScroll$.next(instance);
    }
  }

  /**
   * Setter for updating the matSort$ filtering observable stream.
   */
  @ViewChild(MatSort) public set matSort(instance: MatSort) {
    if (instance) {
      this.matSort$.next(instance);
    }
  }

  /**
   * IonContent element instance. Used to store scroll position with.
   */
  @ViewChild(IonContent) public ionContent: IonContent;

  /**
   * The response message keys that this component can display to the user.
   */
  public _supportedResponseMessageKeys: ResponseMessageKey[] = [
    ResponseMessageKey.PUPIL_FILE_UPLOAD_FEEDBACK,
    ResponseMessageKey.DATASOURCE,
  ];

  /**
   * FormControl for the search input and filters.
   */
  public _searchFormControl: UntypedFormControl = new UntypedFormControl({});

  /**
   * Datasource getter.
   */
  public get dataSource(): UploadFeedbackDataSource {
    return this._dataSource;
  }

  /**
   * Columns for this table.
   */
  public _columns: string[] = ['row', 'fullName', 'email', 'message'];

  /**
   * Pass-through enum of exception types.
   */
  public readonly UploadExceptionType: typeof UploadExceptionType = UploadExceptionType;

  /**
   * DataSource for this table.
   */
  //eslint-disable-next-line @typescript-eslint/naming-convention
  private _dataSource: UploadFeedbackDataSource;

  /**
   * Subject to filter the creation of the DataSource, so the sorting is enabled
   * via the MatSort component that is on the template.
   */
  private matSort$: BehaviorSubject<MatSort> = new BehaviorSubject<MatSort>(
    null as never,
  );

  /**
   * Infinite scroll instance, which needs to be set in the datasource, so this
   * subject allows for a combineLatest to function.
   */
  private infiniteScroll$: BehaviorSubject<IonInfiniteScroll> = new BehaviorSubject<IonInfiniteScroll>(null as never);

  /**
   * Subscription yeet subject.
   */
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private modalController: ModalController,
    private responseService: ResponseMessageService,
    private datasourceService: DataSourceService,
    private alertController: AlertController,
    private uploadApi: PupilUploadApi,
    private injector: Injector,
  ) {}

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    this.createDataSourceSubscriber();
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.datasourceService.delete(UPLOAD_DATASOURCE_KEY);

    if (this.dataSource) {
      this.dataSource.destroy();
    }
  }

  /**
   * TrackBy function for the table to not go haywire when the results are
   * loaded in when reloading the cache.
   */
  public trackBy(index: number, item: PupilUploadError): string | number {
    return item?._id ?? index;
  }

  /**
   * Let the progress service know this modal has done a thing.
   */
  public async dialogAction(action: ModalAction): Promise<void> {
    await this.progressService.handleDialogAction(
      action,
      {
        type: ProgressNotificationType.RESTART,
      } as unknown as ProgressData,
      () => {
        return this.modalController.dismiss({ action: action });
      },
    );
  }

  /**
   * Cancel the upload, update the progressService to remove the uploading state
   * and continue on as if it was successful.
   * Note: this is a temporary measure.
   */
  public async cancelUpload(): Promise<void> {
    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: 'Uploaden afbreken',
      message:
        'Weet u zeker dat u het uploaden wilt afbreken?'
        + ' De leerlingen met foutieve data worden niet geïmporteerd of opgeslagen.',
      buttons: [
        { text: 'Terug', role: 'cancel' },
        { text: 'Afbreken', role: 'confirm' },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();

    if (role === 'confirm') {
      await this.progressService.handleDialogAction(
        ModalAction.OK,
        {
          percentageCompleted: 100,
          uploadErrors: 0,
        } as unknown as ProgressData,
        null as never,
      );
      await this.modalController.dismiss();
    }
  }

  /**
   * Download the error file from the coreInformationApi containing the errors
   * to be rectified.
   */
  public async downloadErrors(): Promise<void> {
    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: 'Download fouten',
      message:
        'De download met alle fouten wordt gestart. <br><br>'
        + 'Dubbel geuploade leerlingen worden niet teruggegeven in deze download'
        + ' en hoeven dus ook niet uit de lijst verwijderd te worden.',
      buttons: [{ text: 'Terug', role: 'cancel' }],
    });

    await alert.present();
    const file: HttpResponse<Blob> = await firstValueFrom(
      this.uploadApi.getErrorFile(this.pupilUpload.getIri() as string),
    );

    FileSaver.saveAs(
      file.body as Blob,
      ('fouten-leerlingen-upload.' + this.pupilUpload.extension) as string,
    );
  }

  /**
   * Since the Datasource expects to be instantiated with the MatSort instance,
   * subscribe to the matSort$ stream and instantiate the Datasource once it's
   * loaded in. Since the template will generate some xChangedAfterY errors,
   * there's a small debounceTime to prevent this.
   * If the infiniteScroll is instantiated later, the Datasource is not created
   * again, but instead the infiniteScroll instance is updated.
   */
  private createDataSourceSubscriber(): void {
    combineLatest([this.matSort$, this.infiniteScroll$])
      .pipe(
        takeUntil(this.destroy$),
        filter(() => !!this.pupilUpload?.getIri()),
        isDefined([0]),
        debounceTime(500),
      )
      .subscribe(([sorting, scroll]) => {
        if (!this._dataSource) {
          sorting.active = 'row';
          sorting.direction = 'asc';

          const dataSource: UploadFeedbackDataSource = new UploadFeedbackDataSource(
            this.injector,
            `${environment.coreApiUrl}${
              this.pupilUpload.getIri() as string
            }/pupil-upload-errors`,
            this._searchFormControl.valueChanges,
          );
          dataSource.sort = sorting;
          dataSource.itemHeight = 36;
          dataSource.infiniteScroll = scroll;
          dataSource.ionContent = this.ionContent;

          this._dataSource = dataSource;
          this.createUploadExceptionTypeSubscriber();
          this.datasourceService.set(UPLOAD_DATASOURCE_KEY, this._dataSource);
          this.datasourceService.refresh(UPLOAD_DATASOURCE_KEY, 250);
        } else {
          this.dataSource.infiniteScroll = scroll;
        }
      });
  }

  /**
   * Check the results for API and UNKNOWN errors and, if found, show an error
   * message at the top of the table to indicate options to the user.
   */
  private createUploadExceptionTypeSubscriber(): void {
    this.dataSource.observer$
      .pipe(
        takeUntil(this.destroy$),
        filter((results) => results[0] !== null),
        map((results) => results.map((item) => item.type)),
      )
      .subscribe((types: UploadExceptionType[]) => {
        if (types.includes(UploadExceptionType.API)) {
          const message: ResponseMessage = denormalize(ResponseMessage, {
            key: ResponseMessageKey.PUPIL_FILE_UPLOAD_FEEDBACK,
            type: MessageColor.DANGER,
            message: [
              {
                value:
                  'Er is een fout opgetreden in de achterliggende verbinding. ',
                styleBold: true,
                display: MarkupDisplay.BLOCK,
              },
              {
                value:
                  'Upload het bestand nog een keer, maar neem contact op met Lyceo als '
                  + 'dit probleem zich voor blijft doen.',
              },
            ],
          });
          this.responseService.setMessage(message);
          return;
        }

        this.responseService.clearMessage(
          ResponseMessageKey.PUPIL_FILE_UPLOAD_FEEDBACK,
        );
      });
  }
}

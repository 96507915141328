import { Exclude, Expose } from 'class-transformer';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterInterface } from '../../interfaces/filters.interface';
import { FilterGroup } from './filter-group.model';

export interface FilterOptions {
  label?: string;
  position?: number;
  disabled?: boolean;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalData?: any;
}

export enum FilterInteractionState {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
enum FilterDisplayState {
  VISIBLE = 'VISIBLE',
  HIDDEN = 'HIDDEN',
}

interface StateChange {
  interactionState: FilterInteractionState;
  displayState: FilterDisplayState;
}

// Without this dynamic parameter AOT compiling will fail.
// @see https://angular.io/guide/angular-compiler-options#strictmetadataemit
// @dynamic
export class Filter implements FilterInterface {
  /**
   * unique identifier for this filter
   */
  @Exclude() public readonly key: string;

  /**
   * The value or values of this filter.
   */
  @Expose() public value!: unknown | unknown[];

  /**
   * This property will be used as display text for the item in the filter menu.
   * If not set the name property will be used.
   */
  @Expose() public label?: string;

  /**
   * The position in which order the filters should be shown.
   * If not set it will order them alphabetically by displayValue (or otherwise name)
   * and if partially set position takes precedence over sort alphabetically.
   */
  @Expose() public position?: number;

  /**
   * This property isn't used in any way. It can by used to add aditional data
   * to a filter which for example can be used in callback give to
   * {@see FilterControllerService.registerTrigger}
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Expose() public additionalData?: any;

  /**
   * The filterGroup where this filter belongs to. This is not set when using the serializer
   * and needs to be set manually to prevent circular dependencies.
   */
  @Exclude() public filterGroup: FilterGroup | undefined;

  /**
   * This state states the current form of interaction that is possible with
   * this filter. There are 2 possible status values:
   *
   * * **ENABLED**:  This filter is active, meaning that the user can select
   *                   this filter.
   * * **DISABLED**: This filter is disabled meaning that the user can't select
   *                   this specific filter.
   *
   * When the filter is disabled it's still possible to change the selection of
   * filters. The component using the model is responsible for the disabling of
   * the filters.
   *
   * Setting the {@see FilterGroup.enable} and {@see FilterGroup.disable}
   * function also changes the {@see interactionState} for each filter.
   */
  @Exclude()
  //eslint-disable-next-line max-len
  protected interactionState: BehaviorSubject<FilterInteractionState> = new BehaviorSubject<FilterInteractionState>(FilterInteractionState.ENABLED);

  /**
   * This state states the current form of interaction that is possible with
   * this filter. The are 2 possible status values:
   *
   * * **VISIBLE**:   This filter is visible, meaning that the user can see
   *                    this filter.
   * * **HIDDEN**:    This filter is hidden, meaning that the user can't see this
   *                    specific filter.
   *
   * The implementation of the display states should be done within the
   * component using this model. This property is merely for storage and doesn't
   * have to be used.
   *
   * Setting the {@see FilterGroup.show} and {@see FilterGroup.hide} function
   * also changes the {@see displayState} for each filter.
   */
  @Exclude() protected displayState: BehaviorSubject<FilterDisplayState> = new BehaviorSubject<FilterDisplayState>(
    FilterDisplayState.VISIBLE,
  );

  constructor(value: unknown | unknown[], options?: FilterOptions) {
    this.key = window.crypto.randomUUID();
    this.value = value;
    this.label = options?.label;
    this.position = options?.position;
    this.additionalData = options?.additionalData;
    this.interactionState.next(
      //eslint-disable-next-line max-len
      (typeof options?.disabled === 'boolean' && options?.disabled) ? FilterInteractionState.DISABLED : FilterInteractionState.ENABLED,
    );
    this.filterGroup = undefined;
  }

  /**
   * Returns the display text of the filter.
   *
   * This is the displayValue or if not set the key of this filter.
   */
  @Exclude()
  public get displayText(): string {
    if (this.label) {
      return this.label;
    }

    if (Array.isArray(this.value)) {
      return this.value.join(', ');
    }

    return (this.value as unknown as string).toString();
  }


  /**
   * Returns true if this filter is disabled. see {@see interactionState}
   * for more information.
   */
  public get disabled(): boolean {
    return this.interactionState.getValue() === FilterInteractionState.DISABLED;
  }

  /**
   * Returns true if this filter is enabled. see {@see interactionState}
   * for more information.
   */
  public get enabled(): boolean {
    return this.interactionState.getValue() === FilterInteractionState.ENABLED;
  }

  /**
   * Returns true if this filter is visible. see {@see displayState}
   * for more information.
   */
  public get visible(): boolean {
    return this.displayState.getValue() === FilterDisplayState.VISIBLE;
  }

  /**
   * Returns true if this filter is hidden. see {@see displayState}
   * for more information.
   */
  public get hidden(): boolean {
    return this.displayState.getValue() === FilterDisplayState.HIDDEN;
  }

  /**
   * Get the selected state of this filter, if a filterGroup is set.
   */
  public get selected(): boolean {
    return this.filterGroup?.isSelected(this) ?? false;
  }

  /**
   * Return a observable emitting when the {@see interactionState} or {@see displayState} changes
   */
  public get stateChange$(): Observable<StateChange> {
    return combineLatest([
      this.displayState,
      this.interactionState,
    ]).pipe(map(([displayState, interactionState]) => ({
      interactionState: interactionState,
      displayState: displayState,
    })));
  }

  /**
   * Enables this filter. See {@see interactionState} for more information.
   */
  @Exclude()
  public enable(): void {
    this.interactionState.next(FilterInteractionState.ENABLED);
  }

  /**
   * Disables this filter. See {@see interactionState} for more information.
   */
  @Exclude()
  public disable(): void {
    this.interactionState.next(FilterInteractionState.DISABLED);
  }

  /**
   * Makes this filter visible. See {@see displayState} for more information.
   */
  @Exclude()
  public show(): void {
    this.displayState.next(FilterDisplayState.VISIBLE);
  }

  /**
   * Makes this filter hidden. See {@see displayState} for more information.
   */
  @Exclude()
  public hide(): void {
    this.displayState.next(FilterDisplayState.HIDDEN);
  }
}

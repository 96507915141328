<div class="wrapper" [class.no-bottom]="actionButtonText || cancelButtonText">
  <ion-fab vertical="top" horizontal="end" slot="fixed" (click)="emitCancel(true)">
    <ion-fab-button size="small" [disabled]="closeDisabled">
      <fa-icon [icon]="_closeIcon" [fixedWidth]="true"></fa-icon>
    </ion-fab-button>
  </ion-fab>

  <div [style.width]="_leftWidth + '%'"
       [class.hide-column]="_leftWidth === 0"
       [class.right-border]="_leftWidth && (_centerWidth || _rightWidth)"
       #leftContainer>
    <ng-content select="[position-left]"></ng-content>
  </div>

  <div [style.width]="_centerWidth + '%'"
       [class.hide-column]="_centerWidth === 0"
       [class.right-border]="_centerWidth && _rightWidth"
       #centerContainer>
    <ng-content select="[position-center]"></ng-content>
  </div>

  <div [style.width]="_rightWidth + '%'"
       [class.hide-column]="_rightWidth === 0"
       #rightContainer>
    <ng-content select="[position-right]"></ng-content>
  </div>
</div>

<div *ngIf="actionButtonText || cancelButtonText"
     [class.border-top]="footerBorder"
     class="footer">
  <ion-button *ngIf="cancelButtonText"
              (click)="emitCancel()"
              size="small"
              class="underline"
              [disabled]="cancelDisabled"
              fill="clear">
    {{ cancelButtonText }}
  </ion-button>

  <ion-button *ngIf="actionButtonText"
              (click)="emitOk()"
              [disabled]="actionDisabled"
              [class.loading-max]="actionButtonState === ModalButtonState.LOADING"
              [class.loading-min]="actionButtonState !== ModalButtonState.LOADING"
              class="action-button">
    <ion-spinner></ion-spinner>
    {{ actionButtonText }}
  </ion-button>
</div>

import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, SegmentCustomEvent } from '@ionic/angular';
import { UsersStoreService } from '@school-dashboard/data-access-users';
import { UserModel } from '@school-dashboard/models';
import { PermissionService } from '@techniek-team/permissions';
import { BehaviorSubject, firstValueFrom, Observable, shareReplay, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { TrackPermission, TrackPermissionSubjects } from '../../core/permission/track/track.permission';
import { ContentName } from '../../shared/components/pupil-modal/content-name.enum';
import { PupilModalComponent } from '../../shared/components/pupil-modal/pupil-modal.component';
import { PupilUploadModalComponent } from '../../shared/components/pupil-upload-modal/pupil-upload-modal.component';
import { ResponseMessageKey } from '../../shared/components/response-message/response-message.enum';
import { TabDefinition } from '../../shared/interfaces/tab.definition';
import { DataSourceService, SelectSource } from '../../shared/services/datasource/datasource.service';
import { ProgressPupilUploadService, StorageItem } from '../../shared/services/progress/progress-pupil-upload.service';

export enum DataSource {
  PUPIL = 'PUPIL',
  CLASSES = 'CLASSES',
}

@Component({
  selector: 'app-student-admin',
  templateUrl: './pupil-admin.page.html',
  styleUrls: ['./pupil-admin.page.scss'],
})
export class PupilAdminPage implements OnInit, OnDestroy {
  /**
   * Tabs for this page.
   */
  public _tabs: TabDefinition[] = [
    { label: 'Leerlingen', route: '/pupils/overview' },
  ];

  /**
   * The response message keys that this component can display to the user.
   */
  public _supportedResponseMessageKeys: ResponseMessageKey[] = [
    ResponseMessageKey.PUPIL_CREATE_UPDATE,
    ResponseMessageKey.TRACK_ATTENDANCE_CREATE_UPDATE,
  ];

  /**
   * Whether a pupil upload is being processed.
   * At the moment only a single {@see ProgressNotificationComponent} can be
   * shown, so we disable the upload button and display a spinner in the button.
   */
  public isProcessingPupilUpload$: Observable<boolean>;

  /**
   * The current pupil datasource as an observable.
   */
  public dataSource$: Observable<SelectSource | null>;

  protected canInitAddLocationMembers$: Observable<boolean>;

  protected readonly TrackPermission: typeof TrackPermission = TrackPermission;

  /**
   * Switch for the origin of the datasource, which can change between the
   * various tabs.
   */
  private sourceOrigin$: BehaviorSubject<DataSource> = new BehaviorSubject<DataSource>(DataSource.PUPIL);

  /**
   * Yeet the hot subscribers.
   */
  private destroy$: Subject<void> = new Subject();

  constructor(
    public location: Location,
    private router: Router,
    private modalController: ModalController,
    private datasourceService: DataSourceService,
    private progressPupilUploadService: ProgressPupilUploadService,
    private permissionService: PermissionService,
    private userStoreService: UsersStoreService,
  ) {
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.dataSource$ = this.createDataSourceObservable();
    this.isProcessingPupilUpload$ = this.createIsProcessingPupilUploadObservable();
    this.canInitAddLocationMembers$ = this.createInitAddLocationMembersObservable();
  }

  private createInitAddLocationMembersObservable(): Observable<boolean> {
    return this.userStoreService.user$.pipe(
      switchMap((user: UserModel) => user.rolesOnCurrentLocation$),
      switchMap(() => {
        return this.permissionService.isGranted(
          TrackPermission,
          TrackPermissionSubjects.INIT_ADD_LOCATION_MEMBER,
        );
      }),
      shareReplay(1),
    );
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Change the tab using the IonSegmentEvent and update the datasource-source
   * name before navigating.
   */
  public async selectTab(cast: Event): Promise<void> {
    const event: SegmentCustomEvent = cast as SegmentCustomEvent;
    const source: DataSource = event.detail.value === 'overview' ? DataSource.PUPIL : DataSource.CLASSES;
    this.sourceOrigin$.next(source);
    await this.router.navigate([event.detail.value]);
  }

  /**
   * Refresh the datasource.
   */
  public refreshPupilDataSource(): void {
    this.datasourceService.refresh(DataSource.PUPIL, 2000);
  }

  /**
   * Create an observable that watches the DataSourceService for the creation
   * or updating of the pupil DataSource.
   */
  public createDataSourceObservable(): Observable<SelectSource | null> {
    return this.sourceOrigin$.pipe(
      switchMap((origin) => {
        return this.datasourceService.get(origin, true) as Observable<SelectSource>;
      }),
    );
  }

  /**
   * Open a modal view with a form that lets the user add a single pupil to the
   * current location.
   */
  public async showPupilAddModal(): Promise<void> {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: PupilModalComponent,
      componentProps: {
        pupilIri: undefined,
        contentName: ContentName.PERSON_EDIT,
      },
      cssClass: 'modal-md',
      backdropDismiss: false,
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      // Note: if this is still not enough to load the class/level for the new
      // pupil, this needs to be refactored to a smarter solution that checks
      // for the existence of certain fields, but only once there's feedback on
      // this issue from users, as discussed with the PO.
      this.refreshPupilDataSource();
    }
  }

  /**
   * Open a modal for uploading a file containing a list of pupils.
   */
  public async showPupilUploadModal(): Promise<void> {
    const uploadInProgress: boolean = await firstValueFrom(
      await this.isProcessingPupilUpload$,
    );

    if (uploadInProgress) {
      return;
    }

    const modal: HTMLIonModalElement = await this.modalController.create({
      component: PupilUploadModalComponent,
      cssClass: 'modal-xs',
      backdropDismiss: false,
    });

    await modal.present();
  }

  /**
   * Create an observable that contains a boolean value. When the value is
   * true the pupil upload file button should be disabled. When the value is
   * false it should be enabled.
   */
  private createIsProcessingPupilUploadObservable(): Observable<boolean> {
    return this.progressPupilUploadService
      .getInProgressForCurrentLocation$()
      .pipe(
        takeUntil(this.destroy$),
        map((items: StorageItem[]) => {
          return items.length > 0;
        }),
        shareReplay(1),
      );
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TrackStoreService } from './track-store.service';
import { TrackEffects } from './track/track.effects';
import { trackFeatureKey, trackReducer } from './track/track.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(trackFeatureKey, trackReducer),
    EffectsModule.forFeature([TrackEffects]),
  ],
  providers: [TrackStoreService],
})
export class TrackDataAccessModule {
  constructor(private trackStoreService: TrackStoreService) {
    trackStoreService.initTracks();
  }
}

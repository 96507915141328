<tt-menu [header]="'Scholendashboard'">
  <tt-menu-item [routerLink]="['/home']"
                [icon]="['fad', 'house-chimney']"
                [title]="'Startpagina'">
  </tt-menu-item>
  <tt-menu-item [routerLink]="['/pupils/overview']"

                [icon]="['fad', 'user-group']"
                [title]="'Leerlingenbeheer'">
  </tt-menu-item>
  <tt-menu-item [routerLink]="['/tracks']"
                [icon]="['fad', 'id-badge']"
                [title]="'Trajecten'">
  </tt-menu-item>
  <tt-menu-item [routerLink]="['/services']"
                [icon]="['fad', 'book-open']"
                [title]="'Dienstenoverzicht'">
  </tt-menu-item>
</tt-menu>


<app-progress-notification *ngIf="(displayProgressNotification$ | async)"
                           [progressService]="progressPupilUploadService">
</app-progress-notification>

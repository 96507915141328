import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ProgressData, ProgressServiceInterface } from '../../../services/progress/progressServiceInterface';
import { ModalAction } from '../../school-dashboard-modal/school-dashboard-modal.component';
import { ProgressNotificationType } from '../progress-notification.enum';

@Component({
  selector: 'app-progress-notification-modal',
  templateUrl: './progress-notification-modal.component.html',
  styleUrls: ['./progress-notification-modal.component.scss'],
})
export class ProgressNotificationModalComponent implements OnInit {
  /**
   * Set a service that keeps track of the progress.
   */
  @Input() public progressService: ProgressServiceInterface;

  /**
   * The data containing information about the progress.
   */
  public _data$: Observable<ProgressData>;

  /**
   * Enum pass through for progress notification types.
   */
  public readonly ProgressNotificationType: typeof ProgressNotificationType = ProgressNotificationType;

  constructor(private modalController: ModalController) {}

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    if (!this.progressService) {
      throw new Error(
        'A service implementing the ProgressServiceInterface should be passed as \'progressService\'.',
      );
    }

    this._data$ = this.progressService.getData$();
  }

  /**
   * Handle the dialog action.
   */
  public async dialogAction(
    action: ModalAction,
    data: ProgressData,
  ): Promise<void> {
    await this.progressService.handleDialogAction(action, data, () => {
      return this.modalController.dismiss({ action: action });
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LocationsEffects } from './+state/locations.effects';
import { LOCATIONS_FEATURE_KEY, locationsReducer } from './+state/locations.reducer';
import { LocationsStoreService } from './locations-store.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(LOCATIONS_FEATURE_KEY, locationsReducer),
    EffectsModule.forFeature([LocationsEffects]),
  ],
  providers: [
    LocationsStoreService,
  ],
})
export class DataAccessLocationsModule {
  constructor(private locationStoreService: LocationsStoreService) {
    locationStoreService.init();
  }
}

import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Exclude, Expose, Type } from 'class-transformer';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { SelectableStyle } from '../enum/selectable-style.enum';
import { createFilterLookupList } from '../functions/create-filter-lookup-list/create-filter-lookup-list.function';
import { keyToFilterModels } from '../functions/key-to-filter-models/key-to-filter-models.function';
import { FilterApiInterface } from '../interfaces/filter-api.interface';
import { SimpleFilterApiInterface } from '../interfaces/filter-api/simple-filter-api.interface';
import { FilterGroupInterface } from '../interfaces/filters.interface';
import { Filter } from './filter/filter.model';

export interface BaseFilterGroupOptions {
  label?: string;
  position?: number;
  multiple?: boolean;
  disabled?: boolean;
  selectableStyle?: SelectableStyle;
}

export enum FilterGroupInteractionState {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
enum FilterGroupDisplayState {
  VISIBLE = 'VISIBLE',
  COLLAPSED = 'COLLAPSED',
  HIDDEN = 'HIDDEN',
}

interface StateChange {
  interactionState: FilterGroupInteractionState;
  displayState: FilterGroupDisplayState;
}

// Without this dynamic parameter AOT compiling will fail.
// @see https://angular.io/guide/angular-compiler-options#strictmetadataemit
// @dynamic
export abstract class BaseFilterGroup implements FilterGroupInterface {

  /**
   * unique identifier for this filter
   */
  @Exclude() public key!: string;

  /**
   * A unique name to identify the group.
   *
   * @inheritDoc
   */
  @Expose() public value!: string;

  /**
   * This property will be used as the display text for the button which will
   * open the menu containing the filters of this group.
   * If not set the name property will be used.
   *
   * @inheritDoc
   */
  @Expose() public label?: string;

  /**
   * The display style of the filters. This will only be used when {@see self.multiple}
   * is false. Otherwise it will always use the style {@see SelectableStyle.CHECKBOX}.
   *
   * @inheritDoc
   */
  @Expose() public selectableStyle: SelectableStyle = SelectableStyle.RADIO;

  /**
   * The position in which order the filter group buttons should be shown.
   * If not set it will order them by displayValue (or otherwise name) and if partially set.
   *
   * @inheritDoc
   */
  @Expose() public position?: number;

  /**
   * Lookup list to easy find filters based on key or value.
   */
  //eslint-disable-next-line max-len
  @Exclude() public lookupList$: BehaviorSubject<Map<string, Filter>> = new BehaviorSubject<Map<string, Filter>>(new Map());

  /**
   * A {@see SelectionModel} containing the currently selection Filters within this
   * FilterGroup.
   */
  @Exclude() protected selection!: SelectionModel<string>;

  /**
   * An array of filters within this group.
   *
   * @inheritDoc from {@see FilterGroupInterface.filters}
   */
  @Exclude() protected availableFilters$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);


  /**
   * This state states the current form of interaction that is possible with
   * this filterGroup. The are 2 possible status values:
   *
   * * **ENABLED**:  This filterGroup is active, meaning that the user can
   *                   select this filter.
   * * **DISABLED**: This filterGroup is disabled meaning that the User can't
   *                   select this specific filter.
   *
   * When the filterGroup is disabled it's still possible to change the selection
   * of filters. The component using the model is responsible for the disabling
   * of the filters. The {@see changed } observable doesn't emit the selection
   * changes when in disabled state.
   *
   * Setting the {@see FilterGroup.enable} and {@see FilterGroup.disable}
   * function also changes the {@see interactionState} for each filter.
   */
  //eslint-disable-next-line max-len
  @Exclude() protected interactionState: BehaviorSubject<FilterGroupInteractionState> = new BehaviorSubject<FilterGroupInteractionState>(
    FilterGroupInteractionState.ENABLED,
  );

  /**
   * This state states the current form of interaction that is possible with
   * this filterGroup. The are 3 possible status values:
   *
   * * **VISIBLE**:   This filterGroup is visible, meaning that the user can see
   *                    the filterGroup.
   * * **COLLAPSED**: This filterGroup is collapsed, meaning that the user can
   *                    see a collapsed version of this filterGroup.
   * * **HIDDEN**:    This filterGroup is hidden, meaning that the user can't
   *                    see the filterGroup.
   *
   * The implementation of the display states should be done within the
   * component using this model. This property is merely for storage and doesn't
   * have to be used.
   *
   * Setting the {@see FilterGroup.show} and {@see FilterGroup.hide} function
   * also changes the {@see displayState} for each filter.
   */
  //eslint-disable-next-line max-len
  @Exclude() protected displayState: BehaviorSubject<FilterGroupDisplayState> = new BehaviorSubject<FilterGroupDisplayState>(FilterGroupDisplayState.VISIBLE);

  /**
   * Subject which emits when the filters get set. This is used to unsubscribe
   * the old subscribe in {@see setFilters}
   */
  @Exclude() protected onFilterInputChange: Subject<void> = new Subject<void>();

  /**
   * Subject which emits when the selection model is set. Used to reable the change
   * observer
   */
  @Exclude() protected refreshSelectionModel: Subject<void> = new Subject<void>();


  /**
   * Setter for {@see SelectionModel.isMultipleSelection}.
   *
   * When true multiple filters can be selected at once, if false only one filter within
   * this group can be selected.
   *
   * @inheritDoc
   */
  @Expose()
  public set multiple(multiple: boolean) {
    this.selection = new SelectionModel<string>(
      multiple,
      (this.selection) ? this.selection.selected : [],
    );
    this.refreshSelectionModel.next();
  }

  /**
   * getter for {@see SelectionModel.isMultipleSelection}
   *
   * When true multiple filters can be selected at once, if false only one filter within
   * this group can be selected.
   *
   * @inheritDoc
   */
  public get multiple(): boolean {
    return this.selection.isMultipleSelection();
  }

  /**
   * Get stream for {@see availableFilters$}.
   *
   * Emits a stream of the current filters within this group. In case of
   * an remote filtering mechanism through an
   * {@see AutocompleteFilterApiInterface} we will never have a complete list
   * of filters. Therefore the {@see availableFilters$} Will also be empty.
   *
   * @inheritDoc
   */
  @Exclude()
  public get filters$(): Observable<Filter[]> {
    return this.availableFilters$.asObservable();
  }

  /**
   * getter for {@see availableFilters$}
   *
   * An array of filters within this group. In case of an remote filtering
   * mechanism through an {@see AutocompleteFilterApiInterface} we will never
   * have a complete list of filters. Therefore the {@see availableFilters$}
   * Will also be empty.
   *
   * @inheritDoc
   */
  @Type(() => Filter)
  @Expose()
  public get filters(): Filter[] {
    return this.availableFilters$.getValue();
  }

  /**
   * Setter for {@see availableFilters$}.
   *
   * An array of filters within this group. In case of an remote filtering
   * mechanism through an {@see AutocompleteFilterApiInterface} we will never
   * have a complete list of filters. Therefor we setting the
   * {@see availableFilters$} can be empty if working with such a filtering
   * mechanism.
   *
   * @inheritDoc
   */
  public set filters(filters: Filter[]) {
    this.setFilters(filters);
  }

  /**
   * Setter for {@see availableFilters$}.
   */
  @Exclude()
  public setFilterObservable(observable: Observable<Filter[]>): void {
    this.setFilters(observable);
  }

  @Exclude()
  public setFilterApiClass(observable: SimpleFilterApiInterface | FilterApiInterface): void {
    this.setFilters(observable);
  }

  /**
   * Returns {@see SelectionChange.change}.
   */
  @Exclude()
  public get changed(): Observable<SelectionChange<string>> {
    return this.refreshSelectionModel.pipe(
      startWith(undefined as void),
      switchMap(() => {
        return this.selection.changed.pipe(
          //filter(() => this.enabled),
          //todo fix thiss
        );
      }),
    );
  }

  /**
   * Returns the amount of available filters.
   */
  @Exclude()
  public get selected$(): Observable<Filter[]> {
    return this.changed.pipe(
      withLatestFrom(this.lookupList$),
      map(([changed, lookupList]: [SelectionChange<string>, Map<string, Filter>]) => {
        return keyToFilterModels<Filter>(changed.source.selected, lookupList);
      }),
    );
  }

  /**
   * Return the currently selected Filters.
   */
  @Exclude()
  public get selected(): Filter[] {
    return keyToFilterModels(this.selection.selected, this.lookupList$.getValue());
  }

  /**
   * Getter for {@see lookupList$}.
   *
   * Returns a Map in which filter are indexed by key and value (if it's unique for this FilterGroup).
   */
  @Exclude()
  public get lookupList(): Map<string, Filter> {
    return this.lookupList$.getValue();
  }

  /**
   * Returns the amount of available filters.
   */
  @Exclude()
  public get size(): number {
    return this.filters.length;
  }

  /**
   * Returns the display text of the filter groups.
   *
   * This is the displayValue or if not set the key of this group.
   */
  @Exclude()
  public get displayText(): string {
    return this.label ?? this.value;
  }

  /**
   * Returns the display style of the checkboxes.
   */
  @Exclude()
  public get style(): string {
    return this.selectableStyle === SelectableStyle.CHECKBOX ? 'md' : 'ios';
  }

  /**
   * Returns true if this filterGroup is disabled. see {@see interactionState}
   * for more information.
   */
  public get disabled(): boolean {
    return this.interactionState.getValue() === FilterGroupInteractionState.DISABLED;
  }

  /**
   * Returns true if this filterGroup is enabled. see {@see interactionState}
   * for more information.
   */
  public get enabled(): boolean {
    return this.interactionState.getValue() === FilterGroupInteractionState.ENABLED;
  }

  /**
   * Returns true if this filterGroup is visible. see {@see displayState}
   * for more information.
   */
  public get visible(): boolean {
    return this.displayState.getValue() === FilterGroupDisplayState.VISIBLE;
  }

  /**
   * Returns true if this filterGroup is collapsed. see {@see displayState}
   * for more information.
   */
  public get collapsed(): boolean {
    return this.displayState.getValue() === FilterGroupDisplayState.COLLAPSED;
  }

  /**
   * Returns true if this filterGroup is hidden. see {@see displayState}
   * for more information.
   */
  public get hidden(): boolean {
    return this.displayState.getValue() === FilterGroupDisplayState.HIDDEN;
  }

  /**
   * Return a observable emitting when the {@see interactionState} or {@see displayState} changes
   */
  public get stateChange$(): Observable<StateChange> {
    return combineLatest([
      this.displayState,
      this.interactionState,
    ]).pipe(map(([displayState, interactionState]) => ({
      interactionState: interactionState,
      displayState: displayState,
    })));
  }

  /**
   * Enables this filterGroup. See {@see interactionState} for more information.
   *
   * @param options Configuration options that determine how the filter
   * propagates changes in the {@see interactionState}.
   *
   * * `onlySelf`:  When true, each change only affects this filterGroup, and not
   * the filters withing this group. Default is false.
   */
  @Exclude()
  public enable(options: { onlySelf: boolean } = { onlySelf: false }): void {
    this.interactionState.next(FilterGroupInteractionState.ENABLED);
    if (!options.onlySelf) {
      for (let filter of this.filters) {
        filter.enable();
      }
    }
  }

  /**
   * Disables this filterGroup. See {@see interactionState} for more information.
   *
   * @param options Configuration options that determine how the filter
   * propagates changes in the {@see interactionState}.
   *
   * * `onlySelf`:  When true, each change only affects this filterGroup, and not
   * the filters withing this group. Default is false.
   */
  @Exclude()
  public disable(options: { onlySelf: boolean } = { onlySelf: false }): void {
    this.interactionState.next(FilterGroupInteractionState.DISABLED);
    if (!options.onlySelf) {
      for (let filter of this.filters) {
        filter.disable();
      }
    }
  }

  /**
   * Makes this filter visible. See {@see displayState} for more information.
   *
   * @param options Configuration options that determine how the filter
   * propagates changes in the {@see interactionState}.
   *
   * * `onlySelf`:  When true, each change only affects this filterGroup, and not
   * the filters withing this group. Default is false.
   */
  @Exclude()
  public show(options: { onlySelf: boolean } = { onlySelf: false }): void {
    this.displayState.next(FilterGroupDisplayState.VISIBLE);
    if (!options.onlySelf) {
      for (let filter of this.filters) {
        filter.show();
      }
    }
  }

  /**
   * Makes this filter invisible. See {@see displayState} for more information.
   *
   * @param options Configuration options that determine how the filter
   * propagates changes in the {@see interactionState}.
   *
   * * `onlySelf`:  When true, each change only affects this filterGroup, and not
   * the filters withing this group. Default is false.
   */
  @Exclude()
  public hide(options: { onlySelf: boolean } = { onlySelf: false }): void {
    this.displayState.next(FilterGroupDisplayState.HIDDEN);
    if (!options.onlySelf) {
      for (let filter of this.filters) {
        filter.hide();
      }
    }
  }

  /**
   * Sets the disabled state of this filterGroup to collapsed. See
   * {@see displayState} for more information.
   */
  @Exclude()
  public collapse(): void {
    this.displayState.next(FilterGroupDisplayState.COLLAPSED);
  }

  /**
   * Returns a stream which emits true if given item is selected. If the key doesn't exist is returns
   * false.
   *
   * See {@see isSelected} for the synchronous version of this method.
   */
  @Exclude()
  public isSelected$(targetFilter: unknown | Filter): Observable<boolean> {
    const key: string = (targetFilter instanceof Filter) ? targetFilter.key : (targetFilter as object).toString()
      .toLowerCase();
    return this.changed.pipe(
      withLatestFrom(this.lookupList$.asObservable()),
      map(([change, lookupList]: [SelectionChange<string>, Map<string, Filter>]) => {
        const lookupFilter: Filter | undefined = lookupList.get(key);
        if (!lookupList.has(key)) {
          return false;
        }
        return (lookupFilter?.key) ? change.source.isSelected(lookupFilter.key) : false;
      }),
      startWith(this.isSelected(key)),
    );
  }

  /**
   * returns true if given items is selected. If the key doesn't exist is returns
   * false as well
   *
   * See {@see isSelected$} for the synchronous version of this method.
   */
  @Exclude()
  public isSelected(targetFilter: unknown | Filter): boolean {
    const lookupList: Map<string, Filter> = this.lookupList$.getValue();
    const key: string = (targetFilter instanceof Filter) ? targetFilter.key : (targetFilter as unknown as string)
      .toString()
      .toLowerCase();

    const lookupFilter: Filter | undefined = lookupList.get(key);
    return (lookupFilter?.key) ? this.selection.isSelected(lookupFilter.key) : false;
  }

  /**
   * Toggle the given filter for this filterGroup. If filter doesn't exist of the
   * {@see disabledState} is true nothing happens.
   */
  @Exclude()
  public toggle(targetFilter: unknown | Filter): void {
    const lookupList: Map<string, Filter> = this.lookupList$.getValue();
    const key: string = (targetFilter instanceof Filter) ? targetFilter.key : (targetFilter as unknown as string)
      .toString()
      .toLowerCase();
    const lookupFilter: Filter | undefined = lookupList.get(key);
    if (lookupFilter?.key) {
      this.selection.toggle(lookupFilter.key);
    }
  }

  /**
   * Selects the given filter for this filterGroup. If filter doesn't exist of the
   * {@see disabledState} is true nothing happens.
   */
  @Exclude()
  public select(targetFilter: unknown | Filter): void {
    const lookupList: Map<string, Filter> = this.lookupList$.getValue();
    const key: string = (targetFilter instanceof Filter) ? targetFilter.key : (targetFilter as unknown as string)
      .toString()
      .toLowerCase();

    const lookupFilter: Filter | undefined = lookupList.get(key);
    if (lookupFilter?.key) {
      this.selection.select(lookupFilter.key);
    }
  }

  /**
   * Deselect the given filter for this filterGroup. If filter doesn't exist of the
   * {@see disabledState} is true nothing happens.
   */
  @Exclude()
  public deselect(targetFilter: unknown | Filter): void {
    const lookupList: Map<string, Filter> = this.lookupList$.getValue();
    const key: string = (targetFilter instanceof Filter) ? targetFilter.key : (targetFilter as unknown as string)
      .toString()
      .toLowerCase();

    const lookupFilter: Filter | undefined = lookupList.get(key);
    if (lookupFilter?.key) {
      this.selection.deselect(lookupFilter.key);
    }
  }

  /**
   * clear the current selection of this filterGroup.
   */
  @Exclude()
  public clear(): void {
    this.selection.clear();
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Sort the list of FilterGroups.
   *
   * 1. Sort by position ascending.
   * 2. Groups with a position take precedence over groups without
   * 3. If no position is set sort alphabetically on displayValue or name if
   *    display value is not set.
   *
   */
  protected sortFilters(
    valueA: Filter,
    valueB: Filter,
  ): number {
    if (typeof valueA.position === 'number' && typeof valueB.position === 'number') {
      return valueA.position - valueB.position;
    }

    if (typeof valueA.position === 'number' || typeof valueB.position === 'number') {
      return (typeof valueA.position === 'number') ? -1 : 1;
    }

    return (valueA?.displayText || '').localeCompare(valueB.displayText);
  }


  /**
   * Sets the filters given as input by the users.
   */
  protected setFilters(filtersInput: Filter[] | Observable<Filter[]> | SimpleFilterApiInterface): void;

  protected setFilters(filtersInput: Filter[] | Observable<Filter[]> | FilterApiInterface): void {
    this.onFilterInputChange.next();

    if (Array.isArray(filtersInput)) {
      for (const item of filtersInput) {
        item.filterGroup = this;
      }
      this.availableFilters$.next(filtersInput.sort(this.sortFilters));
      return;
    }

    if (filtersInput instanceof Observable) {
      filtersInput.pipe(
        takeUntil(this.onFilterInputChange),
        tap(filtersList => filtersList.forEach(filter => filter.filterGroup = this)),
        map(filterList => filterList.sort(this.sortFilters)),
      ).subscribe(filterList => {
        this.clear();
        this.availableFilters$.next(filterList);
      });
      return;
    }

    filtersInput.setFiltersByApi()
      .pipe(
        takeUntil(this.onFilterInputChange),
        map(filterList => filterList.sort(this.sortFilters)),
      )
      .subscribe(filterList => this.availableFilters$.next(filterList));
  }

  /**
   * Subscribe to {@see availableFilters$} and create a lookup list based on it.
   */
  protected createLookupListObserver(): void {
    this.availableFilters$.pipe(
      startWith([]),
      map(filterList => createFilterLookupList(filterList)),
    ).subscribe(lookupList => this.lookupList$.next(lookupList));
  }
}

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Resource } from '@techniek-team/api-platform';
import { PostSubTrackRequest } from '../../../api/sub-track/post-sub-track.request';
import { GetTrackResponse, PurchaseCountInterface } from '../../../api/track/track.response';

// Compiler will infer the type
//eslint-disable-next-line @typescript-eslint/typedef
export const trackActions = createActionGroup({
  source: 'Track',
  events: {
    'Init Tracks': props<{ onlyUpcomingTracks: boolean }>(),
    'Refresh Tracks': emptyProps(),
    'Load Tracks Success': props<{ tracks: Resource<GetTrackResponse>[] }>(),
    'Load Tracks Failure': props<{ error: Error }>(),
    'Set Active Track': props<{ track: string | null }>(),
    'Load Active Track Success': props<{ track: Resource<GetTrackResponse> }>(),
    'Load Active Track Failure': props<{ error: Error }>(),
    'Refresh Active Track': emptyProps(),
    'Add New Sub Track': props<{ request: PostSubTrackRequest }>(),
    'Add New Sub Track Success': props<{
      newTrack: Resource<GetTrackResponse>;
    }>(),
    'Add New Sub Track Failure': props<{ error: Error }>(),
    'Load Purchase Count For ExamTraining Success': props<{
      purchaseCounts: PurchaseCountInterface[];
    }>(),
    'Load Purchase Count For ExamTraining Failure': props<{ error: Error }>(),
  },
});

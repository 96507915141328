import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageColor } from '@school-dashboard/enums';
import { ApiPlatformError, denormalize } from '@techniek-team/class-transformer';
import { ApiErrorExtractorBase } from '../../functions/api-error-extractor-base.function';
import { ResponseMessageKey } from '../response-message/response-message.enum';
import { MarkupDisplay, ResponseMessage, ResponseMessageMarkup } from '../response-message/response-message.model';

@Injectable()
export class PupilUploadApiErrors extends ApiErrorExtractorBase {
  /**
   * @inheritDoc
   */
  public extractError(
    response: HttpErrorResponse,
    fileName: string,
  ): ResponseMessage | null {
    if (response?.status === 403) {
      return denormalize(ResponseMessage, {
        key: ResponseMessageKey.PUPIL_FILE_UPLOAD,
        type: MessageColor.DANGER,
        message: this.invalidRights(),
      });
    }

    const error: ApiPlatformError | null = this.getApiPlatformError(response);
    if (!error) {
      return null;
    }

    return denormalize(ResponseMessage, {
      key: ResponseMessageKey.PUPIL_FILE_UPLOAD,
      type: MessageColor.DANGER,
      message: this.matchError(error, fileName),
    });
  }

  /**
   * @inheritDoc
   */
  public matchError(
    error: ApiPlatformError,
    fileName: string,
  ): ResponseMessageMarkup[] {
    const unableBase: ResponseMessageMarkup[] = [
      {
        value: `Het bestand "${fileName}" kon niet worden geüpload.`,
        display: MarkupDisplay.BLOCK,
        newline: true,
      },
    ];

    switch (true) {
      case error.description.includes('Wrong file format'):
        return this.wrongFileFormat(fileName);
      case error.description.includes('Uploaded file has more than one sheet.'):
        return this.multipleSheets(fileName);
      case error.description.includes(
        'headers in Excel spreadsheet expected, got',
      ):
        return [...unableBase, ...this.invalidHeaderCount(error)];
      case error.description.includes('The header(s) with name'):
        return [...unableBase, ...this.missingHeaders(error)];
      case error.description.includes('The sheet has hidden rows:'):
        return [...unableBase, ...this.hiddenRows(error)];
      case error.description.includes('Cells in the column(s)'):
        return [...unableBase, ...this.emptyCellInColumn(error)];
      case error.description.includes('Could not find prefix header.'):
        return this.missingPrefixHeader(fileName);
      case error.description.includes('Unknown error'):
      default:
        return [...unableBase, ...this.unknownError()];
    }
  }

  /**
   * Some people assume too much.
   */
  private invalidRights(): ResponseMessageMarkup[] {
    return [
      {
        value: 'U heeft niet de juiste rechten.',
        styleBold: true,
        display: MarkupDisplay.BLOCK,
        newline: true,
      },
      {
        value:
          'De huidige rechten op uw gebruiker laten alleen toe dat u data inziet, '
          + 'maar niet kunt aanpassen of toevoegen.',
        display: MarkupDisplay.BLOCK,
      },
    ];
  }

  /**
   * Create a message for the wrong file format, with the supported formats in
   * a nice, bold wrapping.
   */
  private wrongFileFormat(fileName: string): ResponseMessageMarkup[] {
    return [
      { value: `Het bestand "${fileName}" kon niet worden geüpload. Alleen` },
      { value: '\'.xlsx\'', styleBold: true },
      { value: ',' },
      { value: '\'.xls\'', styleBold: true },
      { value: 'en' },
      { value: '\'.csv\'', styleBold: true },
      { value: 'bestanden worden ondersteund.' },
    ];
  }

  /**
   * Only one worksheet is allowed in a file.
   */
  private multipleSheets(fileName: string): ResponseMessageMarkup[] {
    return [
      {
        value:
          `Het bestand "${fileName}" kon niet worden geüpload. Het bestand mag`
          + ' maar 1 tabblad hebben.',
      },
    ];
  }

  /**
   * If more than the required headers are found, the user is expected to remove
   * them.
   */
  private invalidHeaderCount(error: ApiPlatformError): ResponseMessageMarkup[] {
    const split: string[] = error.description.split(
      ' headers in Excel spreadsheet expected, got ',
    );
    const expected: string = split[0];
    const gotten: string = split[1].split('.')[0];

    return [
      { value: 'Er worden' },
      { value: expected, styleBold: true },
      { value: 'kolommen verwacht, maar er zijn er' },
      { value: gotten, styleBold: true },
      { value: 'gevonden.' },
    ];
  }

  /**
   * Kindly mention that the spreadsheet is missing one or more headers that are
   * required.
   */
  private missingHeaders(error: ApiPlatformError): ResponseMessageMarkup[] {
    const split: string[] = error.description
      .split('The header(s) with name ')[1]
      .split(' are missing.')[0]
      .split(', ');
    return [
      {
        value: split.length > 1 ? 'De volgende kolommen worden niet gevonden' : 'De volgende kolom wordt niet gevonden',
        styleBold: true,
        display: MarkupDisplay.BLOCK,
        newline: true,
      },
      ...split.map((item: string) => {
        return {
          value: item.charAt(0).toUpperCase() + item.slice(1),
          display: MarkupDisplay.BLOCK,
          indent: true,
          bullet: true,
        };
      }),
    ];
  }

  /**
   * If a cell is empty in any of the columns, this error will tell the user, but
   * only for that single cell.
   */
  private emptyCellInColumn(error: ApiPlatformError): ResponseMessageMarkup[] {
    const split: string[] = error.description
      .split('Cells in the column(s) ')[1]
      .split(' cannot be empty.')[0]
      .split(', ');

    return [
      {
        value: split.length > 1 ? 'De volgende kolommen hebben lege cellen:' : 'De volgende kolom heeft lege cellen:',
        styleBold: true,
        display: MarkupDisplay.BLOCK,
        newline: true,
      },
      ...split.map((item: string) => {
        return {
          value: item.charAt(0).toUpperCase() + item.slice(1),
          display: MarkupDisplay.BLOCK,
          indent: true,
          bullet: true,
        };
      }),
    ];
  }

  /**
   * If the file contains rows that have been hidden in Excel, the user will be
   * reminded.
   */
  private hiddenRows(error: ApiPlatformError): ResponseMessageMarkup[] {
    const split: string[] = error.description.split('The sheet has hidden rows: ')[1].split(', ');

    return [
      {
        value: 'Verborgen rijen zijn niet toegestaan. Maak deze zichtbaar of verwijder ze.',
        display: MarkupDisplay.BLOCK,
        newline: true,
      },
      {
        value: split.length > 1 ? 'De volgende rijen zijn verborgen' : 'De volgende rij is verborgen:',
        styleBold: true,
        display: split.length > 1 ? MarkupDisplay.BLOCK : undefined,
      },
      ...split.reduce(
        (combined: ResponseMessageMarkup[], item: string, index: number) => {
          if (index !== 0 && index + 1 !== split.length) {
            combined.push({ value: ',' });
          }

          if (index + 1 === split.length && index > 0) {
            combined.push({ value: 'en' });
          }

          combined.push({
            value: item.replace(/\s/g, ''),
            styleBold: true,
          });
          return combined;
        },
        [],
      ),
    ];
  }

  /**
   * Even though last name prefix can be empty, it should still exist in the list
   * of columns, so prefixes and last names aren't joined in a single cell.
   */
  private missingPrefixHeader(fileName: string): ResponseMessageMarkup[] {
    return [
      {
        value:
          `Het bestand "${fileName}" kon niet worden geüpload. De kolom voor het`
          + ' tussenvoegsel kon niet worden gevonden.',
      },
    ];
  }

  /**
   * If all else fails, show an unknown error has occurred.
   */
  private unknownError(): ResponseMessageMarkup[] {
    return [
      {
        value: 'Er is een onbekende fout opgetreden.',
        styleBold: true,
        display: MarkupDisplay.BLOCK,
      },
    ];
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@school-dashboard/environments';
import { ApiPlatformError, denormalize } from '@techniek-team/class-transformer';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export enum EmailValidityType {
  VALID = 'VALID',
  INVALID = 'INVALID',
  IN_USE = 'IN_USE',
  IN_SAME = 'IN_SAME',
  OTHER = 'OTHER',
}

@Injectable()
export class EmailAddressApi {
  constructor(private http: HttpClient) {
  }

  /**
   * Validate the given email-address for the given location.
   */
  public validateEmailAddress(
    emailAddress: string,
    locationIri: string,
  ): Observable<EmailValidityType> {
    const encoded: string = encodeURIComponent(String(emailAddress.toString()));
    const splitIri: string[] = String(locationIri).toString().split('/api/locations/');
    const idOnly: string | undefined = splitIri.length ? splitIri[1] : undefined;
    if (!idOnly) {
      return EMPTY;
    }

    const url: string = `${environment.coreApiUrl}/api/email-addresses/${encoded}/validate/${idOnly}`;
    return this.http.get<EmailValidityType>(url).pipe(
      catchError((error) => {
        if (error?.error) {
          const apiError: ApiPlatformError = denormalize(ApiPlatformError, error.error as object);
          const description: string = apiError.description;
          const invalid: boolean = description.includes('not a valid email');
          if (invalid) {
            return of(EmailValidityType.INVALID);
          }
          const inUse: boolean = description.includes('already has another location');
          if (inUse) {
            return of(EmailValidityType.IN_USE);
          }
          const inSame: boolean = description.includes('already is linked to this location');
          if (inSame) {
            return of(EmailValidityType.IN_SAME);
          }
        }
        return of(EmailValidityType.OTHER);
      }),
      map((state) => (state === null ? EmailValidityType.VALID : state)),
    );
  }
}

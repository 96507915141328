export enum SignupState {
  TOO_MANY = 'TOO_MANY',
  OK = 'OK',
  ALMOST_FULL = 'ALMOST_FULL',
  TOO_FEW = 'TOO_FEW',
}

export const SUPPORTED_SIGNUP_STATES: SignupState[] = [
  SignupState.TOO_MANY,
  SignupState.OK,
  SignupState.ALMOST_FULL,
  SignupState.TOO_FEW,
];

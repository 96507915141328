import { Track } from '../track.model';

export interface HasPupils {
  /**
   * Maximum amount of LocationMembers that should be assigned to this track.
   * This is not a hard limit.
   */
  maxPupils: number;

  get trackAttendancesRemaining(): number;
}

export function isTrackWithPupils(track: Track | HasPupils): track is HasPupils {
  return 'maxPupils' in track;
}

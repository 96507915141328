import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FromEventService } from '../../services/from-event/from-event.service';

export interface Widths {
  leftWidth?: number;
  centerWidth?: number;
  rightWidth?: number;
}

/**
 * The position of each of the containers.
 */
enum Position {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

/**
 * The actions this modal can expose.
 * @see SchoolDashboardModalComponent.action
 */
export enum ModalAction {
  CLOSE = 'CLOSE',
  CANCEL = 'CANCEL',
  OK = 'OK',
}

/**
 * Different states for the modal's different buttons, to allow more flexibility
 * for displaying and state management.
 *
 * - ENABLED: Default state. CANCEL action will dismiss the modal.
 * - LOADING: Disabled with spinner.
 * - DISABLED: Plain disabled.
 * - WARNING: Any CANCEL action will not dismiss the modal.
 */
export enum ModalButtonState {
  ENABLED = 'ENABLED',
  LOADING = 'LOADING',
  DISABLED = 'DISABLED',
  WARNING = 'WARNING',
}

/**
 * Interface with an action and the active data to send down the dismiss tubes.
 */
export interface ModalDismiss {
  action: ModalAction;
  data?: unknown;
}

/**
 * Joint type for the modal dismiss emitter.
 */
export type ModalDismissEmitter =
  | Subject<ModalAction | ModalDismiss>
  | EventEmitter<ModalAction | ModalDismiss>;

/**
 * A generic implementation to be used within a modal. You can determine how
 * many containers you want and which data you want inside of them. There are
 * a total of 3 containers (left, center, right) and also a footer that will
 * be displayed if an action and/or cancel button should be displayed.
 *
 * @example `
 * <app-school-dashboard-modal [widths]="{ leftWidth: 20 }"
 *                             actionButtonText="Save"
 *                             cancelButtonText="Cancel">
 *   <div position-left>My awesome content</div>
 *   <div position-right>Am I right?</div>
 * </app-school-dashboard-modal>`
 *
 * If you want control over when the user can click buttons and what the state
 * of those buttons should be, you can!
 *
 * @example `
 * <app-school-dashboard-modal [widths]="{ leftWidth: 20 }"
 *                             [actionButtonState]="_myState | async"
 *                             [dismiss]="_myEventEmitter"
 *                             (action)="myActionHandler($event)"
 *                             actionButtonText="Save"
 *                             cancelButtonText="Cancel">
 *   <div position-left>My awesome content</div>
 *   <div position-right>Am I right?</div>
 * </app-school-dashboard-modal>`
 *
 * With the inputs for the action, cancel and close button states you can set
 * the possible actions the user can do and see by using a ModalButtonState.
 * These states are sent back to the component via the `action` output. If the
 * button is ENABLED, the dialog is closed. If not, use the callback from the
 * `dismiss` emitter input.
 *
 * The `dismiss` emitter is a fancy callback to the underlying component that
 * uses the dashboard modal and should be used to convert the `actions` output
 * values to events, like a cancel action that prompts the user with an "are you
 * sure you want to close" alert. It's also used to fill the data for the modal
 * controller's dismiss function:
 *
 * @example `
 *  // Send a simple action.
 *  this._myDismiss.next(ModalAction.CANCEL);
 *  // Send an action and data.
 *  this._myDismiss.next({ action: ModalAction.OK, data: myData });`
 */
@Component({
  selector: 'app-school-dashboard-modal',
  templateUrl: './school-dashboard-modal.component.html',
  styleUrls: ['./school-dashboard-modal.component.scss'],
})
export class SchoolDashboardModalComponent
implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  /**
   * The container for the left element.
   * This is used to determine if it has any content, so we know if we might
   * have to assign any leftover width to this element.
   */
  @ViewChild('leftContainer', { static: true }) public left: ElementRef;

  /**
   * The container for the center element.
   * This is used to determine if it has any content, so we know if we might
   * have to assign any leftover width to this element.
   */
  @ViewChild('centerContainer', { static: true }) public center: ElementRef;

  /**
   * The container for the right element.
   * This is used to determine if it has any content, so we know if we might
   * have to assign any leftover width to this element.
   */
  @ViewChild('rightContainer', { static: true }) public right: ElementRef;

  /**
   * Set the widths for each of the containers (left, center, right).
   */
  @Input() public widths: Widths | null;

  /**
   * Set the close icon that should be displayed in the upper right corner.
   */
  @Input() public set closeIcon(icon: IconProp | undefined) {
    if (!icon) {
      return;
    }

    this._closeIcon = icon;
  }

  /**
   * Whether to emit the data property sent by the `dismiss` emitter by default.
   */
  @Input() public emitDataOnAction: boolean = false;

  /**
   * The text to display on the action button.
   * When this text is passed the footer of the modal will be shown including
   * the action button on the right.
   */
  @Input() public actionButtonText: string | undefined;

  /**
   * The text to display on the cancel button.
   * When this text is passed the footer of the modal will be shown including
   * the cancel button on the left.
   */
  @Input() public cancelButtonText: string | undefined;

  /**
   * Change the state of the Action Button for this modal.
   */
  @Input() public actionButtonState: ModalButtonState | null = ModalButtonState.ENABLED;

  /**
   * Change the state of the Cancel Button for this modal.
   */
  @Input() public cancelButtonState: ModalButtonState | null = ModalButtonState.ENABLED;

  /**
   * Change the state of the Close (X) Button for this modal.
   */
  @Input() public closeButtonState: ModalButtonState | null = ModalButtonState.ENABLED;

  /**
   * Set an emitter that can be used to manage modal dismiss functionality and
   * send along the data from the ModalDismiss interface, if so desired.
   */
  @Input() public set dismiss(emitter: ModalDismissEmitter) {
    // If an emitter is already set, make sure it's completed first.
    this.destroy$.next();

    if (emitter) {
      emitter
        .pipe(takeUntil(this.destroy$))
        .subscribe((action) => this.close(action));
    }
  }

  /**
   * This modal can expose different actions. This depends on what buttons the
   * user is clicking. OK is sent when the Action button is pressed. CANCEL is
   * sent when the X or Cancel button are pressed.
   */
  @Output() public readonly action: EventEmitter<ModalAction> = new EventEmitter<ModalAction>();

  /**
   * The current width of the left container.
   * This is a number between 0 and 100, or it is undefined when no content for
   * 'position-left' is specified.
   */
  public _leftWidth: number;

  /**
   * The current width of the center container.
   * This is a number between 0 and 100, or it is undefined when no content for
   * 'position-center' is specified.
   */
  public _centerWidth: number;

  /**
   * The current width of the right container.
   * This is a number between 0 and 100, or it is undefined when no content for
   * 'position-right' is specified.
   */
  public _rightWidth: number;

  /**
   * The close icon that should be displayed in the upper right corner.
   */
  public _closeIcon: IconProp = ['far', 'xmark'];

  /**
   * Enum exposed to template.
   */
  public readonly ModalButtonState: typeof ModalButtonState = ModalButtonState;

  /**
   * Yeet the hot dismiss emitter.
   */
  private destroy$: Subject<void> = new Subject();

  constructor(
    private modalController: ModalController,
    private fromEventService: FromEventService,
  ) {}

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.createKeyupEscapeSubscriber();
  }

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    if (this.widths) {
      this.setComponentWidths(this.widths as Widths);
    }
  }

  /**
   * @inheritDoc
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if ('widths' in changes && this.widths) {
      this.setComponentWidths(this.widths as Widths);
    }
  }

  /**
   * If the widths are set, do certain checks to make sure it can render the
   * component correctly. It will:
   * - Check if elements are passed for the passed widths.
   * - Check if the widths passed are valid.
   * - Check if the total amount of width doesn't exceed 100 (percent).
   * - Assigns the remainder of the width existing elements that didn't have
   * a width set by explicitly passing it.
   */
  private setComponentWidths(widths: Widths): void {
    this.checkElementsExistForWidths(widths);
    this.checkWidthsAreValid(widths);
    this.checkTotalWidthIsValid(widths);
    const widthRemaining: number = this.assignWidthToElements(widths);
    this.assignRemainingWidthToElements(widthRemaining);
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Whether the cancel button can be clicked or not, i.e. it's disabled or
   * loading.
   */
  public get cancelDisabled(): boolean {
    return (
      this.cancelButtonState === ModalButtonState.DISABLED
      || this.cancelButtonState === ModalButtonState.LOADING
    );
  }

  /**
   * Whether the close button can be clicked or not, i.e. it's disabled or
   * loading.
   */
  public get closeDisabled(): boolean {
    return (
      this.closeButtonState === ModalButtonState.DISABLED
      || this.closeButtonState === ModalButtonState.LOADING
    );
  }

  /**
   * Whether the action button can be clicked or not, i.e. it's disabled or
   * loading.
   */
  public get actionDisabled(): boolean {
    return (
      this.actionButtonState === ModalButtonState.DISABLED
      || this.actionButtonState === ModalButtonState.LOADING
    );
  }

  /**
   * Get any combination of more than 1 column in the modal. If so, show the
   * border above the actions. If not, hide it.
   */
  public get footerBorder(): boolean {
    return (
      (!!this._leftWidth && (!!this._centerWidth || !!this._rightWidth))
      || (!!this._centerWidth && !!this._rightWidth)
    );
  }

  /**
   * Close the modal. Check for data and if the data should be dismissed along
   * with the modal before dismissing.
   */
  public close(dismiss: ModalAction | ModalDismiss): void {
    let data: unknown;
    const action: ModalAction | ModalDismiss = (dismiss as ModalDismiss)?.action ?? dismiss;

    if (action === ModalAction.OK || this.emitDataOnAction) {
      data = (dismiss as ModalDismiss)?.data ?? undefined;
    }
    this.modalController.dismiss(data);
  }

  /**
   * Emit the CANCEL action. If the close or cancel button has the button state
   * WARNING, the modal will not be dismissed.
   */
  public emitCancel(close: boolean = false): void {
    const state: ModalButtonState | null = close ? this.closeButtonState : this.cancelButtonState;
    if (state === ModalButtonState.ENABLED) {
      this.close(close ? ModalAction.CLOSE : ModalAction.CANCEL);
    }
    this.action.emit(close ? ModalAction.CLOSE : ModalAction.CANCEL);
  }

  /**
   * Emit the OK action.
   */
  public emitOk(): void {
    if (this.actionButtonState === ModalButtonState.ENABLED) {
      this.close(ModalAction.OK);
    }
    this.action.emit(ModalAction.OK);
  }

  /**
   * Listen to the escape button press and act accordingly.
   */
  private createKeyupEscapeSubscriber(): void {
    this.fromEventService
      .watch('keyup', 'Escape')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.emitCancel());
  }

  /**
   * Check if an element exists when a width has been passed for that element.
   * This will be checked for all the elements (left, center and right).
   */
  private checkElementsExistForWidths(widths: Widths): void {
    if (!this.left.nativeElement.children.length && widths.leftWidth) {
      throw new Error(
        'An element with \'position-left\' wasn\'t found, but a width for this element was passed!',
      );
    }

    if (!this.center.nativeElement.children.length && widths.centerWidth) {
      throw new Error(
        'An element with \'position-center\' wasn\'t found, but a width for this element was passed!',
      );
    }

    if (!this.right.nativeElement.children.length && widths.rightWidth) {
      throw new Error(
        'An element with \'position-right\' wasn\'t found, but a width for this element was passed!',
      );
    }
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Check if the given widths are valid ones. A width is valid when it is
   * between 0 and 100 (including those values).
   */
  private checkWidthsAreValid(widths: Widths): void {
    if (widths.leftWidth && (widths.leftWidth < 0 || widths.leftWidth > 100)) {
      throw new Error(
        `Invalid width for the left element! It should be between 0 and 100. Currently: '${widths.leftWidth}'`,
      );
    }

    if (
      widths.centerWidth
      && (widths.centerWidth < 0 || widths.centerWidth > 100)
    ) {
      throw new Error(
        `Invalid width for the center element! It should be between 0 and 100. Currently: '${widths.centerWidth}'`,
      );
    }

    if (
      widths.rightWidth
      && (widths.rightWidth < 0 || widths.rightWidth > 100)
    ) {
      throw new Error(
        `Invalid width for the right element! It should be between 0 and 100. Currently: '${widths.rightWidth}'`,
      );
    }
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Check if the total width is a valid number. The total width can't exceed
   * 100 (percent).
   */
  private checkTotalWidthIsValid(widths: Widths): void {
    const totalAssignedWidth: number = (widths.leftWidth || 0)
      + (widths.centerWidth || 0)
      + (widths.rightWidth || 0);
    if (totalAssignedWidth > 100) {
      throw new Error(
        `The total assigned width for the elements (${totalAssignedWidth}) exceeds the maximum of 100.`,
      );
    }
  }

  /**
   * Assign the passed widths to the elements. The widths have been checked
   * for validity already.
   */
  private assignWidthToElements(widths: Widths): number {
    let widthRemaining: number = 100;

    if (widths.leftWidth || widths.leftWidth === 0) {
      this._leftWidth = widths.leftWidth;
      widthRemaining = widthRemaining - widths.leftWidth;
    }

    if (widths.centerWidth || widths.centerWidth === 0) {
      this._centerWidth = widths.centerWidth;
      widthRemaining = widthRemaining - widths.centerWidth;
    }

    if (widths.rightWidth || widths.rightWidth === 0) {
      this._rightWidth = widths.rightWidth;
      widthRemaining = widthRemaining - widths.rightWidth;
    }

    return widthRemaining;
  }

  /**
   * Assign the remaining width to visible elements that not have a set width
   * yet.
   *
   * Example: If there is a left and a right element. And only the left element
   * has a set width of 40 (percent). Then the right element should get the
   * remaining 60 (percent). The center element shouldn't be visible, so it will
   * not be taken into account when assigning the remaining width.
   */
  //eslint-disable-next-line complexity
  private assignRemainingWidthToElements(widthRemaining: number): void {
    let positionsWithUnassignedWidth: Position[] = [];

    // Check if elements exist to make sure we only assign width to elements
    // that should be shown.
    if (this.left.nativeElement.children.length && !this._leftWidth && this._leftWidth !== 0) {
      positionsWithUnassignedWidth.push(Position.LEFT);
    }

    if (this.center.nativeElement.children.length && !this._centerWidth && this._centerWidth !== 0) {
      positionsWithUnassignedWidth.push(Position.CENTER);
    }

    if (this.right.nativeElement.children.length && !this._rightWidth && this._rightWidth !== 0) {
      positionsWithUnassignedWidth.push(Position.RIGHT);
    }

    const evenlyDistributedWidth: number = widthRemaining / positionsWithUnassignedWidth.length;

    // Assign width only to elements that need it.
    for (const position of positionsWithUnassignedWidth) {
      if (position === Position.LEFT) {
        this._leftWidth = evenlyDistributedWidth;
      }

      if (position === Position.CENTER) {
        this._centerWidth = evenlyDistributedWidth;
      }

      if (position === Position.RIGHT) {
        this._rightWidth = evenlyDistributedWidth;
      }
    }
  }
}

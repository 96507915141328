import { JsonLd } from '@techniek-team/class-transformer';
import { Expose } from 'class-transformer';

export class PersonMinimal extends JsonLd {
  //eslint-disable-next-line @typescript-eslint/naming-convention
  protected _fullName: string;

  /**
   * Setter for {@see _fullName}.
   */
  @Expose() public set fullName(fullName: string) {
    this._fullName = fullName;
  }

  public get fullName(): string {
    return this._fullName;
  }
}

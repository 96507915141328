import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ProgressData, ProgressServiceInterface } from '../../services/progress/progressServiceInterface';
import {
  ProgressNotificationModalComponent,
} from './progress-notification-modal/progress-notification-modal.component';
import { ProgressNotificationType } from './progress-notification.enum';

@Component({
  selector: 'app-progress-notification',
  templateUrl: './progress-notification.component.html',
  styleUrls: ['./progress-notification.component.scss'],
})
export class ProgressNotificationComponent implements OnInit {
  /**
   * Set a service that keeps track of the progress.
   */
  @Input() public progressService: ProgressServiceInterface;

  /**
   * The data containing information about the progress.
   */
  public _data$: Observable<ProgressData>;

  /**
   * Whether the modal is visible, by joining the internal state and the service
   * based state.
   */
  public isModalVisible$: Observable<boolean>;

  /**
   * Whether the modal is visible or not. When the modal is visible this
   * progress notification is hidden.
   */
  private modalVisibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Enum pass through for progress notification types.
   */
  public readonly ProgressNotificationType: typeof ProgressNotificationType = ProgressNotificationType;

  constructor(private modalController: ModalController) {}

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    if (!this.progressService) {
      throw new Error(
        'A service implementing the ProgressServiceInterface should be passed as \'progressService\'.',
      );
    }

    if (this.progressService.forceProgressModalVisible) {
      this.openModal();
    }

    this._data$ = this.progressService.getData$();
    this.isModalVisible$ = combineLatest([
      this.modalVisibility$,
      this.progressService.hideProgressIndicator$.pipe(
        tap((external) => {
          if (!external && this.progressService.forceProgressModalVisible) {
            this.openModal();
          }
        }),
      ),
    ]).pipe(
      map(([internal, external]) => {
        return internal || external;
      }),
    );
  }

  /**
   * Open a modal with more information about the progress.
   */
  public async openModal(): Promise<void> {
    this.progressService.forceProgressModalVisible = false;
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: ProgressNotificationModalComponent,
      componentProps: { progressService: this.progressService },
      cssClass: 'modal-xs',
    });

    this.modalVisibility$.next(true);
    await modal.present();

    await modal.onWillDismiss();
    this.modalVisibility$.next(false);
  }
}

<app-header></app-header>
<ion-content>
  <ion-grid class="page">
    <ion-row class="page header with-heading above-response">

      <ion-row class="page title">
        <ion-title size="large">Leerlingenbeheer</ion-title>

        <div class="page actions">
          <ion-button
            color="dark"
            fill="outline"
            (click)="refreshPupilDataSource()"
          >
            <fa-icon [icon]="['fas', 'rotate']"></fa-icon>
          </ion-button>

          <ion-button color="dark" fill="outline" (click)="showPupilAddModal()">
            <fa-icon [icon]="['fas', 'user-plus']"></fa-icon>
          </ion-button>

          <div class="icon-only-button-loading">
            <ion-button
              color="dark"
              fill="outline"
              (click)="showPupilUploadModal()"
              [disabled]="isProcessingPupilUpload$ | async"
            >
              <fa-icon [icon]="['fas', 'upload']"></fa-icon>
            </ion-button>

            <ion-spinner
              *ngIf="isProcessingPupilUpload$ | async"
              color="secondary"
            ></ion-spinner>
          </div>
        </div>
      </ion-row>
    </ion-row>

    <ion-row class="page response">
      <tt-response-message
        [watch]="_supportedResponseMessageKeys"
      ></tt-response-message>
    </ion-row>

    <ion-row class="page tabs">
      <div class="page tabs line"></div>
      <ion-segment
        (ionChange)="selectTab($event)"
        color="tertiary"
        [value]="location.path()"
      >
        <ng-container *ngFor="let tab of _tabs">
          <ion-segment-button [value]="tab.route"
            >{{ tab.label }}</ion-segment-button
          >
        </ng-container>
      </ion-segment>
    </ion-row>

    <ion-row class="page content table selectable">
      <ion-router-outlet></ion-router-outlet>
    </ion-row>

    <ng-container *ngIf="canInitAddLocationMembers$ | async">
      <ion-row *ngIf="(dataSource$ | async) as dataSource"
               class="page selection-info">
        <app-table-select-actions [dataSource]="dataSource"></app-table-select-actions>
      </ion-row>
    </ng-container>
  </ion-grid>
</ion-content>

import { Type } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ModalContent } from '../../interfaces/modal-content.interface';
import { ContentName } from './content-name.enum';
import { PersonDetailsComponent } from './person-details/person-details.component';
import { PersonProductsComponent } from './person-products/person-products.component';
import { PupilFormComponent } from './pupil-form/pupil-form.component';

export interface ContentData {
  component: Type<ModalContent>;
}

export interface MenuItemData {
  label: string;
  icons: {
    default: IconProp;
    active: IconProp;
    alt?: IconProp;
  };
}

/**
 * Data representing all the content that is supported.
 * This data is used to show the correct component to the user when a certain
 * 'menu-item' is selected.
 */
export const SUPPORTED_CONTENT_DATA: Map<ContentName, ContentData> = new Map<
ContentName,
ContentData
>([
  [ContentName.PERSON_DETAILS, { component: PersonDetailsComponent }],
  [ContentName.PERSON_PRODUCTS, { component: PersonProductsComponent }],
  [ContentName.PERSON_EDIT, { component: PupilFormComponent }],
]);

/**
 * Data representing all the menu items that are supported.
 */
export const MENU_ITEMS_DATA: Map<ContentName, MenuItemData> = new Map<
ContentName,
MenuItemData
>([
  [
    ContentName.PERSON_DETAILS,
    {
      label: 'Algemeen',
      icons: {
        default: ['fal', 'user'],
        active: ['fas', 'user'],
        alt: ['fas', 'pencil'],
      },
    },
  ],
  // TODO Enable again when the tab gets implemented.
  //  https://gitlab.com/techniek-team/team-nsa/school-dashboard/-/issues/168
  //[
  //  ContentName.PERSON_PRODUCTS,
  //  {
  //    label: 'Trajecten',
  //    icons: {
  //      default: ['fal', 'id-badge'],
  //      active: ['fas', 'id-badge'],
  //    },
  //  },
  //],
]);

<div class="upload-wrapper">
  <label [for]="uuid" class="file-upload" [class.selected]="file">
    <span class="placeholder">{{ file ? file?.name : placeholder }}</span>
    <fa-icon [icon]="['fas', 'file-arrow-up']"></fa-icon>
  </label>
  <input
    [id]="uuid"
    type="file"
    [accept]="accept"
    [disabled]="_isDisabled$ | async"
    #fileInput
  />
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@school-dashboard/environments';
import { SchoolClassExtended } from '@school-dashboard/models';
import { Collection } from '@techniek-team/api-platform';
import { denormalize } from '@techniek-team/class-transformer';
import { CacheService } from '@techniek-team/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CacheIdentifierService } from '../../shared/services/cache-identifier/cache-identifier.service';
import { GetSchoolClassResponse } from './school-class.response';

@Injectable({
  providedIn: 'root',
})
export class SchoolClassApi {
  constructor(
    private httpClient: HttpClient,
    private cache: CacheService,
    private cacheIdentifierService: CacheIdentifierService,
  ) {}

  /**
   * Get SchoolClasses for a given location. Either a full list of classes, or
   * filtered more specifically to LevelYear properties. If no extra filters are
   * set, the results are cached for 30 seconds after the last subscriber ends
   * its subscription.
   */
  public getSchoolClasses(
    locationIri: string,
    level?: string[] | undefined,
    year?: number[] | undefined,
  ): Observable<SchoolClassExtended[]> {
    const url: string = `${environment.coreApiUrl}/api/school-classes`;
    let params: HttpParams = new HttpParams().set('location.id', locationIri);

    if (level) {
      params = params.appendAll({ 'levelYears.schoolLevel[]': level });
    }

    if (year) {
      params = params.appendAll({ 'levelYears.year[]': year });
    }

    const call: Observable<SchoolClassExtended[]> = this.httpClient
      .get<Collection<GetSchoolClassResponse>>(url, { params: params })
      .pipe(map((response) => denormalize(SchoolClassExtended, response)?.toArray() ?? []));

    if (!level && !year) {
      return this.cache.create<SchoolClassExtended[]>(
        this.cacheIdentifierService.getSchoolClassesCacheIdentifier(
          locationIri,
        ),
        call,
        { clearInterval: 30000 },
      );
    }

    return call;
  }
}

<app-school-dashboard-modal [widths]="!!pupilIri ? { leftWidth: 30 } : { leftWidth: 0 }"
                            [actionButtonText]="actionButtonText"
                            [cancelButtonText]="cancelButtonText"
                            [actionButtonState]="buttonService.actionState$ | async"
                            [closeButtonState]="buttonService.closeState$ | async"
                            [cancelButtonState]="buttonService.cancelState$ | async"
                            [dismiss]="buttonService.canDismiss$"
                            (action)="dialogAction($event)">
  <div position-left>
    <app-colored-title *ngIf="(_pupil$ | async) as pupil" color="secondary" [isLoading]="!pupil">
      {{ pupil?.person.fullName }}
    </app-colored-title>

    <ion-list lines="none">
      <ion-item *ngFor="let menuItem of menuItemsData | keyvalue"
                (click)="setActiveContent(menuItem.key)"
                [button]="true"
                [detail]="false"
                [class.active]="getActiveContent(menuItem.key)">
        <fa-icon *ngIf="getActiveContent(menuItem.key); else defaultIcon"
                 [icon]="getActiveIcon(menuItem.key)"
                 [fixedWidth]="true"
                 slot="start">
        </fa-icon>

        <ng-template #defaultIcon>
          <fa-icon [icon]="menuItem.value.icons.default"
                   [fixedWidth]="true"
                   slot="start">
          </fa-icon>
        </ng-template>

        <ion-label>{{ menuItem.value.label }}</ion-label>
      </ion-item>
    </ion-list>
  </div>

  <div position-right>
    <ng-template contentPlaceholder></ng-template>
  </div>
</app-school-dashboard-modal>

import { Track } from '../track.model';

export interface HasDeadline {
  /**
   * The deadline for registering pupils for this Track.
   */
  deadline: Date;
}

export function isTrackWithDeadline(track: Track | HasDeadline): track is HasDeadline {
  return 'deadline' in track;
}

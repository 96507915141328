import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, Validators } from '@angular/forms';
import { LocationsStoreService } from '@school-dashboard/data-access-locations';
import { LocationModel } from '@school-dashboard/models';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, first, map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { EmailAddressApi, EmailValidityType } from '../../../api/email-address/email-address.api';
import { AsyncValidatorState } from '../../functions/validators.function';

/**
 * Email address validation service, providing an AsyncValidator, to use in the
 * creation of a FormControl. The exposed `validity$` and `loading$` Observables
 * give extra options for capturing states and errors. It replaces the default
 * SyncValidators `Validators.required` and `Validators.email`, so those can be
 * left empty.
 *
 * @example ```
 * const control: FormControl = new FormControl(
 *  '', // Default value
 *  [], // SyncValidators
 *  [EmailAddressValidationService.validate()]
 * );```
 */
@Injectable()
export class EmailAddressValidationService {
  /**
   * Email validity state subject.
   */
  private emailValidity$: Subject<EmailValidityType> = new Subject<EmailValidityType>();

  /**
   * Async validation state for managing loading states of the API call.
   */
  private emailApiState$: Subject<AsyncValidatorState> = new Subject<AsyncValidatorState>();

  constructor(
    private emailApi: EmailAddressApi,
    private locationsStoreService: LocationsStoreService,
  ) {}

  /**
   * Get the validity of the email input.
   */
  public get validity$(): Observable<EmailValidityType> {
    return this.emailValidity$.pipe(startWith(EmailValidityType.VALID));
  }

  /**
   * Get the current API state, loading or not.
   */
  public get loading$(): Observable<boolean> {
    return this.emailApiState$.pipe(
      startWith(AsyncValidatorState.CLEAR),
      map((state) => {
        return state === AsyncValidatorState.LOADING;
      }),
    );
  }

  /**
   * Validate the email address based on the Angular email validator and the API
   * validation. The internal states are updated, both loading and validity, to
   * better help the template display the values.
   */
  public validate(originalValue?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      control.valueChanges.pipe(
        debounceTime(350),
        withLatestFrom(this.locationsStoreService.active$),
        switchMap(([emailAddress, location]) =>
          this.setEmailValidityState(
            control,
            originalValue,
            emailAddress,
            location,
          ),
        ),
        first(),
      );
  }

  /**
   * Update the internal state and return the validation error or null.
   */
  private setEmailValidityState(
    control: AbstractControl,
    originalValue: string | undefined,
    emailAddress: string,
    location: LocationModel | undefined,
  ): Observable<ValidationErrors | null> {
    // If there's nothing, not long enough or no location.
    if (!emailAddress || emailAddress.length < 3 || !location) {
      return of({ email: true });
    }
    // Don't do anything if the email address is the original one.
    if (originalValue === emailAddress) {
      this.emailValidity$.next(EmailValidityType.VALID);
      return of(null);
    }
    // Use the Angular validation to do the initial check.
    const invalidEmail: ValidationErrors | null = Validators.email(control);
    if (invalidEmail) {
      this.emailValidity$.next(EmailValidityType.INVALID);
      return of({ email: true });
    }
    // Use the API to check the validity and map it.
    this.emailApiState$.next(AsyncValidatorState.LOADING);
    return this.emailApi
      .validateEmailAddress(emailAddress, location.getIri() as string)
      .pipe(
        map((validity) => {
          this.emailApiState$.next(AsyncValidatorState.CLEAR);
          this.emailValidity$.next(validity as EmailValidityType);
          return validity === EmailValidityType.VALID ? null : { email: true };
        }),
      );
  }
}

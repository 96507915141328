<div class="item" [style.height]="size + 'px'">
  <div class="text">
    <span *ngIf="showPercentage" class="percentage">{{ _value }}%</span>
    <span>{{ text }}</span>
  </div>

  <svg [attr.width]="size" [attr.height]="size" xmlns="http://www.w3.org/2000/svg">
    <g>
      <circle class="background"
              [attr.r]="radius"
              [attr.cy]="size / 2"
              [attr.cx]="size / 2"
              [attr.stroke-width]="strokeWidth"
              fill="none" />

      <circle class="fill"
              [attr.r]="radius"
              [attr.cy]="size / 2"
              [attr.cx]="size / 2"
              [attr.stroke-width]="strokeWidth"
              [style.stroke-dasharray]="circumference"
              [style.stroke-dashoffset]="circumferenceRemaining"
              fill="none" />
    </g>
  </svg>
</div>

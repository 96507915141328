import { CheckType, PractiseTrackVariant, SchoolLevel } from '@school-dashboard/enums';
import { Exclude, Expose } from 'class-transformer';
import { Track } from '../track.model';
import { HasPupils } from './has-pupils.interface';

export class PractiseTrack extends Track implements HasPupils {
  /**
   * @inheritDoc
   */
  @Expose() public maxPupils: number;

  @Expose() public variant: PractiseTrackVariant;

  @Expose() public levels: SchoolLevel[];

  @Expose() public years: number[];

  @Exclude() public canAddTrackAttendances: boolean = true;

  @Exclude() public canDeleteTrackAttendances: boolean = false;

  @Exclude() public canEditTrackAttendances: boolean = false;

  @Exclude() public hasTrainings: boolean = false;

  @Exclude() public canHaveSubTracks: boolean = false;

  @Exclude() public canResendEmailTrackAttendance: boolean = true;

  @Exclude() public availableChecks: Map<CheckType, CheckType[]> = new Map<CheckType, CheckType[]>([
    [CheckType.PUPIL_AMOUNT_DEFINITIVE, []],
    [CheckType.START_DATE_DEFINITIVE, [CheckType.PUPIL_AMOUNT_DEFINITIVE]],
  ]);

  public isExamPractice(): boolean {
    return this.variant === PractiseTrackVariant.EXAM;
  }

  public get trackAttendancesRemaining(): number {
    return this.maxPupils - (this.currentPupils ?? 0);
  }
}

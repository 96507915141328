import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@school-dashboard/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';
import { GetLocationsResponse } from './location.response';

@Injectable({
  providedIn: 'root',
})
export class LocationApi {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  /**
   * Fetch a list of locations. Resource objects with names only.
   */
  public getLocations(): Observable<Collection<GetLocationsResponse>> {
    const url: string = `${environment.dashboardApiUrl}/api/locations`;

    let params: HttpParams = new HttpParams();
    params = params.set('pagination', false);
    params = params.set('withTrack', true);
    params = params.set('order[name]', 'asc');

    return this.httpClient.get<Collection<GetLocationsResponse>>(url, {
      params: params,
    });
  }
}

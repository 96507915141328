import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TtFilterSearchModule } from '@techniek-team/filter-search';
import { TtChipControlComponent } from './tt-chip-control.component';

@NgModule({
  declarations: [TtChipControlComponent],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    TtFilterSearchModule,
  ],
  exports: [
    TtChipControlComponent,
  ],
})
export class TtChipControlModule { }

import { Injectable } from '@angular/core';
import { Hydra, JsonLd } from '@techniek-team/class-transformer';
import { consoleInDev } from '@techniek-team/common';
import { FetchObservable } from '@techniek-team/rxjs';
import { CacheService } from '@techniek-team/services';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Fetch } from '../decorators/fetch.decorator';
import { UpdatableFetchStorageInterface } from './fetch-storage.interface';

type HydraOrMap<T extends JsonLd> = Hydra<T> | Map<string, T>;

@Injectable()
//eslint-disable-next-line max-len
export class CacheServiceFetchStorage<Type extends JsonLd, Contract> implements UpdatableFetchStorageInterface<Contract, Type> {

  /**
   * It will output debug console message when true.
   *
   * The property is set through {@see Reflect} metadata.
   */
  public readonly debug: boolean;

  /**
   * Base url. The combination of this and the properties iri should be a complete
   * absolute url.
   *
   * The property is set through {@see Reflect} metadata.
   */
  public readonly baseUrl: string;


  constructor(private cacheService: CacheService) {
    this.debug = Reflect.getMetadata('ttDebug', Fetch);
    this.baseUrl = Reflect.getMetadata('baseUrl', Fetch);
  }

  public supportsFetch(identifier: string): boolean {
    return this.cacheService.has(identifier);
  }

  public waitForInitialization(): Promise<void> {
    return Promise.resolve();
  }

  public getFetchFromStorage(value: string, obj: unknown, identifier: string): Observable<Type | undefined> {
    return this.cacheService.get<HydraOrMap<Type>, FetchObservable<HydraOrMap<Type>>>(identifier).pipe(
      switchMap((cacheMap) => {
        return of(this.getFromCache(cacheMap, value, obj, identifier));
      }),
    );
  }

  public addFetchedItem(identifier: string, value: Type | Contract): void {
    if (value instanceof JsonLd) {
      return;
    }
    const valueInstance = (value as unknown as Type);

    let observable = this.cacheService.get<HydraOrMap<Type>, FetchObservable<HydraOrMap<Type>>>(identifier);

    observable.pipe(take(1)).subscribe((previousCache => {
      // this is actually tested see test files. But the way its tested it isn't
      // picked up by the code coverage
      /* istanbul ignore next */
      if (previousCache instanceof Hydra) {
        previousCache = previousCache.toMap();
      }

      if (!((previousCache as Map<string, Type>).has(valueInstance.getIri() as string))) {
        this.cacheService.next(identifier, observable.pipe(map(() => {
          (previousCache as Map<string, Type>).set(valueInstance.getIri() as string, valueInstance);
          return previousCache;
        })));
      }
    }));
  }

  /**
   * Checks if the iri can be found in the found cache map and return it. Otherwise,
   * it returns undefined.
   */
  private getFromCache<O>(
    cacheMap: Hydra<Type> | Map<string, Type>,
    value: string,
    obj: O,
    identifier: string,
  ): Type | undefined {
    if (cacheMap instanceof Hydra) {
      cacheMap = cacheMap.toMap();
    }

    if (cacheMap.has(value)) {
      return cacheMap.get(value);
    }

    // if the items isn't in the cache storage it retrieve is from the server
    // and stores the retrieve items in the cache
    consoleInDev(this.debug).debug('Cache map found but Missing cached version of item requesting: ',
      this.baseUrl + value,
      identifier,
      obj,
    );
    return undefined;
  }

}

import { DATE_PIPE_DEFAULT_OPTIONS, DatePipe, DatePipeConfig } from '@angular/common';
import { Inject, LOCALE_ID, Optional, Pipe, PipeTransform } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { isDate as fnsIsDate, isSameDay, isSameMonth, parse } from 'date-fns';
import { TsRangeInterface } from './ts-range.interface';

@Pipe({
  name: 'ttDate',
})
export class TtDateRangePipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Optional() @Inject(DATE_PIPE_DEFAULT_OPTIONS) private datePipeConfig: DatePipeConfig,
  ) {
  }

  public transform(
    value: TsRangeInterface<Date | string> | DateRange<Date> | Date | null,
    format?: string,
    divider?: string,
    shorten?: boolean,
    timezone?: string,
    locale?: string,
  ): string | null;
  public transform(
    value: null | undefined,
    format?: string,
    divider?: string,
    shorten?: boolean,
    timezone?: string,
    locale?: string,
  ): null;
  public transform(
    value: TsRangeInterface<Date | string> | DateRange<Date> | Date | null,
    format?: string,
    divider?: string,
    shorten?: boolean,
    timezone?: string,
    locale?: string,
  ): string | null;
  //eslint-disable-next-line complexity
  public transform(
    value: TsRangeInterface<Date | string> | string | DateRange<Date> | Date | null | undefined,
    format?: string,
    divider: string = ' - ',
    shorten: boolean = false,
    timezone?: string,
    locale?: string,
  ): string | null {
    const datePipe: DatePipe = new DatePipe(locale ?? this.locale, this.datePipeConfig?.timezone, this.datePipeConfig);
    const formatString: string = format ?? this.datePipeConfig.dateFormat as string;

    if (!value) {
      return null;
    }

    if (this.isDate(value) || (typeof value === 'string')) {
      return datePipe.transform(value, format, timezone, locale);
    }
    const startString: string = datePipe.transform(value.start, format, timezone, locale) ?? '';
    const start: Date = this.getDate(value.start as string | Date, formatString);
    const endString: string = datePipe.transform(value.end, format, timezone, locale) ?? '';
    const end: Date = this.getDate(value.end as string | Date, formatString);

    // check if format doesn't contain any smaller than a day
    if (shorten && formatString.match('[^hHmsS]*') && isSameDay(start, end)) {
      return `${startString}`;
    }
    // check if format doesn't contain any smaller than a month
    if (shorten && formatString.match('[^hHmsSd]*') && isSameMonth(start, end)) {
      return `${datePipe.transform(value.start, 'd', timezone, locale)}${divider}${endString}`;
    }

    return `${startString}${divider}${endString}`;
  }

  private isDate(value: unknown): value is Date {
    return fnsIsDate(value);
  }

  private getDate(value: string|Date, format: string): Date {
    return (this.isDate(value)) ? value as Date: parse(value, format, new Date());
  }
}

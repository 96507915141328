import { Expose, Type } from 'class-transformer';
import { Person } from './person.model';
import { Pupil } from './pupil.model';

export class PersonPupil extends Person {
  /**
   * Person with type PUPIL has Pupil model information.
   */
  @Type(() => Pupil)
  @Expose()
  public pupil: Pupil;
}

import { SchoolLevel } from '@school-dashboard/enums';
import { JsonLd } from '@techniek-team/class-transformer';
import { Expose } from 'class-transformer';

export class LevelYear extends JsonLd {
  /**
   * School level for this year. Predefined, enum.
   */
  @Expose() public schoolLevel: SchoolLevel;

  /**
   * Year for this level.
   */
  @Expose() public year: number;
}

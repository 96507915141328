import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USERS_FEATURE_KEY, UsersState } from './users.reducer';

export class UsersSelectors {
  public static readonly selectUsersState = createFeatureSelector<UsersState>(USERS_FEATURE_KEY);

  public static readonly selectUserLoading = createSelector(
    UsersSelectors.selectUsersState,
    (state: UsersState) => state.loading,
  );

  public static readonly selectUserLoaded = createSelector(
    UsersSelectors.selectUsersState,
    (state: UsersState) => state.loaded,
  );

  public static readonly selectUserError = createSelector(
    UsersSelectors.selectUsersState,
    (state: UsersState) => state.error,
  );

  public static readonly selectUser = createSelector(
    UsersSelectors.selectUsersState,
    (state: UsersState) => state.user,
  );
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TtDateRangePipe } from './tt-date-range.pipe';

@NgModule({
  declarations: [
    TtDateRangePipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    TtDateRangePipe,
  ],
})
export class TtDateRangeModule { }

import { Injectable } from '@angular/core';
import { Pupil } from '@school-dashboard/models';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { EMPTY, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, share } from 'rxjs/operators';
import { PupilApi } from '../../../api/pupil/pupil.api';

@Injectable()
export class PupilModalService {
  /**
   * The pupil of which the details are shown within this modal.
   */
  public _pupil$: Observable<Pupil> | undefined;

  constructor(
    private pupilApi: PupilApi,
    private toastService: ToastService,
    private errorHandler: SentryErrorHandler,
  ) {
  }

  /**
   * Get an observable containing a pupil by fetching the pupil by IRI.
   * @param iri The IRI of the pupil.
   * @param refresh If you want to refresh the currently cached pupil.
   */
  public getPupil$(iri: string, refresh: boolean = false): Observable<Pupil> {
    if (!this._pupil$ || refresh) {
      this._pupil$ = this.createPupilObservable(iri);
    }

    return this._pupil$;
  }

  /**
   * Create an observable containing a pupil that we are currently viewing
   * the details of.
   */
  private createPupilObservable(iri: string): Observable<Pupil> {
    return !iri ? EMPTY : this.pupilApi.getPupil(iri).pipe(
      catchError((error) => {
        this.errorHandler.captureError(error);
        this.toastService.error(
          'De leerlinggegevens konden niet worden opgehaald.',
        );

        return of(undefined as never);
      }),
      filter((result) => !!result),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }
}

import { AuthGetProfileResponse } from '../api/auth/auth.response';
import { UserInterface } from '../shared/user/user.interface';

export class LyceoUser implements UserInterface {

  public readonly id: string;

  public readonly firstName: string;

  public readonly middleName?: string;

  public readonly lastName: string;

  public readonly email: string;

  public readonly roles: string[];

  public readonly fullName: string;

  public readonly skoleoId?: string;

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(user: AuthGetProfileResponse, ...args: any[]) {
    this.id = user.person_id as string;
    this.skoleoId = user.id;
    this.firstName = user.first_name;
    this.middleName = user.middle_name;
    this.lastName = user.last_name;
    this.email = user.email;
    this.roles = user.roles;
    this.fullName = this.setFullName(user.full_name);
  }

  private setFullName(given: string | undefined): string {
    if (given) {
      return given;
    }

    if (this.middleName) {
      return `${this.firstName} ${this.middleName} ${this.lastName}`;
    }
    return `${this.firstName} ${this.lastName}`;
  }
}

<mat-form-field appearance="outline"
                class="tt-chip-toggle-autocomplete ion-color-{{color}}"
                [class.hide-input]="replace && _source.selected.length > 0">

  <input matInput
         [formControl]="_autocompleteFormControl"
         #searchStringInput
         [matAutocomplete]="searchAutoComplete"
         (keydown.enter)="select($event, searchStringInput.value)"
         [placeholder]="placeholder" />

  <mat-autocomplete
    autoActiveFirstOption
    class="tt-chip-toggle-autocomplete"
    #searchAutoComplete="matAutocomplete"
    (optionSelected)="select($event)"
    [displayWith]="displayWith">

    <mat-option *ngFor="let option of _source.filteredFilters$ | async"
                [value]="option"
                (click)="select($event, option)">
      <div class="auto-complete-option">
        <ng-container *ngIf="optionRef; else defaultOption">
          <ng-container *ngTemplateOutlet="optionRef; context: { option: option, highlight: searchStringInput.value }"></ng-container>
        </ng-container>

        <ng-template #defaultOption>
          <span [innerHTML]="option.displayText | highlightSearchText: searchStringInput.value"></span>
        </ng-template>
      </div>
    </mat-option>

    <mat-option [disabled]="true"  *ngIf="_autocompleteFormControl.value && !(_source.filteredFilters$ | async)?.length">
      <span>{{ _autocompleteFormControl.value }}</span> is niet gevonden.
    </mat-option>

  </mat-autocomplete>

  <div matSuffix>
    <ng-content select="[slot=end]"></ng-content>
  </div>
</mat-form-field>

<div class="autocomplete selected" [class.hide-input]="replace && _source.selected.length > 0">
  <ng-container *ngIf="_source.selected.length > 0">
    <ion-button *ngFor="let option of _source.selected"
                size="small"
                fill="solid"
                [color]="color"
                (click)="deselect(option)">
      {{ option.displayText }}

      <ion-icon name="close-outline"></ion-icon>
    </ion-button>
  </ng-container>
</div>

<div class="autocomplete unknown" *ngIf="unknown">
  <span>{{ _autocompleteFormControl.value }}</span> is niet gevonden.
</div>

export enum CheckType {
  PUPIL_AMOUNT_DEFINITIVE = 'PUPIL_AMOUNT_DEFINITIVE',
  START_DATE_DEFINITIVE = 'START_DATE_DEFINITIVE',
  SCHOOL_AGREED = 'SCHOOL_AGREED',
}

export enum CheckTypeText {
  PUPIL_AMOUNT_DEFINITIVE = 'Alle leerlingen aangemeld',
  START_DATE_DEFINITIVE = 'Traject is gestart',
  SCHOOL_AGREED = 'School is akkoord',
}

export enum CheckTypeDescription {
  PUPIL_AMOUNT_DEFINITIVE = 'Zijn alle afgesproken leerlingen aangemeld voor dit traject?',
  START_DATE_DEFINITIVE = 'Is dit traject daadwerkelijk gestart?',
  SCHOOL_AGREED = 'Is de school akkoord met dit traject?',
}

export enum CheckTypeConfirmation {
  //eslint-disable-next-line @typescript-eslint/prefer-literal-enum-member
  PUPIL_AMOUNT_DEFINITIVE = '<p>Weet u zeker dat alle afgesproken leerlingen zijn aangemeld voor dit traject?</p>'
    + ' <p>Door deze stap te accorderen staan de aangemelde leerlingen vast en kunnen er voor dit traject geen'
    + ' leerlingen meer worden aangemeld.</p>',
  START_DATE_DEFINITIVE = '<p>Weet u zeker dat het traject is gestart?</p>',
  SCHOOL_AGREED = '<p>Weet u zeker dat de school akkoord is gegaan met dit traject?</p>',
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UsersEffects } from './+state/users.effects';
import { USERS_FEATURE_KEY, usersReducer } from './+state/users.reducer';
import { UsersStoreService } from './users-store.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(USERS_FEATURE_KEY, usersReducer),
    EffectsModule.forFeature([UsersEffects]),
  ],
  providers: [
    UsersStoreService,
  ],
})
export class DataAccessUsersModule {
}

import { NgModule } from '@angular/core';
import { TtMenuModule } from '@techniek-team/components/menu-layout';
import { CoreModule } from '../core/core.module';
import { MenuLayoutPage } from './menu-layout.page';

@NgModule({
  imports: [CoreModule, TtMenuModule],
  declarations: [MenuLayoutPage],
})
export class MenuLayoutPageModule {}

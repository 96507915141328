import { Component, Input, OnInit } from '@angular/core';
import { MessageColor } from '@school-dashboard/enums';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseMessageState } from './response-message.enum';
import { MarkupDisplay, ResponseMessage, ResponseMessageMarkup } from './response-message.model';
import { ResponseMessageService } from './response-message.service';

@Component({
  //eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tt-response-message',
  templateUrl: './response-message.component.html',
  styleUrls: ['./response-message.component.scss'],
})
export class ResponseMessageComponent implements OnInit {
  /**
   * Watch for the given key(s) only.
   */
  @Input() public watch: string | string[];

  /**
   * Enum pass through for response message types.
   */
  public readonly ResponseMessageType: typeof MessageColor = MessageColor;

  /**
   * Enum pass through for response message states.
   */
  public readonly ResponseMessageState: typeof ResponseMessageState = ResponseMessageState;

  /**
   * Enum pass through for response message markup display.
   */
  public readonly MarkupDisplay: typeof MarkupDisplay = MarkupDisplay;

  /**
   * The messages that will be displayed.
   */
  public messages$: Observable<ResponseMessage[]>;

  constructor(private responseService: ResponseMessageService) {}

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.messages$ = this.createMessagesObservable();
  }

  public trackByMarkup(index: number, markup: ResponseMessageMarkup): string {
    return JSON.stringify(markup);
  }

  /**
   * Create a (filtered) list of messages based on the optional watch key input.
   */
  private createMessagesObservable(): Observable<ResponseMessage[]> {
    return this.responseService.messages$.pipe(
      map((messages: ResponseMessage[]) => {
        const watch: string[] = (
          Array.isArray(this.watch) ? this.watch : [this.watch]
        ).filter((item) => !!item);
        return watch.length ? messages.filter((message) => watch.includes(message.key)) : messages;
      }),
    );
  }
}

import { JsonLd, TsRange } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';
import { LevelYear } from './level-year.model';
import { LocationModel } from './location.model';

export class SchoolClass extends JsonLd {
  /**
   * School class name.
   */
  @Expose() public name: string;

  /**
   * Location for this SchoolClass.
   */
  @Type(() => LocationModel)
  @Expose()
  public location: LocationModel;

  /**
   * Validity range.
   */
  @Type(() => TsRange)
  @Expose()
  public validityRange: TsRange;
}

export class SchoolClassExtended extends SchoolClass {
  /**
   * LevelYears associated with this SchoolClass.
   */
  @Type(() => LevelYear)
  @Expose()
  public levelYears: LevelYear[];
}

import { Injectable } from '@angular/core';
import { JsonLd } from '@techniek-team/class-transformer';
import { TtInfiniteScrollPaginationDatasource, TtTableDataSource } from '@techniek-team/datasource';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ScrollSource = TtInfiniteScrollPaginationDatasource<JsonLd & any>;

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectSource = TtTableDataSource<JsonLd & any>;

/**
 * Generic Type for any source that extends TtTableDataSource or
 * TtInfiniteScrollPaginationDatasource.
 */
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnySource = ScrollSource | SelectSource;

// Internal type for the AnySource map.
type SourceMap = Map<string, AnySource>;

/**
 * Service to allow other components to access the same DataSource instance,
 * which can be useful to destroy the DataSource outside the component where it
 * was made, or to access data without having to create another instance.
 */
@Injectable({
  providedIn: 'root',
})
export class DataSourceService {
  /**
   * A map containing all registered sources.
   */
  private sourceMap$: BehaviorSubject<SourceMap> = new BehaviorSubject<SourceMap>(new Map());

  /**
   * Register a datasource with the given name.
   */
  public set(name: string, datasource: AnySource): void {
    const sourceMap: SourceMap = this.sourceMap$.value;
    sourceMap.set(name, datasource);
    this.sourceMap$.next(sourceMap);
  }

  /**
   * Remove existing registered datasource.
   */
  public delete(name: string): void {
    const sourceMap: SourceMap = this.sourceMap$.value;
    sourceMap.delete(name);
    this.sourceMap$.next(sourceMap);
  }

  /**
   * Retrieve a datasource and emit only when it exists. Set `canBeUndefined` to
   * allow an empty return.
   */
  public get<T>(name: string, canBeUndefined?: boolean): Observable<T> {
    return this.sourceMap$.asObservable().pipe(
      map((sourceMap) => sourceMap.get(name) as never as T),
      filter((source) => (canBeUndefined ? true : !!source)),
    );
  }

  /**
   * Simple has check to see if a datasource exists with the given name or not,
   * so creation only has to happen once in certain cases.
   */
  public has(name: string): boolean {
    return this.sourceMap$.value.has(name);
  }

  /**
   * Refresh the named datasource. Add a waiting timer to toggle datasource
   * loading for the given amount of time before the call is made.
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public refresh(name: string, wait: number = 0, ...params: any[]): void {
    const sourceMap: SourceMap = this.sourceMap$.value;
    const datasource: AnySource | undefined = sourceMap.get(name);
    if (!datasource) {
      return;
    }

    if (wait) {
      datasource.clear(true);
      setTimeout(() => {
        datasource.init(...params);
      }, wait);
    } else {
      datasource.clear(true);
      datasource.init(...params);
    }
  }
}

import { Component, Input, OnDestroy } from '@angular/core';
import { fromMutation } from '@techniek-team/rxjs';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { InfiniteScrollContentDirective } from './infinite-scroll-content.directive';

interface BaseStyle {
  style: CSSStyleDeclaration;
}

export interface ElementStyle extends BaseStyle {
  width: number;
  skeleton?: BaseStyle;
}

@Component({
  selector: 'app-infinite-scroll-content',
  templateUrl: './infinite-scroll-content.component.html',
  styleUrls: ['./infinite-scroll-content.component.scss'],
})
export class InfiniteScrollContentComponent implements OnDestroy {
  /**
   * Total infinite scroll skeleton rows to display when loading. Default 5.
   */
  @Input() public set count(amount: string | number) {
    const rows: number = parseInt(String(amount), 10);
    this.rows = isNaN(rows) ? 5 : rows;
  }

  /**
   * Directive input that is applied on the (mat-)table element and used as
   * input to make the elementRef accessible.
   */
  @Input() public set table(element: InfiniteScrollContentDirective) {
    if (!element?.elementRef?.nativeElement) {
      return;
    }

    const table: HTMLTableElement = element.elementRef.nativeElement;

    this.createMutationSubscriber(table);
    this.createResizeSubscriber(table);
  }

  /**
   * The set amount of rows to display, updated with the count setter. Defaults
   * to 5.
   */
  public rows: number = 5;

  /**
   * The column definition for the skeleton cells and the skeleton text items.
   */
  public cells$: BehaviorSubject<ElementStyle[]> = new BehaviorSubject<
  ElementStyle[]
  >([]);

  /**
   * MutationObserver as observable subscription.
   */
  private mutation$: Subscription;

  /**
   * ResizeObserver as observable subscription.
   */
  private resize$: Subscription;

  /**
   * Stored observable that maps the  width or override values to a single
   * grid-template-columns definition string.
   */
  private grid$: Observable<string>;

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.mutation$?.unsubscribe();
    this.resize$?.unsubscribe();
  }

  /**
   * Return the current grid template columns definition, which is created by
   * combining the width of all ElementSize objects defined in the columns$
   * getter stream.
   */
  public get columns$(): Observable<string> {
    if (!this.grid$) {
      this.grid$ = this.cells$.pipe(
        map((columns) => columns.map((column) => column.width + 'px').join(' ')),
      );
    }

    return this.grid$;
  }

  /**
   * Register the table in the MutationObserver, using the fromMutation custom
   * observable.
   */
  private createMutationSubscriber(element: HTMLTableElement): void {
    if (this.mutation$) {
      this.mutation$.unsubscribe();
    }

    this.mutation$ = fromMutation(element)
      .pipe(debounceTime(50))
      .subscribe(() => {
        if (!this.hasFirstRow(element)) {
          return;
        }

        this.setColumns(this.getFirstRow(element));
      });
  }

  /**
   * Register the table in the ResizeObserver, using the fromResize custom
   * observable.
   */
  private createResizeSubscriber(element: HTMLTableElement): void {
    if (this.resize$) {
      this.resize$.unsubscribe();
    }
    // istanbul ignore next
    this.resize$ = fromEvent(window, 'resize')
      .pipe(debounceTime(50))
      .subscribe(() => {
        // istanbul ignore next
        if (!this.hasFirstRow(element)) {
          return;
        }
        // istanbul ignore next
        this.setColumns(this.getFirstRow(element));
      });
  }

  /**
   * Check if there are enough table rows to get the first row that isn't the
   * table header row.
   */
  private hasFirstRow(element: HTMLTableElement): boolean {
    return this.getFirstRow(element) !== undefined;
  }

  /**
   * Get the first table row that isn't a table header row. This should hold the
   * ion-skeleton items initially, but will be used to update the width of the
   * infinite scroll skeletons dynamically when they are rendered and updated.
   */
  private getFirstRow(
    element: HTMLTableElement,
  ): HTMLTableRowElement | undefined {
    const rows: HTMLCollectionOf<HTMLTableRowElement> = element.rows;
    if (rows.length > 1) {
      return rows[1];
    }
  }

  /**
   * Using the row's children, set or update the current columns with the actual
   * height, width, padding and margin for the cell and, if available, the
   * ion-skeleton.
   */
  private setColumns(row: HTMLTableRowElement): void {
    const current: ElementStyle[] | null[] = this.getOrFillColumns(
      this.getCellCount(row),
    );

    let index: number = 0;
    for (const item of current) {
      const size: ElementStyle | null = current[index];
      const element: HTMLTableCellElement = row.children[
        index
      ] as HTMLTableCellElement;
      current[index] = this.getElementSize(element, size);
      index++;
    }

    this.cells$.next(current);
  }

  /**
   * Update an existing ElementSize object, or create a new one if the current
   * parameter is null, by using the computed style and the current clientWidth
   * and clientHeight for the <td> element and, if available, the nested
   * <ion-skeleton-text> element. The values are then divided by the row width
   * to create a percentage and stored as a minmax value.
   */
  private getElementSize(
    element: HTMLTableCellElement,
    current?: ElementStyle,
  ): ElementStyle {
    const styles: CSSStyleDeclaration = window.getComputedStyle(element);
    const size: ElementStyle = current ?? ({} as never);
    size.width = element.clientWidth;
    size.style = styles;

    const skeletor: Element = element.children[0];
    if (skeletor?.tagName?.toLowerCase() === 'ion-skeleton-text') {
      const skeletonStyles: CSSStyleDeclaration = window.getComputedStyle(skeletor);
      size.skeleton = {
        style: skeletonStyles,
      };
    } else {
      if (size.skeleton) {
        delete size.skeleton;
      }
    }

    return size;
  }

  /**
   * Return the current set of ElementSize objects if they exist and have the
   * same length as the given count number, or an array filled with nulls.
   */
  private getOrFillColumns(count: number): ElementStyle[] | null[] {
    const current: ElementStyle[] = this.cells$.value;
    if (!current.length || current.length !== count) {
      return [].constructor(count).fill(null);
    }

    return this.cells$.value;
  }

  /**
   * Extract the exact amount of <td> elements in the row, excluding any
   * annotations or text cells.
   */
  private getCellCount(row: HTMLTableRowElement): number {
    let count: number = 0;
    for (const child of Object.values(row.children)) {
      if (child.tagName.toLowerCase() !== 'td') {
        continue;
      }
      count++;
    }

    return count;
  }
}

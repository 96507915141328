<app-colored-title color="tertiary">Leerlinggegevens</app-colored-title>

<tt-response-message
  [watch]="_supportedResponseMessageKeys"
  class="visible"
></tt-response-message>

<ion-content [scrollY]="true">
  <form *ngIf="activeForm"
        [formGroup]="activeForm">
    <ion-label class="location">Locatie</ion-label>
    <ion-item lines="none" class="location">
      <ion-label>{{ (locationsStoreService.active$ | async)?.name }}</ion-label>
    </ion-item>

    <ion-label class="name">Naam<span class="required">*</span></ion-label>
    <ion-input class="firstname"
               fill="outline"
               type="text"
               aria-label="Voornaam"
               formControlName="firstName"
               placeholder="Voornaam"
               required="true"></ion-input>
    <ion-input class="lastname-prefix"
               fill="outline"
               type="text"
               aria-label="Tussenvoegsel"
               formControlName="lastNamePrefix"
               placeholder="Tussenv."></ion-input>
    <ion-input class="lastname"
               fill="outline"
               type="text"
               aria-label="Achternaam"
               formControlName="lastName"
               placeholder="Achternaam"
               required="true"></ion-input>

    <ion-label class="email">E-mailadres<span class="required">*</span></ion-label>
    <ion-input class="email"
               fill="outline"
               type="text"
               inputmode="email"
               aria-label="E-mailadres"
               formControlName="emailAddress"
               placeholder="E-mailadres"
               required="true"></ion-input>

    <div class="email-icon-container"
         *ngrxLet="checkingEmail$ | async; suspenseTpl: checkingEmail">
      <ng-container [ngSwitch]="emailValid$ | async">
        <fa-icon *ngSwitchCase="'VALID'"
                 class="icon valid"
                 slot="end"
                 [icon]="['fas', 'circle-check']"></fa-icon>
        <fa-icon *ngSwitchCase="'INVALID'"
                 class="icon invalid"
                 slot="end"
                 [icon]="['fas', 'circle-xmark']"></fa-icon>
        <fa-icon *ngSwitchCase="'IN_USE'"
                 class="icon in-use"
                 slot="end"
                 [icon]="['fas', 'triangle-exclamation']"></fa-icon>
        <fa-icon *ngSwitchDefault
                 class="icon"
                 slot="end"
                 [icon]="['fas', 'circle-xmark']"></fa-icon>
      </ng-container>
    </div>
    <ng-template #checkingEmail>
      <div class="email icon loading">
        <ion-spinner></ion-spinner>
      </div>
    </ng-template>
    <ion-label class="gender">Geslacht<span class="required">*</span></ion-label>
    <tt-chip-control class="gender"
                     [source]="_genderFilter"
                     color="dark"
                     formControlName="gender"></tt-chip-control>
    <ion-label class="school-class-name">
      Klas<span class="required">*</span>
    </ion-label>
    <tt-autocomplete-chip-control
      color="dark"
      formControlName="schoolClassName"
      [source]="_schoolClassFilter"
      [multiple]="false"
      [replace]="true"
      placeholder="Geef de klas op"
      [optionRef]="schoolClassOption"
      class="school-class-name inline">
      <ng-template
        #schoolClassOption
        let-option="option"
        let-highlight="highlight">
        <div class="autocomplete-option">
          <span [innerHTML]="option.displayText | highlightSearchText : highlight"></span>
          <span
            *ngIf="!validClassLevelYearCombination(option) && !option"
            class="mismatch">
            Niveau of jaar komt niet overeen.
          </span>
        </div>
      </ng-template>
    </tt-autocomplete-chip-control>
    <ion-label class="school-level">Niveau<span class="required">*</span></ion-label>
    <tt-autocomplete-chip-control color="dark"
                                  formControlName="schoolLevel"
                                  [source]="_levelFilter"
                                  [multiple]="false"
                                  [replace]="true"
                                  class="inline school-level">
    </tt-autocomplete-chip-control>
    <ion-label class="year">Leerjaar<span class="required">*</span></ion-label>
    <tt-autocomplete-chip-control color="dark"
                                  formControlName="year"
                                  [source]="_yearFilter"
                                  [multiple]="false"
                                  [replace]="true"
                                  class="inline year">
    </tt-autocomplete-chip-control>
    <ion-label class="description">Opmerkingen</ion-label>
    <ion-textarea formControlName="description"
                  fill="outline"
                  class="description"
                  placeholder="Extra opmerkingen voor deze leerling"
                  [rows]="4"
                  auto-grow="true">
    </ion-textarea>
    <p class="note required">* Deze velden zijn verplicht</p>
  </form>
</ion-content>

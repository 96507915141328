<ng-container *ngIf="(_data$ | async) as data; else loading">
  <div *ngIf="(isModalVisible$ | async) === false"
       (click)="openModal()"
       class="container ion-activatable ripple-parent"
       [class.danger]="data.type === ProgressNotificationType.DANGER"
       [class.success]="data.type === ProgressNotificationType.SUCCESS"
       [class.in-progress]="data.type === ProgressNotificationType.IN_PROGRESS">
    <div class="icon-container">
      <ng-container [ngSwitch]="data.type">
        <fa-icon *ngSwitchCase="ProgressNotificationType.DANGER" [icon]="['fas', 'exclamation']"></fa-icon>
        <fa-icon *ngSwitchCase="ProgressNotificationType.SUCCESS" [icon]="['fas', 'check']"></fa-icon>
        <app-progress-donut *ngSwitchCase="ProgressNotificationType.IN_PROGRESS"
                            [value]="data.percentageCompleted">
        </app-progress-donut>
      </ng-container>
    </div>

    <div class="text">
      <span class="main" [innerHTML]="data.mainText"></span>
      <span *ngIf="data.subText" class="sub" [innerHTML]="data.subText"></span>
    </div>

    <fa-icon class="expand"
             [icon]="['fas', 'up-right-and-down-left-from-center']"
             [fixedWidth]="true">
    </fa-icon>

    <ion-ripple-effect></ion-ripple-effect>
  </div>
</ng-container>

<ng-template #loading>
  <div class="container loading">
    <div class="icon-container">
      <ion-skeleton-text></ion-skeleton-text>
    </div>

    <div class="text">
      <ion-skeleton-text animated class="main"></ion-skeleton-text>
      <ion-skeleton-text animated class="sub"></ion-skeleton-text>
    </div>

    <div class="expand-placeholder"></div>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { UserServiceInterface } from '@techniek-team/common';
import { isDefined, firstEmitFrom } from '@techniek-team/rxjs';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { AuthApi } from '../../api/auth/auth.api';
import { AuthConfig } from '../../auth.config';
import { LyceoUser } from '../../models/lyceo-user.model';
import { InvalidTokenError } from '../errors/invalid-token.error';
import { ProfileCallFailsError } from '../errors/profile-call-fails.error';
import { OAuthService } from '../oauth/oauth.service';
import { UserInterface } from './user.interface';

@Injectable({
  providedIn: 'root',
})
//eslint-disable-next-line max-len
export class UserService<T extends UserInterface = LyceoUser> implements UserServiceInterface {

  /**
   * BehaviorSubject containing users profile
   */
  private user$: BehaviorSubject<T | undefined> = new BehaviorSubject<T | undefined>(undefined);

  constructor(
    private oauthService: OAuthService,
    private config: AuthConfig<T>,
    private authApi: AuthApi<T>,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
  ) {}

  /**
   * Check whether the profile has been stored in the BehaviorSubject
   * @throws ProfileCallFailsError
   * @throws InvalidTokenError
   */
  public async isAuthenticated(): Promise<boolean> {
    const validToken: boolean = await this.oauthService.hasValidAccessToken();

    if (validToken) {
      let currentUser: T | undefined = this.user$.getValue();

      if (!currentUser) {
        await this.updateProfile(); // throws ProfileCallFailsError
      }

      return true;
    }

    throw new InvalidTokenError();
  }

  public getUser(): Promise<T | undefined> {
    return firstEmitFrom(this.user$.pipe(first()));
  }

  /**
   * Returns the current user
   */
  public currentUser(): Observable<T> {
    return this.user$.pipe(
      isDefined<T>(),
    );
  }

  /**
   * Get all the roles of the current user.
   */
  public getRoles(): Promise<string[]> {
    return firstValueFrom(this.user$.pipe(
      isDefined(),
      map((user: T) => user.roles),
      first(),
    ));
  }

  /**
   * Check if the logged-in user has the provided role
   */
  public hasRole(role: string): boolean {
    const user: T | undefined = this.user$.getValue();

    if (!user) {
      return false;
    }

    return user.roles.indexOf(role) > -1;
  }

  /**
   * Check if the logged-in user has at least one of the provided roles
   */
  public hasOneOfRoles(roles: string[]): boolean {
    const user: T | undefined = this.user$.getValue();

    if (!user) {
      return false;
    }

    return user.roles.some(item => roles.includes(item));
  }

  /**
   * get the loggedIn user object and emit it to the user behaviorSubject
   */
  public async updateProfile(): Promise<T> {
    const newUser: T = await firstValueFrom(this.authApi.getProfile().pipe(
      first(),
      catchError((e) => {
        this.clearUser();
        throw new ProfileCallFailsError(e.error ? e.error.detail : undefined);
      }),
    ));

    this.user$.next(newUser);

    return newUser;
  }

  /**
   * Log the user out or show a toast
   */
  public logout(): Promise<boolean> {
    return firstValueFrom(this.authApi.logout().pipe(
      map(() => true),
      // ignore errors when we log out and the users session
      // already has expired in the backend
      catchError(() => of(false)),
    ), { defaultValue: false });
  }

  /**
   * Check if the user really wants to log out
   */
  public requestIfTheUserWantsToLogOut(): void {
    this.alertCtrl.create({
      header: 'Uitloggen',
      message: 'Weet je zeker dat je wilt uitloggen?',
      buttons: [
        {
          text: 'Nee, bedankt',
          role: 'cancel',
        },
        {
          text: 'Ja, graag',
          //eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          handler: /* istanbul ignore next */ () => this.handleLogoutAction(),
        },
      ],
    }).then(confirm => confirm.present());
  }

  /**
   * Sign the user out of the backend and navigate
   * back to the login page
   */
  public async handleLogoutAction(): Promise<boolean> {
    await this.logout();
    await this.oauthService.clearAuthenticationTokens();
    this.clearUser();
    return this.oauthService.redirectToLoginPage();
  }

  /**
   * Check if the user has all required roles set
   * as data in de {@see routing.module}
   */
  public isGranted(requiredGrants: string[]): boolean {
    const user: T | undefined = this.user$.getValue();
    if (!user) {
      return false;
    }

    // get all the users roles
    const userGrants: Set<string> = new Set(user.roles);

    // check if the user has all provided grants
    const missingGrants: Set<string> = new Set(
      requiredGrants.filter(grant => !userGrants.has(grant)),
    );

    return missingGrants.size === 0;
  }

  /**
   * Clear the user stored in this service
   */
  private clearUser(): void {
    this.user$.next(undefined);
  }
}

import { SelectionModel } from '@angular/cdk/collections';
import { normalizeSearchString } from '@techniek-team/common';
import { Exclude, Expose, Type } from 'class-transformer';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { createFilterLookupList } from '../../functions/create-filter-lookup-list/create-filter-lookup-list.function';
import { AutocompleteFilterApiInterface } from '../../interfaces/filter-api/autocomplete-filter-api.interface';
import { BaseFilterGroup, BaseFilterGroupOptions, FilterGroupInteractionState } from '../base-filter-group.model';
import { Filter } from '../filter/filter.model';


export interface AutoCompleteFilterGroupOptions extends BaseFilterGroupOptions {
  autoCompleteFilterFunction?: AutoCompleteFilterPredicate;
  autoCompletePlaceholder?: string;
  minCharactersSearchQuery?: number;
  allowUnlisted?: boolean;
}

export type AutoCompleteFilterPredicate = (query: string, filters: Filter[]) => Filter[];

// Without this dynamic parameter AOT compiling will fail.
// @see https://angular.io/guide/angular-compiler-options#strictmetadataemit
// @dynamic
export class AutoCompleteFilterGroup extends BaseFilterGroup {

  /**
   * Default implementation for {@see filterPredicate}. It will split on
   * whitespace searching for each segment individually but in the order given.
   *
   * The search will be done on {@see Filter.displayText} since this it the
   * actual text visible to the user.
   *
   * @example
   * Assume that the query param is: `mi title`
   * It wil find the following filters:
   * - `mijn title`
   * - `mijn title van dit fancy boek`
   * - `mijn kant en klare title van dit boek`
   * - `sorry wat was ik aan het vertellen, ojah. mijn kant en klare title echt een title zeker weten`
   * - `ook mi is de title van een dit boek`
   *
   * It doesn't find the following:
   * - `dit is de title: Mijn moeder kwam en zag`
   * - `title`
   * - `mjin title`
   */
  public static defaultFilter<K extends Filter>(query: string, filters: K[]): K[] {
    if (!query || query === '') {
      return filters;
    }

    const segments: string[] = normalizeSearchString(query).split(' ')
      // Remove empty segments.
      .filter((segment: string) => segment && segment !== '')
      // Escaping all special characters.
      .map((segment: string) => segment.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
    if (segments.length === 0) {
      return filters;
    }

    const regex: RegExp = new RegExp(segments.join('.*?'), 'gi');

    return filters.filter(targetFilter => targetFilter.displayText.match(regex));
  }

  /**
   * This function is used to filter the filters. This function is not used when
   * a class uses the {@see AutocompleteFilterApiInterface}. Instead, the query
   * is passed to this function.
   */
  public filterPredicate: AutoCompleteFilterPredicate = AutoCompleteFilterGroup.defaultFilter;

  /**
   * Placeholder value to display in the autocomplete input field.
   */
  public autoCompletePlaceholder: string = 'Zoeken';

  /**
   * Minimum amount of characters needed in the input field before the search
   * is applied.
   */
  public minCharactersSearchQuery: number = 2;

  /**
   * Allow the user to create a new filter item if it's not found in the list of
   * filters supplied in the source.
   */
  public allowUnlisted: boolean = false;

  /**
   * A behavior subject containing the current search query.
   */
  private searchQuery$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  /**
   * A subject which contains the current filter observable.
   *
   * The emitted observable is an observable which emits filters based on the given
   * {@see searchQuery$}. When the user sets the filters property, a new observable
   * through the {@see setFilters} method is pushed to this property.
   */
  //eslint-disable-next-line max-len
  private filteredFiltersObservableSubject: ReplaySubject<Observable<Filter[]>> = new ReplaySubject<Observable<Filter[]>>(1);

  /**
   * Internally accessible filter subject that's used to manipulate the results
   * to the user.
   */
  private filteredFiltersSubject$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);

  /**
   * If an unlisted filter item is allowed to be added, it will be stored here.
   * This BehaviorSubject will be cleared on input change.
   */
  private unlistedFilterItem$: BehaviorSubject<Filter | null> = new BehaviorSubject<Filter | null>(null);

  /**
   * This boolean is set to true the the filtering is done remotely and is used
   * to determine how to create the {@see lookupList$}
   */
  private remoteFiltering: boolean = false;

  constructor(
    value: string,
    filters?: Observable<Filter[]> | Filter[] | AutocompleteFilterApiInterface,
    options?: AutoCompleteFilterGroupOptions,
  ) {
    super();
    this.key = window.crypto.randomUUID();
    this.value = value;
    this.label = options?.label;
    this.position = options?.position;
    this.selection = new SelectionModel<string>(options?.multiple ?? false);
    this.selectableStyle = options?.selectableStyle ?? this.selectableStyle;
    this.allowUnlisted = options?.allowUnlisted ?? false;
    this.interactionState.next(
      //eslint-disable-next-line max-len
      (typeof options?.disabled === 'boolean' && options?.disabled) ? FilterGroupInteractionState.DISABLED : FilterGroupInteractionState.ENABLED,
    );
    this.filterPredicate = options?.autoCompleteFilterFunction ?? AutoCompleteFilterGroup.defaultFilter;
    this.autoCompletePlaceholder = options?.autoCompletePlaceholder ?? this.autoCompletePlaceholder;
    this.minCharactersSearchQuery = options?.minCharactersSearchQuery ?? this.minCharactersSearchQuery;
    this.setFilters(filters ?? []);
    this.createLookupListObserver();
    this.createAvailableFiltersObserver();
  }

  /**
   * Setter for {@see autoCompletePlaceholder}.
   */
  public set placeholder(placeholder: string) {
    this.autoCompletePlaceholder = placeholder;
  }

  /**
   * Getter for {@see autoCompletePlaceholder}.
   */
  public get placeholder(): string {
    return this.autoCompletePlaceholder;
  }

  /**
   * Setter for {@see searchQuery$}.
   */
  public set searchQuery(query: string) {
    this.searchQuery$.next(query);
  }

  /**
   * Get stream for {@see availableFilters$}.
   *
   * Emits a stream of the current filters within this group.
   *
   * @inheritDoc
   */
  @Exclude()
  public override get filters$(): Observable<Filter[]> {
    return this.availableFilters$.asObservable();
  }

  /**
   * Getter for the {@see filteredFiltersSubject$} stream.
   *
   * Emits a stream of the filters within this group filtered by the search
   * query and, if enabled, adds the 'unlisted' item created by the search
   * query, before removing all items that are marked as hidden and/or disabled.
   *
   * @inheritDoc
   */
  @Exclude()
  public get filteredFilters$(): Observable<Filter[]> {
    if (this.allowUnlisted) {
      return combineLatest([
        this.filteredFiltersSubject$,
        this.unlistedFilterItem$,
      ]).pipe(
        map(([filtered, unlisted]) => (unlisted ? [unlisted, ...filtered] : filtered)),
        map((filters) => filters.filter((item) => item.enabled && item.visible)),
      );
    }
    return this.filteredFiltersSubject$.asObservable();
  }

  /**
   * getter for {@see filteredFiltersSubject$}
   *
   * An array of filters within this group filtered by the search query.
   *
   * @inheritDoc
   */
  @Type(() => Filter)
  @Expose()
  public get filteredFilters(): Filter[] {
    const filtered: Filter[] = this.filteredFiltersSubject$.getValue();
    if (this.allowUnlisted) {
      const unlisted: Filter | null = this.unlistedFilterItem$.getValue();
      return unlisted ? [unlisted, ...filtered] : filtered;
    }
    return filtered;
  }

  /**
   * Set the unlisted item for this autocomplete filter, so it can be selected
   * as a proper filter, or clear it.
   */
  public set unlisted(unlisted: Filter | null) {
    if (unlisted) {
      this.setUnlisted(unlisted);
      return;
    }

    this.clearUnlisted();
  }

  /**
   * Get the stored unlisted item for comparison.
   */
  @Exclude() public get unlisted(): Filter | null {
    return this.unlistedFilterItem$.getValue();
  }

  /**
   * Add the unlisted item to the lookup list before updating the filtered
   * streams.
   */
  private setUnlisted(unlisted: Filter): void {
    const lookupList: Map<string, Filter> = this.lookupList$.getValue();
    lookupList.set(unlisted.key, unlisted);
    this.unlistedFilterItem$.next(unlisted);
  }

  /**
   * Remove the unlisted item from the lookup list and clear the unlisted item
   * stream.
   */
  private clearUnlisted(): void {
    if (!this.unlisted) {
      return;
    }
    const lookupList: Map<string, Filter> = this.lookupList$.getValue();
    lookupList.delete(this.unlisted.key);
    this.unlistedFilterItem$.next(null);
  }

  /**
   * @inheritDoc
   */
  protected override setFilters(filtersInput?: Observable<Filter[]> | Filter[] | AutocompleteFilterApiInterface): void {
    let filterObservable: Observable<Filter[]>;
    this.onFilterInputChange.next();
    if (!filtersInput) {
      return;
    }

    if (Array.isArray(filtersInput) || filtersInput instanceof Observable) {
      if (Array.isArray(filtersInput)) {
        filtersInput = of(filtersInput);
      }
      filterObservable = this.setFilterInputFromObservable(filtersInput);
    } else {
      this.remoteFiltering = true;
      filterObservable = this.setFilterInputFromRemote(filtersInput);
    }

    filterObservable.pipe(
      map(filterList => filterList.sort(this.sortFilters)),
    );

    this.filteredFiltersObservableSubject.next(filterObservable);
  }

  /**
   * Create the filtering observable based on the input given and apply filters
   * via this class.
   */
  private setFilterInputFromObservable(filtersInput: Observable<Filter[]>): Observable<Filter[]> {
    filtersInput.pipe(takeUntil(this.onFilterInputChange))
      .pipe(
        tap(filtersList => filtersList.forEach(targetFilter => targetFilter.filterGroup = this)),
      )
      .subscribe(filterList => {
        this.clear();
        this.availableFilters$.next(filterList);
        this.filteredFiltersSubject$.next(filterList);
      });

    return this.searchQuery$.pipe(
      withLatestFrom(this.availableFilters$),
      map(([query, filters]) => this.filterPredicate(query, filters)),
    );
  }

  /**
   * Assume external filtering and just apply the search query to the given API.
   */
  private setFilterInputFromRemote(
    filtersInput: Observable<Filter[]> | Filter[] | AutocompleteFilterApiInterface,
  ): Observable<Filter[]> {
    return this.searchQuery$
      .pipe(
        filter(searchQuery => searchQuery.length >= 2),
        switchMap(query => {
          return (filtersInput as AutocompleteFilterApiInterface).setFiltersByApi(query)
            .pipe(tap(filtersList => filtersList.forEach(targetFilter => targetFilter.filterGroup = this)));
        }),
      );
  }

  /**
   * Method subscribes to the observable within filterFiltersSubject and passes
   * the emits to availableFilters subject.
   */
  private createAvailableFiltersObserver(): void {
    this.filteredFiltersObservableSubject.pipe(
      switchMap(observer => observer),
    ).subscribe(filters => this.filteredFiltersSubject$.next(filters));
  }

  /**
   * Subscribe to {@see availableFilters$} or the remote filter filters list
   * and create a lookup list based on it.
   *
   * In all case where we have a full list of filters$ where using the
   * {@see availableFilters$} to create a complete list. This is however not
   * possible when the filtering is done remotely. In this case we create a
   * lookup list base on the Filtered filters list.
   */
  protected override createLookupListObserver(): void {
    if (this.remoteFiltering) {
      this.filteredFilters$.pipe(
        withLatestFrom(this.selected$.pipe(startWith([]))),
        map(([filteredFilters, _selected]) => {
          return createFilterLookupList(filteredFilters);
        }),
      ).subscribe((lookupList) => {
        this.lookupList$.next(lookupList);
      });
      return;
    }
    this.availableFilters$.pipe(
      startWith([]),
      map(filterList => createFilterLookupList(filterList)),
    ).subscribe(lookupList => this.lookupList$.next(lookupList));
  }
}

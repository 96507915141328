import { Color as IonicColors } from '@ionic/core';

export enum IonColorType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  DARK = 'dark',
  MEDIUM = 'medium',
  LIGHT = 'light',
  LYCEO_DARK_BLUE = 'lyceo-dark-blue',
  LYCEO_RED = 'lyceo-red',
  LYCEO_YELLOW = 'lyceo-yellow',
  LYCEO_GREEN = 'lyceo-green',
}

export type IonColor = IonColorType | IonicColors;

import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { IonColor, IonColorType } from '@techniek-team/lyceo-style';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

export enum TtAvatarShape {
  ROUND = 'ROUND',
  SQUARE = 'SQUARE',
}

@Component({
  selector: 'tt-avatar',
  templateUrl: './tt-avatar.component.html',
  styleUrls: ['./tt-avatar.component.scss'],
})
export class TtAvatarComponent implements OnInit, OnDestroy {

  @Input() public name: string | undefined;

  @Input() public picture: string | undefined;

  @Input() public shape: TtAvatarShape = TtAvatarShape.ROUND;

  /**
   * Color of the avatar.
   */
  @Input() public set color(color: IonColor) {
    if (color) {
      this.colorSubject$.next(color);
    }
  }

  @HostBinding('class.shape-round')
  private get round(): boolean {
    return this.shape === TtAvatarShape.ROUND;
  }

  @HostBinding('class.shape-square')
  private get square(): boolean {
    return this.shape === TtAvatarShape.SQUARE;
  }

  /**
   * Color of the avatar.
   */
  private colorSubject$: BehaviorSubject<IonColor> = new BehaviorSubject<IonColor>(IonColorType.PRIMARY);

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private element: ElementRef, private renderer: Renderer2) {
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.createColorSubscriber();
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private createColorSubscriber(): void {
    this.colorSubject$
      .pipe(takeUntil(this.destroy$))
      .subscribe(color => {
        const previousColorClasses: string[] = this.element.nativeElement.className.split(' ')
          .filter((item: string) => ((item) ? item.match(/ion-color-*./) : false));
        for (let className of previousColorClasses) {
          this.renderer.removeClass(this.element.nativeElement, className);
        }
        this.renderer.addClass(this.element.nativeElement, 'ion-color-'+ (color ?? 'primary'));
      });
  }
}

import { sortDefined } from './sort-defined.function';
import { SortDirection } from './sort-direction.type';
import { compareAsc, compareDesc } from 'date-fns';

export function sortDate(a: Date, b: Date, direction: SortDirection = 'asc'): number {
  const sortDefinedResult: number = sortDefined(a, b, direction);
  if (sortDefinedResult !== 0) {
    return sortDefinedResult;
  }

  return (direction === 'asc') ? compareAsc(a, b) : compareDesc(a, b);
}

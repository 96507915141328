import { Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from '@school-dashboard/environments';
import { LyceoUser, UserService } from '@techniek-team/oauth';
import { SentryEnvironment } from '@techniek-team/sentry-web';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent {
  @ViewChild('icon', { read: ElementRef }) public icon!: ElementRef;

  public user$: Promise<LyceoUser | undefined> = this.userService.getUser();

  public release: string;

  public id: string;

  constructor(
    private userService: UserService,
  ) {
    this.id = window.crypto.randomUUID();
    this.release = environment.release;
    if (environment.environment !== SentryEnvironment.PRODUCTION && this.release === '<<RELEASE>>') {
      this.release = (Math.round(Math.random() * 10)) + '.' + (Math.round(Math.random() * 10)) + '.local';
    }
  }

  /**
   * Logout the user and dismiss the popover.
   */
  public async logout(): Promise<void> {
    await this.userService.handleLogoutAction();
  }

  /**
   * Trigger the click on the icon component so that the popover arrow is in the right position
   */
  public triggerPopover(event: MouseEvent): void {
    if (event.target !== this.icon.nativeElement) {
      this.icon.nativeElement.click();
    }
  }
}

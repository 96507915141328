import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@school-dashboard/environments';
import { UserModel } from '@school-dashboard/models';
import { TtFetchModule } from '@techniek-team/fetch';
import { AuthModule, UserService } from '@techniek-team/oauth';
import { TtPermissionModule } from '@techniek-team/permissions';
import { SentryEnvironment, SentryWebModule } from '@techniek-team/sentry-web';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SchoolDashboardRole } from './core/permission/school-dashboard-role.enum';
import { MenuLayoutPageModule } from './menu-layout/menu-layout.module';
import { UploadFeedbackModule } from './shared/components/upload-feedback-modal/upload-feedback.module';
import { PreFetchService } from './shared/services/pre-fetch.service';

registerLocaleData(localeNl, 'nl');
@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '__school-dashboard',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    SentryWebModule.forRoot({
      environment: environment.environment,
      dsn: 'https://f341ed93fc24409b8a408176e636449e@errors.techniek-team.nl//145',
      autoSessionTracking: true,
      release: environment.release,
      enableTracing: false,
      userService: UserService,
    }),
    AuthModule.forRoot({
      clientId: environment.ssoConfig.clientId,
      profileBaseUrl: environment.ssoConfig.profileBaseUrl,
      ssoBaseUrl: environment.ssoConfig.ssoBaseUrl,
      baseUrl: environment.ssoConfig.baseUrl,
      redirectUri: environment.ssoConfig.redirectUri,
      loginUrl: '/login',
      homeUrl: '/home',
      whitelist: [
        'amazon',
      ],
      model: UserModel,
    }),
    TtPermissionModule.forRoot({
      roleHierarchy: {
        ROLE_SKOLEO_CORE_ADMIN: [
          SchoolDashboardRole.ADMIN,
          'ROLE_SKOLEO_CORE_READ_ONLY_ADMIN',
          'ROLE_SKOLEO_CORE_COORDINATOR',
          'ROLE_SKOLEO_CORE_SCHOOLCONTACT',
        ],
        ROLE_SKOLEO_CORE_READ_ONLY_ADMIN: SchoolDashboardRole.READ_ONLY_ADMIN,
        ROLE_SKOLEO_CORE_COORDINATOR: SchoolDashboardRole.COORDINATOR,
        ROLE_SKOLEO_CORE_SCHOOLCONTACT: SchoolDashboardRole.SCHOOL_CONTACT,
      },
      userService: UserService,
    }),
    TtFetchModule.forRoot({
      baseUrl: environment.dashboardApiUrl,
      preFetchService: PreFetchService,
      debug: false,
    }),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictStateSerializability: false,
          strictActionSerializability: false,
          strictActionWithinNgZone: false,
          strictActionTypeUniqueness: false,
        },
      },
    ),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.environment !== SentryEnvironment.LOCAL, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    CoreModule,
    UploadFeedbackModule,
    MenuLayoutPageModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

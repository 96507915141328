import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IonicModule } from '@ionic/angular';
import { AmountIndicatorBarComponent } from './amount-indicator-bar/amount-indicator-bar.component';
import {
  AmountIndicatorBarComponentProgressBar,
} from './amount-indicator-bar/amount-indicator-progress-bar/amount-indicator-bar-component-progress-bar.component';
import { AmountIndicatorBarsComponent } from './amount-indicator-bars.component';

@NgModule({
  declarations: [
    AmountIndicatorBarsComponent,
    AmountIndicatorBarComponentProgressBar,
    AmountIndicatorBarComponent,
  ],
  imports: [IonicModule, CommonModule, MatTooltipModule],
  exports: [AmountIndicatorBarsComponent, AmountIndicatorBarComponent],
})
export class AmountIndicatorBarsModule {}

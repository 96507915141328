import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

/**
 * This class extends the DataSource class used by CDK Tables with two extra
 * abstract functions that will help with streamlining the way we use these
 * data sources, as well as make them useful outside of use with CDK Tables.
 *
 * Extend this class with your own implementation of `observer$` and `loading$`
 * if you only want to use the DataSource with placeholder items and a loading
 * indicator.
 */
export abstract class TtBaseDataSource<T> extends DataSource<T> {

  /**
   * Getter with an observable with the current dataset.
   */
  public abstract observer$: Observable<T[]>;

  /**
   * Getter containing an observable with the current loading state.
   */
  public abstract loading$: Observable<boolean>;

  /**
   * @inheritDoc
   *
   * Note: the TTBaseDataSource starts the connect stream with a placeholder
   * array of 10 null items, which can be used to show a loading skeleton.
   */
  public connect(collectionViewer: CollectionViewer): Observable<T[] | readonly T[]> {
    return this.observer$.pipe(startWith(this.loadingPlaceholder() as unknown as T[]));
  }

  /**
   * Helper function that returns an array of a certain length (default 10) that
   * can be used as a placeholder preview for skeleton-like loading in the view.
   */
  protected loadingPlaceholder(length: number = 10): null[] {
    return Array.from({ length: length }, () => null);
  }
}

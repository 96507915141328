import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IonInput, Platform } from '@ionic/angular';
import { LocationsStoreService } from '@school-dashboard/data-access-locations';
import { Gender, MessageColor, SchoolLevel, SchoolLevelDisplayValue } from '@school-dashboard/enums';
import { LevelYear, Pupil, SchoolClassExtended } from '@school-dashboard/models';
import { TtAutocompleteChipControl } from '@techniek-team/autocomplete-chip-control';
import {
  AutoCompleteFilterGroup,
  Filter,
  FilterControllerService,
  FilterGroup,
  Trigger,
} from '@techniek-team/filter-search';
import { combineLatest, EMPTY, Observable, Subject, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { EmailValidityType } from '../../../../api/email-address/email-address.api';
import { PupilApi } from '../../../../api/pupil/pupil.api';
import { PatchPupilPerson, PostPupilPerson } from '../../../../api/pupil/pupil.response';
import { SchoolClassApi } from '../../../../api/school-class/school-class.api';
import { maxYearForLevel } from '../../../functions/level.functions';
import { SchoolDashboardValidators } from '../../../functions/validators.function';
import { ModalContentFormInterface } from '../../../interfaces/modal-content.interface';
import {
  EmailAddressValidationService,
} from '../../../services/email-address-validation/email-address-validation.service';
import { ResponseMessageKey } from '../../response-message/response-message.enum';
import { MarkupDisplay, ResponseMessage, ResponseMessageMarkup } from '../../response-message/response-message.model';
import { ResponseMessageService } from '../../response-message/response-message.service';
import { ContentName } from '../content-name.enum';
import { PupilModalService } from '../pupil-modal.service';
import { PupilFormApiErrors } from './pupil-form-api-errors.functions';

interface FilterValue {
  filters: Filter[];
  values: unknown[];
}

@Component({
  selector: 'app-pupil-form',
  templateUrl: './pupil-form.component.html',
  styleUrls: ['./pupil-form.component.scss'],
})
export class PupilFormComponent implements OnInit, AfterViewInit, OnDestroy, ModalContentFormInterface {
  /**
   * List of IonInputs. Used to set the clear-input option.
   */
  @ViewChildren(IonInput) private ionInputs: QueryList<IonInput>;

  /**
   * The Pupil iri.
   */
  @Input() public activeIri: string;

  /**
   * The response message keys that this component can display to the user.
   */
  public _supportedResponseMessageKeys: ResponseMessageKey[] = [
    ResponseMessageKey.PUPIL_CREATE_UPDATE_EMAIL,
    ResponseMessageKey.PUPIL_CREATE_UPDATE_SCHOOL_CLASS,
    ResponseMessageKey.PUPIL_CREATE_UPDATE_LEVEL_YEAR,
    ResponseMessageKey.PUPIL_CREATE_UPDATE_SKOLEO_MESSAGE,
  ];

  /**
   * Content switch trigger subject.
   */
  public componentSwitch$: Subject<ContentName> = new Subject<ContentName>();

  /**
   * Pupil instance when editing. Set by the main dialog.
   */
  @Input() public pupil: Pupil;

  /**
   * Filter items for Gender selection.
   */
  public _genderFilter: Filter[] = [
    new Filter('MALE', { label: 'Man', position: 0 }),
    new Filter('FEMALE', { label: 'Vrouw', position: 1 }),
    new Filter('OTHER', { label: 'Anders', position: 2 }),
  ];

  /**
   * Filter items for Year selection.
   */
  public _yearFilter: AutoCompleteFilterGroup;

  /**
   * Filter items for School Level.
   */
  public _levelFilter: AutoCompleteFilterGroup;

  /**
   * Filter items for the School Class.
   */
  public _schoolClassFilter: AutoCompleteFilterGroup;

  /**
   * FormControl for the pupil form.
   */
  public activeForm: UntypedFormGroup;

  /**
   * Emit a pulse when the component should be ready for the Modal component to
   * listen to various items that should be initialized.
   */
  public componentReady$: Subject<void> = new Subject();

  /**
   * Email validation observer, using the email-address API to display the validity
   * and/or error state of the given input.
   */
  public emailValid$: Observable<EmailValidityType>;

  /**
   * Loading indicator for email-address validation.
   */
  public checkingEmail$: Subject<boolean> = new Subject<boolean>();

  /**
   * Observables too hot? Make them cold with this one simple trick!
   */
  private destroy$: Subject<void> = new Subject();

  constructor(
    public locationsStoreService: LocationsStoreService,
    private emailService: EmailAddressValidationService,
    private pupilApi: PupilApi,
    private pupilModalService: PupilModalService,
    private schoolClassApi: SchoolClassApi,
    private platform: Platform,
    private responseService: ResponseMessageService,
    private filterService: FilterControllerService,
    private apiErrors: PupilFormApiErrors,
  ) {}

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.activeForm = this.createPupilForm();
    this._schoolClassFilter = this.createSchoolClassFilterGroup();
    this._levelFilter = this.createLevelFilterGroup();
    this._yearFilter = this.createYearFilterGroup();
    this.emailValid$ = this.emailService.validity$;

    this.createActivePupilSubscriber();
    this.createSchoolClassLevelYearSubscriber();
    this.createSchoolClassSubscriber();
    this.createEmailAddressValidationSubscriber();

    this.filterService.filterGroups = [
      this._schoolClassFilter,
      this._levelFilter,
      this._yearFilter,
    ];

    this.filterService.registerTrigger(
      'schoolLevel',
      this.createLevelTrigger(),
    );
  }

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    const mobile: boolean = this.platform.is('mobile') || this.platform.is('mobileweb');
    for (const input of this.ionInputs.toArray()) {
      input.clearInput = mobile;
    }

    this.componentReady$.next();
    this.componentReady$.complete();
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.responseService.clearMessage([
      ResponseMessageKey.PUPIL_CREATE_UPDATE_EMAIL,
      ResponseMessageKey.PUPIL_CREATE_UPDATE_SCHOOL_CLASS,
      ResponseMessageKey.PUPIL_CREATE_UPDATE_LEVEL_YEAR,
      ResponseMessageKey.PUPIL_CREATE_UPDATE_SKOLEO_MESSAGE,
    ]);
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * POST or PATCH the given pupil, depending on whether there's a pupilIri
   * available.
   */
  public apiAction(): Observable<Pupil> {
    if (this.activeIri) {
      return this.patchPupil();
    }

    return this.postPupil();
  }

  /**
   * What to do when the apiAction was called successfully.
   */
  public apiActionSuccess(_payload: PatchPupilPerson | PostPupilPerson): void {
    const type: string = this.activeIri ? 'aangepast' : 'toegevoed';

    this.responseService.setMessage(
      ResponseMessageKey.PUPIL_CREATE_UPDATE,
      MessageColor.SUCCESS,
      [
        { value: 'De leerling' },
        {
          styleBold: true,
          value: [
            this.activeForm.get('firstName')?.value,
            this.activeForm.get('lastNamePrefix')?.value,
            this.activeForm.get('lastName')?.value,
          ]
            .filter((values) => !!values)
            .join(' '),
        },
        { value: 'is succesvol ' + type + '.' },
        {
          value: 'Het kan een kort moment duren tot de gegevens zijn bijgewerkt.',
          styleBold: true,
          display: MarkupDisplay.BLOCK,
        },
      ],
      7500,
    );
  }

  /**
   * PATCH an existing pupil.
   */
  private patchPupil(): Observable<Pupil> {
    let payload: PatchPupilPerson = {};

    for (const key in this.activeForm.controls) {
      // Since this can't be tested.
      // istanbul ignore next
      if (!this.activeForm.controls[key]) {
        continue;
      }

      const control: UntypedFormControl = this.activeForm.controls[key] as UntypedFormControl;
      if (control.pristine) {
        continue;
      }

      Object.assign(payload, {
        [key]: this.getValueFromControl(key),
      });
    }

    if (Object.entries(payload).length === 0) {
      return EMPTY;
    }

    return this.locationsStoreService.active$.pipe(
      switchMap((active) => this.pupilApi.patchPupil(this.activeIri, active.getIri() as string, payload)),
      catchError((error) => {
        this.extractError(error);
        return throwError(error);
      }),
    );
  }

  /**
   * POST a new pupil.
   */
  private postPupil(): Observable<Pupil> {
    return this.locationsStoreService.active$.pipe(switchMap(active => {
      const { firstName, lastNamePrefix, lastName, emailAddress, description } = this.activeForm.value;
      const payload: PostPupilPerson = {
        firstName: firstName,
        lastNamePrefix: lastNamePrefix ?? '',
        lastName: lastName,
        gender: this.getValueFromControl('gender') as Gender,
        emailAddress: emailAddress,
        schoolLevel: this.getValueFromControl('schoolLevel') as SchoolLevel,
        schoolClassName: this.getValueFromControl('schoolClassName') as string,
        year: this.getValueFromControl('year') as number,
        description: description ?? '',
        location: active?.getIri() as string,
      };

      return this.pupilApi.postPupil(payload).pipe(
        catchError((error) => {
          this.extractError(error);
          return throwError(error);
        }),
      ) as Observable<Pupil>;
    }));
  }

  /**
   * Determine if the given autocomplete item in the list has the same level and
   * year as the currently selected level and year values. To get the specific
   * state of the validation, set specify to true to get booleans for the
   * [SchoolLevel, SchoolYear] specifically.
   */
  public validClassLevelYearCombination(
    option: Filter,
    specify?: boolean,
  ): boolean | boolean[] {
    const levelValue: string = this.getValueFromControl(
      'schoolLevel',
    ) as string;
    const yearValue: number = this.getValueFromControl('year') as number;
    if (!option || (!levelValue && !yearValue)) {
      return specify ? [true, true] : true;
    }

    const schoolClass: SchoolClassExtended = option.additionalData ?? {};
    const levelYears: LevelYear[] = schoolClass.levelYears ?? [];

    if (specify) {
      return [
        levelYears.some((item) =>
          (levelValue ? item.schoolLevel === levelValue : true),
        ),
        levelYears.some((item) => (yearValue ? item.year === yearValue : true)),
      ] as boolean[];
    }

    return levelYears.some((item) => {
      const { schoolLevel, year } = item;
      const matchLevel: boolean = levelValue ? schoolLevel === levelValue : true;
      const matchYear: boolean = yearValue ? year === yearValue : true;
      return matchLevel && matchYear;
    }) as boolean;
  }

  /**
   * Create the pupil form, matching the payload properties.
   */
  private createPupilForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      firstName: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(2),
      ]),
      lastNamePrefix: new UntypedFormControl(null, [Validators.minLength(2)]),
      lastName: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(2),
      ]),
      emailAddress: new UntypedFormControl(
        null,
        [],
        [this.emailService.validate()],
      ),
      gender: new UntypedFormControl(null, [
        SchoolDashboardValidators.autocompleteValueValidator(),
      ]),
      schoolClassName: new UntypedFormControl(null, [
        SchoolDashboardValidators.autocompleteValueValidator(),
      ]),
      schoolLevel: new UntypedFormControl(null, [
        SchoolDashboardValidators.autocompleteValueValidator(),
      ]),
      year: new UntypedFormControl(null, [
        SchoolDashboardValidators.autocompleteValueValidator(),
      ]),
      description: new UntypedFormControl(null),
    });
  }

  /**
   * Create a filter group for the schoolClassName autocomplete chip control.
   */
  private createSchoolClassFilterGroup(): AutoCompleteFilterGroup {
    return new AutoCompleteFilterGroup(
      'schoolClassName',
      this.locationsStoreService.active$.pipe(
        filter((active) => !!active),
        switchMap((active) =>
          this.schoolClassApi.getSchoolClasses(active?.getIri() as string),
        ),
        map((results) =>
          results.map(
            (schoolClass) =>
              new Filter(schoolClass.name, {
                label: schoolClass.name,
                additionalData: schoolClass,
              }),
          ),
        ),
      ) as never,
      {
        label: 'Klas',
        multiple: false,
        autoCompletePlaceholder: 'Geef de klas op',
        allowUnlisted: true,
      },
    );
  }

  /**
   * Create a filter group for the level autocomplete chip control.
   */
  private createLevelFilterGroup(): AutoCompleteFilterGroup {
    return new AutoCompleteFilterGroup(
      'schoolLevel',
      Object.entries(SchoolLevel)
        .filter(([key]) => {
          switch (key) {
            case SchoolLevel.UNKNOWN:
            case SchoolLevel.ANY:
            case SchoolLevel.PO:
            case SchoolLevel.MBO:
            case SchoolLevel.HBO:
            case SchoolLevel.WO:
            case SchoolLevel.VMBO:
              return false;
            default:
              return true;
          }
        })
        .map(
          ([key, value]) =>
            new Filter(key, { label: SchoolLevelDisplayValue[value] }),
        ),
      {
        label: 'Niveau',
        multiple: false,
        autoCompletePlaceholder: 'Selecteer een niveau',
      },
    );
  }

  /**
   * Create a filter group for the year autocomplete chip control.
   */
  private createYearFilterGroup(): AutoCompleteFilterGroup {
    const filters: Filter[] = []
      .constructor(6)
      .fill(undefined)
      .map(
        (_: undefined, index: number) =>
          new Filter(index + 1, { label: String(index + 1).toString() }),
      );

    return new AutoCompleteFilterGroup('year', filters, {
      label: 'Leerjaar',
      multiple: false,
      autoCompletePlaceholder: 'Selecteer een leerjaar',
    });
  }

  /**
   * Creates a trigger that listens to the schoolLevel input and limits the
   * amount of visible years to choose from.
   */
  private createLevelTrigger(): (data: Trigger) => void {
    return (data: Trigger): void => {
      const { triggeringFilterGroup, allFilterGroups } = data;
      if (
        triggeringFilterGroup.value !== 'schoolLevel'
        || !triggeringFilterGroup.selected.length
      ) {
        return;
      }

      const selected: Filter = triggeringFilterGroup.selected[0];
      const maxYears: number = maxYearForLevel(selected.value as SchoolLevel);
      const yearsGroup: FilterGroup = allFilterGroups.get(
        'year',
      ) as FilterGroup;
      const active: number | undefined = yearsGroup.selected[0]
        ?.value as number;
      yearsGroup.filters = []
        .constructor(maxYears)
        .fill(undefined)
        .map(
          (_: undefined, index: number) =>
            new Filter(index + 1, { label: String(index + 1).toString() }),
        );

      if (active && active <= maxYears) {
        yearsGroup.select(active);
      }
    };
  }

  /**
   * Observe validity and loading state of the EmailAddressService and determine
   * which message to show or action to take.
   */
  private createEmailAddressValidationSubscriber(): void {
    combineLatest([
      this.emailService.validity$.pipe(distinctUntilChanged()),
      this.emailService.loading$.pipe(distinctUntilChanged()),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([state, loading]) => {
        this.checkingEmail$.next(loading);
        this.createEmailValidityMessage(state as EmailValidityType);
      });
  }

  /**
   * Update the form if an active pupil has been loaded in.
   */
  private createActivePupilSubscriber(): void {
    this.pupilModalService.getPupil$(this.activeIri, false)
      .pipe(
        takeUntil(this.destroy$),
        map((pupil) => {
          this.pupil = pupil;
          this.activeForm.setValue(
            {
              firstName: pupil?.person?.firstName,
              lastNamePrefix: pupil?.person?.prefix,
              lastName: pupil?.person?.lastName,
              emailAddress: pupil?.person?.emailAddress?.value,
              gender: pupil?.person?.gender,
              schoolClassName: pupil?.classes[0] ?? null,
              schoolLevel: pupil?.levels[0] ?? null,
              year: pupil?.years[0] ?? null,
              description: pupil?.description,
            },
            { emitEvent: false },
          );

          this.activeForm.get('emailAddress')?.setAsyncValidators(
            this.emailService.validate(pupil?.person?.emailAddress?.value),
          );
        }),
        debounceTime(100),
      )
      .subscribe(() => {
        this.activeForm.get('emailAddress')?.updateValueAndValidity();
        this.activeForm.updateValueAndValidity();
        this.activeForm.markAsPristine();
      });
  }

  /**
   * Combine the class/schoolLevel/year FormControl elements and check if the
   * schoolClass matches the year and/or level selections.
   */
  private createSchoolClassLevelYearSubscriber(): void {
    const classCtrl: UntypedFormControl = this.activeForm.get('schoolClassName') as UntypedFormControl;
    const levelCtrl: UntypedFormControl = this.activeForm.get('schoolLevel') as UntypedFormControl;
    const yearCtrl: UntypedFormControl = this.activeForm.get('year') as UntypedFormControl;

    combineLatest([
      classCtrl.valueChanges.pipe(startWith(classCtrl.value as string)),
      levelCtrl.valueChanges.pipe(startWith(levelCtrl.value as string)),
      yearCtrl.valueChanges.pipe(startWith(yearCtrl.value as number)),
    ])
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.responseService.clearMessage(ResponseMessageKey.PUPIL_CREATE_UPDATE_LEVEL_YEAR),
        ),
        debounceTime(100),
        filter(([schoolClass]) => !!schoolClass),
      )
      .subscribe(([schoolClass]) => {
        const filters: Filter[] = schoolClass.filters ?? [];
        if (filters[0]?.additionalData.unlisted) {
          this.createCustomSchoolClassMessage(filters[0].value as string);
          return;
        }

        const [validLevel, validYear] = this.validClassLevelYearCombination(filters[0], true) as boolean[];
        if (validLevel && validYear) {
          this.responseService.clearMessage(ResponseMessageKey.PUPIL_CREATE_UPDATE_SCHOOL_CLASS);
        } else {
          this.createSchoolClassErrorMessage(validLevel, validYear);
        }
      });
  }

  /**
   * Subscribe to the value changes of the schoolClass FormControl to update the
   * year and level controls with the schoolClass values, but only if they have
   * no value already.
   */
  private createSchoolClassSubscriber(): void {
    const classCtrl: UntypedFormControl = this.activeForm.get(
      'schoolClassName',
    ) as UntypedFormControl;
    classCtrl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((schoolClass) => !!schoolClass),
        debounceTime(100),
      )
      .subscribe((schoolClass) => {
        const filters: Filter[] = schoolClass.filters ?? [];

        if (
          filters[0]?.additionalData.unlisted
          || !filters[0]?.additionalData
        ) {
          return;
        }

        this.patchLevelYearFromSchoolClass(filters[0].additionalData);
      });
  }

  /**
   * Get the raw value of the form if it has not been cast as a FilterValue yet,
   * or return the first FilterValue, since neither Year nor Level are set to
   * multiple.
   */
  private getValueFromControl(name: string): string | number | FilterValue {
    const control: UntypedFormControl = this.activeForm.get(
      name,
    ) as UntypedFormControl;
    const controlValue: string | number | FilterValue = control.value;

    if (typeof controlValue === 'object' && controlValue !== null) {
      const ctrlFilter: Filter = ((controlValue as FilterValue).filters
        ?? [])[0] as Filter;
      return ctrlFilter?.value as string | number;
    }

    return controlValue;
  }

  /**
   * Update the level and/or year values if their values are not set.
   */
  private patchLevelYearFromSchoolClass(
    schoolClass: SchoolClassExtended,
  ): void {
    const { schoolLevel, year } = (schoolClass?.levelYears ?? [])[0] ?? {};
    const levelValue: string = this.getValueFromControl(
      'schoolLevel',
    ) as string;
    const yearValue: number = this.getValueFromControl('year') as number;
    const levelFallback: TtAutocompleteChipControl = {
      values: levelValue ? [levelValue] : [],
      filters: [],
    };
    const yearFallback: TtAutocompleteChipControl = {
      values: yearValue ? [yearValue.toString()] : [],
      filters: [],
    };

    this.activeForm.patchValue({
      schoolLevel: schoolLevel ?? levelFallback,
      year: year ?? yearFallback,
    });
  }

  /**
   * Create a specific error or warning message to the end user, based on the
   * email-address validity.
   */
  //eslint-disable-next-line max-lines-per-function
  private createEmailValidityMessage(validity: EmailValidityType): void {
    if (validity === EmailValidityType.VALID) {
      this.responseService.clearMessage(
        ResponseMessageKey.PUPIL_CREATE_UPDATE_EMAIL,
      );
      return;
    }

    const message: ResponseMessage = new ResponseMessage();
    message.key = ResponseMessageKey.PUPIL_CREATE_UPDATE_EMAIL;

    switch (validity) {
      case EmailValidityType.INVALID:
        message.type = MessageColor.DANGER;
        message.message = 'Het opgegeven e-mailadres is niet geldig';
        break;
      case EmailValidityType.IN_USE:
        message.type = MessageColor.WARNING;
        message.message = [
          {
            value:
              'Deze leerling staat al ingeschreven voor een andere school met dit e-mailadres.',
            display: MarkupDisplay.BLOCK,
            styleBold: true,
          },
          { value: 'Neem contact op met uw contactpersoon van Lyceo.' },
        ];
        break;
      case EmailValidityType.IN_SAME:
        message.type = MessageColor.DANGER;
        message.message = 'Het ingevoerde e-mailadres wordt al gebruikt op'
          + ' deze locatie. Gebruik een ander e-mailadres.';
        break;
      case EmailValidityType.OTHER:
        message.type = MessageColor.DANGER;
        message.message = 'Er is een fout opgetreden met dit e-mailadres.';
        break;
    }

    this.responseService.setMessage(message);
  }

  /**
   * Create the properly formatted warning message for when the schoolClass
   * selection doesn't have the same year and/or level as present in the values
   * of those form controls.
   */
  private createSchoolClassErrorMessage(
    validLevel: boolean,
    validYear: boolean,
  ): void {
    const levelText: ResponseMessageMarkup | undefined = validLevel ? undefined : { value: 'niveau', styleBold: true };
    const yearText: ResponseMessageMarkup | undefined = validYear ? undefined : { value: 'leerjaar', styleBold: true };
    const separator: ResponseMessageMarkup | undefined = levelText && yearText ? { value: 'en' } : undefined;
    const additionalText: ResponseMessageMarkup[] = [
      levelText,
      separator,
      yearText,
    ].filter((item) => !!item) as ResponseMessageMarkup[];

    this.responseService.setMessage(
      ResponseMessageKey.PUPIL_CREATE_UPDATE_SCHOOL_CLASS,
      MessageColor.WARNING,
      [
        { value: 'De geselecteerde klas heeft een ander' },
        ...additionalText,
        {
          value:
            'dan geselecteerd. Bij het opslaan van de leerling wordt het nieuwe',
        },
        ...additionalText,
        { value: 'aan de klas toegevoegd.' },
      ],
    );
  }

  /**
   * If a custom, unlisted class name is inputted by the used, this message will
   * be put at the top of the form.
   */
  private createCustomSchoolClassMessage(name: string): void {
    const message: ResponseMessageMarkup[] = [
      { value: 'De klas' },
      { value: name, styleBold: true },
      {
        value:
          'zal worden toegevoegd met het geselecteerde leerjaar'
          + ' en niveau bij het opslaan van de leerling.',
      },
    ];

    this.responseService.setMessage(
      ResponseMessageKey.PUPIL_CREATE_UPDATE_SCHOOL_CLASS,
      MessageColor.INFO,
      message,
    );
  }

  /**
   * Extract specific backend error messages and create a translated string for
   * the end-user.
   */
  private extractError(response: HttpErrorResponse): void {
    const levelValue: string = this.getValueFromControl(
      'schoolLevel',
    ) as string;
    const yearValue: number = this.getValueFromControl('year') as number;
    const extracted: ResponseMessage = this.apiErrors.extractError(response, {
      schoolLevel: levelValue,
      year: yearValue,
    });
    this.responseService.setMessage(extracted);
  }
}


import { PersonType, SchoolLevel } from '@school-dashboard/enums';
import { denormalize, JsonLd, TsRange } from '@techniek-team/class-transformer';
import { Fetch } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Exclude, Expose, Type } from 'class-transformer';
import { LocationMemberPhoto } from './location-member-photo.model';
import { LocationModel } from './location.model';
import { PersonPupil } from './person-pupil.model';

export class LocationMember extends JsonLd {
  /**
   * Person instance containing pupil property.
   */
  @Type(() => PersonPupil)
  @Expose()
  public person: PersonPupil;

  /**
   * Generic short location modal holding the name of the location.
   */
  @Type(() => LocationModel)
  @Expose()
  public location: LocationModel;

  /**
   * Type of Person model.
   */
  @Expose() public type: PersonType;

  /**
   * Validity range for this location member.
   */
  @Type(() => TsRange)
  @Expose()
  public validityRange: TsRange;

  /**
   * Simple getter to allow dynamic table building, since nested properties are
   * harder to access on a template if you don't access it directly.
   */
  @Exclude() public get fullName(): string {
    return this.person?.fullName;
  }

  /**
   * Get the first schoolClassName for a pupil. Since accessing this nested property
   * dynamically on the template is somewhat harder, let's use a simple getter.
   */
  @Exclude() public get schoolClassName(): string {
    return this.person?.pupil?.classes[0];
  }

  @Exclude() public get level(): SchoolLevel {
    return this.person?.pupil?.levels[0];
  }

  @Exclude() public get year(): number {
    return this.person?.pupil.years[0];
  }

  @Exclude() public get isCoordinator(): boolean {
    return this.type === PersonType.COORDINATOR;
  }

  @Exclude() public get isSchoolContact(): boolean {
    return this.type === PersonType.SCHOOL_CONTACT;
  }

  @Exclude() public get isSalesManager(): boolean {
    return this.type === PersonType.SALES_MANAGER;
  }

  @Exclude() public get isAccountManager(): boolean {
    return this.type === PersonType.ACCOUNT_MANAGER;
  }

  @Exclude() public get isLocationManager(): boolean {
    return this.type === PersonType.LOCATION_MANAGER;
  }

  @Exclude() public get helpText(): string {
    switch (true) {
      case this.isSalesManager:
        return 'contractafspraken en klantrelaties';
      case this.isAccountManager:
        return 'planning, communicatie, aanmeldingen en facturatie';
      case this.isLocationManager:
        return 'de dagelijkse operatie en roostering';
      default:
        return '';
    }
  }

  //eslint-disable-next-line new-cap
  @Fetch(
    () => LocationMemberPhoto,
    denormalize(LocationMemberPhoto, { photoUrl: null }),
  )
  @Expose()
  public profilePhoto: FetchObservable<LocationMemberPhoto>;
}

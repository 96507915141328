import { LyceoUser } from '@techniek-team/oauth';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocationMember } from './location-member.model';

export class UserModel extends LyceoUser {
  private currentRolesOnCurrentLocation$ = new BehaviorSubject<LocationMember[] | undefined>(undefined);

  public isSchoolContactForCurrentLocation: boolean = false;

  public isCoordinatorForCurrentLocation: boolean = false;

  public set rolesOnCurrentLocation(locationMembers: LocationMember[]) {
    if (!locationMembers) {
      locationMembers = [];
    }

    this.currentRolesOnCurrentLocation$.next(locationMembers);
    this.isSchoolContactForCurrentLocation = locationMembers.filter((member: LocationMember) => member.isSchoolContact)
      .length > 0;

    const coordinatorsForCurrentLocation: LocationMember[] = locationMembers.filter((member: LocationMember) => {
      return member.isCoordinator;
    });

    this.isCoordinatorForCurrentLocation = coordinatorsForCurrentLocation.length > 0;
  }

  public get rolesOnCurrentLocation(): LocationMember[] | undefined {
    return this.currentRolesOnCurrentLocation$.getValue();
  }

  public get rolesOnCurrentLocation$(): Observable<LocationMember[] | undefined> {
    return this.currentRolesOnCurrentLocation$.asObservable();
  }
}

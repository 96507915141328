import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { SimpleFilterApiInterface } from '../../interfaces/filter-api/simple-filter-api.interface';
import { BaseFilterGroup, BaseFilterGroupOptions, FilterGroupInteractionState } from '../base-filter-group.model';
import { Filter } from './filter.model';

export interface FilterGroupOptions extends BaseFilterGroupOptions {
}

/* eslint-disable max-len */
/**
 * This model is needed because of a missing feature in JavaScript ES6.
 * It is not possible to give a default value to a class variable of the
 * child class instance which in being used in the constructor of the base class.
 * (In this case through overloading.)
 *
 * See https://stackoverflow.com/questions/43595943/why-are-derived-class-property-values-not-seen-in-the-base-class-constructor
 * for more information.
 *
 * @inheritDoc
 */
/* eslint-enable max-len */
export class FilterGroup extends BaseFilterGroup {
  constructor(
    value: string,
    filters?: Observable<Filter[]> | Filter[] | SimpleFilterApiInterface,
    options?: FilterGroupOptions,
  ) {
    super();
    this.key = window.crypto.randomUUID();
    this.value = value;
    this.label = options?.label;
    this.position = options?.position;
    this.selection = new SelectionModel<string>(options?.multiple ?? false);
    this.selectableStyle = options?.selectableStyle ?? this.selectableStyle;
    this.interactionState.next(
      //eslint-disable-next-line max-len
      (typeof options?.disabled === 'boolean' && options?.disabled) ? FilterGroupInteractionState.DISABLED : FilterGroupInteractionState.ENABLED,
    );
    this.setFilters(filters ?? []);
    this.createLookupListObserver();
  }
}

import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MessageColor, ResourceToTrackType, SupportedTrackTypes, TrackType } from '@school-dashboard/enums';
import { ExamTrainingTrack, isTrackWithPupils, Track } from '@school-dashboard/models';
import { Resource } from '@techniek-team/api-platform';
import { isFuture, parseISO } from 'date-fns';
import { GetTrackResponse, PurchaseCountInterface } from '../../../api/track/track.response';
import { serializeTrack } from '../../../api/track/track.serializer';
import { IndicatorBar } from '../../../shared/components/amount-indicator-bars/indicator-bar.interface';
import { IndicatorMessageType } from '../../../shared/components/amount-indicator-bars/indicator-message-type.enum';
import { selectTrackId, trackAdapter, trackFeatureKey, TrackState } from './track.reducer';

export class TrackSelectors {
  public static readonly getTrackState = createFeatureSelector<TrackState>(trackFeatureKey);

  public static readonly selectLoading = createSelector(
    TrackSelectors.getTrackState,
    (state) => state.loading,
  );

  public static readonly selectLoaded = createSelector(
    TrackSelectors.getTrackState,
    (state) => state.loaded,
  );

  public static readonly containsOnlyUpcomingTracks = createSelector(
    TrackSelectors.getTrackState,
    (state) => state.onlyUpcomingTracks ?? undefined,
  );

  public static readonly onlyUpcomingIsInitialized = createSelector(
    TrackSelectors.getTrackState,
    (state) => state.loaded || state.loading,
  );

  public static readonly allTracksIsInitialized = createSelector(
    TrackSelectors.getTrackState,
    TrackSelectors.containsOnlyUpcomingTracks,
    (state, hasOnlyUpcomingTracksInitialized) => (state.loaded || state.loading) && !hasOnlyUpcomingTracksInitialized,
  );

  public static readonly selectSaving = createSelector(
    TrackSelectors.getTrackState,
    (state) => state.saving,
  );

  public static readonly selectError = createSelector(
    TrackSelectors.getTrackState,
    (state) => state.error,
  );

  public static readonly selectOnlyUpcomingTracks = createSelector(
    TrackSelectors.getTrackState,
    (state) => state.onlyUpcomingTracks ?? undefined,
  );

  public static readonly selectAll = createSelector(
    TrackSelectors.getTrackState,
    (state) => {
      return trackAdapter.getSelectors().selectAll(state);
    },
  );

  public static readonly selectAllUpcomingTracks = createSelector(
    TrackSelectors.selectAll,
    (tracks) => {
      return tracks.filter(track => {
        return isFuture(parseISO(track.validityRange.start));
      });
    },
  );

  public static readonly selectMainTracks = createSelector(
    TrackSelectors.selectAll,
    (tracks) => {
      return tracks.filter((track) => track.parentTrack === undefined);
    },
  );

  //eslint-disable-next-line max-len
  public static readonly selectExamTrainingPurchaseCountsDictionary = createSelector(
    TrackSelectors.getTrackState,
    (state) => {
      return state.examenTrainingPurchaseCount.entities;
    },
  );

  public static readonly selectProgressBarDataForTracks = createSelector(
    TrackSelectors.selectAll,
    TrackSelectors.selectExamTrainingPurchaseCountsDictionary,
    (rawTracks, examTrainingPurchaseCounts) => {
      const tracks: Track[] = serializeTrack(rawTracks);
      const indicatorBarDataDict: Dictionary<IndicatorBar> = {};

      for (const track of tracks) {
        const indicatorBar: IndicatorBar = {
          maximum: isTrackWithPupils(track) ? track.maxPupils : 0,
          minimum: track.minimumTrackAttendances,
          label: undefined,
          streams: [{ amount: track.currentPupils }],
        };

        if (track instanceof ExamTrainingTrack) {
          const purchases: PurchaseCountInterface = examTrainingPurchaseCounts[track.getId()]
            ?? ({
              track: track.getIri(),
              purchaseCount: 0,
              totalCount: 0,
            } as never);
          indicatorBar.streams = [{ amount: purchases.purchaseCount }];

          indicatorBar.message = {
            content: `Minimumaantal van ${track.minimumTrackAttendances} nog niet bereikt.`,
            color: MessageColor.WARNING,
            when: IndicatorMessageType.UNDER_MIN,
          };
        }

        indicatorBarDataDict[track.getId()] = indicatorBar;
      }

      return indicatorBarDataDict;
    },
  );

  public static readonly selectAccumulatedProgressDataForTrack = createSelector(
    TrackSelectors.selectMainTracks,
    TrackSelectors.selectAll,
    TrackSelectors.selectProgressBarDataForTracks,
    (mainTracks, tracks, progressData) => {
      const accumulatedProgressData: Dictionary<IndicatorBar> = {};
      for (let mainTrack of mainTracks) {
        const mainTrackId: string = selectTrackId(mainTrack);
        const subTracks: Resource<GetTrackResponse>[] = tracks.filter(
          (item) => {
            return item.parentTrack?.['@id'] === mainTrack['@id'];
          },
        );

        let newProgressData: IndicatorBar = {
          maximum: progressData[mainTrackId]?.maximum ?? 0,
          minimum: progressData[mainTrackId]?.minimum ?? 0,
          streams: progressData[mainTrackId]?.streams ?? [{ amount: 0 }],
          label: progressData[mainTrackId]?.label,
          message: progressData[mainTrackId]?.message,
        };
        for (let subTrack of subTracks) {
          const subTrackId: string = selectTrackId(subTrack);
          newProgressData.maximum += progressData[subTrackId]?.maximum ?? 0;
          newProgressData.streams = [
            {
              amount:
                newProgressData.streams[0].amount
                + (progressData[subTrackId].streams?.[0].amount ?? 0),
            },
          ];
        }
        accumulatedProgressData[mainTrackId] = newProgressData;
      }
      return accumulatedProgressData;
    },
  );

  public static readonly selectPossibleUpsellTrackTypes = createSelector(
    TrackSelectors.selectLoaded,
    TrackSelectors.selectAll,
    (loaded, tracks) => {
      if (!loaded) {
        return [];
      }

      let supportedTrackTypes: TrackType[] = Object.values(SupportedTrackTypes);

      for (let track of tracks) {
        supportedTrackTypes = Object.values(SupportedTrackTypes).filter(
          (supportedTrackType) => {
            return supportedTrackType !== ResourceToTrackType[track['@type']];
          },
        );
      }

      return supportedTrackTypes;
    },
  );

  public static readonly getTrackEntities = createSelector(
    TrackSelectors.getTrackState,
    (state) => {
      return trackAdapter.getSelectors().selectEntities(state);
    },
  );

  public static readonly getActiveTrackId = createSelector(
    TrackSelectors.getTrackState,
    (state) => state.activeTrack.id,
  );

  public static readonly selectActiveTrack = createSelector(
    TrackSelectors.getTrackEntities,
    TrackSelectors.getActiveTrackId,
    (entities, selectedId) => {
      return selectedId ? (entities[selectedId] as Resource<GetTrackResponse>) : undefined;
    },
  );

  public static readonly activeTrackHasPupils = createSelector(
    TrackSelectors.selectActiveTrack,
    (active) => {
      return active && 'maxPupils' in active;
    },
  );

  public static readonly getSubTracksByMainTrackId = createSelector(
    TrackSelectors.selectAll,
    TrackSelectors.getActiveTrackId,
    (entities, selectedId) => {
      if (!selectedId || entities.length === 0) {
        return null;
      }

      return entities
        .filter((track) => !!track.parentTrack)
        .filter((track) => selectTrackId(track.parentTrack) === selectedId);
    },
  );
}

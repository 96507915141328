import { SelectionChange } from '@angular/cdk/collections';
import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TtDataSourceSelectAction, TtDataSourceSelectActionType } from '@techniek-team/datasource';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SelectSource } from '../../services/datasource/datasource.service';
import { ResponseMessageMarkup } from '../response-message/response-message.model';

export interface TtDataSourceSelectActionCorrect {
  label: string;
  value: string;
  state: TtDataSourceSelectActionType;
  icon?: IconProp;
}

@Component({
  selector: 'app-table-select-actions',
  templateUrl: './table-select-actions.component.html',
  styleUrls: ['./table-select-actions.component.scss'],
})
export class TableSelectActionsComponent {
  /**
   * Setter for the DataSource based on the SelectSource type, one that extends
   * the {@see TtTableDataSource} and implements the abstract function
   * requirements for selection, which are needed to fill the observables in
   * this component.
   */
  @Input() public set dataSource(source: SelectSource | undefined | null) {
    if (!source) {
      return;
    }
    this.count$ = this.createTableCountObservable(source);
    this.label$ = source.createSelectionLabelObserver() as Observable<ResponseMessageMarkup[]>;
    this._dataSource = source as SelectSource;
    this.actions$ = source.createSelectionActionsObserver() as Observable<TtDataSourceSelectActionCorrect[]>;
  }

  /**
   * Getter for the dataSource, so the actions can be handled properly.
   */

  /**
   * Private store for the SelectSource.
   */
  //eslint-disable-next-line @typescript-eslint/naming-convention
  public _dataSource: SelectSource;

  /**
   * State of the amount of selected data.
   */
  public count$: Observable<number>;

  /**
   * The label to display above the actions, based on the ResponseMessageMarkup
   * format.
   */
  public label$: Observable<ResponseMessageMarkup[]>;

  /**
   * List of actions to display.
   */
  public actions$: Observable<TtDataSourceSelectActionCorrect[]>;

  /**
   * Enum pass-through for template matching cases.
   */
  public readonly SelectActionType: typeof TtDataSourceSelectActionType = TtDataSourceSelectActionType;


  /**
   * Create an observer that returns the amount of selected data.
   */
  private createTableCountObservable(source: SelectSource): Observable<number> {
    return source.selection.changed.pipe(
      startWith({ added: [], removed: [], source: source.selection } as SelectionChange<unknown>),
      map((selection) => {
        return selection.source.selected.length;
      }),
    );
  }

  public handleSelectionAction(action: TtDataSourceSelectActionCorrect): void {
    this._dataSource.handleSelectionAction(action as TtDataSourceSelectAction);
  }
}

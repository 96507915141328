import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { locationsActions, LocationsSelectors } from '@school-dashboard/data-access-locations';
import { ApiPlatformError, denormalize } from '@techniek-team/class-transformer';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { from, of, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SubTrackApi } from '../../../api/sub-track/sub-track.api';
import { TrackApi } from '../../../api/track/track.api';
import { PurchaseCountInterface } from '../../../api/track/track.response';
import { InferEffect } from '../../infer-effect';
import { trackActions } from './track.actions';
import { selectTrackId } from './track.reducer';
import { TrackSelectors } from './track.selectors';

@Injectable()
export class TrackEffects {

  private initTracks$: InferEffect;

  private postSubTrack$: InferEffect;

  private postSubTrackSuccess$: InferEffect;

  private postSubTrackFailure$: InferEffect;

  private initTrackFailure$: InferEffect;

  private loadActiveTrack$: InferEffect;

  private loadActiveTrackFailure$: InferEffect;

  private purchaseCountForExamTraining$: InferEffect;

  constructor(
    private readonly actions$: Actions,
    private store: Store,
    private trackApi: TrackApi,
    private subTrackApi: SubTrackApi,
    private errorHandler: SentryErrorHandler,
    private toastService: ToastService,
  ) {
    this.initTracks$ = this.createInitTracksEffect();
    this.postSubTrack$ = this.createPostSubTrackEffect();
    this.postSubTrackSuccess$ = this.createPostSubTrackSuccessEffect();
    this.postSubTrackFailure$ = this.createPostSubTrackFailureEffect();
    this.initTrackFailure$ = this.createInitTrackFailureEffect();
    this.loadActiveTrack$ = this.createLoadActiveTrackEffect();
    this.loadActiveTrackFailure$ = this.createLoadActiveTrackFailureEffect();
    this.purchaseCountForExamTraining$ = this.createPurchaseCountForExamTrainingEffect();
  }

  public createInitTracksEffect(): InferEffect {
    return createEffect(
      () => this.actions$.pipe(
        ofType(
          trackActions.initTracks,
          trackActions.refreshTracks,
          locationsActions.selectLocation,
        ),
        concatLatestFrom(() => this.store.select(LocationsSelectors.activeLocation).pipe(isDefined())),
        concatLatestFrom(() => this.store.select(TrackSelectors.containsOnlyUpcomingTracks)),
        switchMap(([[action, location], onlyUpcoming]) => this.trackApi.getTracksStore(
          location['@id'],
          ('onlyUpcomingTracks' in action) ? action.onlyUpcomingTracks : onlyUpcoming,
        )),
        map((result) => trackActions.loadTracksSuccess(
          { tracks: result },
        )),
        catchError(error => of(trackActions.loadTracksFailure({ error: error }))),
      ));
  }

  public createInitTrackFailureEffect(): InferEffect {
    return createEffect(() => this.actions$.pipe(
      ofType(
        trackActions.loadTracksFailure,
        trackActions.loadPurchaseCountForExamTrainingFailure,
      ),
      tap(action => {
        return Promise.all([
          this.errorHandler.captureError(action.error),
          this.toastService.error({
            message: 'Er is iets misgegaan bij het ophalen van de trajecten.',
            duration: 10000,
            buttons: [{ text: 'Sluiten', role: 'cancel' }],
          }),
        ]);
      }),
    ), { dispatch: false });
  }

  public createPostSubTrackEffect(): InferEffect {
    return createEffect(
      () => this.actions$.pipe(
        ofType(trackActions.addNewSubTrack),
        switchMap(action => this.subTrackApi.postSubTrack(action.request)),
        map((result) => trackActions.addNewSubTrackSuccess(
          { newTrack: result },
        )),
        catchError(error => of(trackActions.addNewSubTrackFailure({ error: error }))),
      ));
  }

  public createPostSubTrackFailureEffect(): InferEffect {
    return createEffect(() => this.actions$.pipe(
      ofType(trackActions.addNewSubTrackFailure),
      tap((action) => {
        const message: string = this.composeErrorMessage(
          action.error as HttpErrorResponse,
          'Er is iets misgegaan bij het aanmaken van het deeltraject.',
        );

        return Promise.all([
          this.errorHandler.captureError(action.error),
          this.toastService.error({
            message: message,
            duration: 10000,
            buttons: [{ text: 'Sluiten', role: 'cancel' }],
          }),
        ]);
      }),
    ), { dispatch: false });
  }

  public createPostSubTrackSuccessEffect(): InferEffect {
    return createEffect(() => this.actions$.pipe(
      ofType(trackActions.addNewSubTrackSuccess),
      tap(() => {
        return this.toastService.create({
          message: 'Deeltraject is succesvol aangemaakt.',
          duration: 3000,
          buttons: [{ text: 'Sluiten', role: 'cancel' }],
        });
      }),
    ), { dispatch: false });
  }

  public createLoadActiveTrackEffect(): InferEffect {
    return createEffect(
      () => this.actions$.pipe(
        ofType(
          trackActions.refreshActiveTrack,
        ),
        concatLatestFrom(() => [
          this.store.select(TrackSelectors.getActiveTrackId),
        ]),
        switchMap(([_, track]) => this.trackApi.getTrackStore(track)),
        map((result) => trackActions.loadActiveTrackSuccess(
          { track: result },
        )),
        catchError(error => of(trackActions.loadActiveTrackFailure({ error: error }))),
      ));
  }

  public createLoadActiveTrackFailureEffect(): InferEffect {
    return createEffect(() => this.actions$.pipe(
      ofType(trackActions.loadActiveTrackFailure),
      tap((action) => {
        return Promise.all([
          this.errorHandler.captureError(action.error),
          this.toastService.error({
            message: 'Er is iets misgegaan bij het inladen van het traject.',
            duration: 10000,
            buttons: [{ text: 'Sluiten', role: 'cancel' }],
          }),
        ]);
      }),
    ), { dispatch: false });
  }

  public createPurchaseCountForExamTrainingEffect(): InferEffect {
    return createEffect(() => this.actions$.pipe(
      ofType(
        trackActions.loadTracksSuccess,
      ),
      map(({ tracks }) => tracks.filter(track => track['@type'] === 'ExamTrainingTrack')),
      switchMap(examTracks => {
        const purchaseCountPromises: Promise<PurchaseCountInterface>[] = [];
        for (let track of examTracks) {
          purchaseCountPromises.push(firstEmitFrom(
            this.trackApi.getPurchaseCountExamTrainingTrackTotalStore(selectTrackId(track)),
          ));
        }
        return from(Promise.all(purchaseCountPromises));
      }),
      map(result => trackActions.loadPurchaseCountForExamTrainingSuccess({ purchaseCounts: result })),
      catchError(error => of(trackActions.loadPurchaseCountForExamTrainingFailure({ error: error }))),
    ));
  }

  private composeErrorMessage(response: HttpErrorResponse, baseMessage: string): string {
    const apiPlatformError: ApiPlatformError = this.getApiPlatformError(response);
    if (apiPlatformError.description !== '') {
      return baseMessage + ` (${apiPlatformError.description})`;
    }

    return baseMessage;
  }

  /**
   * Convert the HttpErrorResponse to an ApiPlatformError.
   */
  private getApiPlatformError(response: HttpErrorResponse): ApiPlatformError | null {
    if (!response?.error) {
      return null;
    }

    const parsed: ApiPlatformError = denormalize(ApiPlatformError, response.error as object);
    // In case of a non API-platform error we might not have a description.
    parsed.description = parsed.description ?? '';

    return parsed;
  }
}
